import Logger from 'js-logger';

import { uniq, compact } from 'lodash';

import { Messager } from 'helioscope/app/utilities/ng';
import { humanizeWatts } from 'helioscope/app/utilities/formatting';
import { Design } from 'helioscope/app/designer/Design';
import { downloadSLD } from 'helioscope/app/singleline/async/download_sld';

import {
    createModuleFileUploader,
    createDeviceFileUploader,
    ModuleFile, PowerDeviceFile,
} from './resources';

const logger = Logger.get('document_set');

export class DocumentSetCtrl {
    constructor(designs, $scope) {
        'ngInject';

        this.$scope = $scope;

        this.validDesigns = designs.filter(des => des.hasComponents());
        this.primaryDesign = _.first(this.validDesigns);
        this.setPrimaryDesign(this.primaryDesign);

        this.moduleUploaders = {};
        this.deviceUploaders = {};
    }

    async setPrimaryDesign({ design_id } = this.primaryDesign) { // eslint-disable-line camelcase
        const design = await Design.get({ design_id }).$promise;

        this.modules = compact(uniq(design.field_segments.map(fs =>
            (fs.module_characterization ? fs.module_characterization.module : null))));
        this.devices = compact(uniq(design.wiring_zones.map(wz => wz.inverter)));
        this.devices.push(...compact(uniq(design.wiring_zones.map(wz => wz.power_optimizer))));

        ModuleFile.query({ module_ids: this.modules.map(mod => mod.module_id) });
        PowerDeviceFile.query({ power_device_ids: this.devices.map(dev => dev.power_device_id) });


        if (!this.$scope.$$phase) { // async functions don't trigger a digest
            this.$scope.$apply();
        }
    }

    designName(design) {
        return `${design.description} ${humanizeWatts(design.field_component_metadata.nameplate)}`;
    }

    async downloadSLD() {
        await downloadSLD(this.primaryDesign.design_id);
    }

    getModuleFileUploader(moduleId) {
        let moduleUploader = this.moduleUploaders[moduleId];

        if (!moduleUploader) {
            this.moduleUploaders[moduleId] = createModuleFileUploader(moduleId);
            moduleUploader = this.moduleUploaders[moduleId];
        }

        return moduleUploader;
    }

    async updateFile(file) {
        try {
            await file.$update();
            Messager.success(`Updated ${file.filename}`);
        } catch (err) {
            logger.warn(err);
            Messager.error(`Error updating ${file.filename}`);
        }
    }

    async deleteFile(file) {
        try {
            await file.$delete();
            Messager.info(`Deleted ${file.filename}`);
        } catch (err) {
            logger.warn(err);
            Messager.error(`Error deleting ${file.filename}`);
        }
    }

    getDeviceFileUploader(powerDeviceId) {
        let deviceUploader = this.deviceUploaders[powerDeviceId];

        if (!deviceUploader) {
            this.deviceUploaders[powerDeviceId] = createDeviceFileUploader(powerDeviceId);
            deviceUploader = this.deviceUploaders[powerDeviceId];
        }

        return deviceUploader;
    }
}
