import { user } from 'helioscope/app/users';
import {
    pusher,
    Authenticator,
    $rootScope,
    $state,
    Messager,
} from 'helioscope/app/utilities/ng';
import { helioscopeConfig } from 'helioscope/app/config';
import Logger from 'js-logger';
import Q from 'q';

const logger = Logger.get('app/system_alerts');

class VersionUpdate {
    constructor() {
        this.lastEvent = null;
        $rootScope.$on('$stateChangeStart', (event, toState, params, fromState) => {
            if (fromState.name.startsWith('designer.design')) {
                return;
            }

            if (this.shouldReload) {
                this.shouldReload = false;
                const href = $state.href(toState.name, params);
                window.location.href = href;
            }
        });
    }

    async eventReceived(event) {
        if (event.version === helioscopeConfig.version) {
            logger.debug(`Discarding update notification for ${event.version}`);
            return;
        }

        try {
            const responses = await Q.all(event.prefetch
                .filter(fl => fl.endsWith('.js'))
                .map(file => fetch(file, { mode: 'no-cors' }))
            );

            for (const resp of responses) {
                if (!resp.ok) {
                    logger.error(`Couldn't fetch ${resp.url}`);
                    return;
                }
            }

            this.lastEvent = event;
            this.shouldReload = true;
        } catch (e) {
            logger.error('Couldn\'t fetch update');
        }
    }
}

export function setupSystemAlerts() {
    const versionUpdater = new VersionUpdate();
    const eventHandlers = {
        logout: () => { Authenticator.logout(); },
        reload: () => { window.location.href = window.location.href; },
        navigate_to: (e) => { window.location.href = e.url; },
        version_update: event => versionUpdater.eventReceived(event),
        deploy_alert: ({ version }) => {
            if (!user.is_admin) return;
            const oldVersion = helioscopeConfig.version;
            if (version === oldVersion) {
                logger.debug(`Discarding version update for ${oldVersion}`);
            }
            const url = `https://github.com/aurorasolar/helioscope/compare/${oldVersion}...${version}`;
            Messager.info(`<a href='${url}' target="_blank"> Latest Deploy is Live</a>`,
                {
                    title: 'Admin Alert',
                    icon: 'fa fa-exclamation-triangle',
                    buttons: { // closers don't work for some reason
                        closer: true,
                        closer_hover: false,
                    },
                    delay: 10 * 60 * 1000,
                },
            );
        },
    };

    const handleEvents = event => {
        const handler = eventHandlers[event.event_type];
        if (handler) {
            handler(event);
        }
    };

    pusher(`users@${user.user_id}`).watch('alert', handleEvents);
    pusher('systemAlerts').watch('alert', handleEvents);
    pusher(`version@${helioscopeConfig.version}`).watch('alert', handleEvents);
}
