/* global angular:true */
/* eslint-disable max-len */

angular.module('helioscope.documentation', ['helioscope.documentation.config',
                                            'helioscope.documentation.directives']);

const mod = angular.module('helioscope.documentation.config', ['ui.router', 'helioscope.users.auth']);

mod.config(['$stateProvider', 'auth.accessLevels', function ($stateProvider, accessLevels) {
    $stateProvider.
        state('documentation', {
            url: '/documentation',
            template: "<div ui-view autoscroll='false'></div>",
            abstract: true,
        })
        // TODO: kill this once we grab the original math doc's from latex
        .state('documentation.math', {
            url: '/mathematical-formulation',
            templateUrl: require('helioscope/app/documentation/partials/math.html'),
            resolve: {
                user: ['Authenticator', function (auth) { return auth.authorize(accessLevels.user); }],
            },
        })
}]);
