/*global angular:true, document:true */
(function (document, angular) {
    'use strict';

    function loadScript(url, callback) {
        var script = document.createElement('script');
        script.src = url;
        script.onload = callback;
        script.onerror = function () {
            throw new Error('Error loading "' + url + '"');
        };

        document.getElementsByTagName('head')[0].appendChild(script);
    }


    angular.module('ngMathJax', [])

        .factory('MathJax', ['$rootScope', '$q', '$window', function ($rootScope, $q, $window) {
            var MathJax,
                apiReady = $q.defer(),
                onLoad = function () {
                    $rootScope.$apply(function () {
                        apiReady.resolve();
                        // <script type="text/x-mathjax-config">
                        MathJax = $window.MathJax;
                        MathJax.Hub.Config({
                            TeX: { equationNumbers: { autoNumber: "AMS" }},
                            tex2jax: {inlineMath: [['$', '$'], ['\\(', '\\)']]}
                        });
                    });
                };

            loadScript('https://cdn.mathjax.org/mathjax/latest/MathJax.js?config=TeX-AMS_HTML.js', onLoad);

            function resetEquationNumbers() {
                //https://groups.google.com/forum/#!msg/mathjax-users/kzOOFw1qtxw/YdAEPJfCEXUJ
                var AMS = MathJax.Extension["TeX/AMSmath"];
                AMS.startNumber = 0;
                AMS.labels = {};
            }

            return {
                typeset: function (callback) {
                    callback = callback || angular.noop;

                    apiReady.promise.then(function () {
                        if (MathJax.Hub.queue.pending > 0) {
                            //dont typeset if the queue is active (implies startup)
                            callback();
                            return;
                        }

                        MathJax.Hub.Queue(
                            resetEquationNumbers,
                            ["PreProcess", MathJax.Hub],
                            ["Reprocess", MathJax.Hub],
                            // ["Typeset",MathJax.Hub],
                            callback
                        );

                        // MathJax.Hub.Queue(["Typeset",MathJax.Hub], callback);
                    });
                }
            };

        }]);

}(document, angular));
