/*global angular:true */

(function (angular) {
    "use strict";

    var mod = angular.module('xeditable.extensions', ['xeditable']);


    /**
    * directive to switch content based on ngModel and editable form values
    * allowing forms that change dynamically based on both committed changes
    * in ngModel (when the form is hidden), and staged changes in the form
    * when it is visible
    *
    * to use, pass a reference to a variable on the scope.
    * when the form is not visible, this model value will be used to determine
    * which elements are visible.  When in form mode, the directive will search
    * for and track the form value directly (since changes are isolated from
    * the model  until commit).
    *
    * <span editable-switch="variableOnScope">
    *
    *   <!-- this will be detected and watched when in form mode -->
    *   <div editable-input="variableOnScope"></div>
    *
    *   <!-- these wil track the form/model values -->
    *   <div editable-switch-when="foo">Value is 'foo' in form or model</div>
    *   <div editable-switch-when="bar">Value is 'bar' in form or model</div>
    * </span>
    */
    mod.directive('editableSwitch', function ($filter, $log) {
        var filter = $filter('filter');

        function findEditable(editables, modelName) {
            // Note – this depends on the name being the same as the modelName,
            // if an 'e-name' attribute is set on the element, it will break
            // the automatic search
            return filter(editables, {name: modelName})[0];
        }


        return {
            restrict: 'EA',
            controller: function () {
                var children = [],
                    modelVal,
                    formVal,
                    formVisible;

                this.registerListener = function (target, element) {
                    // target should be either string or a function that
                    // takes one argument and returns true/false
                    children.push({target: target, element: element});
                };

                this.updateView = function (val) {
                    angular.forEach(children, function (child) {
                        var match = false;

                        if (angular.isFunction(child.target)) {
                            match = child.target(val);
                        } else {
                            match  = (child.target === String(val));
                        }

                        if (match) {
                            child.element.show();
                        } else {
                            child.element.hide();
                        }
                    });
                };

                this.setFormVal = function (val) {
                    formVal = val;

                    if (formVisible) {
                        this.updateView(formVal);
                    }
                };

                this.setModelVal = function (val) {
                    modelVal = val;

                    if (!formVisible) {
                        this.updateView(modelVal);
                    }
                };

                this.updateFormVisibility = function (formVis) {
                    formVisible = formVis;
                    this.updateView(formVisible ? formVal : modelVal);
                };
            },
            require: ['^form', 'editableSwitch'],
            link: function (scope, element, attrs, ctrls) {
                var formCtrl = ctrls[0],
                    switchCtrl = ctrls[1],
                    target = attrs.editableSwitch,
                    editable;

                scope.$watch(target, function (val) {
                    switchCtrl.setModelVal(val);
                });

                scope.$watch(formCtrl.$name + '.$visible', function (val) {
                    if (val === true && editable === undefined) {

                        editable = findEditable(formCtrl.$editables, target);
                        if (!editable) {
                            $log.warn("Could not find editable element for", target);
                            return;
                        }

                        editable.scope.$watch('$data', function (formVal) {
                            switchCtrl.setFormVal(formVal);
                        });
                    }

                    switchCtrl.updateFormVisibility(val);
                });
            }
        };
    });

    /**
     * directive to register on any elements within the switch that
     * will be displayed when the target value in the switch statement
     * matches the value for this directive.
     *
     * editable-switch-when="stringToMatch"
     */
    mod.directive('editableSwitchWhen', function () {
        return {
            restrict: 'EA',
            require: '^editableSwitch',
            link: function (scope, element, attrs, switchCtrl) {
                var targetValue = attrs.editableSwitchWhen;
                switchCtrl.registerListener(targetValue, element);
            }
        };
    });

    /**
     * directive to register on any elements within the switch that
     * will be displayed when the target expression in the switch statement
     * evaluates to true.  `$VALUE` in the expression will be replaced with
     * the value being watched (whether in the form or model)
     *
     * editable-switch-when-expression="$VALUE > 0"
     */
    mod.directive('editableSwitchWhenExpression', function () {
        return {
            restrict: 'EA',
            require: '^editableSwitch',
            link: function (scope, element, attrs, switchCtrl) {
                var expression = attrs.editableSwitchWhenExpression;

                function matcher(val) {
                    return scope.$eval(expression.replace('$VALUE', val));
                }

                switchCtrl.registerListener(matcher, element);
            }
        };
    });




    /**
     * directive to tie a given ng-model value into the commit/cancel cycle of
     * angular-xeditable.  the associated value will be restored to a backup
     * whenever the parent form is cancelled or exited, when the form is
     * submitted successfully, the restore point will be moved forward to the
     * current value
     *
     * Use this for form elements that are not supported or badly supported  by
     * angular-xeditable (e.g. ui-select2, ui-datepicker, ui-timepicker)
     */
    mod.directive('editingBackup', function ($timeout, $q) {
        return {
            restrict: 'EA',
            require: ['^form', 'ngModel'],
            link: function (scope, element, attrs, ctrls) {
                $timeout(function () {
                    var formCtrl = ctrls[0],
                        ngModelCtrl = ctrls[1],
                        backup = ngModelCtrl.$modelValue,
                        formOnAfterSave = formCtrl.$onaftersave.bind(formCtrl);

                    function loadBackup() {
                        ngModelCtrl.$setViewValue(backup);
                    }

                    function storeBackup() {
                        backup = ngModelCtrl.$modelValue;
                    }

                    scope.$watch(formCtrl.$name + '.$visible', function (newVal, oldVal) {
                        if (newVal === true && oldVal === false) {
                            storeBackup();
                        } else if (newVal === false && oldVal === true) {
                            loadBackup();
                        }
                    });

                    formCtrl.$onaftersave = function () {
                        // after the form is done saving (which includes successfully processing
                        // an API call, store the current value
                        return $q.when(formOnAfterSave()).then(storeBackup);
                    };

                    scope.$on('$destroy', function () {
                        loadBackup();
                    });
                });
            }
        };
    });

}(angular));
