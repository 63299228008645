/* global Highcharts: true, angular:true, jQuery:true, _:true, navigator:true */

import * as analytics from 'helioscope/app/utilities/analytics';
import * as config from 'helioscope/app/config';
import { $rootScope } from 'helioscope/app/utilities/ng';
import { parseDesignComponents } from './directive_helpers';
import { helioscopeConfig } from 'helioscope/app/config';


const mod = angular.module('helioscope.projects.directives', []);

mod.directive('reportMetadata', (ClientBrowser, Authenticator, Logo) => {
    return {
        restrict: 'EA',
        scope: { project: '=' },
        templateUrl: require('helioscope/app/projects/partials/reports/directives.report_metadata.html'),
        controller($scope, Authenticator, Logo, logoGalleryDlg) {
            const user = Authenticator.user();

            $scope.user = user;

            if (user.default_logo_id) {
                Logo.get({ logo_id: user.default_logo_id });
            }

            $scope.openLogoGallery = function () {
                logoGalleryDlg(user);
            };
        },
    };
});

mod.directive('designSummary', () => {
    return {
        restrict: 'EA',
        scope: { design: '=' },
        templateUrl: require('helioscope/app/projects/partials/designs/directives.summary.html'),
        controller: ['$scope', 'EditDesignDlg', function ($scope, editDesignDlg) {
            $scope.editDesign = function () {
                editDesignDlg($scope.design);
            };
        }],
    };
});

mod.directive('projectMap', () => {
    return {
        restrict: 'EA',
        scope: { project: '=' },
        templateUrl: require('helioscope/app/projects/partials/directives.map.html'),
        link: ($scope) => {
            const project = $scope.project;
            const STATIC_MAPS_URL = 'https://maps.google.com/maps/api/staticmap';
            const llStr = `${project.location.latitude},${project.location.longitude}`;
            $scope.url = `${STATIC_MAPS_URL}?center=${llStr}&markers=${llStr}&zoom=17&size=400x400&maptype=hybrid`;
            $scope.url += `&key=${helioscopeConfig.google_maps_api_key}`;
        },
    };
});

mod.directive('designComponents', () => {
    return {
        restrict: 'EA',
        scope: { design: '=' },
        templateUrl: require('helioscope/app/projects/partials/designs/directives.components.html'),
        link: ($scope) => {
            $scope.$watch('design', (design) => {
                if (design) {
                    $scope.components = parseDesignComponents($scope.design);
                }
            });
        },
    };
});

mod.directive('designSegments', () => {
    return {
        restrict: 'EA',
        scope: { design: '=' },
        templateUrl: require('helioscope/app/projects/partials/designs/directives.segments.html'),
    };
});

mod.directive('designZones', () => {
    return {
        restrict: 'EA',
        scope: { design: '=' },
        templateUrl: require('helioscope/app/projects/partials/designs/directives.zones.html'),
        controller: function wzSummaryCtrl($scope, $log) {

            function checkCombiners(fieldComponentMetadata) {
                const wiringZones = fieldComponentMetadata ? fieldComponentMetadata.wiring_zones : null;

                if (!wiringZones) {
                    return false;
                }

                try {
                    for (const id in wiringZones) {
                        if (wiringZones[id]['combiner'].length) {
                            return true;
                        }
                    }
                } catch (err) {
                    return false;
                }
                return false;
            }

            $scope.wzCombiners = {};
            $scope.wzBounds = {};

            const hasCombiners = checkCombiners($scope.design.field_component_metadata);
            for (const wz of $scope.design.wiring_zones) {
                try {
                    if (hasCombiners && wz.bus_id !== null) {
                        $scope.wzCombiners[wz.wiring_zone_id] = wz.combiner_poles;
                    } else {
                        $scope.wzCombiners[wz.wiring_zone_id] = '-';
                    }

                    $scope.wzBounds[wz.wiring_zone_id] = wz.stringBounds();
                } catch (err) {
                    $scope.wzBounds[wz.wiring_zone_id] = {};
                }
            }
        },
    };
});

mod.directive('scenarioSummary', () => {
    function getUsedComponents(design) {
        return {
            modules: _(design.field_segments).groupBy(fs =>
                (fs.module_characterization ? fs.module_characterization.module_id : null)).value(),
            powerDevices: new Set(design.wiring_zones.map(wz => wz.inverter_id).concat(
                design.wiring_zones.map(wz => wz.power_optimizer_id))),
        };
    }

    return {
        restrict: 'EA',
        scope: { scenario: '=', design: '=' },
        templateUrl: require('helioscope/app/projects/partials/scenarios/directives.summary.html'),
        controllerAs: 'summaryCtrl',
        controller: ($rootScope, $scope) => {
            let usedComponents;
            $scope.$watch('design', (design) => {
                if (design) usedComponents = getUsedComponents(design);
            });

            $scope.hasFeature = $rootScope.hasFeature;

            $scope.showSingleAxisTrackers = () => $rootScope.user().hasSingleAxisTrackersAccess();

            // default to showing components
            $scope.hasModule = moduleId => usedComponents ? _.has(usedComponents.modules, parseInt(moduleId, 10)) : true;
            $scope.hasPowerDevice = powerDeviceId => usedComponents ? usedComponents.powerDevices.has(parseInt(powerDeviceId, 10)) : true;
            $scope.showWarnings = (moduleCharacterization) => {
                if (usedComponents == null || !(_.has(usedComponents.modules, moduleCharacterization.module_id))) {
                    return '';
                }
                const fsArray = usedComponents.modules[moduleCharacterization.module_id];
                let warnDims = false;
                let warnPower = false;

                fsArray.forEach((fs) => {
                    warnDims = warnDims || (fs.module_characterization.length !== moduleCharacterization.length);
                    warnDims = warnDims || (fs.module_characterization.width !== moduleCharacterization.width);
                    warnPower = warnPower || (fs.module_characterization.power !== moduleCharacterization.power);
                });
                return (
                    (warnDims ? '[WARNING: Dimensions differ from characterization used in design]' : '') +
                    (warnPower ? '[WARNING: Power differs from characterization used in design]' : '')
                );
            };
        },
    };
});

mod.directive('scenarioSoiling', () => {
    return {
        restrict: 'E',
        scope: { scenario: '=', form: '=', minimal: '@' },
        templateUrl: require('helioscope/app/projects/partials/scenarios/directives.soiling.html'),
        controller: ['$scope', 'DefaultScenarioParameters', function ($scope, DefaultScenarioParameters) {
            $scope.shortMonthNames = config.shortMonths;
            $scope.defaultSoiling = DefaultScenarioParameters.default_soiling;

            $scope.shortMonthNames.forEach((month) => {
                $scope.scenario[`${month}_soiling`] = parseFloat($scope.scenario[`${month}_soiling`]);
            });

            $scope.updateAllSoiling = function () {
                if ($scope.defaultSoilingForm.defaultSoiling.$valid) {
                    $scope.shortMonthNames.forEach((month) => {
                        $scope.scenario[`${month}_soiling`] = $scope.defaultSoiling;
                    });
                }
            };
        }],
    };
});

mod.directive('scenarioTemperature', () => {
    return {
        restrict: 'E',
        scope: { scenario: '=', form: '=', minimal: '@' },
        templateUrl: require('helioscope/app/projects/partials/scenarios/directives.temperature.html'),
        controller: ['$scope', 'SandiaCellTempDlg', 'DiffuseCellTempDlg', 'DefaultScenarioParameters', function ($scope, sandiaCellTempDlg, diffuseCellTempDlg, DefaultScenarioParameters) {
            function setDefaultParameters() {
                if ($scope.scenario.cell_temp_model === 'sandia') {
                    $scope.scenario.cell_temp_parameters = angular.copy(DefaultScenarioParameters.temperature_parameters.sandia);
                } else {
                    $scope.scenario.cell_temp_parameters = angular.copy(DefaultScenarioParameters.temperature_parameters.diffuse);
                }
            }

            // set the parameters if they're not set
            if (!$scope.scenario.cell_temp_parameters) { setDefaultParameters(); }

            $scope.$watch('scenario.cell_temp_model', (newValue, oldValue) => {
                if (newValue !== oldValue) { setDefaultParameters(); }
            });

            $scope.advanced = function () {
                const fnForDialog = ($scope.scenario.cell_temp_model === 'sandia' ? sandiaCellTempDlg : diffuseCellTempDlg);

                fnForDialog($scope.scenario.cell_temp_parameters).result.then((newParams) => {
                    angular.extend($scope.scenario.cell_temp_parameters, newParams);
                });
            };
        }],
    };
});

mod.directive('scenarioMismatch', () => {
    return {
        restrict: 'E',
        scope: { scenario: '=', form: '=', minimal: '@' },
        templateUrl: require('helioscope/app/projects/partials/scenarios/directives.mismatch.html'),
        controller: ['$scope', function ($scope) {
            $scope.scenario.max_module_binning = parseFloat($scope.scenario.max_module_binning);
            $scope.scenario.min_module_binning = parseFloat($scope.scenario.min_module_binning);
            $scope.scenario.irradiation_variance = parseFloat($scope.scenario.irradiation_variance);
            $scope.scenario.temperature_variance = parseFloat($scope.scenario.temperature_variance);

            $scope.validators = {
                binning(val) { return isNaN(val) || (val <= 50 && val >= -50); },
                rel_binning(val) { return isNaN(val) || (parseFloat(val) <= parseFloat($scope.scenario.max_module_binning)); },
            };
        }],
    };
});

mod.directive('scenarioAdvancedSettings', () => {
    return {
        restrict: 'E',
        scope: { scenario: '=', form: '=', minimal: '@' },
        templateUrl: require('helioscope/app/projects/partials/scenarios/directives.advanced.html'),
        controller: ['$scope', function ($scope) {
            $scope.solar_angle_sources = [{ k: false, v: 'Meteo File Lat/Lng' }, { k: true, v: 'Project Location' }];
            $scope.transposition_models = [{ k: 'perez', v: 'Perez Model' }, { k: 'hay', v: 'Hay Model' }];
        }],
    };
});

mod.directive('scenarioAcSystem', () => {
    return {
        restrict: 'E',
        scope: { scenario: '=', form: '=', minimal: '@' },
        controller: ['$scope', function ($scope) {
            $scope.scenario.ac_conductor_derate = parseFloat($scope.scenario.ac_conductor_derate);
        }],
        templateUrl: require('helioscope/app/projects/partials/scenarios/directives.ac_system.html'),
    };
});

mod.directive('scenarioTrackers', () => ({
    restrict: 'E',
    scope: { scenario: '=', form: '=', minimal: '@' },
    controller: ['$scope', $scope => {
        $scope.scenario.tracker_max_angle = parseFloat($scope.scenario.tracker_max_angle);
    }],
    templateUrl: require('helioscope/app/projects/partials/scenarios/directives.trackers.html'),
}));

mod.directive('simulationSummary', ($rootScope) => {
    return {
        restrict: 'EA',
        scope: { design: '=', simulation: '=', scenario: '=' },
        templateUrl: require('helioscope/app/projects/partials/reports/directives.power_summary.html'),
        controller: ['$scope', function ($scope) {
            const { design, scenario, simulation } = $scope;
            const weatherSource = scenario.weather_dataset.weather_source;

            if (scenario.weather_dataset.name !== 'TMY') {
                $scope.p90_calculation = 'Please run simulation with TMY weather file to obtain P90 output.';
            } else {
                $scope.p90_calculation = ((simulation.metadata.grid_power / design.field_component_metadata.nameplate) *
                    (1 - (weatherSource.ghi_std / weatherSource.ghi_mean) * 1.282)).toFixed(1);
            }

            const preferredType = scenario.project.team.preferred_weather_source_type;
            if (scenario.is_initial_scenario && preferredType && preferredType !== weatherSource.source_type) {
                $scope.mismatchedWeatherType = true;
            }
        }],
        link: (scope) => {
            scope.hasFeature = $rootScope.hasFeature;
        },
    };
});

mod.directive('lossTree', () => {
    const almostEquals = (a, b, tol = 1e-6) => Math.abs(a - b) < tol;

    return {
        restrict: 'EA',
        scope: { results: '=' },
        templateUrl: require('helioscope/app/projects/partials/reports/directives.loss_tree.html'),
        controller: ['$scope', function ($scope) {
            const { results } = $scope;

            $scope.hasClippingLosses = (results.optimal_dc_power > 0);
            $scope.showAdjustedGHI = results.adjusted_ghi > 0 && !almostEquals(results.adjusted_ghi, results.global_horizontal_irradiance, 5);

            $scope.irradianceRows = (
                6 + // baseline number of fields
                ($scope.showAdjustedGHI > 0 ? 1 : 0)
            );

            $scope.energyRows = (
                6 + // baseline number of fields
                (results.optimizer_input_power > 0 ? 1 : 0) +
                ($scope.hasClippingLosses > 0 ? 1 : 0) +
                (results.grid_power !== null ? 1 : 0)
            );
        }],
    };
});

mod.directive('simulationProgressBar', [function () {
    return {
        restrict: 'EA',
        transclude: true,
        scope: { simulation: '=', display: '=', removeSimulation: '=' },
        templateUrl: require('helioscope/app/projects/partials/reports/matrix.cell.html'),
        controller: ['$scope', 'Simulation', 'Messager', '$stateParams', '$log',
            function ($scope, Simulation, Messager, $stateParams, $log) {
                $scope.project_id = $stateParams.project_id;

                const design = $scope.simulation.design;
                const project = design.project;

                $scope.runSimulation = function () {
                    $scope.isLoading = true;

                    const notify = Messager.load('Initializing new Simulation');
                    const simulation = new Simulation($scope.simulation);

                    simulation.$save((simulation) => {
                        analytics.track('simulation.triggered', {
                            project_id: project.project_id,
                            team_id: $rootScope.user().team_id,
                            design_id: design.design_id,
                            nameplate: design.nameplate,
                        });

                        notify.success('Simulation created successfully');
                        $scope.isLoading = false;
                        angular.extend($scope.simulation, simulation);
                    }, (resp) => {
                        if (resp.data.errors && resp.data.errors.design_id) {
                            notify.error(resp.data.errors.design_id[0]);
                        } else {
                            notify.error('Simulation could not be created');
                        }

                        $log.warn(resp);
                        $scope.isLoading = false;
                    });
                };

                $scope.deleteSimulation = function () {
                    const notification = Messager.load('Deleting simulation');

                    $scope.simulation.$delete(() => {
                        notification.success('Successfully deleted simulation');
                        $scope.removeSimulation($scope.simulation.simulation_id);
                    }, (response) => {
                        notification.error('Could not delete simulation');
                        $log.warn(response);
                    });
                };

                $scope.progressData = {};
                $scope.$watch('simulation.metadata', (metadata) => {
                    if (metadata && metadata.total_slices) {
                        $scope.progressData.info = 100 * (metadata.complete_slices / metadata.total_slices) || 0;
                        $scope.progressData.danger = 100 * (metadata.error_slices / metadata.total_slices) || 0;
                    }
                }, true);
            }],
    };
}]);


mod.directive('simulationMonthlySummary', ['$filter', function ($filter) {
    const numberFilter = $filter('number');
    const fullMonths = ['January', 'February', 'March', 'April', 'May',
                        'June', 'July', 'August', 'September', 'October',
                        'November', 'December'];
    const DAYS_PER_MONTH = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

    return {
        retrict: 'EA',
        scope: { data: '=', daily: '@' },
        templateUrl: require('helioscope/app/projects/partials/reports/directives.simulation_monthly_summary.html'),
        link(scope, element) {
            const chart = new Highcharts.Chart({
                chart: {
                    renderTo: angular.element(element).children('.chart-holder')[0],
                    type: 'column',
                },
                title: '',
                xAxis: {
                    categories: ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun',
                                    'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'],
                },
                yAxis: {
                    min: 0,
                    title: {
                        text: 'kWh',
                    },
                },
                legend: {
                    enabled: false,
                },
                credits: {
                    enabled: false,
                },
                tooltip: {
                    formatter() {
                        return `${this.x}: <b>${numberFilter(this.y, 1)} kWh</b>`;
                    },
                    shared: true,
                    useHTML: true,
                },
                plotOptions: {
                    series: {
                        color: 'rgba(255, 153, 0, 0.7)',
                    },
                },
                series: [{
                    // monthly data may be sparse, so start from an array containing 12 values
                    // some northern countries dont have irradiance in all months
                    data: (scope.daily ?
                        _.range(12).map(idx =>
                            (scope.data[idx + 1] != null ?
                                scope.data[idx + 1].grid_power / 1000 / DAYS_PER_MONTH[idx] : 0)) :
                        _.range(12).map(idx =>
                            (scope.data[idx + 1] != null ?
                                scope.data[idx + 1].grid_power / 1000 : 0))),
                }],
            });

            scope.$on('pdf:resize', () => {
                chart.setSize($(element).width(), $(element).height(), false);
            });

            // for table
            scope.hideTable = true;

            // until we migrate all old simulations (rev 39ba853837bb) optionally show a reduced table
            // some northern countries dont have irradiance in all months
            scope.fullTable = _.has(scope.data[1], 'global_horizontal_irradiance') || _.has(scope.data[6], 'global_horizontal_irradiance');
            scope.monthlyData = [];

            for (let i = 0; i < 12; i++) {
                const monthData = {
                    name: fullMonths[i],
                    ...scope.data[i + 1],
                };
                if (scope.daily) {
                    monthData.nameplate_power /= DAYS_PER_MONTH[i];
                    monthData.grid_power /= DAYS_PER_MONTH[i];
                }
                scope.monthlyData.push(monthData);
            }
        },
    };
}]);

mod.directive('simulationLossChart', [function () {
    function cleanData(data) {
        const lossData = [];
        const constrainedPower = (data.optimal_dc_power
                                - data.inverter_overpower_loss
                                - data.inverter_underpower_loss
                                - data.inverter_overvoltage_loss
                                - data.inverter_undervoltage_loss);

        lossData.push({ name: 'Shading', y: 100 * (1 - Math.max(data.shaded_irradiance / (data.poa_irradiance || 1), 0)), description: 'Irradiance loss due to shading' });
        lossData.push({ name: 'Reflection', y: 100 * (1 - Math.max(data.effective_irradiance / (data.shaded_irradiance || 1), 0)), description: 'Irradiance loss due to reflection' });
        lossData.push({ name: 'Soiling', y: 100 * (1 - Math.max(data.soiled_irradiance / (data.effective_irradiance || 1), 0)), description: 'Irradiance loss due to soiling' });
        lossData.push({ name: 'Irradiance', y: 100 * (1 - Math.max(data.module_irradiance_derated_power / (data.nameplate_power || 1), 0)), description: 'Module low-irradiation power loss' });
        lossData.push({ name: 'Temperature', y: 100 * (1 - Math.max(data.module_mpp_power / (data.module_irradiance_derated_power || 1), 0)), description: 'Module temperature power loss' });
        lossData.push({ name: 'Mismatch', y: 100 * (1 - Math.max(data.module_power / (data.module_mpp_power || 1), 0)), description: 'Power loss due to mismatch' });
        if (data.optimizer_input_power > 0) {
            lossData.push({ name: 'Optimizers', y: 100 * (1 - Math.max((data.optimizer_output_power + (data.module_power - data.optimizer_input_power)) / (data.module_power || 1), 0)), description: 'Power loss due to optimizer efficiency' });
        }
        if (data.optimal_dc_power > 0) {
            lossData.push({ name: 'Wiring', y: 100 * (1 - Math.max(data.optimal_dc_power / ((data.optimizer_output_power + (data.module_power - data.optimizer_input_power)) || 1), 0)), description: 'DC wiring I<sup>2</sup>R loss' });
            lossData.push({ name: 'Clipping', y: 100 * (1 - Math.max(constrainedPower / (data.optimal_dc_power || 1), 0)), description: 'Power loss due to inverter input voltage and power limits' });
        } else {
            lossData.push({ name: 'Wiring', y: 100 * (1 - Math.max(data.actual_dc_power / ((data.optimizer_output_power + (data.module_power - data.optimizer_input_power)) || 1), 0)), description: 'DC wiring I<sup>2</sup>R loss' });
        }
        lossData.push({ name: 'Inverters', y: 100 * (1 - Math.max(data.ac_power / (constrainedPower || 1), 0)), description: 'Power loss due to inverter efficiency' });
        lossData.push({ name: 'AC System', y: 100 * (1 - Math.max(data.grid_power / (data.ac_power || 1), 0)), description: 'Power loss due to AC Conductors and Transformers' });
        return lossData;
    }

    return {
        retrict: 'EA',
        scope: { data: '=' },
        link(scope, element) {
            const chart = new Highcharts.Chart({
                chart: {
                    renderTo: element[0],
                    plotBackgroundColor: null,
                    plotBorderWidth: null,
                    plotShadow: false,
                },
                title: '',
                tooltip: {
                    pointFormat: '{series.name}: <b>{point.y:.1f}%</b><br>{point.description}',
                },
                plotOptions: {
                    pie: {
                        allowPointSelect: true,
                        cursor: 'pointer',
                        innerSize: '40%',
                        dataLabels: {
                            enabled: true,
                            color: '#000000',
                            connectorColor: '#000000',
                            format: '<b>{point.name}</b>: {point.y:.1f}%',
                        },
                    },
                },
                colors: ['#771100', '#CC6633', '#FF9900', '#656565', '#BBB', '#AA3300',
                                '#Ca883a', '#FaBB0a', '#999'],

                series: [{
                    type: 'pie',
                    name: 'System Loss',
                    data: cleanData(scope.data),
                }],
                credits: {
                    enabled: false,
                },
            });

            scope.$on('pdf:resize', () => {
                chart.setSize($(element).width(), $(element).height(), false);
            });
        }, // end linking function
    }; // end return
}]);

mod.directive('horizonGraph', ['$filter', function ($filter) {
    const numberFilter = $filter('number');
    const degreeFilter = function (x) { return `${numberFilter(x, 0)}º`; };
    const makeNorthCenter = function (az) { return az > 180 ? az - 360 : az; };
    const makeAzimuthPositive = function (az) { return az < 0 ? az + 360 : az; };
    const azLabelFilter = _.flow(makeAzimuthPositive, degreeFilter);

    return {
        restrict: 'EA',
        scope: { data: '=' },
        template: '<div id="container"></div>',
        link(scope, element) {
            const isSouth = scope.data.project.location.latitude < 0;
            const azFilter = (isSouth ? makeNorthCenter : angular.identity);
            const minAz = isSouth ? -180 : 0;
            const maxAz = isSouth ? 180 : 360;

            const chart = new Highcharts.Chart({
                chart: {
                    renderTo: element[0],
                    type: 'area',
                    width: 800,
                    height: 500,
                },
                title: 'Horizon Profile',
                xAxis: {
                    allowDecimals: false,
                    title: {
                        text: '<b>Compass Heading</b>',
                    },
                    gridLineWidth: 1,
                    tickInterval: 30,
                    plotLines: _.map(_.range(minAz, maxAz + 1, 90), (angle) => {
                        return {
                            value: angle,
                            width: 2,
                            color: '#999',
                        };
                    }),
                    labels: {
                        formatter() { return azLabelFilter(this.value); },
                    },
                    min: minAz,
                    max: maxAz,
                },
                yAxis: {
                    title: {
                        text: '<b>Elevation</b>',
                    },
                    labels: {
                        formatter() { return azLabelFilter(this.value); },
                    },
                    min: 0,
                    max: 90,
                    plotLines: _.map(_.range(0, 91, 30), (angle) => {
                        return {
                            value: angle,
                            width: 2,
                            color: '#999',
                        };
                    }),
                },
                legend: {
                    enabled: false,
                },
                credits: {
                    enabled: false,
                },
                tooltip: {
                    formatter() {
                        return `Heading: ${azLabelFilter(this.x)}<br>Elevation: ${numberFilter(this.y, 0)}&deg;</b>`;
                    },
                    shared: true,
                    useHTML: true,
                },
                plotOptions: {
                    series: {
                        color: 'rgba(255, 153, 0, 0.7)',
                    },
                },
                series: [{
                    data: _(scope.data.horizon_points)
                        .map((p) => { return [azFilter(p.azimuth), p.elevation]; })
                        .sortBy(_.head)
                        .value(),
                }],
            });

            // stfu linter
            angular.noop(chart);
        },
    };
}]);
