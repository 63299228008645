/* globals zip:true */
import { $q } from 'helioscope/app/utilities/ng';
import * as THREE from 'three';

export const KML_EXTENSIONS = ['kml', 'kmz'];
export const IMAGE_EXTENSIONS = ['jpeg', 'jpg', 'png'];

export const MEGABYTES = 1000000; // change to 1_000_000 for readability once we have es2021
export const MAX_FILE_SIZE_MB_STANDARD = 10;
export const MAX_FILE_SIZE_MB_LARGE = 20;

export function isKMLExtension(extension) {
    return KML_EXTENSIONS.includes(extension.toLowerCase());
}

export function isImageExtension(extension) {
    return IMAGE_EXTENSIONS.includes(extension.toLowerCase());
}

export function generateQuadPoints(width, height, scale, center) {
    const left = width * (-0.25) * scale;
    const right = width * 0.25 * scale;
    const top = (right - left) * height * (-0.5);
    const bottom = (right - left) * height * 0.5;

    const quadPointsRelative = [
        (new THREE.Vector3(left, bottom, 0)),
        (new THREE.Vector3(right, bottom, 0)),
        (new THREE.Vector3(right, top, 0)),
        (new THREE.Vector3(left, top, 0)),
    ];

    const quadPoints = [];
    for (const point of quadPointsRelative) {
        quadPoints.push(new THREE.Vector3().addVectors(point, new THREE.Vector3(center.x, center.y, 0)));
    }

    return quadPoints;
}

function getText(entry) {
    const deferred = $q.defer();
    entry.getData(new zip.TextWriter(), data => {
        deferred.resolve({ metadata: entry, data });
    });

    return deferred.promise;
}

function getBlob(entry) {
    const deferred = $q.defer();
    entry.getData(new zip.BlobWriter(), data => {
        deferred.resolve({ metadata: entry, data });
    });

    return deferred.promise;
}

export function getKMLData(entry) {
    const deferred = $q.defer();
    getText(entry).then((file) => {
        deferred.resolve(file.data);
    });
    return deferred.promise;
}

export function createImageURL(entry) {
    const deferred = $q.defer();
    getBlob(entry).then((file) => {
        deferred.resolve(URL.createObjectURL(file.data));
    });
    return deferred.promise;
}

export function openZip(file) {
    const deferred = $q.defer();
    zip.createReader(new zip.BlobReader(file), (kmlZip) => {
        kmlZip.getEntries(entries => deferred.resolve(entries));
    });
    return deferred.promise;
}
