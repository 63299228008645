import _ from 'lodash';

import { RelationalBase, relationship } from 'helioscope/app/relational';
import { PremadePointSurface } from 'helioscope/app/designer/field_segment/PhysicalSurface';
import { Vector } from 'helioscope/app/utilities/geometry';


class PremadeProxyTreeSphere {
    constructor(entity) {
        this.entity = entity;
    }

    get topHeight() {
        const { parameters } = this.entity.geometry;
        const topHeight = parameters.bot_height + parameters.top_radius * 2.0;
        return topHeight;
    }

    set topHeight(value) {
        const numeric = value || 0.0;
        const { parameters } = this.entity.geometry;
        this.entity.geometry.parameters.bot_height = numeric - parameters.top_radius * 2.0;
    }

    get topRadius() {
        return this.entity.geometry.parameters.top_radius;
    }

    set topRadius(value) {
        this.entity.geometry.parameters.top_radius = value || 0.0;
    }
}


export class EntityPremade extends RelationalBase {
    static relationName = 'EntityPremade';
    static MAX_TOP_RADIUS = 50; // meters
    static MIN_TOP_RADIUS = 0.25; // meters
    static MAX_BOT_HEIGHT = 100.0; // meters
    static MIN_BOT_HEIGHT = 0.5; // meters

    get proxyProperties() {
        if (!this._proxyProperties) {
            this._proxyProperties = this.getProxyPropertyObject();
        }

        return this._proxyProperties || {};
    }

    getProxyPropertyObject(entity = this) {
        switch (this.geometry.premade_type) {
            case 'tree_sphere':
                return new PremadeProxyTreeSphere(entity);
            default:
                return {};
        }
    }

    cloneEntity() {
        const newEntity = new EntityPremade(
            _.merge({}, this,
                {
                    entity_premade_id: null,
                    geometry: _.cloneDeep(this.geometry),
                    _proxyProperties: null,
                })
        );

        return newEntity;
    }

    toString() {
        switch (this.geometry.premade_type) {
            case 'tree_sphere':
                return 'tree (sphere)';
            default:
                return 'premade';
        }
    }

    proxyStackableSurface() {
        if (!this._proxyStackableSurface) {
            this._proxyStackableSurface = new PremadePointSurface(this);
        }

        this._proxyStackableSurface.setPoint(this.geometry.parameters.position);
        return this._proxyStackableSurface;
    }

    proxySurfaceUpdated() {
        this.geometry.parameters.position_3d = this._proxyStackableSurface.point3D();
    }

    zoomPath() {
        const rad = this.geometry.parameters.top_radius;
        const pos = new Vector(this.geometry.parameters.position_3d || this.geometry.parameters.position);

        return [
            pos.addXYZ(rad, rad, 0),
            pos.addXYZ(-rad, rad, 0),
            pos.addXYZ(-rad, -rad, 0),
            pos.addXYZ(rad, -rad, 0),
            pos.addXYZ(rad, rad, this.proxyProperties.topHeight),
            pos.addXYZ(-rad, rad, this.proxyProperties.topHeight),
            pos.addXYZ(-rad, -rad, this.proxyProperties.topHeight),
            pos.addXYZ(rad, -rad, this.proxyProperties.topHeight),
        ];
    }

    centroid() {
        return this.geometry.parameters.position;
    }
}

EntityPremade.configureRelationships({
    design: relationship('Design', { backref: 'entity_premades' }),
    'geometry.parameters.position': (raw) => new Vector(raw),
});

EntityPremade.createEndpoint('/api/entity_premades/:entity_premade_id',
    { entity_premade_id: '@entity_premade_id' },
    { update: { method: 'PUT', isArray: false } });
