import Logger from 'js-logger';

const logger = Logger.get('templates');

export function loadTemplates(ctx) {
    const templates = ctx.keys().filter(x => x.includes('reports/classic_designer') === false);
    const result = templates.map(ctx);

    // if (logger.enabledFor(Logger.DEBUG)) {
    //     logger.debug(JSON.stringify(templates, undefined, 4));
    //     logger.debug(JSON.stringify(result, undefined, 4));
    // }
}
