import _ from 'lodash';

import { $anchorScroll } from 'helioscope/app/utilities/ng';

import { EntityPremade } from './Premade';
import { premadeChangeConfig } from './actions';
import { isMacLike } from 'helioscope/app/utilities/helpers';
import { $rootScope } from 'helioscope/app/utilities/ng';

export class PremadeCtrl {
    constructor($scope, design, dispatcher) {
        'ngInject';

        this.$scope = $scope;
        this.design = design;
        this.project = design.project;
        this.dispatcher = dispatcher;

        this.startControl();

        $scope.$on('$destroy', () => this.stopControl());
    }

    startControl() {
        this.unsubscribers = [];
        this.unsubscribers.push(
            this.dispatcher.subscribe('entityPremadesChanged', (dispatcher, pubsubOptions) => {
                this.handlePremadesChanged(pubsubOptions);
            }));
        this.unsubscribers.push(
            this.dispatcher.subscribe('entityHighlightChanged', (dispatcher, pubsubOptions) => {
                this.handleHighlightChanged(pubsubOptions);
            }));

        this.handleHighlightChanged();
        this.handlePremadesChanged();
    }

    stopControl() {
        for (const unsub of this.unsubscribers) {
            unsub();
        }

        this.unsubscribers = null;

        if (this.addMode) this.toggleModeAdd();
    }

    premadeFilter(type) {
        return _.filter(this.premades, i => i.geometry.premade_type === type);
    }

    handleHighlightChanged(pubsubOptions) {
        if (pubsubOptions && pubsubOptions.sender === this) return;

        for (const entity of this.dispatcher.highlightedEntities) {
            if (entity instanceof EntityPremade) {
                $anchorScroll(this.domId(entity));
                return;
            }
        }
    }

    handlePremadesChanged() {
        this.premades = _.map(this.dispatcher.design.entity_premades, i => i);
    }

    toggleModeAdd(addMode) {
        this.addMode = addMode;

        this.dispatcher.onRendererReady(() => {
            if (this.addMode) {
                this.dispatcher.renderer.activateInteractTool({
                    tool: 'ToolAddPremade',
                });
            } else {
                this.dispatcher.renderer.activateInteractTool(null);
            }
        });
    }

    addModeActive() {
        return this.addMode;
    }

    selectEntity(premade) {
        this.dispatcher.selectEntity(premade);
    }

    highlightEntity(premade, highlight) {
        this.dispatcher.highlightEntity(premade, highlight, { sender: this });
    }

    entitySelected(premade) {
        return this.dispatcher.selectedEntity === premade;
    }

    entityHighlighted(premade) {
        return this.dispatcher.highlightedEntities.has(premade);
    }

    domId(premade) {
        return `premade:${premade.entity_premade_id}`;
    }

    panTo(premade, select = false) {
        this.dispatcher.renderer.zoom(premade);

        if (select) {
            this.selectEntity(premade);
        }
    }

    deletePremade(premade) {
        const { dispatcher } = this;
        return dispatcher.stateHandler.deleteObject(premade, premadeChangeConfig(dispatcher, premade));
    }
}

export class PremadeMenuControl {
    constructor(premade, dispatcher) {
        'ngInject';

        this.dispatcher = dispatcher;
        this.premade = premade;
        this.internalClipboard = dispatcher.internalClipboard;
    }

    deletePremade() {
        const { dispatcher, premade } = this;
        this.dispatcher.selectEntity(premade, {}, false);
        return dispatcher.stateHandler.deleteObject(premade, premadeChangeConfig(dispatcher, premade));
    }

    copyPremade() {
        this.dispatcher.copyEntityFromMenu(this.premade);
    }

    hasComponentInClipboard() {
        return this.internalClipboard.isNotEmpty();
    }

    paste() {
        this.dispatcher.activatePasteMode();
    }

    isOnMac() {
        return isMacLike();
    }
}

export const PremadeContextMenu = {
    templateUrl: require('helioscope/app/designer/premade/partials/context.html'),
    controller: PremadeMenuControl,
    controllerAs: 'pmMenu',
};
