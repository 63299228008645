// A fallback chat client that does nothing so that we don't have null reference problems
// in case Zendesk doesn't load properly.

export function login() {}

export function newMessage() {}

export function hideChat() {}

export function logout() {}

export function shutdown() {}

export function update() {}
