import moment from 'moment';
import { get } from 'lodash';

import { helioscopeConfig } from 'helioscope/app/config';
import { RelationalBase } from 'helioscope/app/relational';


export class Subscription extends RelationalBase {
    static relationName = 'Subscription';

    constructor(data) {
        super(data);

        if (this.subscribers == null) {
            this.subscribers = [];  // protection for users who
        }
    }

    now() {
        return this.test_clock_frozen_time ? moment(this.test_clock_frozen_time) : moment();
    }

    get product() {
        if (Number(this.version) !== 2) {
            return 'basic';
        }

        return get(helioscopeConfig, 'product_metadata.pro.v2_stripe_id') === get(this, 'stripe_data.plan.product')
            ? 'pro'
            : 'basic';
    }

    get isV2() {
        return this.version === 2;
    }
}

Subscription.configureRelationships({
    current_period_end: (date) => moment(date),
    extension_end_date: (date) => moment(date),
});
