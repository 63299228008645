/*global angular: true, _:true */

(function (angular) {
    'use strict';
    var mod = angular.module('helioscope.projects.controllers.reports',
            ['helioscope.services', 'ui', 'helioscope.projects.resources', 'ngPDF']);

    mod.controller('ProjectReportsCtrl', ['$scope', 'Scenario', 'Design', 'Simulation',
        function ($scope, Scenario, Design, Simulation) {

            $scope.display = "kwh_kwp";

            if ($scope.projectData.scenarios === undefined) {
                $scope.projectData.scenarios = Scenario.query({project_id: $scope.project.project_id});
            }
            if ($scope.projectData.designs === undefined) {
                $scope.projectData.designs = Design.query({project_id: $scope.project.project_id});
            }
            if ($scope.projectData.simulations === undefined) {
                $scope.projectData.simulations = Simulation.query({project_id: $scope.project.project_id},
                    function (simulations) {
                        angular.forEach(simulations, function (s) {
                            angular.extend($scope.getSimulation(s.design_id, s.scenario_id), s);
                        });
                    });
            }

            $scope.hasModules = function (design) {
                return design && design.field_component_metadata && design.field_component_metadata.nameplate > 0;
            };

            $scope.hasCompleteSimulation = function () {
                return _.some($scope.projectData.designs, function (design) {
                    if (!$scope.hasModules(design)) { return false; }

                    return _.some($scope.projectData.scenarios, function (scenario) {
                        var sim = $scope.getSimulation(design.design_id, scenario.scenario_id);

                        return (sim && sim.status === "completed");
                    });
                });

            };

        }]);

    mod.controller('AnnualReportModalCtrl', ['$scope', 'simulation', '$modalInstance',
        function ($scope, simulation, $modalInstance) {
            $scope.simulation = simulation;
            $scope.data = {
                design: simulation.design,
            };

            $scope.close = function (message) {
                $modalInstance.close(message);
            };
        }]);

    mod.controller('AnnualReportPrintCtrl', ['$scope', 'simulation',
                                             function ($scope, simulation) {
            $scope.simulation = simulation;
            $scope.data = {
                design: simulation.design,
            };
``
        }]);

    mod.controller('AnnualReportCtrl', ['$scope', 'Scenario', function ($scope, Scenario) {
            $scope.data.scenario = Scenario.get({scenario_id: $scope.simulation.scenario_id});
        }]);

}(angular));
