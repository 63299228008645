/* global angular:true, _:true */
import moment from 'moment';

import { RelationalBase } from 'helioscope/app/relational';
import { Messager, $log } from 'helioscope/app/utilities/ng';

export class Team extends RelationalBase {
    static relationName = 'Team';

    set preferredWeatherSourceType(newType) {
        this.preferred_weather_source_type = newType;
        if (newType == null) {
            this.preferred_weather_source_radius = null;
        } else {
            // default to 50 miles (80467 meters)
            this.preferred_weather_source_radius = this.preferred_weather_source_radius || 80467;
        }
    }
    get preferredWeatherSourceType() {
        return this.preferred_weather_source_type;
    }
}

Team.configureRelationships({
    custom_plan_end: (rawTime) => moment(rawTime),
});
Team.createEndpoint(
    '/api/teams/:team_id',
    { team_id: '@team_id' },
    {
        update: { method: 'PUT', isArray: false },
        updateUser: { method: 'PUT', isArray: false, url: '/api/teams/:team_id/update_user' },
        limitsAndUsage: { method: 'GET', isArray: false, url: '/api/teams/:team_id/limits_and_usage' },
    },
);

class TeamCtrl {
    constructor(team, selectedUser) {
        'ngInject';

        this.user = selectedUser;
        this.team = team;
    }

    updateAdminStatus(user) {
        Team.updateUser({}, { team_id: user.team_id,
            email: user.email,
            team_admin: user.team_admin }).$promise.then(
            () => {
                Messager.info('Updated Project Admin');
            },
            () => {
                Messager.error('Could not update Project Admin');
                user.team_admin = !user.team_admin;
            },
        );
    }

    updateTeam() {
        this.isLoading = true;
        this.team.$update()
            .then(() => Messager.success('Updated Team Successfully'))
            .catch((err) => {
                Messager.error('Could not update Team');
                $log.warn(err);
            })
            .finally(() => { this.isLoading = false; });
    }

    WeatherSourceTypes = {
        tmy3: 'NREL Typical Meteo Year (tmy3)',
        tmy2: 'NREL Typical Meteo Year (tmy2)',
        epw: 'DOE Energy Plus (epw)',
        prospector: 'Prospector',
        psm3: 'PSM v3',
        swera: 'SWERA',
        meteonorm: 'Meteonorm',
    };
}

const mod = angular.module('helioscope.users.teams', ['relational', 'ui.select2']);
mod.factory('Team', () => Team);
mod.controller('TeamCtrl', TeamCtrl);
