/*globals angular:false, $:false, _:true */

(function (angular) {
    "use strict";

    var module = angular.module('ng-help', []);

    module.factory('highlightEvents', [function () {
        // register event listenrs to propagate clicks through HTML layers
        // register an element and a function to be triggered if a click is
        // on that element

        var listeners = [],
            callbacks = [];

        function checkClick(evt, domElement) {
            var divCoords = domElement[0].getBoundingClientRect(),
                coords = {
                    x: evt.clientX,
                    y: evt.clientY
                };

            return (coords.x >= divCoords.left &&
                        coords.x <= divCoords.right &&
                        coords.y >= divCoords.top &&
                        coords.y <= divCoords.bottom);
        }

        return {
            registerListener: function (domElement, callback) {
                listeners.push(domElement);
                callbacks.push(callback);
            },
            removeListener: function (element) {
                if (listeners.indexOf(element) !== -1) {
                    var idx = listeners.indexOf(element);
                    listeners.splice(idx, 1);
                    callbacks.splice(idx, 1);
                }
            },
            checkClick: function (evt) {
                var length = listeners.length,
                    i;

                for (i = 0; i < length; i += 1) {
                    if (checkClick(evt, listeners[i])) {
                        setTimeout(callbacks[i]);
                        return true;
                    }
                }

                return false;
            }
        };
    }]);

    module.factory('TopScrollElement', ['$document', '$window', '$q', function ($document, $window, $q) {
        // cribbed from: http://stackoverflow.com/questions/8149155/animate-scrolltop-not-working-in-firefox#answer-21583714

        var deferred = $q.defer();


        // Note that the DOM needs to be loaded first,
        // or else document.body will be undefined
        function getScrollTopElement() {

            // if missing doctype (quirks mode) then will always use 'body'
            if ($document[0].compatMode !== 'CSS1Compat') {
                return 'body';
            }

            var html = $('html'),
                body = $('body'),
                windowHeight = $($window).height(),
                // get our starting position.
                // pageYOffset works for all browsers except IE8 and below
                startingY = $window.pageYOffset || body.scrollTop() || html.scrollTop(),

                // scroll the window down by 1px (scrollTo works in all browsers)
                newY = startingY + 1,

                result;


            //make sure the page is tall enough to scroll
            body.height(windowHeight + 10);

            $window.scrollTo(0, newY);

            // And check which property changed
            // FF and IE use only html. Safari uses only body.
            // Chrome has values for both, but says
            // body.scrollTop is deprecated when in Strict mode.,
            // so let's check for html first.
            result = (html.scrollTop() === newY) ? html : body;

            // now reset back to the starting position
            $window.scrollTo(0, startingY);

            // really should have a better reset value for this
            body.css('height', '100%');
            return result;
        }

        $($document[0]).ready(function () {
            deferred.resolve(getScrollTopElement());
        });


        return deferred.promise;
    }]);

    module.directive('highlight', ['$document', '$log', '$window', 'highlightEvents', 'TopScrollElement', function ($document, $log, $window, highlightEvents, topScrollElement) {
        function cloneElementAndParents(domTarget, removeBackgrounds) {
            // clone a target element and all its parents so that any css rules will be applied
            // use remove backgrounds to force any parent element backgrounds to be invisible

            var domOverlay = domTarget.clone(false).removeAttr('id'),
                lastElement = domOverlay;

            removeBackgrounds = (removeBackgrounds === true);

            angular.forEach($(domTarget).parentsUntil('body'), function (p) {
                var parent = angular.element(p),
                    newElement = parent.clone(false).empty().addClass('remove-pseudo');

                if (removeBackgrounds === true) {

                    newElement.css({
                        'background-color': 'rgba(0, 0, 0, 0)',
                        'border-color': 'rgba(0, 0, 0, 0)',
                        'box-shadow': 'none',
                        'border': 'none',
                        'background': 'none',
                        'overflow': 'visible',
                        'filter': 'none'
                    });
                }

                newElement.append(lastElement);

                if (lastElement.prop('tagName') === 'TD' || lastElement.prop('tagName') === 'TR') {
                    //if it's a table element, add a TD element after to
                    // allow the sizing to adjust correctly
                    newElement.append(angular.element('<td style="visibility:hidden;border:none" class="remove-pseudo";>'));
                }

                lastElement = newElement;
            });

            return {
                domOverlay: domOverlay,
                overlayRoot: lastElement
            };
        }

        function positionElement(domOverlay, domTarget, domOverlayRoot, options) {
            var zIndex = angular.element('.modal-backdrop').zIndex() + 1;

            domOverlayRoot.zIndex(zIndex);

            // raise overlay content's z-index so that clicks exit the view
            // not sure this is necessary
            // angular.element(".full-screen-help-modal").zIndex(zIndex + 5);

            // set the overlays width, in case the underlying element changed
            // make slightly large because have oserved some firefox reflow issues
            domOverlay.css('width', (Math.ceil(domTarget.outerWidth() + 1) + (options.createBuffer === true ? options.buffer.pixels * 2 : 0)) + 'px');
            domOverlay.css('height', (Math.ceil(domTarget.outerHeight() + 1) + (options.createBuffer === true ? options.buffer.pixels * 2 : 0)) + 'px');
            if (options.createBuffer === true) {
                domOverlay.css({
                    'margin': options.buffer.pixels,
                    'padding': options.buffer.pixels,
                    'background-color': options.buffer.color,
                    'border-radius': options.buffer.radius,
                });
            }

            // first make sure the element is on the dom, then force it to
            // align with the target by shifting the root element
            domOverlayRoot.css({
                'position': 'absolute',
                'bottom': 0,
                'visibility': 'hidden',
            }).css({
                'top': domTarget.offset().top - (domOverlay.offset().top - domOverlayRoot.offset().top) - (options.createBuffer === true ? options.buffer.pixels : 0),
                'left': domTarget.offset().left - (domOverlay.offset().left  - domOverlayRoot.offset().left)  - (options.createBuffer === true ? options.buffer.pixels : 0),
                'visibility': 'visible'
            });
        }

        var defaultOptions = {
                removeBackgrounds: true,
                createBuffer: false,
                buffer: {
                    'color': 'white',
                    'pixels': 10,
                    'radius': 5,
                }
            };

        return {
            restrict: 'E',
            scope: {
                target: "@",
                active: "=?",
                options: "=?"
            },
            link: function (scope, element, attrs) {
                angular.noop(element);
                if (attrs.target === undefined || scope.target === '') {
                    $log.warn("No target specified for highlight directive.");
                    return;
                }
                if (attrs.active === undefined) {
                    scope.active = true;
                }

                var domTarget,
                    domOverlay,
                    domOverlayRoot,
                    options = angular.extend({}, defaultOptions, scope.options),
                    positionHandler;

                domTarget = $(scope.target + (options.showHidden !== true ? ":visible" : '')).first();

                if (domTarget.length === 0) {
                    $log.warn("Could not find visible element: ", scope.target);
                    return;
                }

                positionHandler = _.throttle(function _positionHandler() {
                    if (domOverlay !== undefined && scope.active === true) {
                        var retry, lastOffset, currentOffset;

                        for (retry = 0; retry <= 10; retry += 1) {
                            // repositioning the element sometimes changes the reflow, so refine
                            // the position until it is unchanged
                            // but limit the iteration, just in case
                            lastOffset = currentOffset || domOverlayRoot.offset();
                            positionElement(domOverlay, domTarget, domOverlayRoot, options);

                            currentOffset = domOverlayRoot.offset();

                            if (Math.round(lastOffset.top * 10) === Math.round(currentOffset.top * 10)
                                    && Math.round(lastOffset.left * 10) === Math.round(currentOffset.left * 10)) {
                                break;
                            }
                        }

                        // animate future position changes, if this is on for the
                        // first time, the positioning algorithm breaks
                        // (because it positions to intermediate states)
                        domOverlayRoot.css({
                            '-webkit-transition': '0.1s ease-in-out',
                            '-moz-transition': '0.1s ease-in-out',
                            '-o-transition': '0.1s ease-in-out',
                            'transition': '0.1s ease-in-out'
                        });
                    }

                }, 20);

                //add element for holding everything
                scope.$watch('active', function (newVal) {
                    if (newVal === true) {
                        //in a timeout to pick up any angular digest changes
                        if (domOverlayRoot === undefined) {
                            var overlayElements = cloneElementAndParents(domTarget, options.removeBackgrounds);

                            domOverlay = overlayElements.domOverlay;
                            domOverlay.addClass(attrs.class);

                            domOverlay.removeClass('ng-hide');

                            domOverlayRoot = overlayElements.overlayRoot;
                            $document[0].body.appendChild(domOverlayRoot[0]);

                            if (options.propagateClicks === true) {
                                highlightEvents.registerListener(domOverlay, function () {
                                    domTarget.click();
                                });
                            }
                        }

                        domOverlayRoot.css('visibility', 'visible');
                        domOverlay.css('visibility', 'visible');

                        // position in a timeout in case modal zIndex needs to be set
                        setTimeout(positionHandler, 100);

                    } else {
                        if (domOverlayRoot !== undefined) {

                            domOverlayRoot.css('visibility', 'hidden');
                            domOverlay.css('visibility', 'hidden');
                        }
                    }
                });

                topScrollElement.then(function (element) {
                    var lastParent = domTarget.parentsUntil('body').last(),
                        scrollParent;

                    if (lastParent.hasClass('modal')) {
                        scrollParent = lastParent;
                    } else {
                        scrollParent = element;
                    }

                    $($window).on('resize', positionHandler);
                    scrollParent.on('scroll', positionHandler);

                    //remove the element when destroyed
                    scope.$on('$destroy', function () {
                        if (domOverlayRoot) {
                            domOverlayRoot.remove();
                        }

                        $($window).off('resize', positionHandler);
                        scrollParent.off('scroll', positionHandler);

                        if (options.propagateClicks === true) {
                            highlightEvents.removeListener(domOverlay);
                        }

                    });
                });

            }
        };
    }]);


    /**
     * The `placeBy` directive allows one element to be placed either directly on or next to
     * a target element.
     */
    module.directive("placeBy", ['$window', 'TopScrollElement', function ($window, topScrollElement) {

        var DefaultOffsets = {
                top:    {x:   0, y: -10},
                bottom: {x:   0, y:  10},
                left:   {x: -10, y:   0},
                right:  {x:  10, y:   0},
                over:   {x:   0, y:   0},
            },
            AlignFactors = {
                top:    0,
                bottom: 1,
                left:   0,
                right:  1,
                middle: 0.5,
                center: 0.5,
            };

        return {
            restrict: 'A',
            scope: {placeBy: '='},
            link: function (scope, element) {

                var css = {
                        // position needs to be absolute for scrollable entities, but some things
                        // may need to be fixed
                        'position': scope.placeBy.cssPosition || 'absolute',

                        // hide the element until it is positioned
                        'visibility': 'hidden'
                    },
                    target = scope.placeBy.target,
                    defaultOffset = DefaultOffsets[scope.placeBy.position || 'over'],
                    xOffset = parseInt(scope.placeBy.xOffset || defaultOffset.x, 10),
                    yOffset = parseInt(scope.placeBy.yOffset || defaultOffset.y, 10),
                    alignFactor = AlignFactors[scope.placeBy.align || 'middle'],

                    // if the element sometimes takes a long time to load a
                    slowLoad = (scope.placeBy.slowLoad === true),

                    // if allow scroll is enabled, this element will not adjust its position on
                    // scroll or resize events
                    allowScroll = (scope.placeBy.allowScroll === true),
                    modalElement = $('.modal').last(), //get the last modal, since this will be on top
                    targetElement = angular.element(target).first(),

                    body,
                    positionHandler,
                    i;

                element = angular.element(element);

                // set the absolute positioning to ensure that the element is sized correctly for the
                // positioning step
                element.css(css);


                function positionElement(isResize) {
                    if (!body) {
                        return;
                    }
                    isResize = (isResize === true);
                    var winTop = modalElement.scrollTop() - body.scrollTop(),
                        winLeft = modalElement.scrollLeft()  - body.scrollLeft(),
                        targetOffset = targetElement.offset();

                    if (targetElement.length > 0 && targetElement.is(":visible")) {
                        css.visibility = 'visible';  // display the element with the positioning data

                        switch (scope.placeBy.position) {
                        case 'top':  // place element above target
                            css.top = (winTop + targetOffset.top - element.outerHeight() + yOffset) + 'px';
                            css.left = (winLeft + targetOffset.left + (targetElement.outerWidth() - element.outerWidth()) * alignFactor + xOffset) + 'px';
                            break;
                        case 'bottom':  // place element below target
                            css.top = (winTop + targetOffset.top + targetElement.outerHeight() + yOffset) + 'px';
                            css.left = (winLeft + targetOffset.left + (targetElement.outerWidth() - element.outerWidth()) * alignFactor + xOffset) + 'px';
                            break;
                        case 'left':  // place element to left of target
                            css.top = (winTop + targetOffset.top + (targetElement.outerHeight() - element.outerHeight()) * alignFactor + yOffset) + 'px';
                            css.left = (winLeft + targetOffset.left - element.outerWidth() + xOffset) + 'px';
                            break;
                        case 'right': // place element to right of target
                            css.top = (winTop + targetOffset.top + (targetElement.outerHeight() - element.outerHeight()) * alignFactor + yOffset) + 'px';
                            css.left = (winLeft + targetOffset.left + targetElement.outerWidth() + xOffset) + 'px';
                            break;
                        case 'over':  // place element directly over (overlapping) target
                            // these currently ignore 'align' and always center
                            css.top = (winTop + targetOffset.top + (targetElement.outerHeight() - element.outerHeight()) / 2 + yOffset) + 'px';
                            css.left = (winLeft + targetOffset.left + (targetElement.outerWidth() - element.outerWidth()) / 2 + xOffset) + 'px';
                            break;
                        }
                        css['-webkit-transition'] = '0.1s ease-in-out';
                        css['-moz-transition'] = '0.1s ease-in-out';
                        css['-o-transition'] = '0.1s ease-in-out';
                        css.transition = '0.1s ease-in-out';

                        if (isResize === true && allowScroll === true) {
                            // don't change the Z Position if the user resizes the browser
                            // this way anything that is supposed to scroll will maintain it's
                            // vertical position
                            delete css.top;
                        }

                    } else {
                        // hide the element if there's nothing to place
                        css.display = "none";
                    }
                    element.css(css);
                }

                positionHandler = _.throttle(positionElement, 30, {leading: false});
                function resizePositionHandler() { positionHandler(true); }

                setTimeout(positionHandler);

                scope.$watch(function watchVisibility() { return element.is(':visible'); },
                             function repositionOnVisibilityChange(val, prev) {
                        if (val === true && prev === false) {
                            positionHandler();
                        }
                    });

                if (slowLoad) {
                    // try to reposition the element 5 times over the next 2.5 seconds
                    // used for resources that may not be loaded when an overlay is invoked
                    for (i = 0; i < 5; i += 1) {
                        setTimeout(positionHandler, (i + 1) * 500);
                    }
                }

                topScrollElement.then(function (element) {
                    body = element;
                    // watch the underlying windows for scrolling/resizes
                    $($window).on('resize', resizePositionHandler);

                    if (allowScroll !== true) {
                        modalElement.on('scroll', positionHandler);
                        element.on('scroll', positionHandler);
                    }

                    scope.$on('$destroy', function removeEventListeners() {
                        if (allowScroll !== true) {
                            modalElement.off('scroll', positionHandler);
                            element.off('scroll', positionHandler);
                        }

                        $($window).off('resize', resizePositionHandler);
                    });


                });


            }

        };

    }]);

    module.factory('YouTubeModal', ['$modal', '$sce', function ($modal, $sce) {
        // returns a function that opens a modal and plays an embedded (iframe)
        // youtube video

        var opts = {
            templateUrl: require('helioscope/app/utilities/ng-help/youtube-modal.html'),
            controller: ['$scope', 'url', function ($scope, url) {
                if (url.indexOf('autoplay') === -1) {
                    url += (url.indexOf('?') === -1 ? '?' : '&') + 'autoplay=true';
                }

                $scope.url = $sce.trustAsResourceUrl(url);

            }],
            windowClass: 'ng-help-youtube-modal'
        };

        return function (url) {
            return $modal.open(angular.extend({}, opts, {resolve: {url: function () {return url; }}}));
        };
    }]);


    module.controller('DefaultOverlayController', ['$modalInstance', 'YouTubeModal', '$state', function ($modalInstance, YouTubeModal, $state) {
        // populate the overlay controller with key convenience methods if we
        // want to build interactive overlays
        this.close = function () {
            $modalInstance.dismiss();
        };

        this.playVideo = YouTubeModal;
        this.stateData = {};

        this.stateData.$stateParams = $state.$current.params;
        this.stateData.$resolve = $state.$current.locals.globals;
        this.stateData.$scope = _.find($state.$current.locals, function (val, key) {angular.noop(val); return key[0] === '@'; }).$scope;

    }]);

    module.factory('ContextualHelp', ['$state', '$modal',  'Messager', '$location', '$timeout', 'highlightEvents', '$rootScope', '$log', 'TopScrollElement',
        function ($state, $modal, Messager, $location, $timeout, highlightEvents, $rootScope, $log, topScrollElement) {

            var modalIsOpen = false,
                defaultModalOptions = {
                    controller: 'DefaultOverlayController',
                    controllerAs: 'OverlayController',
                    windowClass: 'full-screen-help-modal',
                    backdropClass: "full-screen-help-backdrop",
                };

            function getModalConfig(templateUrlOverride) {
                var url = templateUrlOverride || ($state.$current.data && $state.$current.data.helpOverlayUrl);

                if (url !== undefined) {
                    return angular.extend({}, defaultModalOptions, {
                        templateUrl: url
                    });
                }
            }

            function getActualHeight(element, otherHeight) {
                // recursively calculate the height of an element and all its children
                // this is necessary because absolutely positioned elements do not factor
                // into traditional height metrics
                var height = element.offset().top + element.outerHeight();

                $(element).children().each(function () {
                    height = Math.max(height, getActualHeight($(this), otherHeight || 0));
                });

                return height;
            }

            function modalDomSetup(modalInstance, modalWindowClass, topScrollElement) {
                var baseLayer = $('.modal').not("." + modalWindowClass),
                    baseLayerIsBody = false,
                    helpModal = $('.modal.' + modalWindowClass),
                    helpModalDialog = helpModal.children('.modal-dialog');

                if (baseLayer.length === 0) {
                    baseLayer = topScrollElement;
                    baseLayerIsBody = true;
                }

                helpModalDialog.click(function (evt) {
                    if (highlightEvents.checkClick(evt) || this === evt.target) {
                        // if the click is on an active highlighted element or
                        // if the user clicks directly on the dialog element
                        // (not its children), then hide the modal
                        modalInstance.close();
                    }
                });

                helpModal.on('scroll', _.throttle(function () {

                    baseLayer.scrollTop(helpModal.scrollTop());
                }, 15, {leading: false}));


                setTimeout(function () {
                    var height = getActualHeight(helpModalDialog);

                    // make the modal height the height of the underlying page
                    helpModalDialog.height(
                        Math.max(height + 10,
                                 baseLayerIsBody ? baseLayer.height()
                                                 : baseLayer.children('.modal-dialog').height())
                    );

                    // if the base layer is a modal, extend the height
                    // since this makes for a slightly better user experience
                    // and the modal height won't permanently alter the page
                    // (the same way setting the body height would)
                    if (!baseLayerIsBody) {
                        baseLayer.children('.modal-dialog').height(helpModalDialog.height());
                    }
                }, 50);
            }

            function showModal(templateUrlOverride) {
                if (modalIsOpen) {
                    $log.warn('Help Modal already open');
                    return;
                }
                var modalConfig = getModalConfig(templateUrlOverride),
                    modalInstance,
                    stateListener = angular.noop;


                if (modalConfig !== undefined) {

                    modalInstance = $modal.open(modalConfig);

                    modalInstance.opened.then(function setupHelpModal() {
                        modalIsOpen = true;

                        //add help to the parameters in location
                        $location.search("help", "true");

                        // need to manually manage modal backgrop class because
                        // it isn't automatically handled for nested modals
                        $(".modal-backdrop").addClass(modalConfig.backdropClass);

                        $timeout(function configureModal() {
                            topScrollElement.then(function (topScroll) {
                                modalDomSetup(modalInstance, modalConfig.windowClass, topScroll);
                            });


                            // close the overlay if the url changes
                            stateListener = $rootScope.$on('$locationChangeSuccess', function () {
                                modalInstance.dismiss();
                            });

                        }, 100);
                    });

                    modalInstance.result.finally(function cleanupHelpModal() {
                        modalIsOpen = false;

                        //remove help from the location's parameters
                        var parameters = $location.search();
                        delete parameters.help;
                        $location.search(parameters);

                        // need to manually manage modal backgrop class because
                        // it isn't automatically handled for nested modals
                        $(".modal-backdrop").removeClass(modalConfig.backdropClass);


                        // remove the state Change Listener
                        stateListener();
                    });

                } else {
                    Messager.info("No help view is available for this page.");
                }
            }

            return {
                showHelpModal: showModal,
                isOpen: function isOpen() {
                    return modalIsOpen;
                },
                hasHelpModal: function hasHelpModal(templateUrlOverride) {
                    return (getModalConfig(templateUrlOverride) !== undefined);
                },
                addStateListener: function addStateListeners() {
                    // give the page longer to load if it is an initial state change
                    if ($location.search().help) {
                        $timeout($rootScope.ContextualHelp.showHelpModal, 1500);
                    }

                    var first = true;
                    $rootScope.$on('$locationChangeSuccess', function () {
                        if (!first && !modalIsOpen && $location.search().help) {
                            $timeout($rootScope.ContextualHelp.showHelpModal, 250);
                        }

                        first = false;
                    });
                }
            };

        }]);

}(angular));
