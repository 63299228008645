import { Simulation } from 'helioscope/app/projects';

import * as pusher from 'helioscope/app/utilities/pusher';

export async function findOptimalTiltAzimuth(project, weatherDataset, moduleCharacterization) {
    const baseSimulation = {
        project_id: project.project_id,
        weather_dataset_id: weatherDataset.weather_dataset_id,
        module_characterization_id: moduleCharacterization.module_characterization_id,
    };

    const { channel } = await Simulation.find_optimal_tilt_azimuth(baseSimulation).$promise;
    return pusher.promiseFromChannel(channel);
}
