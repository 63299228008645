import * as THREE from 'three';

import {
    makeConvexPathGeometrySolid,
    makeConvexPathGeometryWire,
    makeConvexPathGeometryWireInstanced,
    makeSphereGeometrySolid,
    makeCylinderGeometrySolid,
    makeConeGeometrySolid,
} from './GLHelpers';
import { applyRotationToFourPoints } from './GeometryHelpers';

export class SimpleShapeBuilder {
    constructor() {
        this.shapeCache = {};
    }

    clearAllShapes() {
        for (const key of Object.keys(this.shapeCache)) {
            if (key.dispose) {
                key.dispose();
            }

            delete this.shapeCache[key];
        }
    }

    retrieveOrBuild(key, fn) {
        let cached = this.shapeCache[key];
        if (!cached) {
            cached = fn();
            this.shapeCache[key] = cached;
            this.shapeCache[key]._external = true;
        }
        return cached;
    }

    circlePath(radius, segments) {
        const path = [];
        for (let i = 0; i < segments; i++) {
            const x1 = radius * Math.cos((i / segments) * 2.0 * Math.PI);
            const y1 = radius * Math.sin((i / segments) * 2.0 * Math.PI);
            path.push(new THREE.Vector2(x1, y1));
        }
        return path;
    }

    circleFill(radius, segments) {
        const key = `circleFill_${radius}_${segments}`;
        return this.retrieveOrBuild(key,
            () => makeConvexPathGeometrySolid(this.circlePath(radius, segments)));
    }

    circleStroke(radius, segments) {
        const key = `circleStroke_${radius}_${segments}`;
        return this.retrieveOrBuild(key,
            () => makeConvexPathGeometryWire(this.circlePath(radius, segments)));
    }

    circleStrokeInstanced(radius, segments) {
        const key = `circleStrokeInstanced_${radius}_${segments}`;
        return this.retrieveOrBuild(key,
            () => makeConvexPathGeometryWireInstanced(this.circlePath(radius, segments)));
    }

    quadPath(width, height) {
        const whalf = 0.5 * width;
        const hhalf = 0.5 * height;
        return [
            new THREE.Vector2(-whalf, -hhalf, 0.0),
            new THREE.Vector2(whalf, -hhalf, 0.0),
            new THREE.Vector2(whalf, hhalf, 0.0),
            new THREE.Vector2(-whalf, hhalf, 0.0),
        ];
    }

    quadFill(width, height) {
        const key = `quadFill_${width}_${height}`;
        return this.retrieveOrBuild(key,
            () => makeConvexPathGeometrySolid(this.quadPath(width, height)));
    }

    quadFillWithRotation(width, height, rotationRadians) {
        const key = `quadFill_${width}_${height}_${rotationRadians}`;
        return this.retrieveOrBuild(key, () => {
            const rotatedQuadPath = applyRotationToFourPoints(this.quadPath(width, height), -rotationRadians);
            return makeConvexPathGeometrySolid(rotatedQuadPath);
        });
    }

    quadStroke(width, height) {
        const key = `quadStroke_${width}_${height}`;
        return this.retrieveOrBuild(key,
            () => makeConvexPathGeometryWire(this.quadPath(width, height)));
    }

    sphereSolid(thetaSteps, phiSteps, radius) {
        const key = `sphereSolid_${thetaSteps}_${phiSteps}_${radius}`;
        return this.retrieveOrBuild(key,
            () => makeSphereGeometrySolid(thetaSteps, phiSteps, radius));
    }

    coneSolid(phiSteps, baseRadius, height) {
        const key = `coneSolid_${phiSteps}_${baseRadius}_${height}`;
        return this.retrieveOrBuild(key,
            () => makeConeGeometrySolid(phiSteps, baseRadius, height));
    }

    cylinderSolid(phiSteps, radius, height) {
        const key = `cylinderSolid_${phiSteps}_${radius}_${height}`;
        return this.retrieveOrBuild(key,
            () => makeCylinderGeometrySolid(phiSteps, radius, height));
    }
}
