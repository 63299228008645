import { Simulation } from 'helioscope/app/projects';
import * as pusher from 'helioscope/app/utilities/pusher';

export class NewShadeCalculator {
    constructor(design, weatherDataset, horizon = null, options = {}) {
        this.design = design;
        this.weatherDataset = weatherDataset;
        this.horizon = horizon;
        this.options = options;
    }

    initialize() {
        return true;
    }

    makeRequest() {
        const surfacePaths = _(this.design.physicalSurfaces())
            .filter(ps => ps.castsShadows())
            .map(ps => {
                ps.recompute3DPaths();
                return ps.geometry.path_3d;
            })
            .value();

        const simpleQuads = [];

        // if (!this.options.ignoreRowToRow) {
        //     simpleQuads.push(..._(this.design.field_segments)
        //         .map(fs => fs.getRacks())
        //         .flatten()
        //         .map(rack => rack.path)
        //         .value(),
        //     );
        // }

        const moduleGroups = _.map(this.design.field_segments,
            fs => ({
                module_characterization_id: fs.module_characterization_id,
                rack_type: fs.rack_type,
                module_geometry: _.map(fs.modules(),
                    module => {
                        const parallel = module.path[1].subtract(module.path[0]);
                        const transverse = module.path[3].subtract(module.path[0]);

                        return [
                            [module.topLeft.x, module.topLeft.y, module.topLeft.z],
                            [parallel.x, parallel.y, parallel.z],
                            [transverse.x, transverse.y, transverse.z],
                        ];
                    }),
            }));

        return {
            options: { ignore_row_to_row: this.options.ignoreRowToRow },
            project_id: this.design.project.project_id,
            weather_dataset_id: this.weatherDataset.weather_dataset_id,
            horizon_id: this.horizon ? this.horizon.horizon_id : null,
            physical_surface_paths: surfacePaths,
            module_groups: moduleGroups,
            simple_quad_paths: simpleQuads,
            geometry_premades: _.map(this.design.entity_premades, i => i.geometry),
        };
    }

    makeResult(response) {
        const moduleResults = [];

        let moduleIdx = 0;

        for (const fs of this.design.field_segments) {
            const fsSubResults = [];

            for (const module of fs.modules()) {
                let acEnergy = 0;
                let poaIrradiance = 0;
                let shadedAcEnergy = 0;
                let shadedIrradiance = 0;

                const monthly = {};

                for (let month = 0; month < 12; ++month) {
                    const monthlyResult = {
                        acEnergy: response[moduleIdx][month][0],
                        poaIrradiance: response[moduleIdx][month][1],
                        shadedAcEnergy: response[moduleIdx][month][2],
                        shadedIrradiance: response[moduleIdx][month][3],
                    };

                    acEnergy += monthlyResult.acEnergy;
                    poaIrradiance += monthlyResult.poaIrradiance;
                    shadedAcEnergy += monthlyResult.shadedAcEnergy;
                    shadedIrradiance += monthlyResult.shadedIrradiance;

                    monthly[month + 1] = monthlyResult;
                }

                fsSubResults.push({
                    module,
                    acEnergy,
                    poaIrradiance,
                    shadedIrradiance,
                    shadedAcEnergy,
                    monthly,
                });

                ++moduleIdx;
            }

            moduleResults.push({
                fieldSegment: fs,
                moduleResults: fsSubResults,
            });
        }

        return moduleResults;
    }

    async getModuleResults() {
        const request = this.makeRequest();
        const { channel } = await Simulation.global_single_diode_hourly(request).$promise;
        const rawResults = await pusher.promiseFromChannel(channel);
        return this.makeResult(rawResults);
    }
}
