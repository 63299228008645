import { FieldSegment } from 'helioscope/app/designer/field_segment/FieldSegment';
import { Keepout } from 'helioscope/app/designer/keepout/Keepout';
import { EntityPremade } from 'helioscope/app/designer/premade/Premade';
import { $http } from 'helioscope/app/utilities/ng';
import { RelationalBase } from 'helioscope/app/relational';
import { fetchJSON, postJSON } from 'helioscope/app/utilities/relational';
import { MapConfig } from '../MapConfig';


export class BulkEntityChange extends RelationalBase {
    constructor(entities, changes = new Map(), undoChanges = new Map()) {
        super();

        if (!entities || entities.length === 0) {
            throw new Error("Entities cannot be undefined or empty");
        }

        this.entities = entities;
        this.design_id = entities[0].design_id;
        this.length = entities.length;

        this.changes = changes;
        this.undoChanges = undoChanges;

        this.field_segments = entities.filter(object => object instanceof FieldSegment);
        this.keepouts = entities.filter(object => object instanceof Keepout);
        this.entity_premades = entities.filter(object => object instanceof EntityPremade);
    }

    _generatePayload() {
        return {
            field_segments: this.field_segments,
            keepouts: this.keepouts,
            entity_premades: this.entity_premades,
        };
    }

    getEntityChanges(entity) {
        return this.changes.has(entity) ? {...entity, ...this.changes.get(entity)} : entity;
    }

    _generateUpdatePayload() {
        return {
            field_segments: this.field_segments.map(field_segment => {
                return this.getEntityChanges(field_segment);
            }),
            keepouts: this.keepouts.map(keepout => {
                return this.getEntityChanges(keepout);
            }),
            entity_premades: this.entity_premades.map(entity_premade => {
                return this.getEntityChanges(entity_premade);
            }),
        }
    }

    deregisterObjects() {
        this.objects.forEach(object => {
            object.$deregister();
        });
    }

    $delete() {
        return fetchJSON(
            `/api/bulk_operations/${this.design_id}`,
            { body: this._generatePayload(), method: 'DELETE' }
        )
            .then(() => {
                this.deregisterObjects();
                return this;
            })
            .catch(error => Promise.reject(error));
    }

    initializeObjects(createdObjects) {
        this.field_segments.forEach((field_segment, index) => {
            field_segment.$initialize(createdObjects.field_segments[index]);
        });
        this.keepouts.forEach((keepout, index) => {
            keepout.$initialize(createdObjects.keepouts[index]);
        });
        this.entity_premades.forEach((entity_premade, index) => {
            entity_premade.$initialize(createdObjects.entity_premades[index]);
        });
    }

    $save() {
        return postJSON(
            `/api/bulk_operations/${this.design_id}`,
            this._generatePayload()
        )
            .then((response) => {
                this.initializeObjects(response.data)
                return this;
            })
            .catch(error => Promise.reject(error));
    }

    applyEntityChanges(entity, undo = false) {
        const entityChanges = undo ? this.undoChanges.get(entity) : this.changes.get(entity);
        if (entityChanges) {
            for(const key in entityChanges) {
                entity[key] = entityChanges[key];
            }
        }
    }

    $update() {
        return fetchJSON(
            `/api/bulk_operations/${this.design_id}`,
            { body: this._generateUpdatePayload(), method: 'PUT' }
        )
            .then((response) => {
                return this;
            })
            .catch(error => Promise.reject(error));
    }
}
