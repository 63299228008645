// This file acts as a loader for old-world libraries.
// require() is used instead of import to avoid the hoisting.
// This is exploited to set up window-level variables.
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
// ~~    DO NOT ADD NEW LIBRARIES TO HERE. USE `import`   ~~
// ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
window.metrics = Object.assign({
    jsRunStartTime: performance.now(),
}, window.metrics);

window.jQuery = window.$ = require('jquery');
window.angular = require('angular');
window._ = require('lodash');
require('helioscope/libs/jquery-ui/jquery-ui-1.10.3.custom.min');
require('helioscope/libs/select2/select2');
require('angular-cookies');
require('helioscope/libs/angular-ui');
require('angular-xeditable');
require('angular-ui-router');
require('angular-messages');
require('angular-sanitize');
require('angular-animate');
window.bcCountries = require('bc-countries');
require('helioscope/libs/bc-phone-number/js/bc-phone-number');
require('helioscope/libs/pines/pnotify.custom');
require('helioscope/libs/highcharts/highcharts-ng');
require('helioscope/libs/zipjs/lib-zip-core');
require('helioscope/libs/zipjs/lib-zip-ext');
require('helioscope/libs/zipjs/lib-zip-fs');
require('helioscope/libs/zipjs/mime-types');
window.Highcharts = require('highcharts');
