import { Vector } from 'helioscope/app/utilities/geometry';
import { PrimitiveMeshFill } from './Primitives';
import { WidgetSphereTreeCollection } from './WidgetPremade';

const PREMADE_TREE_SPHERE_RENDER_CONFIG = {
    FILL_COLOR_UNSELECTED: '#22cc22',
    FILL_COLOR_SELECTED: '#eeff44',
    FILL_COLOR_HIGHLIGHT: '#ccf044',
};

export class RenderablePremadeTreeSphere {
    constructor(renderer, premade) {
        this.renderer = renderer;
        this.premade = premade;
    }

    clearRenderable() {
        this.clearTree();
        this.clearWidgets();
    }

    renderRenderable(options_) {
        this.clearRenderable();

        const options = options_ || this.options;
        this.renderTree(options);

        if (this.renderer.options.enableEditing && options.editable && !options.dragging) {
            this.createWidgets();
        }

        this.options = options;
    }

    updateRenderable(options) {
        const updated = _.assign(this.options, options);
        this.renderRenderable(updated);
    }

    setParameterOverrides(parameters) {
        this.parameterOverrides = parameters;
    }

    offsetRenderablePosition(delta) {
        const {
            position,
            position_3d: position3D,
            bot_height: botHeight,
            top_radius: topRadius,
        } = this.premade.geometry.parameters;
        const basePoint = position3D || position;

        this.topPrimitive.setRenderPosition(
            new Vector(basePoint.x + delta.x, basePoint.y + delta.y, basePoint.z + botHeight + topRadius));
        this.botPrimitive.setRenderPosition(
            new Vector(basePoint.x + delta.x, basePoint.y + delta.y, basePoint.z));
    }

    renderTree(options) {
        const selectionData = {
            type: 'Premade',
            selectionPriority: 100,
            object: this.premade,
            draggablePremade: true,
        };

        let fillColor = options.fillColor || PREMADE_TREE_SPHERE_RENDER_CONFIG.FILL_COLOR_UNSELECTED;
        if (options.highlight) fillColor = PREMADE_TREE_SPHERE_RENDER_CONFIG.FILL_COLOR_HIGHLIGHT;

        const parameters = this.parameterOverrides || this.premade.geometry.parameters;
        const {
            position,
            position_3d: position3D,
            top_radius: topRadius,
            bot_radius: botRadius,
            bot_height: botHeight,
        } = parameters;
        const basePoint = position3D || position;

        const scene = this.renderer.physicalSurfaceLayer;
        const material = this.renderer.inlineShaderMaterial('vertexShaderNormal', 'fragmentShaderNormal');

        const topGeometry = this.renderer.shapeBuilder.sphereSolid(12, 24, 1.0);

        const topOptions = {
            scene,
            material,
            geometry: topGeometry,
            fillColor,
        };
        this.topPrimitive = this.renderer.renderPrimitive(PrimitiveMeshFill, topOptions);
        this.topPrimitive.setRenderScale(new Vector(topRadius, topRadius, topRadius));
        this.topPrimitive.setRenderPosition(new Vector(
            basePoint.x,
            basePoint.y,
            basePoint.z + botHeight + topRadius));

        const botGeometry = this.renderer.shapeBuilder.cylinderSolid(12, 1.0, 1.0);

        const botOptions = {
            scene,
            material,
            geometry: botGeometry,
            fillColor,
        };
        this.botPrimitive = this.renderer.renderPrimitive(PrimitiveMeshFill, botOptions);
        this.botPrimitive.setRenderScale(new Vector(botRadius, botRadius, botHeight + topRadius));
        this.botPrimitive.setRenderPosition(new Vector(basePoint.x, basePoint.y, basePoint.z));

        if (selectionData) {
            const dummyMat = this.renderer.graphicResourceCache.dummyMaterial;

            this.topSelectionPrimitive = this.renderer.renderPrimitive(PrimitiveMeshFill,
                {
                    scene: this.renderer.selectionLayerScene,
                    material: dummyMat,
                    geometry: topGeometry,
                    selectionData,
                });
            this.topSelectionPrimitive.setRenderScale(new Vector(topRadius, topRadius, topRadius));
            this.topSelectionPrimitive.setRenderPosition(
                new Vector(basePoint.x, basePoint.y, basePoint.z + botHeight + topRadius),
            );

            this.botSelectionPrimitive = this.renderer.renderPrimitive(PrimitiveMeshFill,
                {
                    scene: this.renderer.selectionLayerScene,
                    material: dummyMat,
                    geometry: botGeometry,
                    selectionData,
                });
            this.botSelectionPrimitive.setRenderScale(new Vector(botRadius, botRadius, botHeight + topRadius));
            this.botSelectionPrimitive.setRenderPosition(new Vector(basePoint.x, basePoint.y, basePoint.z));
        }
    }

    clearTree() {
        if (this.topPrimitive) {
            this.topPrimitive.clearInstances();
            this.topPrimitive = null;
        }

        if (this.botPrimitive) {
            this.botPrimitive.clearInstances();
            this.botPrimitive = null;
        }

        if (this.topSelectionPrimitive) {
            this.topSelectionPrimitive.clearInstances();
            this.topSelectionPrimitive = null;
        }

        if (this.botSelectionPrimitive) {
            this.botSelectionPrimitive.clearInstances();
            this.botSelectionPrimitive = null;
        }
    }

    createWidgets(options) {
        this.widgetCollection = new WidgetSphereTreeCollection(this.renderer, this.premade);
        this.widgetCollection.createWidget(options);
    }

    clearWidgets() {
        if (this.widgetCollection) {
            this.widgetCollection.clearWidget();
            this.widgetCollection = null;
        }
    }
}
