import { RelationalBase, relationship } from 'helioscope/app/relational';
import { FileUploaderS3, Messager, $http } from 'helioscope/app/utilities/ng';


export class ModuleFile extends RelationalBase {
    static relationName = 'ModuleFile';

    static requestUploadUrl(moduleId, filename) {
        return $http.post('/api/module_files/request_upload', {
            module_id: moduleId, filename,
        });
    }
}

ModuleFile.createEndpoint(
    '/api/module_files/:module_file_id',
    { module_file_id: '@module_file_id' },
    {
        update: { method: 'PUT', isArray: false },
    },
);


ModuleFile.MAX_FILE_SIZE = 10000000; // 10MB

ModuleFile.configureRelationships({
    module: relationship('Module', { backref: 'module_files' }),
});


export class PowerDeviceFile extends RelationalBase {
    static relationName = 'PowerDeviceFile';

    static requestUploadUrl(powerDeviceId, filename) {
        return $http.post('/api/power_device_files/request_upload', {
            power_device_id: powerDeviceId, filename,
        });
    }
}

PowerDeviceFile.createEndpoint(
    '/api/power_device_files/:power_device_file_id',
    { power_device_file_id: '@power_device_file_id' },
    {
        update: { method: 'PUT', isArray: false },
    },
);


PowerDeviceFile.MAX_FILE_SIZE = 10000000; // 10MB

PowerDeviceFile.configureRelationships({
    power_device: relationship('PowerDevice', { backref: 'power_device_files' }),
});


function buildUploadCreator(ComponentFileClass, serverKeyName) {
    return function createFileUploader(primaryKeyId) {
        const uploader = new FileUploaderS3({
            removeAfterUpload: true,
            filters: [
                {
                    name: 'maxSize',
                    fn: item => item.size < ComponentFileClass.MAX_FILE_SIZE,
                },
            ],
        });

        uploader.onAfterAddingFile = (fileItem) => {
            const filename = fileItem.file.name;

            fileItem.notification = Messager.load(`Uploading ${filename}`);

            // ask the server to give us a signed URL
            ComponentFileClass.requestUploadUrl(primaryKeyId, filename)
                .catch(() => fileItem.notification.error(`Could not upload ${filename}`))
                .then(resp => {
                    fileItem.url = resp.data.put_url;
                    fileItem.upload();
                });

            return true;
        };

        uploader.onSuccessItem = (fileItem) => {
            const componentFile = new ComponentFileClass({
                [serverKeyName]: primaryKeyId,
                filename: fileItem.file.name,
            });

            componentFile.$save()
                .catch(() => fileItem.notification.error(`Could not upload ${fileItem.file.name}`))
                .then(() => fileItem.notification.success(`Uploaded ${fileItem.file.name}`));
        };

        uploader.onErrorItem = (fileItem) => fileItem.notification.error(`Could not upload ${fileItem.file.name}`);

        uploader.onWhenAddingFileFailed = (item, filter) => {
            if (filter.name === 'maxSize') {
                Messager.error(`${item.name} is larger than 10MB, please choose a smaller file`);
            } else {
                Messager.error(`Could not upload ${item.name}`);
            }
        };

        return uploader;
    };
}

export const createModuleFileUploader = buildUploadCreator(ModuleFile, 'module_id');
export const createDeviceFileUploader = buildUploadCreator(PowerDeviceFile, 'power_device_id');
