/*global angular:true, _:true */
(function (angular, _) {
    'use strict';

    var mod = angular.module('helioscope.libraries.state_builder',
                             ['helioscope.services', 'ngGrid']);


    mod.provider('libraryState', [function () {

        function mainControllerFactory(resource, config) {

            return ['$scope', '$state', 'Messager', '$log', resource, config.list.config,
                    function ($scope, $state, messager, $log, resource, grid_config) {

                    $scope.$on('$destroy', function cleanup() {
                        if (resource.clear !== undefined) {
                            $log.log("Cleaning up " + config.name + " cache");
                            resource.clear();
                        }
                    });

                    $scope.items = [];

                    var preventSelect = false,
                        _infHadDataLast = true,
                        _infGettingData = false;

                    $scope.selectedItems = [];
                    $scope.search = {};

                    $scope.updateFavorite = function (item) {
                        preventSelect = true;
                        item.favorite = !item.favorite;
                        var notification = messager.load("Updating status");
                        item.$update(function () {
                            notification.success("Successfully updated status");
                        }, function (resp) {
                            notification.error("Could not updated status");
                            item.favorite = !item.favorite;
                            $log.warn(resp);
                        });
                    };



                    function getData(query, sort) {
                        if (query !== undefined || sort !== undefined) {
                            //reset the search data to force it through
                            _infHadDataLast = true;
                            _infGettingData = false;
                        }

                        if ((!_infHadDataLast || _infGettingData) && query === undefined) {
                            return;
                        }

                        $log.log("Fetching Data");
                        sort = sort || $scope.gridOptions.sortInfo;
                        _infGettingData = true;

                        var notification = messager.load("Loading more...",
                            {
                                'stack': {
                                    addpos2: 0,
                                    animation: false,
                                    dir1: "up",
                                    dir2: "left",
                                    firstpos1: 25,
                                    firstpos2: 25,
                                    nextpos1: 25,
                                    nextpos2: 25
                                },
                                'addclass': "stack-bottomright"
                            }),
                            count = query ? 0 : $scope.items.length,
                            search = query || $scope.search,
                            queryString = angular.extend(
                                {offset: count, limit: 150},
                                search,
                                {sort: _.reduce(_.zip(sort.fields, sort.directions),
                                                function (memo, x) {return memo + x[0] + " " + x[1] + ","; }, "")}
                            );

                        resource.query(queryString, function (items) {
                            notification.close();
                            if (query !== undefined) {
                                $scope.items = items;
                            } else {
                                $scope.items.push.apply($scope.items, items);
                            }

                            _infHadDataLast = (items.length > 0);
                            _infGettingData = false;

                        }, function (err) {
                            notification.error("Error");
                            $log.log("Error loading...");
                            $log.log(err);
                        });
                    }

                    $scope.$watch('search', _.debounce(function (cur) {
                        if (_.keys(cur).length > 0) {
                            $scope.$apply(function () { getData($scope.search); });
                        }
                    }, 250), true);

                    $scope.$on('ngGridDataRequest', function () {
                        getData();
                    });

                    $scope.$watch('gridOptions.sortInfo', function (sortInfo) {
                        if (sortInfo.columns !== undefined) {
                            getData($scope.search, sortInfo);
                        }
                    }, true);

                    $scope.mySelection = [];
                    $scope.gridOptions = {
                        data: 'items',
                        showFilter: false,
                        filterOptions: {useExternalFilter: true},
                        useExternalSorting: true,
                        showColumnMenu: true,
                        multiSelect: false,
                        enableColumnResize: true,
                        infinite: {
                            threshold: 75
                        },
                        selectedItems: $scope.mySelection,
                        sortInfo: grid_config.sortInfo,
                        columnDefs: grid_config.columnDefs,
                        beforeSelectionChange: function () {
                            if (preventSelect) {
                                preventSelect = false;
                                return false;
                            }
                            return true;
                        }
                    };

                    $scope.$watch('mySelection', function (items) {
                        if (items.length > 0) {

                            var item = items[0],
                                target = {};

                            target[config.id] = item[config.id];
                            target[config.characterizationId] = item.defaultCharacterizationId();

                            $state.go(config._states.characterization_detail, target);
                        }
                    }, true);

                }];
        }

        function getCharacterizationListController(resource, config) {
            return ['$scope', '$modalInstance', 'characterizations', 'Messager', '$log', '$state', resource,
                    function ($scope, $modalInstance, characterizations, messager, $log, $state, resource) {

                    $scope.item = resource.cached(characterizations[0][config.id]);

                    if ($scope.$state.current.name === config._states.characterizations) {
                        var target = {};

                        target[config.id] = $scope.item[config.id];
                        target[config.characterizationId] = $scope.item.defaultCharacterizationId();

                        $state.go(config._states.characterization_detail, target, {location: 'replace'});
                    }

                    $scope.characterizations = characterizations;
                    $scope.savedItem = angular.copy($scope.item);

                    $scope.updateFavorite = function () {
                        $scope.item.favorite = !$scope.item.favorite;
                        $scope.updateItem($scope.item);
                    };

                    $scope.updateItem = function (item) {
                        var notification = messager.load("Updating...");
                        item.$update(function () {
                            notification.success("Successfully updated.");
                            $scope.savedItem = angular.copy($scope.item);
                        }, function (resp) {
                            notification.error("Could not updated...");
                            angular.extend(item, $scope.savedItem);
                            $log.warn(resp);
                        });
                    };

                    $scope.close = function () {
                        $modalInstance.close();
                    };
                }];
        }

        function getCharacterizationDetailController(characterizationId) {
            return ['$scope', '$stateParams', function ($scope, $stateParams) {

                $scope.characterization = _.find(
                    $scope.characterizations,
                    function (c) {return c[characterizationId] === parseInt($stateParams[characterizationId], 10); }
                );
                // bit of a hack to get characterization info in the CharacterizationListController
                $scope.$parent.characterization = $scope.characterization;
            }];
        }

        function providerFactory(resource, request, $stateParams_) {
            request = request || 'get';

            return [resource, '$stateParams', '$q', function (Resource, $stateParams, $q) {

                $stateParams = $stateParams_ || $stateParams;

                var deferred = $q.defer();

                Resource[request]($stateParams,
                    function (module) {
                        deferred.resolve(module);
                    },
                    function (resp) {
                        deferred.reject({type: resp.status, message: "Could not load the " + resource});
                    });

                return deferred.promise;
            }];
        }
        this.patchStateProvider = function ($stateProvider) {
            $stateProvider.libraryState = function (config) {
                var name = config.name,
                    resource = config.resource,
                    characterizationResource = config.characterizationResource,
                    id = config.id,
                    characterizationId = config.characterizationId;

                config._states = {};
                config._states.list = config.baseName + "." + config.name;
                config._states.details = config._states.list + ".specs";
                config._states.characterizations = config._states.details + ".characterization";
                config._states.characterization_detail = config._states.characterizations + ".detail";

                return $stateProvider.
                    state(config._states.list, {
                        controller: mainControllerFactory(resource, config),
                        url: "/" + name,
                        templateUrl: config.list.templateUrl,
                        data: config.list.data
                    }).
                    state(config._states.details, {
                        url: '/{' + id + ':[0-9]+}',
                        'abstract': true,
                        resolve: {
                            //this resolve is necessary to load the item into the RelationalCache, making it available
                            'item': providerFactory(resource)
                        }
                    }).
                    modalState(config._states.characterizations, {
                        url: '/characterization',
                        baseState: config._states.list,
                        modalOptionsFactory: ['$stateParams', function ($stateParams) {
                            return {
                                windowClass: 'full-screen-modal',
                                controller: getCharacterizationListController(resource, config),
                                templateUrl: config.characterizations.templateUrl,
                                resolve: {
                                    'characterizations': providerFactory(characterizationResource, 'query', $stateParams),
                                }
                            };
                        }]
                    }).
                    state(config._states.characterization_detail, {
                        url: '/{' + characterizationId + ':[0-9]+}',
                        views: {
                            'characterization@': {
                                templateUrl: config.characterizationDetail.templateUrl,
                                controller: getCharacterizationDetailController(characterizationId, config),
                                data: config.characterizationDetail.data
                            }
                        },
                    });
            };
        };

        this.$get = function () {
            return angular.noop;
        };
    }]);


}(angular, _));
