/*global angular:true*/

(function (angular) {
    'use strict';
    var mod = angular.module('helioscope.projects.controllers.scenarios', ['helioscope.services', 'ui', 'helioscope.projects.resources']);

    mod.controller('ProjectScenarioCtrl', ['$scope', 'Scenario', function ($scope, Scenario) {
        if ($scope.projectData.scenarios === undefined) {
            $scope.projectData.scenarios = Scenario.query({project_id: $scope.project.project_id});
        }
    }]);

    mod.controller('ProjectScenarioDetailCtrl', ['$scope', 'scenario', '$modalInstance',
                                                 function ($scope, scenario, $modalInstance) {
            $scope.scenario = scenario;

            $scope.close = function () {
                $modalInstance.close();
            };
        }]);
}(angular));
