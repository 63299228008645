import _ from 'lodash';

import { $q } from 'helioscope/app/utilities/ng';
import { relationship, deserializeObject } from 'helioscope/app/relational';
import { bufferPolygonMulti, differencePaths, defaultAzimuth, toRadians, Vector } from 'helioscope/app/utilities/geometry';
import { getValueOrDefault } from 'helioscope/app/utilities/helpers';
import { Module, ModuleCharacterization } from 'helioscope/app/libraries';

import { PhysicalSurface } from './PhysicalSurface';
import { LayoutRegion, LAYOUT_ENGINES } from './racking';

// want to keep specific racking dimensions around, but don't
// want to persist the full details all the time
const defaultRackingCache = new WeakMap();


function azimuthDir(azimuth) {
    const azRad = toRadians(azimuth);
    return new Vector(-Math.sin(azRad), -Math.cos(azRad), 0);
}

export function getPathLowPoint(path, azimuth) {
    const az = azimuthDir(azimuth);
    return _.minBy(path, pt => az.dot(pt));
}

function getPathHighPoint(path, azimuth) {
    const az = azimuthDir(azimuth);
    return _.maxBy(path, pt => az.dot(pt));
}

export class FieldSegment extends PhysicalSurface {
    static relationName = 'FieldSegment';

    static DefaultPropertyValues = Object.freeze({
        tilt: 15,
        azimuth: 180,
        rotation: 0.0,
        alignment: 'left',
        orientation: 'horizontal',
        row_spacing: 0.5,
        module_spacing: 0.01,
        dome_spacing: 0.0,
        bank_width: 1,
        bank_depth: 1,
        independent_tilt_surface_tilt: 0,
        independent_tilt_surface_azimuth: (fs) =>  defaultAzimuth(fs.design),
    });

    get module_id() {
        return this.module_characterization ? this.module_characterization.module_id : null;
    }

    set module_id(newVal) {
        newVal = Number(newVal);
        if (this.design && this.design.field_segments) {
            const otherFs = this.design.field_segments.filter(fs => (
                fs.module_id === newVal && fs.module_characterization_id
            ));
            if (otherFs.length > 0) {
                this.module_characterization_id = otherFs[0].module_characterization_id;
                return;
            }
        }
        const module = Module.cached(newVal);
        this.module_characterization_id = module && module.defaultCharacterizationId();
    }

    surfaceAzimuth() {
        if (this.independentTiltEnabled) {
            return getValueOrDefault(this, 'independent_tilt_surface_azimuth', FieldSegment.DefaultPropertyValues);
        }

        return getValueOrDefault(this, 'azimuth', FieldSegment.DefaultPropertyValues);
    }

    surfaceTilt() {
        if (this.rack_type === 'flush' || this.rack_type === 'carport') {
            return getValueOrDefault(this, 'tilt', FieldSegment.DefaultPropertyValues);
        }

        if (this.independentTiltEnabled) {
            return getValueOrDefault(this, 'independent_tilt_surface_tilt', FieldSegment.DefaultPropertyValues);
        }

        return 0;
    }

    moduleFill(racking = this.layoutEngine().generateLayout()) {
        defaultRackingCache.set(this, racking);

        const moduleCount = _.sumBy(racking, 'moduleCount');
        const frameCount = _.sumBy(racking, 'frameCount');

        this.data = {
            modules: moduleCount,
            frames: frameCount,
            power: this.modulePower() * moduleCount,
            area: this.surfaceArea(),
        };

        return racking;
    }

    calculateMaxModules() {
        const moduleCount = this.layoutEngine().calculateMaxModules();
        return {
            modules: moduleCount,
            power: this.modulePower() * moduleCount,
            area: this.surfaceArea(),
        };
    }

    /**
     * todo update to use a frozen point or let user set (like keepouts);
     */
    referencePoint() {
        const pt = getPathLowPoint(this.geometry.path || [], this.surfaceAzimuth());
        return pt || new Vector(0, 0, 0);
    }

    castsShadows() {
        return this.shadow_caster && (this.reference_height > 0 || this.surfaceTilt() > 0);
    }

    receivesShadows() {
        return true;
    }

    getRacks() {
        return defaultRackingCache.get(this);
    }

    serializeRacks() {
        return _(this.getRacks())
            .map(({ racking_structure_id, tracker_pivot }) => ({
                racking_structure_id, tracker_pivot,
            }))
            .keyBy('racking_structure_id')
            .value();
    }

    modulePower() {
        return this.module_characterization ? this.module_characterization.power : 240;
    }

    moduleCount() {
        return _.get(this, 'data.modules', 0);
    }

    /**
     * returns an array of paths where you can put modules
     */
    layoutPaths() {
        return this.design.designScene().layoutPaths(this);
    }

    move(shift, { shiftPath = false } = {}) {
        if (this.geometry.layout_start) {
            this.geometry.layout_start = shift.add(this.geometry.layout_start);
        }

        if (this.geometry.removed_module_locations) {
            this.geometry.removed_module_locations = (
                this.geometry.removed_module_locations.map(loc => shift.add(loc))
            );
        }

        if (shiftPath && this.racking && this.racking.manual_modules) {
            this.racking.manual_modules = this.racking.manual_modules.map(manualModule => ({
                ...manualModule,
                top_left: shift.add(new Vector(manualModule.top_left)),
            }));
        }
        if (shiftPath && this.geometry.path) {
            // optionally shift the path, because when an event comes from the map, the path
            // has already been moved
            this.geometry.path = this.geometry.path.map(loc => shift.add(loc));
        }
    }

    layoutEngine(paths = null, params = null) {
        const engine = LAYOUT_ENGINES()[this.rack_type];

        if (paths && params) {
            return new engine(new LayoutRegion(paths, this, params));
        } else {
            return new engine(this.design.designScene().layoutRegion(this));
        }
    }

    layoutParams() {
        const region = this.design.designScene().layoutRegion(this);
        if (region) return region.params;
        return null;
    }

    modules() {
        return _(this.getRacks())
                .map(rack => rack.getModules())
                .flatten()
                .value();
    }

    get moduleAzimuth() {
        return getValueOrDefault(this, 'azimuth', FieldSegment.DefaultPropertyValues);
    }

    get bankWidth() {
        return this.bank_width || FieldSegment.getDefaultValue('bank_width');
    }

    get bankDepth() {
        return this.bank_depth || FieldSegment.getDefaultValue('bank_depth');
    }

    get rowSpacing() {
        return getValueOrDefault(this, 'row_spacing', FieldSegment.DefaultPropertyValues);
    }

    get moduleSpacing() {
        return getValueOrDefault(this, 'module_spacing', FieldSegment.DefaultPropertyValues);
    }

    /**
     * frame spacing defaults to module spacing when frame spacing is not defined or 0
     */
    get frameSpacing() {
        return this.frame_spacing || this.moduleSpacing;
    }

    /**
     * The span-to-rise ratio is based on the front-to-back distance between modules, divided
     * by the height at the back of the module bank.
     * This ratio is used to calculate the row spacing.
     */
    get spanToRise() {
        const frameVector = this.layoutEngine().frameVector();
        const rowSpacing = this.rowSpacing;

        return rowSpacing / frameVector.z;
    }

    set spanToRise(ratio) {
        if (ratio === null || ratio === undefined) return;

        const frameVector = this.layoutEngine().frameVector();
        this.row_spacing = frameVector.z * ratio;
    }

    /**
     * Ground Coverage Ratio (GCR) is calculated as the ratio of the active modules to the footprint
     * of the ground taken up each frame and its spacing.
     * For independent tilt, takes the footprint area and projects it onto the ground.
     * The ground projection area is computed by multiplying the surface footprint area by cos(surface_tilt).
     *
     * Tech design - https://www.notion.so/aurorasolar/Ground-Coverage-Ratio-Proposal-for-Independent-Tilt-c0ccc437c35648baa2af69cc7c20368b
     */
    get groundCoverageRatio() {
        const bankDepth = this.bankDepth;
        const bankWidth = this.bankWidth;
        const rowSpacing = this.rowSpacing;
        const frameSpacing = this.frameSpacing;
        const frameVector = this.layoutEngine().frameVector();

        // module area
        const moduleArea = (this.module_characterization.length * bankWidth) * (this.module_characterization.width * bankDepth);
        // footprint area
        const frameFootprintArea = (frameVector.y + rowSpacing) * (frameVector.x + frameSpacing);

        if (this.independentTiltEnabled) {
            return moduleArea / (frameFootprintArea * Math.cos(toRadians(this.surfaceTilt())));
        }

        return moduleArea / frameFootprintArea;
    }

    set groundCoverageRatio(ratio) {
        if (ratio === null || ratio === undefined) return;

        const bankDepth = this.bankDepth;
        const bankWidth = this.bankWidth;
        const frameSpacing = this.frameSpacing;
        const frameVector = this.layoutEngine().frameVector();

        // module area
        const moduleArea = (this.module_characterization.length * bankWidth) * (this.module_characterization.width * bankDepth);
        // frame footprint length
        const frameFootprintLength = frameVector.x + frameSpacing;
        // frame footprint width
        const frameFootprintWidth = frameVector.y;

        if (this.independentTiltEnabled) {
            const cosSurfaceTilt = Math.cos(toRadians(this.surfaceTilt()));
            this.row_spacing = (moduleArea / (ratio * frameFootprintLength * cosSurfaceTilt)) - frameFootprintWidth;
        } else {
            this.row_spacing = ((moduleArea / ratio) / (frameVector.x + frameSpacing)) - frameVector.y;
        }
    }

    linkedPropertyCallback(changes) {
        // reset manual modules if needed
        const paths = _.map(changes, i => i.path);

        if (paths.find(i => i === 'racking.manual_modules')) return [];

        if ((paths.length === 1 && paths[0] === 'geometry.path') &&
            (this.racking && this.racking.manual_modules)) {
            const valids = this.cullManualModules(this.racking.manual_modules, changes[0].newVal);
            return [{ path: 'racking', oldVal: this.racking, newVal: { manual_modules: valids } }];
        }

        if ((paths.length === 1 && paths[0] === 'tilt') &&
            (this.racking && this.racking.manual_modules)) {
            const modules = this.tiltManualModules(this.racking.manual_modules, changes[0].newVal, changes[0].oldVal);
            const valids = this.cullManualModules(modules);
            return [{ path: 'racking', oldVal: this.racking, newVal: { manual_modules: valids } }];
        }

        const rackingPaths = _.intersection(
            paths, ['azimuth', 'tilt', 'independent_tilt_surface_azimuth', 'independent_tilt_surface_tilt', 'geometry', 'geometry.path', 'rack_type', 'orientation', 'alignment']);

        if (rackingPaths.length) {
            let linkedChanges = [
                { path: 'racking', oldVal: this.racking, newVal: {} },
                {
                    path: 'geometry.removed_module_locations',
                    oldVal: this.geometry.removed_module_locations,
                    newVal: [],
                },
            ];

            if (paths.length === 1 && paths[0] === 'rack_type' && this.rack_type !== 'rack' &&
                this.independent_tilt_enabled) {
                linkedChanges = linkedChanges.concat([
                    {
                        path: 'independent_tilt_enabled',
                        oldVal: this.independent_tilt_enabled,
                        newVal: false,
                    },
                    {
                        path: 'independent_tilt_surface_azimuth',
                        oldVal: this.independent_tilt_surface_azimuth,
                        newVal: undefined,
                    },
                    {
                        path: 'independent_tilt_surface_tilt',
                        oldVal: this.independent_tilt_surface_tilt,
                        newVal: undefined,
                    },
                ]);
            }

            return linkedChanges;
        }

        return [];
    }

    tiltManualModules(modules, newTilt, oldTilt) {
        const highPoint = getPathHighPoint(this.geometry.path, this.azimuth);
        const az = azimuthDir(this.azimuth);
        const ratio = Math.cos(toRadians(newTilt)) / Math.cos(toRadians(oldTilt));

        return modules.map(i => {
            const delta = new Vector(i.top_left.x, i.top_left.y).subtract(highPoint);
            const azProj = az.scale(delta.dot(az));
            const azOrth = delta.subtract(azProj);
            const newDelta = azProj.scale(ratio).add(azOrth);
            const newPt = highPoint.add(newDelta);
            return _.assign({}, i, { top_left: _.assign({}, newPt) });
        });
    }

    cullManualModules(modules, path = this.geometry.path) {
        const paths = bufferPolygonMulti(path, -this.innerSetback || 0);

        const worldPath = (overrides) => {
            const params = _.assign({}, this.layoutParams(), overrides);
            params.tilt = params.module_tilt;
            const measureEngine = this.layoutEngine([], params);
            const pathfn = measureEngine.frameLayout().modulePathCreator();
            return pathfn({ topLeft: new Vector(0.0, 0.0, 0.0), rotation: 0.0 });
        };

        const horzPath = worldPath({ orientation: 'horizontal' });
        const vertPath = worldPath({ orientation: 'vertical' });

        const valids = modules.filter(i => {
            if (i.orientation === 'vertical') {
                const diff = differencePaths(vertPath.map(j => j.add(i.top_left)), paths);
                return diff.length === 0;
            } else {
                const diff = differencePaths(horzPath.map(j => j.add(i.top_left)), paths);
                return diff.length === 0;
            }
        });

        return valids;
    }

    get independentTiltEnabled() {
        return this.independent_tilt_enabled && this.rack_type === 'rack';
    }

    loadDependencies() {
        return $q.all([
            (this.module_characterization_id && !this.module_characterization) &&
            ModuleCharacterization.get({ module_characterization_id: this.module_characterization_id }).$promise,
        ]).then(() => this);
    }

    toString() {
        return this.description;
    }

    toJSON() {
        const rtn = super.toJSON();

        rtn.geometry.inner_setbacks_3d = (
            this.design.designScene().layoutManager.getExclusionPathsForRenderer(this, false)
        );

        return rtn;
    }
}

FieldSegment.configureRelationships({
    design: relationship('Design', { backref: 'field_segments' }),
    wiring_zone: relationship('WiringZone', { backref: 'field_segments' }),
    module_characterization: relationship(ModuleCharacterization),
    'geometry.path': deserializeObject(Array(Vector)),
    'geometry.path_3d': deserializeObject(Array(Vector)),
    'geometry.base_3d': deserializeObject(Array(Vector)),
    'geometry.layout_start': deserializeObject(Vector),
    'geometry.removed_module_locations': deserializeObject(Array(Vector)),
});


FieldSegment.createEndpoint('/api/field_segments/:field_segment_id',
                            { field_segment_id: '@field_segment_id' },
                            { update: { method: 'PUT', isArray: false } });
