/* global angular:true */
import { $filter, ngRefs as ng } from 'helioscope/app/utilities/ng';

import { Vector, GeoPoint } from 'helioscope/app/utilities/geometry';
import { RelationalBase, relationship, baseResourceFactory, deserializeObject } from 'helioscope/app/relational';
import { user } from 'helioscope/app/users/auth';
import { Team } from 'helioscope/app/users/teams';

import { rootFind, minimize } from 'helioscope/app/utilities/optimization';


export class AcConfig extends RelationalBase {
    static relationName = 'AcConfig';

    toString() {
        return this.name;
    }
}

AcConfig.configureRelationships();
AcConfig.createEndpoint('/api/ac_configs/:ac_config_id', { ac_config_id: '@ac_config_id' },
                        { query: { method: 'GET', isArray: true, cache: true } });

export class PowerDevice extends RelationalBase {
    static relationName = 'PowerDevice';

    constructor(data) {
        super(data);

        // hack to report correct optimizer power until things are fully
        // wired correctly in helioscope
        if (this.modules_per_optimizer !== undefined && this.modules_per_optimizer > 1) {
            this.max_power *= this.modules_per_optimizer;
        }
    }

    defaultCharacterizationId() {
        return this.user_default_characterization_id || this.default_characterization_id;
    }

    defaultBreakerSize() {
        return this.default_breaker_size || 20;
    }

    teamName() {
        return this.team ? this.team.name : 'Folsom Labs';
    }

    toString(includePower) {
        let power = this.max_power;
        if (power > 1000) {
            power = `${power / 1000}k`;
        }

        return `${this.manufacturer}, ${this.name}` + (includePower ? ` (${power}W)` : '');
    }
}

PowerDevice.configureRelationships({
    ac_config: relationship(AcConfig),
    team: relationship(Team),
});

PowerDevice.createEndpoint(
    '/api/power_devices/:power_device_id',
    { power_device_id: '@power_device_id' },
    {
        update: { method: 'PUT', isArray: false },
        project_inverters: {
            method: 'GET',
            isArray: true,
            url: '/api/power_devices/project_inverters/',
        },
        project_optimizers: {
            method: 'GET',
            isArray: true,
            url: '/api/power_devices/project_optimizers/',
        },
    },
);


export class Module extends RelationalBase {
    static relationName = 'Module';

    defaultCharacterizationId() {
        return this.user_default_characterization_id || this.default_characterization_id;
    }

    defaultCharacterization() {
        return this.user_default_characterization || this.default_characterization;
    }

    operatingConditions() {
        return `${+this.defaultCharacterization().v_mp.toFixed(3)}V, \
                ${+this.defaultCharacterization().i_mp.toFixed(3)}A`;
    }

    teamName() {
        return this.team ? this.team.name : 'Folsom Labs';
    }

    toString() {
        return `${this.manufacturer}, ${this.name} (${this.power}W)`;
    }
}

Module.configureRelationships({
    team: relationship(Team),
    default_characterization: relationship('ModuleCharacterization'),
    user_default_characterization: relationship('ModuleCharacterization'),
});

Module.createEndpoint(
    '/api/modules/:module_id', { module_id: '@module_id' },
    {
        update: { method: 'PUT', isArray: false },
        project_modules: { method: 'GET', isArray: true, url: '/api/modules/project_modules/' },
    },
);

export class ModuleCharacterization extends RelationalBase {
    static relationName = 'ModuleCharacterization';

    downloadUrl() {
        return 'api/module_characterizations/' + this.module_characterization_id + '/pan';
    }

    implementation(irradiance, temp) {
        if (this.module_model_name === 'pvsyst') {
            return new ng.PVSystDiodeModule(this, irradiance, temp);
        }

        return new ng.FullDiodeModule(this, irradiance, temp);
    }

    get vector() {
        return new Vector(this.length, this.width, 0);
    }
}

ModuleCharacterization.configureRelationships({
    module: relationship(Module),
});

ModuleCharacterization.createEndpoint('/api/module_characterizations/:moduleCharacterizationId',
                                      {moduleCharacterizationId: '@module_characterization_id'});

export const PowerDeviceCharacterization = baseResourceFactory('/api/power_device_characterizations/:characterizationId',
                                                               {'characterizationId': '@power_device_characterization_id'});


export class Wire extends RelationalBase {
    static relationName = 'Wire';

    toString() {
        return `${this.name} (${this.material})`;
    }
}

Wire.configureRelationships({}, { id: 'wire_gauge_id' });
Wire.createEndpoint('/api/wires/:wire_gauge_id', {wire_gauge_id: '@wire_gauge_id'},
                    {'query': {method: 'GET', isArray: true, cache: true}});


    // var upperCase = $filter('uppercase'),
export class WeatherSource extends RelationalBase {
    static relationName = 'WeatherSource';

    constructor(data) {
        super(data);

        this.latJitter = 0;
        this.lngJitter = 0;
    }

    jitter(jitter) {
        this.latJitter = jitter * (Math.random() - 0.5);
        this.lngJitter = jitter * (Math.random() - 0.5);
    }

    toString() {
        return `${this.name}, ${this.source} (${this.source_type}${this.station_class ? ', ' + _.upperCase(this.station_class) : ''})`;
    }
}

WeatherSource.configureRelationships({
    location: deserializeObject(GeoPoint),
});

WeatherSource.createEndpoint('/api/weather_sources/:weather_source_id', {weather_source_id: '@weather_source_id'},
                             {'update': {method: 'PUT', isArray: false}});

export class WeatherDataset extends RelationalBase {
    static relationName = 'WeatherDataset';

    toString() {
        return `${this.name}, ${this.weather_source}`;
    }
}
WeatherDataset.configureRelationships({
    weather_source: relationship(WeatherSource, { backref: 'weather_datasets' }),
});

WeatherDataset.createEndpoint('/api/weather_datasets/:weather_dataset_id', {weather_dataset_id: '@weather_dataset_id'});


export class FinancialTemplate extends RelationalBase {
    static relationName = 'FinancialTemplate';

    toString() {
        return this.description;
    }
}

FinancialTemplate.configureRelationships({});

FinancialTemplate.createEndpoint(
    '/api/financial_templates/:financial_template_id',
    { financial_template_id: '@financial_template_id' },
    {},
);


export class Incentive extends RelationalBase {
    static relationName = 'Incentive';

    toString() {
        return this.name;
    }
}

Incentive.configureRelationships({});

Incentive.createEndpoint(
    '/api/incentives/:incentive_id',
    { incentive_id: '@incentive_id' },
    {},
);


const _module = angular.module('helioscope.libraries.resources', []);// ['ngResource']);
_module.factory('Module', () => Module);

_module.factory('ModulePhysics', function() {
    var Q = 1.60217657e-19,
        K = 1.3806488e-23,
        T = 273;

    return {
        Q: Q,
        K: K,
        Q_K: Q / K,
        T: T,
        absolute_temp: function (t) {return T + t; }
    };
});

_module.factory('DiodeModuleFactory', () => {
    function DiodeModule(characterization, irradiance, temp) {
        this.characterization = characterization;
        this.irradiance = irradiance || 1000;
        this.temp = temp || 25;
    }

    DiodeModule.prototype.setConditions = function (irradiance, temp) {
        this.irradiance = parseFloat(irradiance);
        this.temp = parseFloat(temp);
    };

    DiodeModule.prototype.iSc = function () {
        var that = this,
            vDiode = rootFind(
                function (x) {
                    return that.voltage(x);
                },
                -5,
                this.characterization.v_oc,
                1e-5
            );

        return this.current(vDiode);
    };

    DiodeModule.prototype.vOc = function () {
        var that = this,
            vDiode = rootFind(
                function (x) {
                    return that.current(x);
                },
                0,
                this.characterization.v_oc * 2,
                1e-5
            );

        return this.voltage(vDiode);
    };

    DiodeModule.prototype.maxPower = function (bounds) {
        bounds = bounds || [-20, 2 * this.characterization.v_oc];

        var that = this,
            vDiode = minimize(function (x) {
                var i = that.current(x);
                return -that.voltage(x, i) * i;
            }, bounds),
            cur = this.current(vDiode),
            vol = this.voltage(vDiode, cur);

        return {
            vDiode: vDiode,
            vMp: vol,
            iMp: cur,
        };

    };


    return function DiodeModuleFactory() {

        function NewModule(characterization, irradiance, temp) {
            this.characterization = characterization;
            this.irradiance = irradiance || 1000;
            this.temp = temp || 25;
        }

        angular.extend(NewModule.prototype, DiodeModule.prototype);

        return NewModule;
    };
});

_module.factory('PVSystDiodeModule', ['ModulePhysics', 'DiodeModuleFactory', function (physics, diodeModuleFactory) {

    var PVSystModule = diodeModuleFactory();

    PVSystModule.prototype.gamma = function () {
        return (this.characterization.gamma_ref
                + this.characterization.mu_gamma
                * (this.temp - this.characterization.t_ref));
    };

    PVSystModule.prototype.saturation_current = function () {
        return (this.characterization.i0_ref *
                Math.pow(physics.absolute_temp(this.temp) / physics.absolute_temp(this.characterization.t_ref),
                      3) *
                Math.exp((physics.Q * this.characterization.module.cell_technology.adj_bandgap) / (physics.K * this.gamma()) *
                      (1 / physics.absolute_temp(this.characterization.t_ref) -
                       1 / physics.absolute_temp(this.temp))));
    };

    PVSystModule.prototype.photocurrent = function () {
        return ((this.irradiance / this.characterization.g_ref) *
                (this.characterization.iph_ref + (this.characterization.mu_isc / 1000) *
                   (physics.absolute_temp(this.temp) - physics.absolute_temp(this.characterization.t_ref))));
    };

    PVSystModule.prototype.shunt_resistance = function () {
        return (this.characterization.rshunt_gref + (this.characterization.rshunt_0 - this.characterization.rshunt_gref) *
                Math.exp(-this.characterization.rshunt_exp * this.irradiance / this.characterization.g_ref));
    };

    PVSystModule.prototype.exponent_coefficient = function () {
        return physics.Q / (physics.K * physics.absolute_temp(this.temp) *
                            this.characterization.module.series_cells * this.gamma());
    };

    PVSystModule.prototype.recombination_coefficient = function () {
        return Math.max(0, this.photocurrent() * this.characterization.di2_mu_tau);
    };

    PVSystModule.prototype.voltage = function (v_diode, current) {
        var cur = current || this.current(v_diode);
        return (v_diode - cur * this.characterization.rseries);
    };

    PVSystModule.prototype.current = function (v_diode) {

        return (this.photocurrent() -
                (this.saturation_current() *
                   (Math.exp(this.exponent_coefficient() * v_diode) - 1)) -
                 v_diode / this.shunt_resistance() -
                 this.recombination_coefficient() / (this.characterization.module.cell_technology.vbi *
                                                    this.characterization.module.series_cells - v_diode));
    };

    return PVSystModule;
}]);

_module.factory('FullDiodeModule', ['ModulePhysics', 'DiodeModuleFactory', function (physics, diodeModuleFactory) {

    var FullDiodeModule = diodeModuleFactory();

    FullDiodeModule.prototype.temp_adj_saturation_current = function () {
        return this.characterization.i0 * Math.pow(1 + this.characterization.temp_i0, this.temp - 25);
    };

    FullDiodeModule.prototype.diode_coefficient = function () {
        return physics.Q_K / physics.absolute_temp(this.temp) / this.characterization.a;
    };

    FullDiodeModule.prototype.short_circuit_current = function () {
        return this.irradiance / 1000 * this.characterization.i_sc;
    };


    FullDiodeModule.prototype.current = function (v_diode) {
        return this.short_circuit_current() -
                this.temp_adj_saturation_current() * (Math.exp(v_diode * this.diode_coefficient()) - 1) -
                v_diode / this.characterization.parallel_resistance;
    };

    FullDiodeModule.prototype.voltage = function (v_diode, current) {
        return v_diode - this.characterization.series_resistance * (current || this.current(v_diode));
    };

    return FullDiodeModule;

}]);

_module.factory('ModuleCharacterization', () => ModuleCharacterization)



_module.factory('PowerDevice', () => PowerDevice);
_module.factory('PowerDeviceCharacterization', () => PowerDeviceCharacterization);


_module.factory('OptimizerLibrary', ['LibraryProviderFactory',
                                    function (libraryProviderFactory) {
        return libraryProviderFactory(PowerDevice, {'device_type': 'optimizer'});
    }]);

_module.factory('optimizerLibrary', function(OptimizerLibrary) {
    return OptimizerLibrary;
});


_module.factory('Wire', () => Wire);
_module.factory('AcConfig', () => AcConfig);

_module.factory('acConfigLibrary', ['AcConfig', 'LibraryProviderFactory',
    function (AcConfig, libraryProviderFactory) {

        return libraryProviderFactory(AcConfig);

    }]);



_module.factory('WireLibrary', ['Wire', 'LibraryProviderFactory',
                               function (Wire, libraryProviderFactory) {

        return libraryProviderFactory(Wire);

    }]);

_module.factory('wireLibrary', function(WireLibrary) {
    return WireLibrary;
});

_module.factory('WeatherSource', () => WeatherSource);
_module.factory('WeatherDataset', () => WeatherDataset);


_module.filter('wireFilter', () => (wires, preferences) => {
    if (preferences === undefined) {
        user.preferences = user.preferences || {};
        user.preferences.wiring = user.preferences.wiring || {};
        preferences = _.get(user, 'preferences.wiring', {});
    }

    const units = preferences.wire_units;
    const material = preferences.show_aluminum ? ['Copper', 'Aluminum'] : ['Copper'];

    return _.filter(wires, w => !w.wire_gauge_id || ((!units || w.units === units) && material.includes(w.material)));
});

_module.factory('LibraryProviderFactory', ['$q', '$log', function ($q, $log) {
    return function (resource, args) {
        var _cache;
        args = args || {};
        return function Provider() {
            var deferred = $q.defer();

            if (_cache === undefined) {
                resource.query(args, function (result) {
                    _cache = result;
                    deferred.resolve(_cache);
                }, function (response) {
                    $log.warn('Could not load library');
                    $log.warn(response);
                    deferred.reject({type: response.status,
                                     message: 'Could not load library'});
                });
            } else {
                deferred.resolve(_cache);
            }

            return deferred.promise;
        };
    };

}]);
