import * as THREE from 'three';

import { user } from 'helioscope/app/users';
import { Vector, Bounds, pathOrientation, clampRadians } from 'helioscope/app/utilities/geometry';
import { arrayItemWrap } from 'helioscope/app/utilities/helpers';
import { FieldSegmentEdgeMenu, getPathLowPoint, PhysicalSurface } from 'helioscope/app/designer/field_segment';
import { RendererOptions } from './RendererOptions';
import {
    containerPoint,
    createContextMenu,
    hitTestRendererObjects,
    publishUpdatePath,
    drawHandleCircleFill,
    drawHandleCircleStroke,
} from './InteractHelpers';
import {
    DragActionEditPhysicalSurfaceMoveVertex,
    DragActionEditPhysicalSurfaceCreateVertex,
} from './InteractGeometry';
import {
    PrimitiveMeshFill,
    PrimitiveTextTexture,
} from './Primitives';

function formattedDistance(len) {
    const unit = user.preferences.units.distance || 'm';
    let conversion = 1.0;
    if (unit === 'ft') {
        conversion = 3.28084;
    }

    const displaylen = (len * conversion).toFixed(1);
    return `${displaylen} ${unit}`;
}

function showSurfaceEdgeContextMenu(widget, renderer, event) {
    const { dispatcher } = renderer;
    const pt = containerPoint(renderer, event);
    const hitTest = hitTestRendererObjects(renderer, pt);
    const ctxmenu = _.assign({},
        FieldSegmentEdgeMenu,
        { locals:
            _.assign({
                location: new Vector(hitTest.intersectPoint),
                averagePosition: {},
                fieldSegment: widget.surface,
                dispatcher,
            },
            widget.surface.getEdgeInfo(widget.pathIndex)),
        });
    createContextMenu(renderer, ctxmenu, ({ x: pt.x - 15, y: pt.y - 10 }));
}

function middlePoint(vecA, vecB) {
    return (new THREE.Vector3())
        .addVectors(vecA, vecB)
        .multiplyScalar(0.5);
}

// TODO: MT: eventually edges and vertices may be promoted to first class geometry objects
// with renderables that are child renderables of surfaces and not just be widgets
export class WidgetSurfaceCollection {
    constructor(renderer, surface, surfaceType) {
        this.renderer = renderer;
        this.surface = surface;
        this.surfaceType = surfaceType;
    }

    createWidget(options) {
        this.renderableWidgets = [];

        const { renderer, surface, surfaceType } = this;

        // True if the signed area of path is non-negative (the points are counter clockwise)
        const orientation = pathOrientation(surface.geometry.path);

        for (let idx = 0; idx < surface.geometry.path_3d.length; idx++) {
            if (options.dragHandles) {
                // move vertex on ps path
                this.renderableWidgets.push(
                    new WidgetSurfaceVertexHandle(renderer, surface, surfaceType, idx)
                );

                // add vertex to ps path edge
                this.renderableWidgets.push(
                    new WidgetSurfaceEdgeHandle(renderer, surface, surfaceType, idx)
                );
            }

            if (options.pathEdgeLabels) {
                // path edge widgets
                this.renderableWidgets.push(
                    new PathEdgeWidget(renderer, surface, surfaceType, idx)
                );
            }

            if (options.verticalEdgeLabels) {
                // vertical edge widgets
                this.renderableWidgets.push(
                    new VerticalEdgeWidget(renderer, surface, idx)
                );
            }
        }

        if (options.surfaceLabels) {
            // surface name widgets
            this.renderableWidgets.push(new SurfaceNameWidget(renderer, surface));
        }

        this.preframeFn = () => { this.updateWidget(); };
        this.removeUpdate = this.renderer.registerPreFrameCallback(this.preframeFn, true);

        for (const widget of this.renderableWidgets) {
            widget.createWidget(orientation);
        }
    }

    clearWidget() {
        if (this.renderableWidgets) {
            for (const widget of this.renderableWidgets) {
                widget.clearWidget();
            }

            this.renderableWidgets = null;
        }

        if (this.removeUpdate) {
            this.removeUpdate();
            this.removeUpdate = null;
        }
    }

    updateWidget() {
        if (this.renderableWidgets) {
            for (const widget of this.renderableWidgets) {
                widget.updateWidget(this.renderer.cameraProjectionMatrix);
            }
        }
    }
}

export class WidgetSurfaceVertexHandle {
    constructor(renderer, surface, objectType, index) {
        this.renderer = renderer;
        this.surface = surface;
        this.objectType = objectType;
        this.pathIndex = index;
    }

    createWidget() {
        const selectionData = {
            object: this,
            type: 'WidgetSurfaceVertexHandle',
        };

        const options = _.assign({}, RendererOptions.vertexHandleOptions, { selectionData });
        this.fillPrimitive = drawHandleCircleFill(this.renderer, options);
        this.strokePrimitive = drawHandleCircleStroke(this.renderer, options);
    }

    updateWidget(camProjMtx) {
        const renderable = this.renderer.objectRenderMap.get(this.surface);
        const pt = this.surface.geometry.path_3d[this.pathIndex];
        const mtx = renderable ? renderable.worldMatrix() : new THREE.Matrix4();
        const clientPt = this.renderer.transformObjectMatrixToClient(mtx, camProjMtx, pt);
        this.fillPrimitive.setRenderPosition(clientPt);
        this.strokePrimitive.setRenderPosition(clientPt);
    }

    clearWidget() {
        this.fillPrimitive.clearInstances();
        this.fillPrimitive = null;
        this.strokePrimitive.clearInstances();
        this.strokePrimitive = null;
    }

    widgetMouseDown(event) {
        if (event.button === 0) {
            this.renderer.activateDragAction(
                new DragActionEditPhysicalSurfaceMoveVertex(
                    this.renderer, event,
                    {
                        object: this.surface,
                        objectType: this.objectType,
                        pathIndex: this.pathIndex,
                    }));
            return true;
        }

        if (event.button === 2) {
            if (this.surface.geometry.path.length > 2) {
                const newPath = _.map(this.surface.geometry.path, i => Vector.fromObject(i));
                newPath.splice(this.pathIndex, 1);
                publishUpdatePath(this.renderer, this.surface, this.objectType, newPath);
            }

            return true;
        }

        return false;
    }
}

export class WidgetSurfaceEdgeHandle {
    constructor(renderer, surface, objectType, index) {
        this.renderer = renderer;
        this.surface = surface;
        this.objectType = objectType;
        this.pathIndex = index;
    }

    createWidget() {
        const selectionData = {
            object: this,
            type: 'WidgetSurfaceEdgeHandle',
        };

        const options = _.assign({}, RendererOptions.edgeHandleOptions, { selectionData });
        this.fillPrimitive = drawHandleCircleFill(this.renderer, options);
        this.strokePrimitive = drawHandleCircleStroke(this.renderer, options);
    }

    updateWidget(camProjMtx) {
        const renderable = this.renderer.objectRenderMap.get(this.surface);
        const pta = arrayItemWrap(
            this.surface.geometry.path_3d, this.pathIndex);
        const ptb = arrayItemWrap(
            this.surface.geometry.path_3d, this.pathIndex + 1);
        const pt = (new THREE.Vector3()).addVectors(pta, ptb).multiplyScalar(0.5);
        const mtx = renderable ? renderable.worldMatrix() : new THREE.Matrix4();
        const clientPt = this.renderer.transformObjectMatrixToClient(mtx, camProjMtx, pt);
        this.fillPrimitive.setRenderPosition(clientPt);
        this.strokePrimitive.setRenderPosition(clientPt);
    }

    clearWidget() {
        this.fillPrimitive.clearInstances();
        this.fillPrimitive = null;
        this.strokePrimitive.clearInstances();
        this.strokePrimitive = null;
    }

    widgetMouseDown(event) {
        if (event.button === 0) {
            this.renderer.activateDragAction(
                new DragActionEditPhysicalSurfaceCreateVertex(
                    this.renderer, event,
                    {
                        object: this.surface,
                        objectType: this.objectType,
                        pathIndex: this.pathIndex,
                    }));
            return true;
        }

        if (event.button === 2) {
            if (this.objectType === 'FieldSegment') {
                showSurfaceEdgeContextMenu(this, this.renderer, event);
                return true;
            }
        }

        return false;
    }
}

/**
 * Class to render text and quad backing for labels on surfaces
 * @param {Design3DRenderer} renderer - the renderer object
 * @param {Object} renderOptions - options for rendering the label
 * @param {Object} drawOptions - options for drawing the label
 * @param {Number} drawOptions.scaling - scaling factor for the label
 * @param {Number} drawOptions.rotation - rotation of the label
 * @param {Boolean} drawOptions.parallelToEdge - whether the label should be parallel to the edge
 */
class SurfaceLabel {
    constructor(renderer, renderOptions, drawOptions = { scaling: 1.0, rotation: 0.0, parallelToEdge: true }) {
        this.renderer = renderer;
        this.renderOptions = renderOptions;
        this.drawOptions = drawOptions;
        this.labelValue = '';
    }

    renderLabelText(selectionData, order = 2) {
        selectionData.noRaycast = true;

        const options = {
            screenSpace: true,
            text: this.labelValue,
            font: this.renderer.graphicResourceCache.notoSansRegularFont,
            texture: this.renderer.graphicResourceCache.notoSansRegularTexture,
            fillColor: this.renderOptions.textFG,
            scale: this.drawOptions.scaling * this.renderOptions.textureFontScaling,
            scene: this.renderer.interactLayer,
            renderOrder: order,
            selectionData,
        };

        this.textPrimitive = this.renderer.renderPrimitive(PrimitiveTextTexture, options);
    }

    renderLabelQuad(selectionData, order = 1) {
        selectionData.noRaycast = false;

        const geometry = this.renderer.shapeBuilder.quadFill(1.0, 1.0);
        const material = this.renderer.inlineShaderMaterial('vertexShaderBasic', 'fragmentShaderBasic');

        const options = {
            geometry,
            material,
            scene: this.renderer.interactLayer,
            screenSpace: true,
            fillColor: this.renderOptions.textBG,
            renderOrder: order,
            selectionData,
        };

        this.quadPrimitive = this.renderer.renderPrimitive(PrimitiveMeshFill, options);
    }

    transformPathToClient(surface, path, camProjMatrix) {
        const renderable = this.renderer.objectRenderMap.get(surface);
        const objMatrix = renderable ? renderable.worldMatrix() : new THREE.Matrix4();
        return this.renderer.transformObjectMatrixToClient(objMatrix, camProjMatrix, path);
    }

    updateLabelValue(value) {
        this.labelValue = value;
    }

    updateLabelRotation(clientVecA, clientVecB) {
        const edgeVectorNormal = (new THREE.Vector2())
            .subVectors(clientVecB, clientVecA)
            .normalize();

        let rotation = clampRadians(Math.atan2(edgeVectorNormal.y, edgeVectorNormal.x));
        // when drawOptions.parallelToEdge is false, the text and quad backing should be
        // perpendicular to the edge.
        if (!this.drawOptions.parallelToEdge) {
            rotation = clampRadians(rotation + Math.PI / 2);
        }

        this.drawOptions.rotation = rotation;
    }

    /**
     * Set top left point of text, put in middle of edge,
     * and offset to the outside
     */
    updateLabelText(position, edgeOffset = null) {
        const { rotation } = this.drawOptions;
        // perpendicular offset away from actual edge midpoint at which to render label
        edgeOffset = edgeOffset || this.renderOptions.offset;

        // determine whether to flip the text based on the camera angle
        const textOrientation = rotation > 1.5 * Math.PI || rotation < 0.5 * Math.PI;
        const textRotation = textOrientation ? rotation : rotation - Math.PI;

        // rotational transformation to the z-axis of the text primitive
        const textEuler = new THREE.Euler(0, 0, textRotation);
        const textRotationMatrix = (new THREE.Matrix4()).makeRotationFromEuler(textEuler);

        const offset = textOrientation ?
            new THREE.Vector3(-this.textPrimitive.boundingBox.max.x * 0.5,
                edgeOffset + this.textPrimitive.boundingBox.max.y, 0) :
            new THREE.Vector3(-this.textPrimitive.boundingBox.max.x * 0.5,
                -edgeOffset, 0);

        // apply rotational transformation to the offset vector
        offset.applyMatrix4(textRotationMatrix);

        const textPosition = (new THREE.Vector3()).addVectors(position, offset);
        this.textPrimitive.setRenderRotation(textEuler);
        this.textPrimitive.setRenderPosition(textPosition);
    }

    /**
     * Set center point of the quad backing the text, put in middle of edge,
     * and offset to the outside
     */
    updateLabelQuad(position, edgeOffset = null) {
        const { rotation } = this.drawOptions;
        // perpendicular offset away from actual edge midpoint at which to render label
        edgeOffset = edgeOffset || this.renderOptions.offset;

        // quad dimensions
        const quadPadding = this.renderOptions.bgPadding;
        const quadHeight = this.textPrimitive.boundingBox.max.y + quadPadding * 2;
        const quadWidth = this.textPrimitive.boundingBox.max.x + quadPadding * 2;

        // rotational transformation to the z-axis of the quad primitive
        const quadEuler = new THREE.Euler(0, 0, rotation);
        const quadRotationMatrix = (new THREE.Matrix4()).makeRotationFromEuler(quadEuler);

        const offset = new THREE.Vector3(0, edgeOffset + quadHeight * 0.5 - quadPadding, 0);
        // apply rotational transformation to the offset vector
        offset.applyMatrix4(quadRotationMatrix);

        const quadPosition = (new THREE.Vector3()).addVectors(position, offset);
        this.quadPrimitive.setRenderScale(new THREE.Vector3(quadWidth, quadHeight, 1.0));
        this.quadPrimitive.setRenderRotation(quadEuler);
        this.quadPrimitive.setRenderPosition(quadPosition);
    }

    clearPrimitives() {
        this.textPrimitive.clearInstances();
        this.textPrimitive = null;
        this.quadPrimitive.clearInstances();
        this.quadPrimitive = null;
    }
}

/**
 * Widget for displaying the distance of an edge along the path of a surface
 * @param {Design3DRenderer} renderer - the renderer object
 * @param {PhysicalSurface} surface - the surface object
 * @param {String} surfaceType - the type of surface (FieldSegment, Keepout, etc.)
 * @param {Number} index - the index of the edge along the paths vector array
 *
*/
export class PathEdgeWidget {
    constructor(renderer, surface, surfaceType, index) {
        this.surfaceLabel = new SurfaceLabel(renderer, RendererOptions.edgeLabelOptions, {
            scaling: user.preferences.designer.label_scale_factor || 1,
            parallelToEdge: true
        });
        this.surface = surface;
        this.surfaceType = surfaceType;
        this.pathIndex = index;
        this.pathOrientation = false;
    }

    createWidget(pathOrientation) {
        // True if the signed area of path is non-negative (the points are counter clockwise)
        this.pathOrientation = pathOrientation;

        const edge = this.surface.getEdgeInfo(this.pathIndex, false);
        const edgeDistance = formattedDistance(edge.distance);
        const selectionData = {
            object: this,
            type: 'HorizontalEdgeWidget',
        };

        this.surfaceLabel.updateLabelValue(edgeDistance);
        this.surfaceLabel.renderLabelText(selectionData);
        this.surfaceLabel.renderLabelQuad(selectionData);
    }

    updateWidget(camProjMtx) {
        const pathA = arrayItemWrap(this.surface.geometry.path_3d, this.pathIndex);
        const pathB = arrayItemWrap(this.surface.geometry.path_3d, this.pathIndex + 1);
        const pathAClient = this.surfaceLabel.transformPathToClient(this.surface, pathA, camProjMtx);
        const pathBClient = this.surfaceLabel.transformPathToClient(this.surface, pathB, camProjMtx);

        // depending on the path orientation, swap two points to ensure label renders on the outside of the edge
        if (!this.pathOrientation) {
            [pathAClient, pathBClient] = [pathBClient, pathAClient];
        }

        // update rotation
        this.surfaceLabel.updateLabelRotation(pathAClient, pathBClient);
        // update label
        const edgeMiddlePoint = middlePoint(pathAClient, pathBClient);
        this.surfaceLabel.updateLabelText(edgeMiddlePoint);
        this.surfaceLabel.updateLabelQuad(edgeMiddlePoint);
    }

    clearWidget() {
        this.surfaceLabel.clearPrimitives();
    }

    widgetMouseDown(event) {
        if (event.button === 2 && this.surfaceType === 'FieldSegment') {
            showSurfaceEdgeContextMenu(this, this.surfaceLabel.renderer, event);
            return true;
        }

        return false;
    }
}

/**
 * Widget for displaying the distance of a vertical edge along the path of a surface
 * @param {Design3DRenderer} renderer - the renderer object
 * @param {PhysicalSurface} surface - the surface object
 * @param {Number} index - the index of the edge along the paths vector array
 */
export class VerticalEdgeWidget {
    constructor(renderer, surface, index) {
        this.surfaceLabel = new SurfaceLabel(renderer, RendererOptions.edgeLabelOptions, {
            scaling: user.preferences.designer.label_scale_factor || 1,
            parallelToEdge: false
        });
        this.surface = surface;
        this.pathIndex = index;
        this.pathOrientation = false;
    }

    createWidget(pathOrientation, val) {
        // True if the signed area of path is non-negative (the points are counter clockwise)
        this.pathOrientation = pathOrientation;

        const edge = this.surface.getEdgeInfo(this.pathIndex, true);
        const edgeDistance = formattedDistance(edge.distance);
        const selectionData = {
            object: this,
            type: 'VerticalEdgeWidget',
        };

        this.surfaceLabel.updateLabelValue(edgeDistance);
        this.surfaceLabel.renderLabelText(selectionData);
        this.surfaceLabel.renderLabelQuad(selectionData);
    }

    updateWidget(camProjMtx) {
        const pathSurface = arrayItemWrap(this.surface.geometry.path_3d, this.pathIndex);
        const pathBase = arrayItemWrap(this.surface.geometry.base_3d, this.pathIndex);
        const pathSurfaceClient = this.surfaceLabel.transformPathToClient(this.surface, pathSurface, camProjMtx);
        const pathBaseClient = this.surfaceLabel.transformPathToClient(this.surface, pathBase, camProjMtx);

        // depending on orientation, swap two points to ensure label renders on the outside of the edge
        if (!this.pathOrientation) {
            [pathSurfaceClient, pathBaseClient] = [pathBaseClient, pathSurfaceClient];
        }

        // update rotation
        this.surfaceLabel.updateLabelRotation(pathSurfaceClient, pathBaseClient);

        // only display widgets when the camera is not in top-down view.
        // this results in a rotation value of Pi or greater
        if (this.surfaceLabel.drawOptions.rotation >= Math.PI) {
            // show widget if previously hidden
            this.showWidget();
            // update label
            const edgeMiddlePoint = middlePoint(pathSurfaceClient, pathBaseClient);
            // perpendicular offset away from edge midpoint at which to render label
            const edgeOffset = -this.surfaceLabel.renderOptions.offset;
            this.surfaceLabel.updateLabelText(edgeMiddlePoint, edgeOffset);
            this.surfaceLabel.updateLabelQuad(edgeMiddlePoint, edgeOffset);
        } else {
            // hide widget in top-down view
            this.hideWidget();
        }
    }

    showWidget() {
        const { textPrimitive, quadPrimitive } = this.surfaceLabel;

        if (textPrimitive.glInstance && !textPrimitive.glInstance.visible) {
            textPrimitive.glInstance.visible = true;
        }

        if (quadPrimitive.glInstance && !quadPrimitive.glInstance.visible) {
            quadPrimitive.glInstance.visible = true;
        }
    }

    hideWidget() {
        const { textPrimitive, quadPrimitive } = this.surfaceLabel;

        if (textPrimitive.glInstance && textPrimitive.glInstance.visible) {
            textPrimitive.glInstance.visible = false;
        }

        if (quadPrimitive.glInstance && quadPrimitive.glInstance.visible) {
            quadPrimitive.glInstance.visible = false;
        }
    }

    clearWidget() {
        this.surfaceLabel.clearPrimitives();
    }

    widgetMouseDown(event) {
        return false;
    }
}

/**
 * Widget for displaying the name/description of a surface
 * @param {Design3DRenderer} renderer - the renderer object
 * @param {PhysicalSurface} surface - the surface object
 */
export class SurfaceNameWidget {
    constructor(renderer, surface) {
        this.surfaceLabel = new SurfaceLabel(renderer, RendererOptions.surfaceLabelOptions);
        this.surface = surface;
        this.pathOrientation = false;
    }

    createWidget(pathOrientation) {
        // True if the signed area of path is non-negative (the points are counter clockwise)
        this.pathOrientation = pathOrientation;

        const selectionData = {
            object: this,
            type: 'SurfaceNameWidget',
        };

        this.surfaceLabel.updateLabelValue(this.surface.description);
        this.surfaceLabel.renderLabelText(selectionData);
        this.surfaceLabel.renderLabelQuad(selectionData, -10);
    }

    updateWidget(camProjMtx) {
        const midPoint = Bounds.pathMidPoint(this.surface.geometry.path);
        const objPoint = this.surface.pointOnSurface(midPoint).addXYZ(0, 0, 0.1);
        const objPointClient = this.surfaceLabel.transformPathToClient(this.surface, objPoint, camProjMtx);

        // update label
        this.surfaceLabel.updateLabelText(objPointClient);
        this.surfaceLabel.updateLabelQuad(objPointClient);
    }

    clearWidget() {
        this.surfaceLabel.clearPrimitives();
    }

    widgetMouseDown() {
        return false;
    }
}
