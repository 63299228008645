import { RelationalBase } from 'helioscope/app/relational';
import { $state, Messager } from 'helioscope/app/utilities/ng';
import { getBetaIntegrationsURL } from 'helioscope/app/utilities/url';

const SERVICE_NAMES = {
    nearmap: 'Nearmap',
};

class ExternalCredentials extends RelationalBase {
    static relationName = 'ExternalCredentials';
}

ExternalCredentials.configureRelationships();
ExternalCredentials.createEndpoint('/api/external_credentials/:external_credentials_id', {
    external_credentials_id: '@external_credentials_id',
});

class ExternalCredentialsCtrl {
    constructor(allExternalCredentials) {
        'ngInject';
        this.allExternalCredentials = allExternalCredentials;
        this.betaExternalCredentialsURL = getBetaIntegrationsURL();
    }

    hasService(service) {
        return _.some(this.allExternalCredentials, cred => cred.service === service);
    }

    delete(cred) {
        cred.$delete().then(() => {
            const idx = this.allExternalCredentials.indexOf(cred);
            this.allExternalCredentials.splice(idx, 1);
        });
    }

}


const mod = angular.module('helioscope.users.external_credentials', []);
mod.factory('ExternalCredentials', () => ExternalCredentials);
mod.controller('ExternalCredentialsCtrl', ExternalCredentialsCtrl);
