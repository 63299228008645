(function(angular, factory) {
    if (typeof define === 'function' && define.amd) {
        define('angular-file-upload-extensions', ['angular'], function(angular) {
            return factory(angular);
        });
    } else {
        return factory(angular);
    }
}(typeof angular === 'undefined' ? null : angular, function(angular) {

    'use strict';

    // get existing module
    var module = angular.module('angularFileUpload.Extensions', ['angularFileUpload']);

    /*
     FileUploaderS3
     clobbers _xhrTransport and uses non-form PUT to push stuff to S3 (angular-file-upload always uses a form)
    */
    module.factory('FileUploaderS3', ['FileUploader', function(FileUploader) {

        class FileUploaderS3 extends FileUploader {
            _xhrTransport(item) {
                var xhr = item._xhr = new XMLHttpRequest();
                var getResponseHeader = angular.bind(xhr, xhr.getResponseHeader);

                if (this._onBeforeUploadItem(item) === false) {
                    item.isUploading = false;
                    this.isUploading = false;
                    this._render();
                    return;
                }

                xhr.upload.onprogress = (event) => {
                    var progress = Math.round(event.lengthComputable ? event.loaded * 100 / event.total : 0);
                    this._onProgressItem(item, progress);
                };

                xhr.onload = () => {
                    var headers = this._parseHeaders(xhr.getAllResponseHeaders());
                    var response = this._transformResponse(xhr.response, getResponseHeader);
                    var gist = this._isSuccessCode(xhr.status) ? 'Success' : 'Error';
                    var method = '_on' + gist + 'Item';
                    this[method](item, response, xhr.status, headers);
                    this._onCompleteItem(item, response, xhr.status, headers);
                };

                xhr.onerror = () => {
                    var headers = this._parseHeaders(xhr.getAllResponseHeaders());
                    var response = this._transformResponse(xhr.response, getResponseHeader);
                    this._onErrorItem(item, response, xhr.status, headers);
                    this._onCompleteItem(item, response, xhr.status, headers);
                };

                xhr.onabort = () => {
                    var headers = this._parseHeaders(xhr.getAllResponseHeaders());
                    var response = this._transformResponse(xhr.response, getResponseHeader);
                    this._onCancelItem(item, response, xhr.status, headers);
                    this._onCompleteItem(item, response, xhr.status, headers);
                };

                xhr.open('PUT', item.url, true);

                xhr.withCredentials = item.withCredentials;

                angular.forEach(item.headers, (value, name) => {
                    xhr.setRequestHeader(name, value);
                });

                xhr.send(item._file);
                this._render();
            }
        };

        return FileUploaderS3;
    }]);

   /**
    * The ng-thumb directive
    * @author: nerv
    * @version: 0.1.2, 2014-01-09
    */
    module.directive('ngThumb', ['$window', '$timeout', function($window, $timeout) {
        var helper = {
            support: !!($window.FileReader),
            isFile: function (item) {
                return angular.isObject(item) && item instanceof $window.File;
            },
            isImage: function (file) {
                var type = '|' + file.type.slice(file.type.lastIndexOf('/') + 1) + '|';
                return '|jpg|png|jpeg|bmp|gif|'.indexOf(type) !== -1;
            }
        };

        return {
            restrict: 'A',
            scope: {
                file: '='
            },
            link: function ($scope, element, attributes) {
                if (!helper.support) return;
                if (!helper.isFile($scope.file)) return;
                if (!helper.isImage($scope.file)) return;
                var objUrl = URL.createObjectURL($scope.file);

                if (element.prop('tagName') === 'IMG') {
                    element.attr('src', objUrl);
                } else {
                    element.attr('style', 'background: url(' + objUrl + ');');
                }
            }
        };
    }]);
}));
