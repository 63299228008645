(function (angular) {
  'use strict';

  var mod = angular.module('helioscope.libraries.directives', []);

  mod.directive('moduleIvCurves', ['$filter', '$timeout', function ($filter, $timeout) {
    var numberFilter = $filter('number');

    function drawChart(scope, element) {
      var implementation = scope.characterization.implementation();
      function getData() {
        var seriesData, dependentData;

        if (scope.conditions.dependence === "irradiance") {
          seriesData = scope.conditions.irradianceKeys;
          dependentData = scope.conditions.temperature;
        } else {
          seriesData = scope.conditions.temperatureKeys;
          dependentData = scope.conditions.irradiance;
        }

        return _.map(seriesData, function (series) {
          var ivCurve = [];
          if (scope.conditions.dependence === "irradiance") {
            implementation.setConditions(series, dependentData);
          } else {
            implementation.setConditions(dependentData, series);
          }
          var maxVoltage = implementation.vOc();

          for (var i = 0; i <= 80; i++) {
            var vDiode = maxVoltage * i / 75;
            var cur = implementation.current(vDiode);
            var vol = implementation.voltage(vDiode, cur);
            ivCurve.push([vol, cur * (scope.conditions.graphType === 'power' ? vol : 1)]);
            if (cur <= 0) {
              break;
            }
          }
          return {
            name: series,// + (scope.conditions.dependence==="irradiance" ? "W/m2":"ºC"),
            data: ivCurve
          };
        });
      }  //end getData


      scope.$watch("conditions", _.debounce(function (conditions, oldConditions) {
        if (typeof (conditions) !== 'undefined') {
          angular.forEach(getData(), function (data, i) {
            if (conditions.dependence !== oldConditions.dependence) {
              chart.series[i].update(data, false);
            } else {
              chart.series[i].setData(data.data);
            }
          });
          // chart.yAxis[0].setTitle({title:conditions.showPower? 'Power' : 'Current'}, false);
          chart.yAxis[0].setTitle({
            text: conditions.graphType === 'power' ? 'Power' : 'Current'
          }, false);
          chart.redraw();
        }
      }, 250), true); //end Watch

      var chart = new Highcharts.Chart({
        chart: {
          renderTo: element[0],
          type: 'line',
          spacingRight: 35
        },
        title: '',
        xAxis: {
          min: 0,
          startOnTick: false,
          lineColor: '#444',
          title: {
            text: 'Voltage'
          }
        },
        yAxis: [
          {
            min: 0,
            title: {
              text: 'Current'
            },
          }],
        legend: {
          align: 'right',
          layout: 'vertical',
          verticalAlign: 'top',
          backgroundColor: 'white',
          floating: 'true',
          enabled: true,
          labelFormatter: function () {
            if (scope.conditions.dependence === "irradiance")
              return this.name + "W/m<sup>2</sup>";
            else
              return this.name + "ºC<br>";
          },
          // useHTML: true,
          x: 25
        },
        credits: {
          enabled: false
        },
        tooltip: {
          formatter: function () {
            if (scope.conditions.dependence === "irradiance")
              return this.series.name + "W/m<sup>2</sup>, " + scope.conditions.temperature + "&deg;C<br>" + numberFilter(this.x, 2) + "V, " + numberFilter(this.y, 2) + (scope.conditions.graphType === 'power' ? 'W' : 'A');
            else
              return scope.conditions.irradiance + "W/m<sup>2</sup>, " + this.series.name + "&deg;C<br>" + numberFilter(this.x, 2) + "V, " + numberFilter(this.y, 2) + (scope.conditions.graphType === 'power' ? 'W' : 'A');
          },
          useHTML: true
        },
        plotOptions: {
          series: {
            marker: {
              enabled: false
            }
          }
        },
        series: getData()
      }); //end chart
    }

    return {
      retrict: 'EA',
      scope: { 'characterization': '=', 'conditions': '=' },
      link: function (scope, element, attrs) {
        // drawing the chart from a timeout ensures that it is rendered at
        // the appropriate size
        $timeout(function () {
          drawChart(scope, element);
        });
      } //end link
    }; //end return
  }]);

  mod.directive('deviceEfficiency', ['$filter', '$timeout', function ($filter, $timeout) {
    var numberFilter = $filter('number');
    var powerFilter = $filter('hsPower');
    function drawChart(scope, element) {

      var characterization = scope.characterization;

      var axes = (characterization.power_device.device_type_name != "buck_optimizer");

      var f1_name, f2_name, pluck_name, legend_name;
      if (axes) {
        f1_name = characterization.power_device.efficiency_factor_1;
        f2_name = characterization.power_device.efficiency_factor_2;
        legend_name = "factor_1";
        pluck_name = "factor_2";
        legend = _(characterization.efficiency_points).map('factor_1').uniq().value();
      } else {
        f1_name = characterization.power_device.efficiency_factor_2;
        f2_name = characterization.power_device.efficiency_factor_1;
        legend_name = "factor_2";
        pluck_name = "factor_1";
      }

      var legend = _(characterization.efficiency_points).map(legend_name).uniq().value();

      var xFilter;

      if (f2_name == "Power") {
        xFilter = function () { return powerFilter(this.value); };
      } else if (f2_name == "Power Ratio" || f2_name == "Voltage Buck Ratio") {
        xFilter = function () { return numberFilter(this.value * 100, 1) + '%'; };
      } else {
        xFilter = function () { return this.value; };
      }


      function getData() {
        return _.map(legend, function (f1) {
          return {
            name: f1,
            data: _(characterization.efficiency_points).filter(function (o) { return o[legend_name] == f1; }).map(function (f) { return [f[pluck_name], f.efficiency]; }).sortBy(function (x) { return x[0] * 1; }).value()
          };
        });
      }  //end getData

      var chart = new Highcharts.Chart({
        chart: {
          renderTo: element[0],
          type: 'spline',
          spacingRight: 35
        },
        title: '',
        xAxis: {
          startOnTick: false,
          lineColor: '#444',
          title: {
            text: f2_name
          },
          labels: {
            formatter: xFilter
          }

        },
        yAxis: [
          {
            title: {
              text: 'Efficiency'
            },
            labels: {
              formatter: function () { return numberFilter(this.value * 100, 1) + '%'; }
            }
          }],
        legend: {
          align: 'right',
          layout: 'vertical',
          verticalAlign: 'top',
          backgroundColor: 'white',
          floating: 'true',
          enabled: (legend.length > 1),
          title: { text: f1_name },
          labelFormatter: function () {
            if (f1_name == 'Voltage') {
              return numberFilter(this.name) + "V";
            } else if (f1_name == 'Voltage Ratio') {
              return numberFilter(this.name * 100) + "%";
            } else {
              return this.name;
            }
          },
          // useHTML: true,
          x: 25
        },
        credits: {
          enabled: false
        },
        plotOptions: {
          series: {
            marker: {
              enabled: false
            }
          }
        },
        series: getData()
      }); //end chart
    }

    return {
      retrict: 'EA',
      scope: { 'characterization': '=' },
      link: function (scope, element, attrs) {
        // drawing the chart from a timeout ensures that it is rendered at
        // the appropriate size
        $timeout(function () {
          drawChart(scope, element);
        });
      } //end link
    }; //end return
  }]);


})(angular);
