import _ from 'lodash';
import * as THREE from 'three';

import { toRadians } from 'helioscope/app/utilities/geometry';

export const COPLANAR = Symbol('COPLANAR');
export const PARALLEL = Symbol('PARALLEL');

const segmentParts = (tagged, plane) => {
    const parts = [];
    for (let i = 0; i < tagged.length; i++) {
        const i1 = (i + 1) % tagged.length;
        const pt1 = tagged[i].point;
        const pt2 = tagged[i1].point;
        const raw = new LineSegment3(pt1, pt2);

        if (tagged[i].project === 0 && tagged[i1].project === 0) {
            parts.push({ segment: raw, side: 0, forceEnd: true });
        } else if (tagged[i].project >= 0 && tagged[i1].project >= 0) {
            parts.push({ segment: raw, side: 1, forceEnd: tagged[i1].project === 0 });
        } else if (tagged[i].project <= 0 && tagged[i1].project <= 0) {
            parts.push({ segment: raw, side: -1, forceEnd: tagged[i1].project === 0 });
        } else {
            const intersect = raw.intersectSegmentPlane(plane);
            const raw1 = new LineSegment3(pt1, intersect);
            const raw2 = new LineSegment3(intersect, pt2);
            if (tagged[i].project > 0) {
                parts.push({ segment: raw1, side: 1, forceEnd: true });
                parts.push({ segment: raw2, side: -1 });
            } else {
                parts.push({ segment: raw1, side: -1, forceEnd: true });
                parts.push({ segment: raw2, side: 1 });
            }
        }
    }

    return parts;
};

const pathPieces = (parts) => {
    const allPieces = [];
    let forceEnd = false;
    let currSide = parts[0].side;
    let currPath = [parts[0].segment.point1];
    for (const part of parts) {
        if (forceEnd) {
            allPieces.push({
                path: currPath,
                side: currSide,
            });

            currSide = part.side;
            currPath = [part.segment.point1];
        }

        currPath.push(part.segment.point2);

        // for when path touches plane then goes back to same side
        forceEnd = part.forceEnd;
    }

    if (!forceEnd) {
        currPath.splice(currPath.length - 1);
        allPieces[0].path = currPath.concat(allPieces[0].path);
    } else {
        allPieces.push({
            path: currPath,
            side: currSide,
        });
    }

    return allPieces;
};

const sortPieces = (pieces, refPoint, planeCross) => {
    _.each(pieces, i => {
        const e1 = i.subPoly.path[0];
        const e2 = i.subPoly.path[i.subPoly.path.length - 1];
        const t1 = e1.clone().sub(refPoint).dot(planeCross);
        const t2 = e2.clone().sub(refPoint).dot(planeCross);
        i.t1 = Math.min(t1, t2);
        i.t2 = Math.max(t1, t2);
    });

    return _.sortBy(pieces, i => i.t1);
};

const nextNegativeIndex = (i0, t0, sorted) => {
    for (let i = i0; i < sorted.length; i++) {
        if (sorted[i].signedArea < 0 && sorted[i].t1 >= t0) return i;
    }

    return -1;
};

const mergePieces = (sorted, front) => {
    const merged = [];
    for (const positive of _.filter(sorted, i => i.signedArea > 0)) {
        let negativePath = [];

        let i = 0;
        let t = positive.t1;
        while (true) {
            i = nextNegativeIndex(i, t, sorted);
            if (i < 0) break;

            const negative = sorted[i];
            if (negative.t1 > positive.t2) break;
            if (front) negativePath = negativePath.concat(negative.subPoly.path);
            else negativePath = negative.subPoly.path.concat(negativePath);
            i++;
            t = negative.t2;
        }

        positive.merged = positive.subPoly.path.concat(negativePath);
        merged.push(positive);
    }

    return merged;
};

const cleanPieces = pieces => {
    for (const piece of pieces) {
        const last = _.last(piece.merged);
        if (piece.merged[0].distanceToSquared(last) === 0) {
            piece.merged.splice(piece.merged.length - 1);
        }
    }

    return pieces;
};

export class Plane3 {
    static fromGroundPlane() {
        return Plane3.fromPointNormal(new THREE.Vector3(0, 0, 0), new THREE.Vector3(0, 0, 1));
    }

    static fromPointNormal(point, normal) {
        const constant = -(point.dot(normal));
        return new Plane3(normal, constant);
    }

    static fromAzimuthTiltPointDeg(azimuthDeg, tiltDeg, point) {
        const phi = toRadians((90 - azimuthDeg) % 360);
        const theta = toRadians(tiltDeg);

        const normal = new THREE.Vector3(
            Math.sin(theta) * Math.cos(phi),
            Math.sin(theta) * Math.sin(phi),
            Math.cos(theta),
        );

        return Plane3.fromPointNormal(point, normal);
    }

    constructor(normal, constant) {
        this.normal = normal;
        this.constant = constant;
    }

    clone() {
        return new Plane3(this.normal.clone(), this.constant);
    }

    getOffset(offset) {
        return new Plane3(this.normal.clone(), this.constant - this.normal.dot(offset));
    }

    getFlipped() {
        return new Plane3(this.normal.clone().multiplyScalar(-1), -this.constant);
    }

    orthoBasis() {
        if (!this.cachedOrthoBasis) {
            const axis = [
                new THREE.Vector3(1, 0, 0),
                new THREE.Vector3(0, 1, 0),
                new THREE.Vector3(0, 0, 1)];

            const minaxis = _.minBy(axis, i => Math.abs(i.dot(this.normal)));

            const cross1 = (new THREE.Vector3()).crossVectors(this.normal, minaxis).normalize();
            const cross2 = (new THREE.Vector3()).crossVectors(this.normal, cross1).normalize();

            const basis4 = (new THREE.Matrix4()).makeBasis(cross1, cross2, this.normal);
            this.cachedOrthoBasis = (new THREE.Matrix3()).setFromMatrix4(basis4);
        }

        return this.cachedOrthoBasis;
    }

    orthoBasisTransforms() {
        const basis = this.orthoBasis();
        const basisI = basis.clone().transpose();
        const zOffset = -this.constant;

        return { basis, basisI, zOffset };
    }

    referencePoint() {
        if (!this.cachedReferencePoint) {
            this.cachedReferencePoint = this.normal.clone().multiplyScalar(-this.constant);
        }

        return this.cachedReferencePoint;
    }

    zFromXY(point) {
        if (this.normal.z === 0) return null;
        return (this.normal.x * point.x + this.normal.y * point.y + this.constant) / (-this.normal.z);
    }

    pointFromXY(point) {
        if (this.normal.z === 0) return null;
        const z = (this.normal.x * point.x + this.normal.y * point.y + this.constant) / (-this.normal.z);
        return new THREE.Vector3(point.x, point.y, z);
    }

    multiPointFromXY(points) {
        const rtn = new Array(points.length);

        for (let i = 0; i < points.length; i++) {
            rtn[i] = this.pointFromXY(points[i]);
        }

        return rtn;
    }

    projectNormalDistance(point) {
        const diff = point.clone().sub(this.referencePoint());
        return diff.dot(this.normal);
    }

    projectNormalPoint(point) {
        const diff = point.clone().sub(this.referencePoint());
        return point.clone().sub(this.normal.clone().multiplyScalar(diff.dot(this.normal)));
    }

    parallel(plane) {
        return Math.abs(this.normal.dot(plane.normal)) === 1;
    }

    coplanarOriented(plane) {
        return (
            this.normal.x === plane.normal.x &&
            this.normal.y === plane.normal.y &&
            this.normal.z === plane.normal.z &&
            this.constant === plane.constant);
    }

    nearCoplanarOriented(plane) {
        const normEps = 0.9999;
        const constEps = 0.0001;

        return (this.normal.dot(plane.normal) > normEps &&
            Math.abs(this.constant - plane.constant) < constEps);
    }

    nearCoplanar(plane) {
        return this.nearCoplanarOriented(plane) || this.getFlipped().nearCoplanarOriented(plane);
    }

    splitPolygon(polygon) {
        const front = [];
        const back = [];
        const coplanar = [];

        const tagged = _.map(polygon.path,
            i => ({
                point: i,
                project: this.projectNormalDistance(i),
            }));

        if (_.every(tagged, i => i.project === 0)) {
            coplanar.push(polygon.clone());
            return { front, back, coplanar };
        }

        if (_.every(tagged, i => i.project > 0)) {
            front.push(polygon.clone());
            return { front, back, coplanar };
        }

        if (_.every(tagged, i => i.project < 0)) {
            back.push(polygon.clone());
            return { front, back, coplanar };
        }

        const parts = segmentParts(tagged, this);
        const allPieces = pathPieces(parts);

        const frontPieces = [];
        const backPieces = [];

        for (const piece of allPieces) {
            const subPoly = new Polygon3(piece.path, polygon.plane);
            if (piece.side > 0) {
                frontPieces.push({
                    subPoly,
                    signedArea: subPoly.signedArea(),
                });
            } else if (piece.side < 0) {
                backPieces.push({
                    subPoly,
                    signedArea: subPoly.signedArea(),
                });
            }
        }

        const planeCross = this.normal.clone().cross(polygon.plane.normal);
        const refPoint = allPieces[0].path[0];

        const frontMerged = cleanPieces(
            mergePieces(
                sortPieces(frontPieces, refPoint, planeCross),
                true));

        const backMerged = cleanPieces(
            mergePieces(
                sortPieces(backPieces, refPoint, planeCross),
                false));

        for (const piece of frontMerged) {
            front.push(new Polygon3(piece.merged, polygon.plane));
        }

        for (const piece of backMerged) {
            back.push(new Polygon3(piece.merged, polygon.plane));
        }

        return { front, back, coplanar };
    }
}

export class Ray3 {
    constructor(point, dir) {
        this.point = point;
        this.dir = dir;
    }

    pointPositive(point) {
        // inclusive
        const delta = point.clone().sub(this.point);
        return delta.dot(this.dir) >= 0.0;
    }

    intersectLinePlane(plane, allowBehind = false) {
        const ddotn = this.dir.dot(plane.normal);
        if (ddotn === 0) {
            if (plane.projectNormalDistance(this.point) === 0) return COPLANAR;
            return null;
        }

        const odotn = this.point.dot(plane.normal);
        const t = -(odotn + plane.constant) / ddotn;
        if (!allowBehind && t < 0.0) return null;
        return this.dir.clone().multiplyScalar(t).add(this.point);
    }

    intersectLinePolygon(polygon) {
        const intersection = this.intersectLinePlane(polygon.plane, true);
        if (intersection !== null) {
            if (polygon.pointWithinPolygonBounds(intersection)) {
                return intersection;
            }
        }
        return null;
    }

    intersectTriangle(triangle) {
        const v1v2 = triangle.edge1;
        const v3v1 = triangle.edge3;
        const pvec = (new THREE.Vector3()).crossVectors(v3v1, this.dir);

        const det = v1v2.dot(pvec);
        if (det === 0) return null;

        const idet = 1.0 / det;
        const tvec = (new THREE.Vector3()).subVectors(this.point, triangle.vertex1);

        const u = tvec.dot(pvec) * idet;
        if (u < 0 || u > 1) return null;

        const qvec = tvec.cross(v1v2);
        const v = this.dir.dot(qvec) * idet;
        if (v < 0 || u + v > 1) return null;

        const t = -v3v1.dot(qvec) * idet;
        if (t < 0) return null;

        return t;
    }
}

export class Triangle3 {
    constructor(vertex1, vertex2, vertex3) {
        this.vertex1 = vertex1;
        this.vertex2 = vertex2;
        this.vertex3 = vertex3;

        this.edge1 = (new THREE.Vector3()).subVectors(this.vertex2, this.vertex1);
        this.edge2 = (new THREE.Vector3()).subVectors(this.vertex3, this.vertex2);
        this.edge3 = (new THREE.Vector3()).subVectors(this.vertex1, this.vertex3);

        this.normal = (new THREE.Vector3()).crossVectors(this.edge3, this.edge1).normalize();
    }

    getCentroid() {
        const centroid = this.vertex1.clone().add(this.vertex2).add(this.vertex3).multiplyScalar(1.0 / 3.0);
        return centroid;
    }
}

export class LineSegment3 {
    constructor(point1, point2) {
        this.point1 = point1;
        this.point2 = point2;
        this.delta = this.point2.clone().sub(this.point1);
    }

    get normal() {
        if (!this._normal) this._normal = this.delta.clone().normalize();
        return this._normal;
    }

    pointBetweenEndpoints(point) {
        const d1 = point.clone().sub(this.point1);
        const d2 = point.clone().sub(this.point2);
        if (d1.dot(d2) <= 0) return true;
        return false;
    }

    projectLinePoint(point) {
        const diff = point.clone().sub(this.point1);
        const proj = this.normal.clone();
        return this.point1.clone().add(proj.multiplyScalar(diff.dot(proj)));
    }

    nearestPointLineLine(lineseg) {
        // nearest point on this line to other line
        const cross1 = this.delta.clone().cross(lineseg.delta);
        if (cross1.lengthSq() < Number.EPSILON) return PARALLEL;

        // intersect with plane normal to cross2
        const cross2 = lineseg.delta.clone().cross(cross1);
        const pt = (this.point1.clone()
            .add(this.delta.clone()
                .multiplyScalar((lineseg.point1.clone().sub(this.point1)).dot(cross2) / cross2.dot(this.delta))));
        return pt;
    }

    intersectLinePlane(plane) {
        return (new Ray3(this.point1, this.delta)).intersectLinePlane(plane, true);
    }

    intersectSegmentPlane(plane) {
        // inclusive
        const intersect = this.intersectLinePlane(plane);
        if (!intersect || intersect === COPLANAR) return intersect;

        if (!this.pointBetweenEndpoints(intersect)) return null;
        return intersect;
    }

    projectSegmentPlane(plane) {
        const d1 = plane.projectNormalDistance(this.point1);
        const d2 = plane.projectNormalDistance(this.point2);

        const scalar1 = d1 > 0 ? -1 * d1 : d1;
        const scalar2 = d2 > 0 ? -1 * d2 : d2;

        const delta1 = (new THREE.Vector3()).copy(plane.normal).multiplyScalar(scalar1);
        const delta2 = (new THREE.Vector3()).copy(plane.normal).multiplyScalar(scalar2);

        const newPt1 = (new THREE.Vector3()).addVectors(this.point1, delta1);
        const newPt2 = (new THREE.Vector3()).addVectors(this.point2, delta2);

        return new LineSegment3(newPt1, newPt2);
    }

    equals(lineseg) {
        return (this.point1.distanceToSquared(lineseg.point1) < Number.EPSILON) &&
            (this.point2.distanceToSquared(lineseg.point2) < Number.EPSILON);
    }
}

export class MultiPolygon3 {
    constructor(paths, plane) {
        this.paths = paths;
        this.plane = plane;
        this.subPolys = [];

        for (const path of this.paths) {
            this.subPolys.push(new Polygon3(path, this.plane));
        }
    }

    signedArea() {
        let sum = 0;
        for (const i of this.subPolys) sum += i.signedArea();
        return sum;
    }

    getOffset(offset) {
        const paths = _.map(this.paths, path => _.map(path, i => i.clone().add(offset)));
        return new MultiPolygon3(paths, this.plane.getOffset(offset));
    }

    forceOrientation() {
        for (const i of this.subPolys) i.forceOrientation();
    }
}

export function forceMultiPolygon3Winding(outerPaths, innerPaths, plane) {
    const finalPath = [];
    for (const path of outerPaths) {
        const tempPolygon = new Polygon3(path, plane);
        tempPolygon.forceOrientation();
        finalPath.push(tempPolygon.path);
    }

    for (const path of innerPaths) {
        const tempPolygon = new Polygon3(path, plane);
        tempPolygon.forceOrientation();
        finalPath.push(_.reverse(tempPolygon.path));
    }

    return new MultiPolygon3(finalPath, plane);
}

export class Polygon3 {
    constructor(path, plane) {
        this.path = path;
        this.plane = plane;
    }

    signedArea() {
        if (!this.cachedSignedArea) {
            const len = this.path.length;
            let area = 0;
            let lastPoint = this.path[len - 1];

            for (let i = 0; i < len; i += 1) {
                const point = this.path[i];
                area += lastPoint.clone().cross(point).dot(this.plane.normal);
                lastPoint = point;
            }

            this.cachedSignedArea = area * 0.5;
        }

        return this.cachedSignedArea;
    }

    getOffset(offset) {
        return new Polygon3(
            _.map(this.path, i => i.clone().add(offset)), this.plane.getOffset(offset));
    }

    clone() {
        return new Polygon3(_.map(this.path, i => i.clone()), this.plane.clone());
    }

    wrapIndex(i) {
        return i % this.path.length;
    }

    segmentAtIndex(i) {
        return new LineSegment3(this.path[i], this.path[this.wrapIndex(i + 1)]);
    }

    forceOrientation() {
        if (this.signedArea() < 0) {
            _.reverse(this.path);
            this.cachedSignedArea *= -1;
        }
    }
}

export function paths3to2(paths, { basisI } = {}) {
    const paths2 = paths.map(
        path => path.map(i => (new THREE.Vector2()).copy(i.clone().applyMatrix3(basisI))));
    return paths2;
}

export function paths2to3(paths, { basis, zOffset } = {}) {
    const paths3 = paths.map(
        path => path.map(i => (new THREE.Vector3(i.x, i.y, zOffset)).applyMatrix3(basis)));
    return paths3;
}

export class AABB3 {
    constructor() {
        this.empty = true;
        this.xMin = Number.POSITIVE_INFINITY;
        this.xMax = Number.NEGATIVE_INFINITY;
        this.yMin = Number.POSITIVE_INFINITY;
        this.yMax = Number.NEGATIVE_INFINITY;
        this.zMin = Number.POSITIVE_INFINITY;
        this.zMax = Number.NEGATIVE_INFINITY;
    }

    setBoundingBox(xMin, xMax, yMin, yMax, zMin, zMax) {
        this.empty = false;
        this.xMin = xMin;
        this.xMax = xMax;
        this.yMin = yMin;
        this.yMax = yMax;
        this.zMin = zMin;
        this.zMax = zMax;
    }

    fromBoundingBoxes(boxes) {
        for (const box of boxes) {
            if (!box.empty) {
                this.xMin = Math.min(this.xMin, box.xMin);
                this.xMax = Math.max(this.xMax, box.xMax);
                this.yMin = Math.min(this.yMin, box.yMin);
                this.yMax = Math.max(this.yMax, box.yMax);
                this.zMin = Math.min(this.zMin, box.zMin);
                this.zMax = Math.max(this.zMax, box.zMax);
            }
        }
        this.empty = false;

        return this;
    }

    fromPoints(points) {
        for (const point of points) {
            this.xMin = Math.min(this.xMin, point.x);
            this.xMax = Math.max(this.xMax, point.x);
            this.yMin = Math.min(this.yMin, point.y);
            this.yMax = Math.max(this.yMax, point.y);
            this.zMin = Math.min(this.zMin, point.z);
            this.zMax = Math.max(this.zMax, point.z);
        }
        this.empty = false;

        return this;
    }

    intersectBBox(bbox) {
        const corner1 = new THREE.Vector2(bbox.xMin, bbox.yMin);
        const corner2 = new THREE.Vector2(bbox.xMin, bbox.yMax);
        const corner3 = new THREE.Vector2(bbox.xMax, bbox.yMin);
        const corner4 = new THREE.Vector2(bbox.xMax, bbox.yMin);

        return this.pointInBBox(corner1) || this.pointInBBox(corner2) ||
                this.pointInBBox(corner3) || this.pointInBBox(corner4);
    }

    pointInBBox(point) {
        // inclusive
        return this.minX <= point.x && point.x <= this.maxX &&
                this.minY <= point.y && point.y <= this.maxY;
    }

    hasIntersection(ray) {
        let tmin = (this.xMin - ray.point.x) / ray.dir.x;
        let tmax = (this.xMax - ray.point.x) / ray.dir.x;

        if (tmin > tmax) {
            const temp = tmin;
            tmin = tmax;
            tmax = temp;
        }

        if (tmax < 0) return false;

        let tymin = (this.yMin - ray.point.y) / ray.dir.y;
        let tymax = (this.yMax - ray.point.y) / ray.dir.y;

        if (tymin > tymax) {
            const temp = tymin;
            tymin = tymax;
            tymax = temp;
        }

        if ((tmin > tymax) || (tymin > tmax)) return false;

        tmin = Math.min(tmin, tymin);
        tmax = Math.max(tmax, tymax);

        if (tmax < 0) return false;

        let tzmin = (this.zMin - ray.point.z) / ray.dir.z;
        let tzmax = (this.zMax - ray.point.z) / ray.dir.z;

        if (tzmin > tzmax) {
            const temp = tzmin;
            tzmin = tzmax;
            tzmax = temp;
        }

        if ((tmin > tzmax) || (tzmin > tmax)) return false;

        tmin = Math.min(tmin, tzmin);
        tmax = Math.max(tmax, tzmax);

        if (tmax < 0) return false;

        return true;
    }

    getBoxCentroid() {
        const boxCentroid = new THREE.Vector3(
            (this.xMin + this.xMax) * 0.5,
            (this.yMin + this.yMax) * 0.5,
            (this.zMin + this.zMax) * 0.5,
        );

        return boxCentroid;
    }

    getBoxMin() {
        return new THREE.Vector3(this.xMin, this.yMin, this.zMin);
    }

    getBoxMax() {
        return new THREE.Vector3(this.xMax, this.yMax, this.zMax);
    }

    // Helper function
    contains(center) {
        return this.xMin <= center.x && center.x <= this.xMax &&
                this.yMin <= center.y && center.y <= this.yMax &&
                this.zMin <= center.z && center.z <= this.zMax;
    }

    // Helper function for checking whether two bboxes are the same
    sameAsBox(box) {
        return (this.xMin === box.xMin && this.xMax === box.xMax &&
                this.yMin === box.yMin && this.yMax === box.yMax &&
                this.zMin === box.zMin && this.zMax === box.zMax);
    }

    expand(delta) {
        this.xMin -= delta.x;
        this.xMax += delta.x;
        this.yMin -= delta.y;
        this.yMax += delta.y;
        this.zMin -= delta.z;
        this.zMax += delta.z;
        return this;
    }
}
