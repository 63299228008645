import { $rootScope } from 'helioscope/app/utilities/ng';
import { betaRootUrlGenerator } from 'helioscope/app/utilities/url';
import { getClassicToBetaCopyTextID } from 'helioscope/app/utilities/helpers';

const mod = angular.module('helioscope.users.directives', []);

mod.directive('betaBannerAlert', () => ({
    restrict: 'E',
    templateUrl: require('helioscope/app/users/partials/beta-banner.html'),
    scope: { user: '=', inSidebar: '=', betaPage: '=' },
    controller($scope) {
        $scope.betaURL = `${betaRootUrlGenerator()}/${$scope.betaPage}`;
        $scope.copyTextID = getClassicToBetaCopyTextID($scope.user);
        $scope.contactUsURL = 'mailto:support@helioscope.com';
    },
}));
