/*globals angular:true, _:true */
import { user } from 'helioscope/app/users';

(function (angular, _) {
    "use strict";

    var mod = angular.module('helioscope.libraries.services', ['ui.select2']);

    const folsomLabsId = 1;
    const moduleSelect2Options = {
        sortFn: (module) => `${module.manufacturer} ${module.power || module.max_power}`,
        groupBy: (module) => (module.team_id || folsomLabsId),
        sortTitles: [
            (group) => (group.children[0].team_id === user.team_id ? 0 : 1),
            // We (Folsom Labs) used to upload modules as team_id null,
            // so treat null the same as folsomLabsId
            (group) => (group.children[0].team_id == null ||
                        group.children[0].team_id === folsomLabsId ? 0 : 1),
            (group) => group.text.toLowerCase(),
        ],
        formatTitles: (children, _group) => ({
            text: children[0].team != null ? children[0].team.name : 'Folsom Labs',
            children,
        }),
        sortOverrides: 'current_team_owns_module asc,module_team_public asc,teamName() asc,manufacturer asc,power asc',
    };

    // return a debounced function for querying the DB in select2 boxes
    mod.factory('select2QueryFactory', function ($log) {
        return function (options) {
            var timeout = options.timeout || 300,
                resource = options.resource,
                sortFn = options.sortFn,
                defaultArgs = options.args || {},
                groupBy = options.groupBy || ((_x) => 'All'),
                sortTitles = options.sortTitles || 'text',
                formatTitles = options.formatTitles || ((children, group) => ({ text: group, children })),
                sortOverrides = options.sortOverrides || '';

            return _.debounce(function (query) {
                $log.log("Querying DB for: " + query.term);
                if (query.term.length < 3) {
                    query.callback({
                        results: [
                            {text: "Enter at least three characters", locked: true, disabled: true}
                        ]
                    });
                } else {
                    var page = query.context ? query.context.page + 1 : 1,
                        pageSize = 50;

                    resource.query(
                        angular.extend(
                            defaultArgs,
                            {
                                q: query.term,
                                offset: (page - 1) * pageSize,
                                limit: pageSize,
                                sort: sortOverrides,
                            },
                        ),
                        function (items) {
                            query.callback({
                                more: items.length === pageSize,
                                context: {page: page},
                                results: (_(items)
                                    .sortBy(sortFn)
                                    .groupBy(groupBy)
                                    .map(formatTitles)
                                    .sortBy(sortTitles)
                                    .value()),
                            });//end callback
                        }, function (err) {
                            $log.log("Error loading data");
                            $log.log(err);
                        },
                    );
                }
            }, timeout);
        };
    });

    // return the options to make an async select2 dialog box for component
    // selection
    mod.factory("querySelectOptions", function ($q, $log) {

        function parseProjectComponents(items, getId) {
            const sortFn = (module) => `${module.manufacturer} ${module.power}`;
            const current = _(items).filter({ _current_project: true }).uniq(getId).sortBy(sortFn)
                .value();
            const favorites = _(items).filter({ favorite: true }).uniq(getId).sortBy(sortFn)
                .value();
            const rtn = [];

            if (current.length) {
                rtn.push({
                    text: 'Current Project',
                    children: current
                });
            }

            if (favorites.length) {
                rtn.push({
                    text: 'Favorites',
                    children: favorites
                });
            }

            if (rtn.length === 0 && items.length > 0) {
                return items;
            }
            return rtn;
        }

        return function getDropdownOptions(options) {

            var id = options.id,                    // function to get the primary key of an item
                formatter = options.formatter,      // formatter for showing selection
                cacheName = options.cacheName,
                initialQuery = options.initialQuery, // query for initial data load
                searchQuery = options.searchQuery,   // query to be used for retrieving search data
                itemFromId = options.itemFromId,
                noResultsText = options.noResultsText,

                queryArgs = options.queryArgs || {},      //
                cacheObject = options.cacheObject || {},  //
                allowClear = options.allowClear === true, //

                items,
                itemsLoaded;

            if (cacheObject[cacheName] !== undefined) {
                items = cacheObject[cacheName];
                itemsLoaded = $q.when(items);
            } else {
                itemsLoaded = initialQuery(queryArgs).then(
                    function (m) {
                        items = parseProjectComponents(m, id);
                        cacheObject[cacheName] = items;
                    },
                    function (resp) {
                        $log.error(resp.status);
                        items = [];
                    }
                );
            }

            return {
                dropdownCss: {'font-size': '10px'},
                id: id,
                allowClear: allowClear,
                formatResult: formatter,
                formatSelection: formatter,
                initSelection: function (element, callback) {
                    itemsLoaded.then(function () { callback(itemFromId(element.val())); });
                },
                query: function (query) {
                    // items[0].children are the components in the current project, items[1].children are the components
                    // in favorites.
                    const noModuleInCurrentProjectOrFavorites = items != null && items.length === 2 && (
                        !!items[0].children && items[0].children.length === 0 &&
                        !!items[1].children && items[1].children.length === 0
                    );
                    if (!query.term || query.term.length === 0) {
                        if (items == null || items.length === 0 || noModuleInCurrentProjectOrFavorites) {
                            query.callback({results: [
                                {
                                    text: noResultsText,
                                    locked: true,
                                    disabled: true,
                                },
                            ]});
                        } else {
                            query.callback({ results: items });
                        }
                    } else {
                        searchQuery(query);
                    } // end else
                } // end query
            };

        };
    });


    // return the configuration for a directive to automatically add select2 based on
    // passed in options
    mod.factory('select2DirectiveFactory', function ($compile, querySelectOptions) {
        return function (name, config) {
            return {
                restrict: 'A',
                replace: false,
                priority: 1000,  // ensure this directive executes first
                terminal: true,  // don't let any other directives execute after it
                                 // (because it returns the fully compiled element)
                link: function postLink(scope, element, attrs) {

                    // can't use an isolated scope or the select2 model interactions
                    // won't propagate, so evaluate the interface variables manually
                    var options = scope.$eval(attrs[name]);

                    scope.select2Config = querySelectOptions(angular.extend({}, config, options));

                    // add the select2 directive
                    element.attr('ui-select2', 'select2Config');

                    // remove this one so we don't create an infinite loop when compiling to html
                    element.removeAttr(_.kebabCase(name));
                    element.removeAttr("data-" + _.kebabCase(name));

                    // compile the element with the directive in place
                    $compile(element)(scope);
                }
            };
        };
    });

    mod.directive('moduleSelector', function (Module, select2DirectiveFactory, select2QueryFactory) {
        var moduleSelectOptions = {
            resource: Module,
            id: function (m) { return m && m.module_id; },
            formatter: function (m) { return m && (m.module_id ? m.toString() : m.text); },
            itemFromId: function (id) { return Module.cached(id); },
            noResultsText: "Search the library for a module now. <br><br> You can also make commonly used modules 'favorites' from within the module library, favorites will always be available in the module dropdown.",
            cacheName: 'moduleLibrary',
            initialQuery: function (args) { return Module.project_modules(args).$promise; },
            searchQuery: select2QueryFactory(angular.extend(moduleSelect2Options, { resource: Module })),
        };

        return select2DirectiveFactory('moduleSelector', moduleSelectOptions);
    });

    mod.directive('inverterSelector', function (PowerDevice, select2DirectiveFactory, select2QueryFactory) {
        var inverterSelectOptions = {
            resource: PowerDevice,
            id: function (pd) { return pd && pd.power_device_id; },
            formatter: function (m) { return m && m.power_device_id ? m.toString(true) : m && m.text; },
            itemFromId: function (id) { return PowerDevice.cached(id); },
            noResultsText: "Search the library for an inverter now. <br><br>You can also add commonly used inverters to your 'favorites' on the component library page.",
            cacheName: 'inverterLibrary',
            initialQuery: function (args) { return PowerDevice.project_inverters(args).$promise; },
            searchQuery: select2QueryFactory(angular.extend(moduleSelect2Options,
                { resource: PowerDevice, args: { device_type: 'inverter' } })),
        };

        return select2DirectiveFactory('inverterSelector', inverterSelectOptions);
    });

    mod.directive('optimizerSelector', function (PowerDevice, select2DirectiveFactory, select2QueryFactory) {
        var optimizerSelectOptions = {
            resource: PowerDevice,
            id: function (pd) { return pd && pd.power_device_id; },
            formatter: function (m) { return m && m.power_device_id ? m.toString(true) : m && m.text; },
            itemFromId: function (id) { return PowerDevice.cached(id); },
            noResultsText: "Search the library for an optimizer now. <br><br>You can also add commonly used optimizers to your 'favorites' on the component library page.",
            cacheName: 'optimizerLibrary',
            initialQuery: function (args) { return PowerDevice.project_optimizers(args).$promise; },
            searchQuery: select2QueryFactory(angular.extend(moduleSelect2Options,
                { resource: PowerDevice, args: { device_type: 'optimizer' } })),
            allowClear: true,
        };

        return select2DirectiveFactory('optimizerSelector', optimizerSelectOptions);
    });

}(angular, _));
