/* eslint-disable no-use-before-define */
import { incrementMap } from 'helioscope/app/utilities/containers';
import { $state } from 'helioscope/app/utilities/ng';
import { PowerDevice } from 'helioscope/app/libraries/resources';
import Logger from 'js-logger';
import _ from 'lodash';
const logger = Logger.get('projects/directive_helpers');

/**
 * parse Design Components into a view that can be used by the design Components directive
 * TODO: AC Panels: untested, AC Branches (microinverters), Optimizers
 */
export function parseDesignComponents(design) {
    const componentData = design.field_component_metadata;
    if (!componentData) {
        return [];
    }

    const wiringZonesData = componentData.wiring_zones;
    return _([
        parseWiringZoneData(wiringZonesData, 'inverter', 'power_device_id', summarizeDevice('Inverters')),

        buildFakeTransformers(design, wiringZonesData),

        parseWiringZoneData(wiringZonesData, 'ac_panel', 'inputs', summarizeCombiner('AC Panel')),
        parseWiringZoneData(wiringZonesData, 'ac_run', 'wire_gauge_id', summarizeWire('AC Home Runs')),
        parseWiringZoneData(wiringZonesData, 'bus', 'wire_gauge_id', summarizeWire('Home Runs')),
        parseWiringZoneData(wiringZonesData, 'combiner', 'inputs', summarizeCombiner('Combiner')),

        // TODO: will need to do something special here when microinverters
        parseWiringZoneData(wiringZonesData, 'ac_branch', 'wire_gauge_id', summarizeWire('AC Branches')),
        parseWiringZoneData(wiringZonesData, 'string', 'wire_gauge_id', summarizeWire('Strings')),
        parseWiringZoneData(wiringZonesData, 'optimizer', 'power_device_id', summarizeDevice('Optimizers')),
        parseModules(design, componentData.field_segments),
    ])
    .flatten() // each parser returns an array of matching components, flatten together
    .compact() // remove empty results
    .value();
}

function buildFakeTransformers(design, wiringZonesData) {
    if (!design.ac_config_id) {
        return [];
    }
    const res = {};
    const wiringZones = design.wiring_zones;
    for (const wiringZone of wiringZones) {
        if (!wiringZone.use_transformers) {
            continue;
        }

        const wzOutputConfig = wiringZone.panel_transformer_config || wiringZone.inverter_ac_config;

        if (wzOutputConfig && wzOutputConfig !== design.ac_config) {
            const from = wzOutputConfig;
            const to = design.ac_config;
            const key = `${to.ac_config_id}|${from.ac_config_id}`;

            const current = res[key] || {};
            current.type = 'Transformer';
            current.count = 1 + (current.count || 0);
            current.name = `Primary Side: ${to.name}, Secondary: ${from.name}`;
            res[key] = current;
        }

        if (wiringZone.inverter_ac_config_id && wiringZone.panel_transformer_config_id) {
            const from = wiringZone.inverter_ac_config;
            const to = wiringZone.panel_transformer_config;
            const key = `${to.ac_config_id}|${from.ac_config_id}`;

            const current = res[key] || {};
            current.type = 'Transformer';
            current.name = `Primary Side: ${to.name} , Secondary: ${from.name}`;
            res[key] = current;

            let count = (current.count || 0);
            const wiringZoneId = wiringZone.wiring_zone_id;
            const wiringZoneData = wiringZonesData[wiringZoneId];
            const acPanels = wiringZoneData.ac_panel;
            count += _.sumBy(acPanels, 'count');
            current.count = count;
        }
    }
    return Object.values(res);
}

function parseModules(design, fsModuleCounts) {
    const moduleCounts = new Map();

    _.each(fsModuleCounts, (moduleCount, fieldSegmentId) => {
        const fs = design.fieldSegment(fieldSegmentId);
        if (fs) {
            incrementMap(moduleCounts, fs.module_characterization.module, moduleCount);
        } else {
            // TODO: this happens quite often, would be good to figure out why
            // maybe due to adding field segment after loading design
            const data = {
                extra: {
                    designId: design.design_id,
                    wantedFieldSegmentId: fieldSegmentId,
                },
            };
            logger.error('parseModules: field_segment not found', data);
        }
    });

    return [...moduleCounts].map(([module, moduleCount]) => ({
        type: 'Module',
        id: module.module_id,
        name: module.toString(),
        count: moduleCount,
        power: moduleCount * module.power,
        href: $state.href('library.modules.specs.characterization', module, { absolute: true }),
    }));
}

export function parseWiringZoneData(wiringZonesData, componentKey, groupBy, summarize) {
    // for each wiringZone, get the array for the category
    // flatten the array into a list
    let components = _.flatMap(wiringZonesData, componentKey);
    components = _.compact(components);  // remove unused element
    // groupBy the unique identifier for the group
    const grouped = _.groupBy(components, groupBy);
    const res = _.map(grouped, subComponents => summarize(subComponents));
    return res;
}

export function summarizeDevice(type) {
    return (devices, { name, manufacturer, max_power, power_device_id } = _.first(devices)) => {
        // hack for current SE device scalars
        const device = PowerDevice.cached(power_device_id);
        let deviceScalar = 1;

        if (device && type === 'Optimizer') {
            deviceScalar = device.modules_per_optimizer || 1;
        }

        return {
            type,
            id: power_device_id,
            name: `${name} (${manufacturer})`,
            count: Math.ceil(_.sumBy(devices, x => x.inverter_count || x.count) / deviceScalar),
            power: _.sumBy(devices, x => x.inverter_count || x.count) * max_power, // eslint-disable-line camelcase
            href: $state.href('library.components.specs.characterization', { power_device_id }, { absolute: true }),
        };
    };
}

function summarizeCombiner(type) {
    return (combiners, { inputs } = _.first(combiners)) => ({
        type: `${type}s`,
        name: `${inputs} input ${type}`,
        count: _.sumBy(combiners, 'count'),
    });
}

function summarizeWire(type) {
    return (wires, { wire_gauge } = _.first(wires)) => {
        // note: the field components API aggregates bom_length into length, so just use length here
        const length = _.sumBy(wires, 'length');
        if (!length) { return null; }

        return {
            type,
            name: wire_gauge,
            count: _.sumBy(wires, 'count'),
            length,
            href: $state.href('library.wires()', {}, { absolute: true }),
        };
    };
}
