/* global angular:true, document:true, window:true, google:true, _:true */

/* eslint-disable camelcase */

import Logger from 'js-logger';
import { get } from 'lodash';
import Q from 'q';

import * as analytics from 'helioscope/app/utilities/analytics';
import { FLError } from 'helioscope/app/utilities/helpers';
import { $http, $modal, $stateParams, $rootScope } from 'helioscope/app/utilities/ng';

import request from 'reports/modules/request.ts';

import { latLonToGoogle } from 'helioscope/app/utilities/maps/spherical_mercator';
import { fastPow2 } from 'helioscope/app/utilities/geometry/math';
import { createGoogleMap, loadGoogleMaps } from 'helioscope/app/utilities/maps';
import { getBetaIntegrationsURL } from 'helioscope/app/utilities/url';


const TILE_SIZE = 256;

const logger = Logger.get('scaled_layer');


export async function initService(layer, project) {
    const service = new layer.TileService(layer.imagery);
    await service.initCache(project);
    return service;
}


class TileService {
    constructor({ maxZoom = 21, urlRotator = 4 } = {}) {
        this.ABS_MAX_ZOOM = maxZoom;
        this.localMaxZoom = this.ABS_MAX_ZOOM - 3;
        this._urlRotatorIndex = 0;
        this._urlRotatorCount = urlRotator;
    }

    async initCache(project) {
        if (project && this.mapType.dataSource) {
            analytics.track('design.fetch_image_cache',
                {
                    source: this.mapType.dataSource,
                    design_id: $stateParams.design_id ? $stateParams.design_id : null,
                    project_id: project.project_id,
                    team_id: $rootScope.user().team_id,
                });

            this.project = project;
            const url = `/api/tile_cache/${project.project_id}/${this.mapType.dataSource}`;

            try {
                const resp = await $http.get(url);
                if (resp.data.x_span && resp.data.y_span) {
                    this.serverTileCache = resp.data;
                }
            } catch (e) {
                logger.warn(`Imagery cache GET failed for ${this.mapType.dataSource}, requesting POST`);
            }
        }
    }

    async getMaxZoom(lat, lng) {
        const name = this.name;

        for (let zoom = this.ABS_MAX_ZOOM; zoom >= 5; zoom--) {
            const coords = latLonToGoogle(lat, lng, zoom);
            const url = this._url(coords.x, coords.y, zoom);

            if (await this._checkTile(url)) {
                logger.info(`Found Max Zoom for ${name}:`, zoom);
                return zoom;
            }
        }

        throw `Can't find zoom for ${name} tiles`;
    }

    async _checkTile(url) {
        throw 'Unimplemented';
    }

    login(_mapCenter, _zoom) {
        return Q.when({ loggedIn: true });
    }

    loginNew(_mapCenter, _zoom) {
        return Q.when({ loggedIn: true });
    }

    currentUrlRotator() {
        this._urlRotatorIndex = (this._urlRotatorIndex + 1) % this._urlRotatorCount;
        return this._urlRotatorIndex;
    }

    url(x, y, zoom) {
        const cached = this.checkCache(x, y, zoom);
        if (cached) return cached;
        return this._url(x, y, zoom);
    }

    checkCache(x, y, zoom) {
        if (!this.serverTileCache) return null;

        // in tile cache check
        const { x_min, x_span, y_min, y_span, zoom_min, zoom_max, s3_prefix } = this.serverTileCache;
        const multi = 1 << (zoom - zoom_min);

        if (zoom >= zoom_min && zoom <= zoom_max &&
            x >= (x_min * multi) && x < ((x_min + x_span) * multi) &&
            y >= (y_min * multi) && y < ((y_min + y_span) * multi)) {
            return s3_prefix.replace('$X$', x).replace('$Y$', y).replace('$Z$', zoom);
        }

        return null;
    }
}

async function createDummyGoogleMap() {
    // create dummy google maps object to ensure access to maps tile api
    const dummyDivId = '__googleTileServiceDummy';

    if (!document.getElementById(dummyDivId)) {
        const dummy = document.createElement('div');
        dummy.id = dummyDivId;
        dummy.style.display = 'none';
        document.getElementsByTagName('body')[0].appendChild(dummy);
        await loadGoogleMaps();
        createGoogleMap(dummyDivId);
    }
}

export class GoogleTileService extends TileService {
    name = 'Google';

    constructor(mapTypeName) {
        super({ maxZoom: 21, urlRotator: 2 });
        this.TILE_URL_COUNT = 4;

        createDummyGoogleMap();

        const mapTypes = {
            satellite: {
                type: 'Satellite',
                query: 'lyrs=s',
                dataSource: 'google_satellite',
            },
            street: {
                type: 'Street',
                query: '', // lyrs=r
                dataSource: 'google_street',
            },
            hybrid: {
                type: 'Satellite',
                query: 'lyrs=y',
            },
            topographical: {
                type: 'Topgraphical',
                query: 'lyrs=t',
            },
            line: {
                type: 'Line',
                query: 'lyrs=h',
            },
        };

        this.mapType = mapTypes[mapTypeName];
        this.type = this.mapType.type;
        this.query = this.mapType.query;
    }


    // the google maps service MaxZoom API is finnicky, sometimes it
    // won't return unless you round URLs, sometimes it hangs up, but
    // fortunately google fails HEAD requests for tiles that aren't
    // there
    //
    // so start by checking the absolute max zoom and stopping when
    // you know the tile exists
    async _checkTile(url) {
        try {
            await fetch(url, { method: 'HEAD' });
            return true;
        } catch (e) {
            return false;
        }
    }

    _url(x, y, zoom) {
        const xMax = fastPow2(zoom);
        if (x < 0) {
            x = xMax + (x % xMax);
        }

        if (x >= xMax) {
            x = x % xMax;
        }
        //TODO: update to new google tile servers (this one is almost certainly deprecated)

        return `https://mt${this.currentUrlRotator()}.google.com/vt/?${this.query}&x=${x}&y=${y}&z=${zoom}&client=google`;
    }
}

export class BingTileService extends TileService {
    // JSON Endpoint to get Tile Server:
    // http://dev.virtualearth.net/REST/v1/Imagery/Metadata/{BING_MAP_NAME}?key={BING_KEY}
    // http://dev.virtualearth.net/REST/v1/Imagery/BasicMetadata/imagerySet/centerPoint?orientation=orientation&zoomLevel=zoomLevel&include=ImageryProviders&key=BingMapsKey
    name = 'Bing';

    constructor(mapTypeName) {
        super({ maxZoom: 21 });
        const mapTypes = {
            satellite: {
                type: 'Satellite',
                bingMapName: 'Aerial',
                urlKey: 'a',
                dataSource: 'bing_satellite',
            },
            hybrid: {
                type: 'Hybrid',
                bingMapName: 'AerialWithLabels',
                urlKey: 'h',

            },
            map: {
                type: 'Road',
                bingMapName: 'road',
                urlKey: 'r',
            },
        };

        this.mapType = mapTypes[mapTypeName];
        this.type = this.mapType.type;
        this.urlKey = this.mapType.urlKey;
    }

    async _checkTile(url) {
        try {
            // while Bing does return an (undocumented) header indicating the availability of headers,
            // they don't expose it via CORS, so the fetch() request wont return it.
            const response = await fetch(url);
            const contentType = response.headers.get('content-type');
            return contentType !== 'image/png';
        } catch (e) {
            return false;
        }
    }

    _url(x, y, zoom) {
        const quadKey = this.urlKey + tileXYToQuadKey(x, y, zoom);
        return (`https://ecn.t${this.currentUrlRotator()}.tiles.virtualearth.net/tiles/${quadKey}.jpeg?g=3517`);
    }
}

export class NearmapTileService extends TileService {
    // available imagery dates by layer
    name = 'Nearmap';

    constructor(mapTypeName) {
        super({ maxZoom: 24 });
        const mapTypes = {
            // street maps appear to be deleated to Google
            // E, W, S, N are 45 degree views
            // Dsm is a broken download
            satellite: {
                type: 'Satellite',
                urlKey: 'V',
                dataSource: 'nearmap_satellite',
            },
            terrain: {
                type: 'Hybrid',
                urlKey: 'D', // Dem also works

            },
        };

        this.mapType = mapTypes[mapTypeName];
        this.urlKey = this.mapType.urlKey;

        this.rootDomain = 'https://api.nearmap.com';
    }

    async _checkTile(url) {
        try {
            await this.probeTile(url);
            return true;
        } catch (e) {
            return false;
        }
    }

    async probeTile(url) {
        const response = await fetch(url);

        if (response.ok) {
            return response;
        } else {
            throw new FLError('nearmaps tile probe failed', { error: 'Could not access Nearmap Tile Service', response });
        }
    }

    _url(x, y, zoom) {
        return `${this.rootDomain}/tiles/v3/Vert/${zoom}/${x}/${y}.jpg?apikey=${this._api_key}&tertiary=satellite`;
    }

    async login(mapCenter = { latitude: 37.7749, longitude: 122.4194 }, zoom = 14) {
        try {
            const response = await $http.get('/api/external_credentials/nearmap');
            this._api_key = response.data.api_key || null;

            if (this._api_key === null ) {
               return {loggedIn: false, error: 'User has no API key for Nearmap'}
            }

            const { x, y } = latLonToGoogle(mapCenter.latitude, mapCenter.longitude, zoom);
            await this.probeTile(`${this._url(x, y, zoom)}&cache_bust=${Math.random()}`);

            // track successful login via api_key

            analytics.track('designer.nearmap_login',
                {
                    design_id: $stateParams.design_id ? $stateParams.design_id : null,
                    project_id: this.project? this.project.project_id : null,
                    team_id: $rootScope.user().team_id,
                    success: true
                });

            return { loggedIn: true };
        } catch (err) {
            const reason = { error: get(err, 'data.error', 'Unknown Error') };
            let nearmapResponse = null;

            const response = get(err, 'data.response');

            if (response) {
                nearmapResponse = {
                    headers: Array.from(response.headers),
                    ok: response.ok,
                    redirected: response.redirected,
                    status: response.status,
                    statusText: response.statusText,
                    type: response.type,
                    url: response.url,
                };
                logger.warn('error checking tile for nearmap', nearmapResponse);
            } else {
                logger.warn('unknown nearmap error');
            }

            // track login fail via api_key
                analytics.track('designer.nearmap_login',
                    {
                        design_id: $stateParams.design_id ? $stateParams.design_id : null,
                        project_id: this.project? this.project.project_id : null,
                        team_id: $rootScope.user().team_id,
                        success: false
                    });

            // If this a restricted region for the Nearmap user, Nearmap returns a 403.
            // In this case we want to show a dialog to the user indicating that their key is valid
            // but they need to upgrade their subscription
            if (response && response.status == 403) {
                return { loggedIn: false, restricted_region: true };
            }

            return { loggedIn: false, reason };
        }
    }

    async loginNew(mapCenter = { latitude: 37.7749, longitude: 122.4194 }, zoom = 14) {
        try {
            const response = await request.get('/api/external_credentials/nearmap').withCredentials();
            this._api_key = response.data.api_key || null;
            const { x, y } = latLonToGoogle(mapCenter.latitude, mapCenter.longitude, zoom);
            await this.probeTile(`${this._url(x, y, zoom)}&cache_bust=${Math.random()}`);
            return { loggedIn: true };
        } catch (err) {
            logger.warn('unknown nearmap error');

            const response = get(err, 'data.response');

            analytics.track('designer.nearmap_login',
            {
                design_id: $stateParams.design_id ? $stateParams.design_id : null,
                project_id: this.project? this.project.project_id : null,
                team_id: $rootScope.user().team_id,
                success: false
            });

            // If this a restricted region for the Nearmap user, Nearmap returns a 403.
            // In this case we want to show a dialog to the user indicating that their key is valid
            // but they need to upgrade their subscription
            if (response && response.status == 403) {
                return { loggedIn: false, restricted_region: true };
            }
            return { loggedIn: false };
        }
    }
}

const MAX_LAYER_ZOOM = 23;

/**
 * Keeps track of in-flight tile requests
 */
class TileRequestTracker {
    constructor() {
        this._outstandingRequests = new Set();
        this._index = 0;
        this._deferred = Q.defer();
    }

    increment() {
        const index = this._index++;
        this._outstandingRequests.add(index);
        return index;
    }

    done(index) {
        if (this._outstandingRequests.has(index) === false) {
            return;
        }


        this._outstandingRequests.delete(index);
        if (this._outstandingRequests.size === 0) {
            logger.debug('finished loading tiles');
            this._deferred.resolve();
        }
    }

    count() {
        return this._outstandingRequests.size;
    }

    waitForCompletion() {
        return this._deferred.promise;
    }

    reset() {
        this._deferred.reject();
        this._deferred = Q.defer();
        this._outstandingRequests.clear();
    }
}

export function showRestrictedRegionDialog(tileService, loginStatus) {
    const { name } = tileService;
    logger.log(`TileService ${name} is trying to view a restricted region`);

    $modal.open({
        animation: true,
        templateUrl: require('helioscope/app/utilities/maps/nearmap-restricted-region-modal.html'),
        windowClass: 'maps-login-modal-nearmap',
        controller() {
            this.betaExternalCredentialsURL = getBetaIntegrationsURL();
            this.helpCenterArticle = "https://help-center.helioscope.com/hc/en-us/articles/23029761257235"
        },
        controllerAs: 'ctrl',
    });
}

export function showLoginRequiredDialog(tileService, loginStatus) {
    const { name } = tileService;
    logger.log(`TileService ${name} isn't logged in`);

    $modal.open({
        animation: true,
        templateUrl: require('helioscope/app/utilities/maps/nearmap-beta-login-modal.html'),
        windowClass: 'maps-login-modal-nearmap',
        controller() {
            this.betaExternalCredentialsURL = getBetaIntegrationsURL();
            this.helpCenterArticle = "https://help-center.helioscope.com/hc/en-us/articles/23029761257235"
        },
        controllerAs: 'ctrl',
    });
}

/**
 * Proxies actual tile service, and performs scaling of the DIVs if needed.
 *
 * Entities:
 * - renderedDivs: contains the actual scaled DIVs. This is used for bookkeeping purposes.
 * - tileCache: all tiles. These may get scaled and fed into renderedDivs
 * - tile.coords: the image coords as requested by google maps
 * - tile.imageryCoords: the underlying imagery coords
 *
 * The hack here is that when we need a tile that can't be servied by the imagery provider, we stretch the *div*
 * so that it takes the same size as the original image.
 *
 * So instead of
 * +----------------+
 * |        |       |
 * |   D1   |   D2  |
 * |        |       |
 * |--------|-------|
 * |        |       |
 * |   D3   |  D4   |
 * |        |       |
 * +----------------+
 * We have
 * +----------------+
 * |                |
 * |   D1   |       |
 * |                |
 * |---  ---|---  --|
 * |                |
 * |        |       |
 * |                |
 * +----------------+
 * (D2..D4 are empty, and D1 is stretched to the entire area)
 *
 *
 * When updateView is called, we pick the scaled divs that are closest to the center, and assign the *same* imagery
 * to them.
 * This is because google would release the tiles on the edge.
 *
 */
export class ScaledZoomMapType {
    constructor(map, tileService, name = tileService.name) {
        this.maxImageryZoom = tileService.localMaxZoom;
        this.tileService = tileService;
        this.tileCache = [];
        this.renderedDivs = {};
        this.map = map;
        this._shouldProbeZoom = false;
        this._loginDone = Q.defer();
        this._foundMaxZoom = Q.defer();
        this.name = name || tileService.name;
        this.requestTracker = new TileRequestTracker();

        // necessary for google maps
        this.maxZoom = MAX_LAYER_ZOOM;
        this.tileSize = new google.maps.Size(TILE_SIZE, TILE_SIZE);
        // render from tile cache
        this.updateView = _.debounce(() => {
            const point = this.map.getCenter();
            const centerPx = mapCoords(point.lat(), point.lng(), this.map.getZoom());
            const closestPoints = {};

            // at each point, find the best tile
            for (const div of this.tileCache) {
                const dist = pointDistance(centerPx, div.coords);
                if (closestPoints[div.imageryCoords] === undefined || closestPoints[div.imageryCoords].dist > dist) {
                    closestPoints[div.imageryCoords] = { dist, div };
                }
            }

            for (const val of _.values(closestPoints)) {
                const div = val.div;
                this.renderedDivs[div.imageryCoords] = div;

                // always render the view for the innermost div
                if (div.innerHTML === '' || div.forceRender) {
                    this.renderScaledDiv(div);
                    div.forceRender = false;
                }
            }
        }, 1);
    }

    /**
     * Find the max zoom and apply it to the map
     */
    _probeZoom(delay = 20) {
        if (this._shouldProbeZoom) {
            return;
        }

        this._shouldProbeZoom = true;

        let zoomListener = null;
        let setup = async () => {
            logger.debug(`probing zoom for ${this.tileService.name}`);

            try {
                const point = this.map.getCenter();
                const zoom = await this.tileService.getMaxZoom(point.lat(), point.lng());
                this.setMaxImageryZoom(zoom);
            } catch (err) {
                logger.error(`Error finding Max Zoom: ${err}`);
                this.setMaxImageryZoom(12);
            } finally {
                google.maps.event.removeListener(zoomListener);
                this._foundMaxZoom.resolve();
            }
        };

        setup = _.debounce(setup, delay);
        zoomListener = google.maps.event.addListener(this.map, 'bounds_changed', setup);

        // takes a while to 'set up' due to a lot of hairy map init code
        if (Number.isNaN(this.map.getCenter().lat()) === false) {
            setup();
        }
    }


    /**
     * Called once zoom has been probed. Updated the cached tiles.
     * @param maxImageryZoom
     */
    setMaxImageryZoom(maxImageryZoom) {
        if (maxImageryZoom === this.maxImageryZoom) {
            return;
        }

        this.maxImageryZoom = maxImageryZoom;

        logger.log(`Rerendering tiles at zoom:${this.maxImageryZoom}`);
        let dirty = false;

        for (const div of this.tileCache) {
            // refine the tile if the zoom we discovered is different from the one we assumed
            if (div.currentMaxZoom !== this.maxImageryZoom) {
                div.currentMaxZoom = this.maxImageryZoom;
                this.populateImageryCoordinates(div);
                div.forceRender = true;

                dirty = true;
            }
        }

        if (dirty) {
            this.renderedDivs = {};
            this.requestTracker.reset();
            this.updateView();
        }
    }

    releaseTile(div) {
        const idx = this.tileCache.indexOf(div);
        if (idx !== -1) {
            this.tileCache.splice(idx, 1);
        }
        if (this.renderedDivs[div.imageryCoords] === div) {
            // tecnically there could be multiple renders at this location
            // but handling that correctly doesn't improve experience, because
            // google maps handles the tile lifecycle
            delete this.renderedDivs[div.imageryCoords];
        }

        this.updateView();
    }

    clearCache() {
        logger.debug(`Clearing cache for ${this.name} map layer`);
        this.renderedDivs = {};
        this.tileCache.length = 0;
        this.requestTracker.reset();
    }

    async activate() {
        const tileService = this.tileService;
        const loginStatus = await tileService.login();

        if (loginStatus.loggedIn) {
            this._loginDone.resolve();
            this._probeZoom();
        } else {
            showLoginRequiredDialog(tileService, loginStatus);
        }
    }

    mapTile(coords, zoom, div, scalar, offsetCoords) {
        scalar = scalar || 1;
        const tileSize = TILE_SIZE;
        const size = `${Math.round(10 * scalar * tileSize) / 10}px`;

        div.style.width = size;
        div.style.height = size;
        div.className += ' scaled-map-tile';

        if (offsetCoords !== undefined) {
            div.style.marginLeft = `${tileSize * offsetCoords.x}px`;
            div.style.marginTop = `${tileSize * offsetCoords.y}px`;
        }

        const tileIndex = this.requestTracker.increment();
        const img = document.createElement('img');
        img.onerror = () => {
            img.style.display = 'none';
            this.requestTracker.done(tileIndex);
            logger.debug(`tile { x: ${coords.x}, y: ${coords.y}, zoom: ${zoom} } fetch failed`);
        };

        img.onload = () => {
            img.className = 'loaded';
            this.requestTracker.done(tileIndex);
            // logger.debug(`tile { x: ${coords.x}, y: ${coords.y}, zoom: ${zoom} } fetch done`);
        };

        this._loginDone.promise.then(() => {
            img.src = this.tileService._url(coords.x, coords.y, zoom);
        });

        // $(div).empty();
        div.appendChild(img);
        return div;
    }

    async tilesLoaded() {
        await this.foundMaxZoom();
        await this.requestTracker.waitForCompletion();
    }

    foundMaxZoom() {
        return this._foundMaxZoom.promise;
    }

    renderScaledDiv(div) {
        return this.mapTile(
            div.imageryCoords,
            Math.min(this.maxImageryZoom, div.zoom),
            div,
            1 / div.zoomScalar,
            div.offsetCoords
        );
    }

    populateImageryCoordinates(div) {
        div.zoomScalar = Math.min(1.0, fastPow2(this.maxImageryZoom - div.zoom));

        const rawX = div.coords.x * div.zoomScalar;
        const rawY = div.coords.y * div.zoomScalar;

        div.imageryCoords = new google.maps.Point(fastTrunc(rawX), fastTrunc(rawY));
        div.offsetCoords = new google.maps.Point(Math.round((div.imageryCoords.x - rawX) / div.zoomScalar),
            Math.round((div.imageryCoords.y - rawY) / div.zoomScalar));
    }

    getTile(coords, zoom, ownerDocument) {
        const div = ownerDocument.createElement('div');

        div.coords = coords;
        div.zoom = zoom;
        div.currentMaxZoom = this.maxImageryZoom;
        this.tileCache.push(div);

        if (zoom <= this.maxImageryZoom) {
            // pass through directly to Google Maps
            this.mapTile(coords, zoom, div);
        } else {
            this.populateImageryCoordinates(div);

            if (this.renderedDivs[div.imageryCoords] === undefined) {
                // rendering here eliminated some browser flashing
                this.renderedDivs[div.imageryCoords] = this.renderScaledDiv(div);
            }

            this.updateView();
        }

        return div;
    }
}

function mapCoords(lat, lng, zoom) {
    const centerPx = latLonToGoogle(lat, lng, zoom);

    return new google.maps.Point(
        centerPx.x,
        centerPx.y
    );
}

function pointDistance(p1, p2) {
    return Math.pow(p1.x - p2.x, 2) + Math.pow(p1.y - p2.y, 2);
}

function fastTrunc(val) {
    return val | 0;
}

function tileXYToQuadKey(x, y, zoom) {
    const quadKey = [];
    let digit;
    let mask;
    for (let i = zoom; i > 0; i -= 1) {
        digit = 0;
        mask = 1 << (i - 1);
        if ((x & mask) !== 0) {
            digit += 1;
        }
        if ((y & mask) !== 0) {
            digit += 1;
            digit += 1;
        }
        quadKey.push(digit);
    }
    return quadKey.join('');
}
