/*global window:true, angular:true, jQuery:true, _:true, PNotify:true */
import Logger from 'js-logger';
import * as pusher from 'helioscope/app/utilities/pusher';
import { $http, $timeout, $q } from 'helioscope/app/utilities/ng';

const logger = Logger.get('app/services');


(function (angular, _) {
    'use strict';

    var module = angular.module('helioscope.services', ['ui.router.state']);

    module.factory('pusher', () => pusher.makeChannel);

    module.factory('Messager', ['$rootScope', '$compile', function ($rootScope, $compile) {

        function message(text, type, options) {
            var settings = {
                text: text,
                type: type,
                delay: 3000,
                buttons: {
                    sticker: false,
                    sticker_hover: false,
                },
                styling: PNotify.styling.fontawesome,
            };

            _.merge(settings, options || {});
            return new PNotify(settings);
        }

        function loaderMessage(text, options) {
            var settings = {
                nonblock: {
                    nonblock: true,
                    nonblock_opacity: 0.2
                },
                icon: 'fa fa-spinner fa-spin',
                hide: false,
                opacity: 0.9
            },
                notification;

            _.merge(settings, options || {});
            notification = message(text, 'info', settings);

            notification.progress = function (options) {
                this.update(options);
            };

            notification.success = function (text, { nonblock = false, delay = 1500 } = {}) {
                this.update({
                    opacity: 1,
                    type: 'success',
                    icon: 'fa fa-check-square-o',
                    hide: true,
                    delay,
                    nonblock: {
                        nonblock,
                    },
                    buttons: {
                        closer: true
                    },
                    text: text,
                });
            };

            notification.error = function (text) {
                this.update({
                    opacity: 1,
                    icon: 'fa fa-minus-circle',
                    type: 'error',
                    nonblock: {
                        nonblock: false
                    },
                    buttons: {
                        closer: true
                    },
                    hide: true,
                    delay: 1500,
                    text: text
                });

            };

            notification.close = function () {
                this.remove();
            };

            return notification;
        }


        function asyncLoader(text, promise, options) {
            var $scope,
                settings = {
                    nonblock: {
                        nonblock: true,
                        nonblock_opacity: 0.2
                    },
                    icon: 'fa fa-spinner fa-spin',
                    hide: false,
                    opacity: 0.9
                },
                notification;

            angular.extend(settings, options || {});

            notification = message(text, 'info', settings);

            function success({ title = '', text = '', delay = 2000, nonblock = false, ...options } = {}) {
                notification.update(angular.extend({
                    type: 'success',
                    icon: 'fa fa-check-square-o',
                    hide: true,
                    buttons: {
                        closer: true,
                    },
                    delay,
                    nonblock: {
                        nonblock,
                    },
                    text: _.escape(text),
                    title: _.escape(title),
                }, options || {}));
            }

            function error(options) {
                if (options.text) options.text = _.escape(options.text);
                if (options.title) options.title = _.escape(options.title);
                notification.update(angular.extend({
                    icon: 'fa fa-minus-circle',
                    type: 'error',
                    buttons: {
                        closer: true
                    },
                    nonblock: {
                        nonblock: false
                    },
                    hide: true,
                    delay: 2000,
                    text: '',
                    title: '',

                }, options || {}));
            }

            function progress(data) {
                if ($scope === undefined) {
                    $scope = $rootScope.$new();

                    notification.update({
                        title: '{{data.text}}',
                        text: '<div class="async-loader-progress-bar"><progressbar class="progress-striped active" value="data.value"></progressbar></div>',
                        nonblock: {
                            nonblock: true,
                            nonblock_opacity: 0.2
                        },
                        type: 'info',
                        after_close: function () {
                            $scope.$destroy();
                        }
                    });
                    $scope.data = data;
                    $compile(notification.elem)($scope);
                } else {
                    $scope.data = data;
                }
            }

            promise.then(success, error, progress);

        }

        function fakeProgressLoader(text, promise, {maxProgressDuration = 3000, incrementDuration = 50, maxProgress = 80, ...options } = {}) {
            let currentProgress = 0;
            const progressIncrement = (maxProgress / maxProgressDuration) * incrementDuration;
            const messageDeferred = $q.defer();
            const loader = asyncLoader(text, messageDeferred.promise, options);

            let progressInterval = $timeout(function incrementProgress() {
                currentProgress += progressIncrement;
                if (currentProgress < maxProgress) {
                    messageDeferred.notify({ value: currentProgress, text: text });
                    $timeout(incrementProgress, incrementDuration);
                }
            }, incrementDuration, false);

            promise.then((opts) => {
                $timeout.cancel(progressInterval);
                messageDeferred.resolve(opts);
            }).catch((opts) => {
                $timeout.cancel(progressInterval);
                messageDeferred.reject(opts);
            });
        }

        return {
            info: function (text, options) { return message(_.escape(text), 'info', options); },
            success: function (text, options) { return message(_.escape(text), 'success', options); },
            error: function (text, options) { return message(_.escape(text), 'error', options); },
            load: function (text, options) { return loaderMessage(_.escape(text), options); },
            asyncLoader: function (startText, promise, options) { return asyncLoader(startText, promise, options); },
            fakeProgressLoader: function (startText, promise, options) { return fakeProgressLoader(startText, promise, options); },
        };
    }]);

    module.provider('modalState', ['$stateProvider', function ($stateProvider) {

        $stateProvider.modalState = function (state, opts) {
            var baseState = opts.baseState || state.substring(0, state.lastIndexOf('.'));

            return $stateProvider.state(state, {
                url: opts.url,
                data: opts.data,
                onEnter: ['$modal', '$state', '$injector', '$rootScope', '$location', '$stateParams', '$timeout',
                    function ($modal, $state, $injector, $rootScope, $location, $stateParams, $timeout) {

                        $timeout(function () {
                            var queryString = $location.search(),
                                nextState = queryString.state || baseState,
                                listener = angular.noop,
                                opened = false,
                                parentController = _($state.$current.parent.locals).find(function (val, key) {
                                    angular.noop(val);
                                    return key[0] === '@';
                                }),
                                parentScope = parentController ? parentController.$scope : undefined,
                                modalInstance = $modal.open(
                                    angular.extend({ scope: parentScope },
                                        $injector.invoke(opts.modalOptionsFactory, {}, { '$stateParams': $stateParams }))
                                );

                            delete queryString.state;
                            $location.search(queryString);

                            // once the model is open, handle state changes
                            modalInstance.opened.then(function () {
                                opened = true;
                                listener = $rootScope.$on('$stateChangeSuccess', function (event, toState, toParams, fromState, fromParams) {
                                    angular.noop(event, toState, toParams, fromParams);

                                    //if the state changes, closes the modal, unless it's a nested view
                                    if ($state.includes(fromState)) {
                                        // Nested view, don't close
                                        return;
                                    }
                                    listener();
                                    modalInstance.dismiss('stateChange');
                                });
                            });

                            function closeState(error) {
                                if (!opened) { // if the modal was never opened, this is a state change error
                                    $rootScope.$broadcast('$stateChangeError', null, null, null, null, error);
                                } else if (error !== 'stateChange') {
                                    $state.go(nextState);
                                }
                            }

                            modalInstance.result.then(
                                closeState, //usually onSuccess, for $modal  closed
                                closeState //usually onError, for $modal means dismissed
                            ).finally(function () {
                                listener();
                            });

                        });

                    }]
            });
        };

        this.$get = function () {
            return angular.noop;
        };
    }]);

}(angular, _));


const SOLAR_LOCATIONS = [
    { lat: 44.014299, lng: 6.008059, zoom: 16 },  // 18MW in France
    { lat: 38.890515, lng: -6.286758, zoom: 15 }, // Trackers in Spain
    { lat: 38.89191, lng: -6.267187, zoom: 17 },  // Spain
    { lat: 39.627507, lng: -2.074502, zoom: 17 }, // Spain
    { lat: 40.866667, lng: -72.85, zoom: 16 }, // Long Island, NY
    { lat: 27.323089, lng: -81.8021, zoom: 16 }, // Single-axis trackers in Florida
    { lat: 35.783842, lng: -114.995534, zoom: 15 }, // Searchlight, Nevada
    { lat: 42.347717, lng: -70.955828, zoom: 19 }, // Deer Island, MA
    { lat: 42.945114, lng: -82.333739, zoom: 15 }, // Sarnia, Canada
    { lat: 45.587677, lng: -94.402589, zoom: 18 }, // St John's MN
    { lat: 41.766739, lng: -72.688889, zoom: 19 }, // Aetna Atrium Building, Hartford, CT
    { lat: 39.961835, lng: -75.080138, zoom: 18 }, // Carpenter & Patterson, NJ
    { lat: 40.836227, lng: -74.066365, zoom: 18 }, // LPS Industries, NJ
    { lat: 45.75037, lng: -122.875318, zoom: 19 }, // Otto Peterson Elementary School, OR
    { lat: 37.36473, lng: -121.922574, zoom: 18 }, // San Jose Airport
    { lat: 40.027676, lng: -105.212248, zoom: 18 }, // Special Transit
    { lat: 45.51426, lng: -122.554207, zoom: 18 }, // Cherrywood Retirement, OR
    { lat: 47.732671, lng: -122.623856, zoom: 18 }, // Poulsbo Middle School, OR
    { lat: 33.981499, lng: -118.106537, zoom: 17 }, // Pico Rivera Towne Center
    { lat: 38.406363, lng: -121.951247, zoom: 17 }, // Mariani Packing Company, CA
    { lat: 38.421688, lng: -122.408837, zoom: 18 }, // Far Niete Winery Floatovoltaics ,CA
    { lat: 39.747202, lng: -104.943381, zoom: 19 }, // Denver Museum of Nature & Science
    { lat: 39.838826, lng: -104.673477, zoom: 16 }, // Denver Airport
    { lat: 29.443828, lng: -98.48018, zoom: 19 }, // Pearl Brewery, TX
    { lat: 40.030011, lng: -105.25744, zoom: 19 }, // Movement Climbing & Fitness, CO
    { lat: 38.151605, lng: -121.411463, zoom: 18 }, // Sutter Home Winery, CA
    { lat: 39.72288, lng: -121.816246, zoom: 18 }, // Sierra Nevada Brewing, CA
    { lat: 37.642302, lng: -122.131016, zoom: 19 }, // Pan Ocean Aquarium, CA
    { lat: 37.846782, lng: -122.293354, zoom: 18 }, // Clif Bar & Company, CA
    { lat: 39.804069, lng: -76.950658, zoom: 16 }, // Snyder's of Hanover, PA
    { lat: 28.41852, lng: -81.425547, zoom: 17 }, // Darden Restaurants HQ
    // { lat: 40.706108, lng: -74.008131, zoom: 19 }, // Deutsche Bank, 60 Wall Street
    { lat: 37.717911, lng: -121.88587, zoom: 17 }, // Santa Rita Jail
    { lat: 37.833774, lng: -121.947606, zoom: 19 }, // Athenian Schools, Danville
    { lat: 34.585975, lng: -113.178653, zoom: 18 }, // Copper Mine, Bagdad, AZ
    { lat: 32.366732, lng: -112.831639, zoom: 16 }, // Copper Mine, Ajo, AZ
    { lat: 37.750901, lng: -122.483574, zoom: 17 }, // Sunset Reservoir, San Francisco
    { lat: 37.367889, lng: -122.211847, zoom: 18 }, // Corte Madera School, Portola Valley
    { lat: 40.427549, lng: -74.708459, zoom: 17 }, // Johnson & Johnson, Skillman, NJ
    { lat: 38.403504, lng: -122.818397, zoom: 19 }, // Sebastopol
    { lat: 51.18918, lng: 4.363675, zoom: 18 }, // Belgium rooftop
];

export function randomSolarLocation() {
    return _.sample(SOLAR_LOCATIONS);
};
