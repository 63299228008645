import { BulkEntityChange } from './persistence/BulkEntityChange';
import { $log, Messager } from 'helioscope/app/utilities/ng';

import { MapConfig } from './MapConfig';
import { FieldSegment } from './field_segment';
import { Keepout } from './keepout';
import { EntityPremade } from './premade';

class BulkActionsMixin {
    changeConfig(resource, dispatcher) {
        const selectionSize = resource.length;

        const updateEntity = (entity, bulkEntityChange, undo = false) => {
            bulkEntityChange.applyEntityChanges(entity, undo);
            if (entity instanceof FieldSegment) {
                dispatcher.designManager.updateSurfaceGeometry(entity, { updateLayout: true });
            }
            if (entity instanceof Keepout) {
                dispatcher.designManager.updateSurfaceGeometry(entity);
            }
            if (entity instanceof EntityPremade) {
                dispatcher.designManager.updatePremadeGeometry(entity);
            }
        }

        return {
            delete: {
                text: `Remove group with ${selectionSize} objects`,
                preflight: () => { },
                onSuccess: (bulkEntityChange) => {
                    bulkEntityChange.entities.forEach((entity) => {
                        if (entity instanceof FieldSegment) {
                            dispatcher.designManager.removeFieldSegment(entity);
                        }
                        if (entity instanceof Keepout) {
                            dispatcher.designManager.removeKeepout(entity);
                            dispatcher.publish('entityKeepoutsChanged', { deleted: entity });
                        }
                        if (entity instanceof EntityPremade) {
                            dispatcher.designManager.removePremade(entity);
                            dispatcher.publish('entityPremadesChanged', { deleted: entity });
                        }
                    });

                    dispatcher.deselectEntity();
                    Messager.success(`Successfully deleted ${selectionSize} objects`);
                },
                onError: (err, bulkEntityChange) => {
                    bulkEntityChange.entities.forEach((entity) => {
                        if (entity instanceof FieldSegment) {
                            dispatcher.publish('entityFieldSegmentsChanged', { error: entity });
                        }
                        if (entity instanceof Keepout) {
                            dispatcher.publish('entityKeepoutsChanged', { error: entity });
                        }
                        if (entity instanceof EntityPremade) {
                            dispatcher.publish('entityPremadesChanged', { error: entity });
                        }
                    });

                    Messager.error(`Error when deleting ${selectionSize} objects`);
                },
            },
            create: {
                text: `Create group with ${selectionSize} objects`,
                preflight: (bulkEntityChange) => {
                    bulkEntityChange.entities.forEach((entity) => {
                        delete entity[entity._idName];
                        if (entity instanceof FieldSegment) {
                            dispatcher.designManager.updateSurfaceGeometry(entity, { updateLayout: true });
                        }
                        if (entity instanceof Keepout) {
                            dispatcher.designManager.updateSurfaceGeometry(entity);
                        }
                        if (entity instanceof EntityPremade) {
                            dispatcher.designManager.updatePremadeGeometry(entity);
                        }
                        dispatcher.renderer.renderEntity(entity);
                    });
                },
                onSuccess: (bulkEntityChange) => {
                    dispatcher.deselectEntity();
                    bulkEntityChange.entities.forEach((entity) => {
                        if (entity instanceof FieldSegment) {
                            dispatcher.publish('entityFieldSegmentsChanged', { created: entity, isClone: true });
                        }
                        if (entity instanceof Keepout) {
                            dispatcher.publish('entityKeepoutsChanged', { created: entity, isClone: true });
                        }
                        if (entity instanceof EntityPremade) {
                            dispatcher.publish('entityPremadesChanged', { created: entity, isClone: true });
                        }
                        dispatcher.selectEntity(entity, {}, true);
                    });

                    Messager.success(`Successfully created ${selectionSize} objects`);
                },
                onError: (err, bulkEntityChange) => {
                    bulkEntityChange.entities.forEach((entity) => {
                        if (entity instanceof FieldSegment) {
                            dispatcher.designManager.removeFieldSegment(entity);
                        }
                        if (entity instanceof Keepout) {
                            dispatcher.designManager.removeKeepout(entity);
                            dispatcher.publish('entityKeepoutsChanged', { error: entity });
                        }
                        if (entity instanceof EntityPremade) {
                            dispatcher.designManager.removePremade(entity);
                        }
                    });
                    Messager.error(`Error when creating ${selectionSize} objects`);
                },
            },
            update: {
                text: `Update group with ${selectionSize} objects`,
                preflight: (bulkEntityChange) => {
                    bulkEntityChange.entities.forEach((entity) => {
                        updateEntity(entity, bulkEntityChange);
                    });
                    dispatcher.renderUpdater.updateArray();
                },
                onSuccess: (bulkEntityChange) => {
                    dispatcher.publish('bulkEntityChanged', { updated: bulkEntityChange });
                    Messager.success(`Successfully updated ${selectionSize} objects`);
                },
                onError: (err, bulkEntityChange) => {
                    bulkEntityChange.entities.forEach((entity) => {
                        updateEntity(entity, bulkEntityChange, true);
                    });
                    Messager.error(`Error when updating ${selectionSize} objects`);
                },
            },
        };
    }

    create(dispatcher, entities) {
        const bulkEntityChange = new BulkEntityChange(entities);
        dispatcher.stateHandler.createObject(bulkEntityChange, this.changeConfig(bulkEntityChange, dispatcher));
    }

    delete(dispatcher, entities) {
        const bulkEntityChange = new BulkEntityChange(entities);
        dispatcher.stateHandler.deleteObject(bulkEntityChange, this.changeConfig(bulkEntityChange, dispatcher));
    }

    getStates(entities, propertyChanges) {
        const newState = new Map();
        const previousState = new Map();

        for (const entity of entities) {
            newState.set(entity, { ...propertyChanges });
            const currentProperties = {};
            Object.keys(propertyChanges).forEach((key) => (currentProperties[key] = entity[key]));
            previousState.set(entity, currentProperties);
        }
        return { newState, previousState };
    }

    update(dispatcher, entities, propertyChanges, states = {}) {
        let newState, previousState;

        if (states.newState && states.previousState) {
            ({ newState, previousState } = states);
        } else {
            ({ newState, previousState } = this.getStates(entities, propertyChanges));
        }

        const change = new BulkEntityChange(entities, newState, previousState);
        const undoChange = new BulkEntityChange(entities, previousState, newState);
        dispatcher.stateHandler.updateObject(change, undoChange, this.changeConfig(change, dispatcher));
    }
}

export const bulkActions = new BulkActionsMixin();
