import { startCase, lowerCase } from 'lodash';

import { RelationalBase, deserializeObject } from 'helioscope/app/relational';
import { GeoPoint } from 'helioscope/app/utilities/geometry';

const properCase = str => startCase(lowerCase(str));


export class AshraeWeather extends RelationalBase {
    static relationName = 'AshraeWeather';

    get minTemp() {
        return this.min_temp_extreme_mean;
    }

    get maxTemp() {
        return this.max_temp_extreme_mean;
    }

    toString() {
        if (this.ashrae_weather_id !== undefined) {
            return properCase(this.name);
        }

        return `${properCase(this.name)}, ${this.state || this.country || ''} (ASHRAE)`;
    }
}

AshraeWeather.configureRelationships({
    location: deserializeObject(GeoPoint),
});

AshraeWeather.createEndpoint('/api/ashrae_weather/:ashrae_weather_id', { ashrae_weather_id: '@ashrae_weather_id' });
