import { CursorSvgPaths } from 'helioscope/app/designer/CursorConfig';
import { toDegrees } from 'helioscope/app/utilities/geometry';
import { addSvgContainer, groupAndRotateSvgPaths, svgStringToCursorUrl } from '../InteractHelpers';
import { degreesToRotateCursor } from './OverlayHelpers';

export function generateMoveCursor() {
    // The move cursor never rotates, so no need to call groupAndRotateSvgPaths on its paths.
    // Just wrap the paths with the <g>...</g> group container.
    const svgGroup = `<g>${CursorSvgPaths.MOVE.paths}</g>`;
    const cursorString = addSvgContainer(svgGroup, CursorSvgPaths.MOVE.dimensions);
    return svgStringToCursorUrl(cursorString, CursorSvgPaths.MOVE.dimensions);
}

export function generateResizeCursor(overlay, index) {
    // index: 0 (south), 1 (east), 2 (north), 3 (west), when overlay is not rotated
    const rotationRadians = overlay.overlay_parameter.rotation_angle;
    const degreesToRotate = degreesToRotateCursor('resize', index, toDegrees(rotationRadians));
    const rotatedPathsGroup = groupAndRotateSvgPaths(
        CursorSvgPaths.RESIZE.paths,
        degreesToRotate,
        CursorSvgPaths.RESIZE.dimensions,
    );
    const cursorString = addSvgContainer(rotatedPathsGroup, CursorSvgPaths.RESIZE.dimensions);
    return svgStringToCursorUrl(cursorString, CursorSvgPaths.RESIZE.dimensions);
}

export function generateScaleCursor(overlay, index) {
    // handleIndex: 0 (southwest), 1 (southeast), 2 (northeast), 3 (northwest), when overlay is not rotated
    const rotationRadians = overlay.overlay_parameter.rotation_angle;
    const degreesToRotate = degreesToRotateCursor('scale', index, toDegrees(rotationRadians));
    const rotatedPathsGroup = groupAndRotateSvgPaths(
        CursorSvgPaths.SCALE.paths,
        degreesToRotate,
        CursorSvgPaths.SCALE.dimensions,
    );
    const cursorString = addSvgContainer(rotatedPathsGroup, CursorSvgPaths.SCALE.dimensions);
    return svgStringToCursorUrl(cursorString, CursorSvgPaths.SCALE.dimensions);
}

export function generateRotateCursor(overlay) {
    const rotationRadians = overlay.overlay_parameter.rotation_angle;
    const degreesToRotate = degreesToRotateCursor('rotate', null, toDegrees(rotationRadians));
    const rotatedPathsGroup = groupAndRotateSvgPaths(
        CursorSvgPaths.ROTATE.paths,
        degreesToRotate,
        CursorSvgPaths.ROTATE.dimensions,
    );
    const cursorString = addSvgContainer(rotatedPathsGroup, CursorSvgPaths.ROTATE.dimensions);
    return svgStringToCursorUrl(cursorString, CursorSvgPaths.ROTATE.dimensions);
}
