/* global angular:true, navigator:true */

import * as analytics from 'helioscope/app/utilities/analytics';

const mod = angular.module('helioscope.users.services', ['helioscope.directives']);


mod.factory('SignupDlg', ['$modal', function SignupDialog($modal) {
    const opts = {
        templateUrl: require('helioscope/app/users/partials/signup-modal.html'),
        controller: 'SignupModalCtrl',
        backdropFade: true,
        dialogFade: true,
        windowClass: 'login-modal',
    };

    return () => $modal.open(opts);
}]);

mod.controller('SignupModalCtrl',
    // eslint-disable-next-line prefer-arrow-callback
    function SignupModalCtrl($scope, Messager, User, $state, $log, $modalInstance, Authenticator) {
        $scope.newUser = new User(User.computeLocalizationProps());

        $scope.checkPasswords = () => $scope.newUser.password === $scope.newUser.confirm_password;
        $scope.close = () => {
            $modalInstance.dismiss();
            Authenticator.promptLogin();
        };

        $scope.createUser = () => {
            delete $scope.errors;
            $scope.denied = false;
            $scope.isLoading = true;

            const notification = Messager.load('Creating new account');

            $scope.newUser.$save((user) => {
                Authenticator.loginUser(user);
                notification.success('Successfully created your account');
                analytics.trackConversion(user, analytics.conversionLabels.accoutCreated);

                $state.go('home.notActivated', { email: $scope.newUser.email });

                $scope.isLoading = false;
            }, (response) => {
                $log.warn('Could not create new user');
                $log.warn(response);
                $scope.errors = response.data;
                $scope.denied = true;
                $scope.isLoading = false;
                notification.error('Could not create user');
            });
        };
    });

// eslint-disable-next-line prefer-arrow-callback
mod.factory('ResetPasswordDlg', function resetPasswordDlg($modal) {
    return (user) => {
        const opts = {
            templateUrl: require('helioscope/app/users/partials/reset-password.html'),
            controller: 'ResetPasswordCtrl',
            backdropFade: true,
            dialogFade: true,
            resolve: { user() { return user; } },
        };

        return $modal.open(opts);
    };
});

// eslint-disable-next-line prefer-arrow-callback
mod.controller('ResetPasswordCtrl', function ResetPasswordCtrl($scope, $modalInstance, Messager, User, user) {
    $modalInstance.opened.then(() => { $scope.isOpen = true; });

    if (user) {
        $scope.user = user;
    } else {
        $scope.user = {};
    }

    $scope.resetPassword = () => {
        $scope.isLoading = true;
        const notification = Messager.load('Resetting Password...');

        User.resetPassword({ email: $scope.user.email },
            () => {
                $scope.isLoading = false;
                notification.success(`We've sent instructions to recover this account to ${$scope.user.email}`);
                $modalInstance.close();
            }, () => {
                $scope.isLoading = false;
                notification.error('There was an error attempting to recover the account');
            });
    };

    $scope.cancel = () => {
        $modalInstance.close();
    };
});


mod.service('ClientBrowser', function ClientBrowser() {
    const browser = this;

    this.isIE = () => navigator.appVersion.indexOf('MSIE') !== -1;

    this.isOldIE = () => {
        if (browser.isIE()) {
            const start = navigator.appVersion.indexOf('MSIE');

            return (navigator.appVersion.substring(start + 5, start + 8) < 10);
        }

        return false;
    };
});
