import _ from 'lodash';
import Logger from 'js-logger';

import { RelationalBase, relationship } from 'helioscope/app/relational';
import { $q } from 'helioscope/app/utilities/ng';

import { Wire, PowerDevice } from 'helioscope/app/libraries';

import { ComponentConfigurationBuilder } from './ComponentConfigurationBuilder';

import {
    WireComponentParser,
    summarizePower,
    DEFAULT_AC_CONFIG,
    flattenComponentTree,
} from '../components';

import * as stringing from '../stringing';

const logger = Logger.get('wiring_zone');

export class WiringZone extends RelationalBase {
    static relationName = 'WiringZone';

    constructor(data) {
        super(data);

        // TODO: correctly implement Dual MPPT Inverters
        if (!this.string_allocation) {
            this.string_allocation = 1;
        }

        this._wiringSummary = [];
        this._components = [];
    }

    power() {
        return _.sumBy(this.field_segments, 'data.power') || 0;
    }

    wiredDcPowerUsage() {
        return this.field_segments.reduce((totalPower, fs) =>
            totalPower +
                this.getWiredModulesPerFieldSegment(fs.field_segment_id).length * (fs.data.power / fs.data.modules), 0);
    }

    /**
     * if the user has defined an inverter count, use that, otherwise try to target a specific
     * inverter load ratio based on the total module nameplate power and a target ILR
     */
    targetInverterCount(dcAcRatio = this.max_dc_ac_ratio || 1.25) {
        if (this.inverter_count) {
            return this.inverter_count;
        } else if (this.inverter) {
            return Math.ceil(this.power() / this.inverter.max_power / dcAcRatio);
        }

        return 1;
    }

    inverterPower() {
        // this should be adjusted for the number of inverters a wiringZone actually has
        // (e.g. in case of partial strings)

        const inv = this.inverter;
        if (inv && inv.microinverter) {
            return this.getFieldInvertersCount() * this.inverter.max_power;
        }

        return this.targetInverterCount() * (this.inverter && this.inverter.max_power);
    }

    /**
     * inverter string bounds, will eventually include ashrae sometimes
     */
    stringBounds(inputConfiguration = this.inputConfiguration()) {
        return stringing.determineBounds(this, inputConfiguration);
    }

    moduleCount() {
        // TODO: data.modules (and data.frames and data.power) can be null
        // for empty design (/projects/201326/designs/435409), which is a separate
        // bug that should be fixed (they should be 0). This causes _.sumBy()
        // to return null, so convert to 0 in that case as a work-around
        const cnt = _.sumBy(this.field_segments, 'data.modules');
        if (!cnt && cnt !== 0) {
            logger.warn(`Module count is ${cnt} for this.field_segments=${this.field_segments}`);
        }
        return cnt || 0;
    }

    inputConfiguration(ignoreUser = false) {
        return new stringing.InputConfiguration(this, ignoreUser);
    }

    loadFieldComponents(rawComponents) {
        const componentParser = new WireComponentParser(this, rawComponents);
        const { parsedComponents, unmatchedModules } = componentParser;

        logger.log('Matched raw components,', unmatchedModules.length, 'unmatched modules');
        this.setComponents(parsedComponents);

        return { components: parsedComponents, unmatchedModules };
    }

    setComponents(components) {
        this._components = components;
        this.generateWiringSummary();
    }

    generateWiringSummary() {
        this._wiringSummary = summarizePower(this._components);
        return this._wiringSummary;
    }

    getFieldInvertersCount() {
        return flattenComponentTree(this._components, {inverter: true}).length
    }

    wiringSummary() {
        return this._wiringSummary;
    }

    components() {
        return this._components;
    }

    getWiredModules() {
        return flattenComponentTree(this.components(), { module: true });
    }

    getWiredModulesPerFieldSegment(fieldSegmentId) {
        return flattenComponentTree(this.components(), { module: true }).filter(
            module => module.fieldSegment.field_segment_id.toString() === fieldSegmentId.toString(),
        );
    }

    getUnwiredModules() {
        const wiredModules = this.getWiredModules();
        // Using frame_index and topLeft properties on module object to identify unique modules
        // `field_component_id` does exist on modules which is unique but isn't present
        // for new modules created on client or after a design update.
        // TODO: Generate `field_component_id` for modules on `resourceUpdate` event on client side and use it here.
        const wiredModuleIndexes = new Set(wiredModules.map(module => this.uniqueKeyForModule(module)));

        const unwiredModulesPerFieldSegment = {};

        this.field_segments.forEach(fs => {
            unwiredModulesPerFieldSegment[fs.field_segment_id] = fs.modules().filter(module =>
                !wiredModuleIndexes.has(this.uniqueKeyForModule(module)));
        });

        return unwiredModulesPerFieldSegment;
    }

    uniqueKeyForModule(module) {
        return JSON.stringify([module.frame_index, module.topLeft]);
    }

    getUnwiredModuleCount() {
        let numberOfUnwiredModulesInWiringZone = 0;
        const unwiredModules = this.getUnwiredModules();

        if (this.field_segments && unwiredModules) {
            for (const fs of this.field_segments) {
                numberOfUnwiredModulesInWiringZone += unwiredModules[fs.field_segment_id].length;
            }
        }

        return numberOfUnwiredModulesInWiringZone;
    }

    loadDependencies() {
        const loadWire = (propName, idName = `${propName}_id`) => (
            this[idName] && !this[propName] && Wire.get({ wire_gauge_id: this[idName] }).$promise
        );

        const loadDevice = (propName, idName = `${propName}_id`) => (
            this[idName] && !this[propName] && PowerDevice.get({ power_device_id: this[idName] }).$promise
        );

        return $q.all([
            loadWire('string'),
            loadWire('bus'),
            loadWire('trunk'),
            loadWire('ac_branch'),
            loadWire('ac_run'),

            loadDevice('inverter'),
            loadDevice('power_optimizer'),
        ]).then(() => this);
    }


    inverterAcConfig() {
        if (this.inverter_ac_config_id) {
            return this.inverter_ac_config;
        } else if (this.inverter_id && this.inverter.ac_config_id) {
            return this.inverter.ac_config;
        }

        return DEFAULT_AC_CONFIG;
    }


    /**
     * contribution to circuit current from an individual microinveter
     */
    _microinverterBranchCurrent() {
        const inverter = this.inverter;
        const acConfig = this.inverterAcConfig();

        return inverter.max_power / (acConfig.voltage * Math.sqrt(acConfig.phase));
    }

    codeMaxAcBranchLength(codeBufferRequirement = 1.25) {
        const codeMaxCurrent = this.inverter.defaultBreakerSize() / codeBufferRequirement;

        return Math.floor(codeMaxCurrent / this._microinverterBranchCurrent());
    }

    maxAcBranchLength() {
        if (this.ac_branch_length) {
            return this.ac_branch_length;
        }

        return this.codeMaxAcBranchLength();
    }

    acBranchCurrent() {
        return this._microinverterBranchCurrent() * this.maxAcBranchLength();
    }

    componentConfiguration(subsystem = 'dc') {
        const config = (new ComponentConfigurationBuilder(this)).buildConfig();

        if (subsystem) {
            return config[subsystem];
        }

        return config;
    }

    toString() {
        return this.description;
    }
}

WiringZone.configureRelationships({
    design: relationship('Design', { backref: 'wiring_zones' }),
    inverter: relationship('PowerDevice'),
    power_optimizer: relationship('PowerDevice'),
    panel_transformer_config: relationship('AcConfig'),
    inverter_ac_config: relationship('AcConfig'),
    string: relationship('Wire'),
    bus: relationship('Wire'),
    trunk: relationship('Wire'),
    ac_branch: relationship('Wire'),
    ac_run: relationship('Wire'),
});

WiringZone.createEndpoint(
    '/api/wiring_zones/:wiring_zone_id',
    { wiring_zone_id: '@wiring_zone_id' },
    {
        update: { method: 'PUT', isArray: false },
        updateFieldSegmentPriority: {
            method: 'POST',
            isArray: false,
            url: '/api/wiring_zones/:wiring_zone_id/update_field_segment_priority',
        },
    },
);
