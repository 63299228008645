import Logger from 'js-logger';

import * as zendeskChat from './zendesk-chat';
import * as noopChat from './noop-chat';

const logger = Logger.get('chat');

export function getChat() {
    if (window.zE) {
        return zendeskChat;
    } else {
        logger.info('No chatbox configured on window.');
        return noopChat;
    }
}
