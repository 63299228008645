import * as THREE from 'three';
import { RendererOptions } from '../RendererOptions';
import {
    drawHandleCircleFill,
    drawHandleCircleStroke,
    drawHoverZoneSquareFill,
} from '../InteractHelpers';
import {
    DragActionImageOverlayMove,
    DragActionImageOverlayEdgeEdit,
    DragActionImageOverlayVertexEdit,
    DragActionImageOverlayRotationEdit,
} from './InteractOverlay';
import { getFourPoints, getRotationHandlePoint } from './OverlayHelpers';

export class WidgetImageOverlayCollection {
    constructor(renderer, overlay) {
        this.renderer = renderer;
        this.overlay = overlay;
    }

    createWidget(options) {
        this.renderableWidgets = [];
        if (options.dragHandles) {
            const widget = new WidgetImageOverlayDragHandle(this.renderer, this.overlay);
            widget.createWidget();
            this.renderableWidgets.push(widget);
        }

        if (options.edgeHandles) {
            for (let i = 0; i < 4; i++) {
                const widget = new WidgetImageOverlayEdgeHandle(this.renderer, this.overlay, i);
                widget.createWidget();
                this.renderableWidgets.push(widget);
            }
        }

        if (options.vertexHandles) {
            for (let i = 0; i < 4; i++) {
                const widget = new WidgetImageOverlayVertexHandle(this.renderer, this.overlay, i);
                widget.createWidget();
                this.renderableWidgets.push(widget);
            }
        }

        if (options.rotationHandle) {
            const widget = new WidgetImageOverlayRotationHandle(this.renderer, this.overlay);
            widget.createWidget();
            this.renderableWidgets.push(widget);
        }
        const preframeFn = () => { this.updateWidget(); };
        this.removeUpdate = this.renderer.registerPreFrameCallback(preframeFn, true);
    }

    clearWidgets() {
        if (this.renderableWidgets) {
            for (const widget of this.renderableWidgets) {
                widget.clearWidget();
            }
            this.renderableWidgets = null;
        }

        if (this.removeUpdate) {
            this.removeUpdate();
            this.removeUpdate = null;
        }
    }

    updateWidget() {
        if (this.renderableWidgets) {
            for (const widget of this.renderableWidgets) {
                widget.updateWidget(this.renderer.cameraProjectionMatrix);
            }
        }
    }
}

export class WidgetImageOverlayDragHandle {
    constructor(renderer, overlay) {
        this.renderer = renderer;
        this.overlay = overlay;
    }

    createWidget() {
        const selectionData = {
            object: this,
            type: 'WidgetImageOverlayDragHandle',
        };
        const handleOptions = _.assign({}, RendererOptions.greenOutlineHandleOptions, { selectionData });
        const rotationRadians = this.overlay.overlay_parameter.rotation_angle;
        const hoverZoneOptions = _.assign({}, RendererOptions.hoverZoneOptions, { selectionData }, { rotationRadians });
        this.fillPrimitive = drawHandleCircleFill(this.renderer, handleOptions);
        this.strokePrimitive = drawHandleCircleStroke(this.renderer, handleOptions);
        this.hoverZonePrimitive = drawHoverZoneSquareFill(this.renderer, hoverZoneOptions);
    }

    updateWidget(camProjMtx) {
        const fourPoints = getFourPoints(this.overlay);
        this.midpoint = new THREE.Vector3((fourPoints[0].x + fourPoints[1].x + fourPoints[2].x + fourPoints[3].x) / 4.0,
            (fourPoints[0].y + fourPoints[1].y + fourPoints[2].y + fourPoints[3].y) / 4.0, 0);
        const mtx = new THREE.Matrix4();
        const clientPt = this.renderer.transformObjectMatrixToClient(mtx, camProjMtx, this.midpoint);
        this.fillPrimitive.setRenderPosition(clientPt);
        this.strokePrimitive.setRenderPosition(clientPt);
        this.hoverZonePrimitive.setRenderPosition(clientPt);
    }

    clearWidget() {
        this.fillPrimitive.clearInstances();
        this.strokePrimitive.clearInstances();
        this.hoverZonePrimitive.clearInstances();
    }

    widgetMouseDown(event) {
        this.renderer.activateDragAction(
            new DragActionImageOverlayMove(
                this.renderer, this.overlay, event));
        return true;
    }
}

export class WidgetImageOverlayEdgeHandle {
    constructor(renderer, overlay, index) {
        this.renderer = renderer;
        this.overlay = overlay;
        this.index = index;
    }

    createWidget() {
        const selectionData = {
            object: this,
            type: 'WidgetImageOverlayEdgeHandle',
        };
        const handleOptions = _.assign({}, RendererOptions.greenOutlineHandleOptions, { selectionData });
        const rotationRadians = this.overlay.overlay_parameter.rotation_angle;
        const hoverZoneOptions = _.assign({}, RendererOptions.hoverZoneOptions, { selectionData }, { rotationRadians });
        this.fillPrimitive = drawHandleCircleFill(this.renderer, handleOptions);
        this.strokePrimitive = drawHandleCircleStroke(this.renderer, handleOptions);
        this.hoverZonePrimitive = drawHoverZoneSquareFill(this.renderer, hoverZoneOptions);
    }

    updateWidget(camProjMtx) {
        const fourPoints = getFourPoints(this.overlay);
        const corner = (new THREE.Vector3()).addVectors(fourPoints[this.index], fourPoints[(this.index + 1) % 4])
            .multiplyScalar(0.5);

        const mtx = new THREE.Matrix4();

        const clientPt = this.renderer.transformObjectMatrixToClient(mtx, camProjMtx, corner);
        this.fillPrimitive.setRenderPosition(clientPt);
        this.strokePrimitive.setRenderPosition(clientPt);
        this.hoverZonePrimitive.setRenderPosition(clientPt);
    }


    clearWidget() {
        this.fillPrimitive.clearInstances();
        this.strokePrimitive.clearInstances();
        this.hoverZonePrimitive.clearInstances();
    }

    widgetMouseDown(event) {
        this.renderer.activateDragAction(
            new DragActionImageOverlayEdgeEdit(
                this.renderer, this.overlay, this.index, event));
        return true;
    }
}

export class WidgetImageOverlayVertexHandle {
    constructor(renderer, overlay, index) {
        this.renderer = renderer;
        this.overlay = overlay;
        this.index = index;
    }

    createWidget() {
        const selectionData = {
            object: this,
            type: 'WidgetImageOverlayVertexHandle',
        };
        const handleOptions = _.assign({}, RendererOptions.greenOutlineHandleOptions, { selectionData });
        const rotationRadians = this.overlay.overlay_parameter.rotation_angle;
        const hoverZoneOptions = _.assign({}, RendererOptions.hoverZoneOptions, { selectionData }, { rotationRadians });
        this.fillPrimitive = drawHandleCircleFill(this.renderer, handleOptions);
        this.strokePrimitive = drawHandleCircleStroke(this.renderer, handleOptions);
        this.hoverZonePrimitive = drawHoverZoneSquareFill(this.renderer, hoverZoneOptions);
    }

    updateWidget(camProjMtx) {
        const fourPoints = getFourPoints(this.overlay);
        const mtx = new THREE.Matrix4();
        const clientPt = this.renderer.transformObjectMatrixToClient(mtx, camProjMtx, fourPoints[this.index]);
        this.fillPrimitive.setRenderPosition(clientPt);
        this.strokePrimitive.setRenderPosition(clientPt);
        this.hoverZonePrimitive.setRenderPosition(clientPt);
    }

    clearWidget() {
        this.fillPrimitive.clearInstances();
        this.strokePrimitive.clearInstances();
        this.hoverZonePrimitive.clearInstances();
    }

    widgetMouseDown(event) {
        this.renderer.activateDragAction(
            new DragActionImageOverlayVertexEdit(
                this.renderer, this.overlay, this.index, event));
        return true;
    }
}

export class WidgetImageOverlayRotationHandle {
    constructor(renderer, overlay) {
        this.renderer = renderer;
        this.overlay = overlay;
    }

    createWidget() {
        const selectionData = {
            object: this,
            type: 'WidgetImageOverlayRotationHandle',
        };
        const handleOptions = _.assign({}, RendererOptions.greenOutlineHandleOptions, { selectionData });
        const rotationRadians = this.overlay.overlay_parameter.rotation_angle;
        const hoverZoneOptions = _.assign({}, RendererOptions.hoverZoneOptions, { selectionData }, { rotationRadians });

        this.fillPrimitive = drawHandleCircleFill(this.renderer, handleOptions, rotationRadians);
        this.strokePrimitive = drawHandleCircleStroke(this.renderer, handleOptions, rotationRadians);
        this.hoverZonePrimitive = drawHoverZoneSquareFill(this.renderer, hoverZoneOptions);
    }

    updateWidget(camProjMtx) {
        const fourPoints = getFourPoints(this.overlay);
        const mtx = new THREE.Matrix4();
        const handlePoint = getRotationHandlePoint(fourPoints);

        const clientPt = this.renderer.transformObjectMatrixToClient(mtx, camProjMtx, handlePoint);

        this.fillPrimitive.setRenderPosition(clientPt);
        this.strokePrimitive.setRenderPosition(clientPt);
        this.hoverZonePrimitive.setRenderPosition(clientPt);
    }

    clearWidget() {
        this.fillPrimitive.clearInstances();
        this.strokePrimitive.clearInstances();
        this.hoverZonePrimitive.clearInstances();
    }

    widgetMouseDown(event) {
        this.renderer.activateDragAction(
            new DragActionImageOverlayRotationEdit(
                this.renderer, this.overlay, event));
        return true;
    }
}
