/* eslint camelcase: 0 */

import Logger from 'js-logger';
import { chain } from 'lodash';

import { sortModuleBatch } from './wire_scheduling';

import { ComponentLocationCache, groupArray, batchArray } from './helpers';

import {
    FieldString,
    FieldOptimizer,
    FieldCombiner,
    FieldBus,
    FieldInverter,
    AcBranch,
    AcRun,
    AcPanel,
    Interconnect,
    ParallelConnection,
    SeriesConnection,
} from './field_components';

const logger = Logger.get('component-tree');

const CONNECTIONS = {
    ParallelConnection,
    SeriesConnection,
};

/**
 * create a function that turns groups of modules into an appropriate string
 * for this wiring zone

 * Note: in reality, with multiple modules per optimizer, if is unlikely that
 * users would want to group all modules into one optimizer arbitrarily. If
 * two modules are particularly far apart or spanning field segments, it is
 * likely they they would each be assigned their own optimizer.  In practice
 * this level of detail is probably unnecessary at this point *
 *
 * @param  {WiringZone} wiringZone
 * @param  {Number} optMinStringLength the minimum number of modules to target per string only when
 *                                     using multiple optimizers, e.g. if there are 10 modules, two
 *                                     modules/optimizer and a minimum string length of 8, this would
 *                                     group the modules in groups of 2, 2, 1, 1, 1, 1, 1, instead of
 *                                     blindly using the maximum
 * @return {function}                  a function that turns an array of modules into a FieldString
 */
function getStringFactory(wiringZone, optMinStringLength = 0) {
    let optimizerFilter;

    if (wiringZone.power_optimizer_id) {
        const optimizer = wiringZone.power_optimizer;
        const optimizerId = optimizer.power_device_id;
        const inputConfiguration = wiringZone.inputConfiguration();

        if (inputConfiguration.maxModules === 1) {
            // map one to one to optimizers
            optimizerFilter = (mods) => mods.map((x) => FieldOptimizer.create(wiringZone, optimizerId, [x]));
        } else {
            optimizerFilter = (modules) =>
                inputConfiguration
                    .createInputCircuits(modules, optMinStringLength, CONNECTIONS)
                    .map((connection) => FieldOptimizer.create(wiringZone, optimizerId, [connection]));
        }
    } else {
        optimizerFilter = (modules) => modules;
    }

    return (modules) => FieldString.create(wiringZone, wiringZone.string_id, optimizerFilter(modules));
}

function createCombinerConnection(wiringZone, combinerComponents, outputWireId, tier) {
    const combiner = FieldCombiner.create(wiringZone, combinerComponents, `${tier}_combiner`);
    return FieldBus.create(wiringZone, outputWireId, combiner, tier);
}

/**
 * generated ordered groups of modules for each inverter, by sorting by rack first and then
 * generating strings (necessary for Dual MPPT to be allocated properly)
 *
 * currently relies on the assumption that frames are already logically sorted,
 * in reality should at least sort the frames, but ideally cluster them properly.
 *
 * Batching is performed solely based on inverter-module size. There is no distinction
 * between string lengths and string counts.
 */
export function createOrderedModuleBatches(wiringZone, inverterModuleCounts) {
    const fieldSegments = _.sortBy(wiringZone.field_segments, (fs) => fs.wiring_priority);

    // create a list of < rack, module > tuples for all the modules connected to this wiring zone
    const moduleTuples = [];
    for (const fieldSegment of fieldSegments) {
        const racking = fieldSegment.getRacks();
        if (!racking) {
            continue;
        }
        const { transformMatrix } = fieldSegment.layoutEngine().rackingSpaceTransforms();

        for (const rack of fieldSegment.getRacks()) {
            // transform into racking space
            rack.transform(transformMatrix);

            const rackModuleTuples = rack.getModules().map((module) => ({ rack, module }));

            moduleTuples.push(...rackModuleTuples);
        }
        // at this point modules within the rack are sorted by creation order
        // sort first by module rotation with east being first (0 rotation),
        // followed by west (180 rotation) to avoid inverters getting a mixture
        // of east and west modules when wiring east/west racking
        // for better up/down racking, we want to group them by x
        moduleTuples.sort((el1, el2) => {
            const rotationDiff = el1.module.rotation - el2.module.rotation;
            if (rotationDiff === 0) {
                const rackDiff = el1.module.racking_structure_id - el2.module.racking_structure_id;
                if (rackDiff === 0) {
                    // module is coordinates are in racking space due to earlier transform
                    return el1.module.topLeft.x - el2.module.topLeft.x;
                }
                return rackDiff;
            }
            return rotationDiff;
        });
    }

    // this works only because we are assuming the modules are in order of their frames
    const rawBatches = batchArray(moduleTuples, inverterModuleCounts);
    const batches = rawBatches.map((batch) => sortModuleBatch(wiringZone, batch));

    for (const fieldSegment of fieldSegments) {
        const racking = fieldSegment.getRacks();
        if (racking) {
            const { returnMatrix } = fieldSegment.layoutEngine().rackingSpaceTransforms();
            for (const rack of fieldSegment.getRacks()) {
                // transform back into world space
                rack.transform(returnMatrix);
            }
        }
    }

    return batches;
}

function createStringsFromAssignments(inverterModuleAllocations, stringFactory) {
    const inverterStringAssignments = [];

    for (const { stringsByInput, modules } of inverterModuleAllocations) {
        const inputs = [];
        let remainingModules = modules;
        for (const stringSizes of stringsByInput) {
            const batchedModules = batchArray(remainingModules, stringSizes, true);
            remainingModules = batchedModules.pop();
            inputs.push(batchedModules.map(stringFactory));
        }

        if (remainingModules.length > 0) {
            logger.warn(`Inverter Assignment had unused modules ${remainingModules.length}`);
        }

        inverterStringAssignments.push(inputs);
    }

    return inverterStringAssignments;
}

/**
 * Generates a list of module strings
 */
function createInverterStrings(wiringZone, inverterSchedule, stringBounds) {
    const inverterModuleCounts = inverterSchedule.map((x) => x.moduleCount());
    const orderedModuleBatches = createOrderedModuleBatches(wiringZone, inverterModuleCounts);

    const inverterModuleAllocations = _.map(inverterSchedule, (inverterConfig, i) => ({
        stringsByInput: inverterConfig.stringsByInput(),
        modules: orderedModuleBatches[i],
    }));

    let minOptimizersPerString = 0;
    if (stringBounds.baseSource.source === 'solaredge') {
        // if using solaredge and the min optimizers per string, make sure to use the manufacturer
        // source, and not the user source
        minOptimizersPerString = stringBounds.baseSource.bounds.min;
    }

    return createStringsFromAssignments(
        inverterModuleAllocations,
        getStringFactory(wiringZone, minOptimizersPerString),
    );
}

function generateMicroinverterDc(wiringZone) {
    const inverterId = wiringZone.inverter_id;
    const inputConfiguration = wiringZone.inputConfiguration();

    // batching first ensures the branches are ordered in a way similar to how wiring would be done
    // in other topologies, for microinverters we simply order all modules in a single batch which
    // is then used to create inverter groupings later
    const orderedInverterModuleBatches = createOrderedModuleBatches(wiringZone, [wiringZone.moduleCount()]);
    return chain(orderedInverterModuleBatches)
        .map((branchModules) => inputConfiguration.createInputCircuits(branchModules, 0, CONNECTIONS))
        .flatten()
        .map((inverterCircuit) => {
            // the inverter children should be an array of elements that go on a MPPT input (parallel combiner)
            // so can unroll the array in the case the outer element is a parallel combiner
            if (inverterCircuit instanceof ParallelConnection) {
                return FieldInverter.create(wiringZone, inverterId, [inverterCircuit.children]);
            } else {
                return FieldInverter.create(wiringZone, inverterId, [[inverterCircuit]]);
            }
        })
        .value();
}

function generateDcComponentTree(wiringZone) {
    /**
     * TODO: refactor to only take a single wiringZone
     *
     * current behavior for generating strings:
     * 1) group modules into strings of equal length, discard remainder
     * 2) SDGE: use the user input string length as a max string length, and fill array
     *    (filtered on buck_boost_optimizer)
     */

    let dcComponentConfiguration;

    try {
        dcComponentConfiguration = wiringZone.componentConfiguration('dc');
    } catch (err) {
        logger.warn('Could not configure DC Component Tree', err);
        return [];
    }

    const { combinerTiers, inverterSchedule, stringBounds } = dcComponentConfiguration;

    const inverterInputStrings = createInverterStrings(wiringZone, inverterSchedule, stringBounds);

    const fieldInverters = [];

    for (const inverterInputs of inverterInputStrings) {
        const inverterChildren = [];

        for (const inverterStrings of inverterInputs) {
            let lastGroup = inverterStrings;
            for (const { outputWireId, inputCount, tier } of combinerTiers) {
                const groupSize = lastGroup.length;
                const combinerCount = Math.ceil(groupSize / inputCount);

                // currently just assign strings to combiners in order
                // could improve this by doing basic spatial clustering
                const combinerGroups = groupArray(lastGroup, combinerCount);

                lastGroup = [];
                for (const combinerGroup of combinerGroups) {
                    lastGroup.push(createCombinerConnection(wiringZone, combinerGroup, outputWireId, tier));
                }
            }

            inverterChildren.push(lastGroup);
        }

        fieldInverters.push(FieldInverter.create(wiringZone, wiringZone.inverter_id, inverterChildren));
    }

    return _.compact(fieldInverters);
}

function generateAcComponentTree(wiringZone, inverters) {
    const { ac_branch_id: acBranchId, ac_run_id: acRunId, ac_panel_size: acPanelSize } = wiringZone;

    const useMicros = wiringZone.inverter.microinverter === true;

    let acComponents;
    if (useMicros) {
        // these should be ordered correctly based on the DC component tree generation to be
        // consistent with this batching pattern, but it's a little ugly the way it's divided right
        // now because AC branches are effectively equivalent to strings
        const acBranchLength = wiringZone.maxAcBranchLength();
        const branchCount = Math.ceil(inverters.length / acBranchLength);
        const batchedInverters = groupArray(inverters, branchCount);

        acComponents = batchedInverters.map((batch) => AcBranch.create(wiringZone, acBranchId, batch, 'ac_branch'));
    } else {
        acComponents = inverters.map((inverter) => AcRun.create(wiringZone, acBranchId, inverter, 'ac_branch'));
    }

    if (acRunId && acPanelSize) {
        const acPanelOutputs = [];
        const panelCount = Math.ceil(acComponents.length / acPanelSize);

        for (const panelChildren of groupArray(acComponents, panelCount)) {
            // tier name same format as for DC side
            const acPanel = AcPanel.create(wiringZone, panelChildren, 'ac_run_combiner');
            acPanelOutputs.push(AcRun.create(wiringZone, acRunId, acPanel, 'ac_run'));
        }

        acComponents = acPanelOutputs;
    }

    return acComponents;
}

const microsComponentFilter = {
    ac_panel: true,
};

const allCombinersFilter = {
    ac_panel: true,
    combiner: true,
    inverter: true,
};

export function generateFullComponentTree(design) {
    const wiringZones = design.wiring_zones.filter((wz) => wz.inverter !== undefined);
    const hasInterconnect = design.hasInterconnect();
    const hasMicroinverters = _.some(wiringZones, 'inverter.microinverter');

    // force AC usage if there are any microinverters:
    // 1. for backwards compatibility
    // 2. because not having AC on microinverters will give them a huge
    //    advantage over DC systems
    const needsAc = hasInterconnect || hasMicroinverters;

    let allComponents = [];
    let interconnect;

    if (needsAc) {
        // initialize an interconnect now so it can be used for later
        // components as they are generated
        interconnect = Interconnect.create(design.defaultPccLocation(), []);
    }

    for (const wiringZone of wiringZones) {
        const useMicros = wiringZone.inverter.microinverter === true;

        const locationCache = new ComponentLocationCache(
            wiringZone.components() || [],
            useMicros ? microsComponentFilter : allCombinersFilter,
        );

        let components;

        if (useMicros) {
            components = generateMicroinverterDc(wiringZone);
        } else {
            components = generateDcComponentTree(wiringZone);
        }

        if (needsAc) {
            components = generateAcComponentTree(wiringZone, components);

            for (const component of components) {
                // ensures the interconnect is set as the parent on child components
                // for applying the location cache
                interconnect.addChild(component);
            }
        }

        locationCache.apply(components);
        wiringZone.setComponents(components);
        allComponents.push(...components);
    }

    if (needsAc) {
        allComponents = [interconnect];
    }

    return allComponents;
}
