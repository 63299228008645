/* eslint-disable */
/* global google:true, angular:true, _:true */
import { loadGoogleMaps } from 'helioscope/app/utilities/maps';
import { randomSolarLocation } from 'helioscope/app/services';
import * as analytics from 'helioscope/app/utilities/analytics';
import { getAddProjectModalURL } from 'helioscope/app/utilities/url';
import { Team } from 'helioscope/app/users/teams';
import { $window, $rootScope } from 'helioscope/app/utilities/ng';
import moment from 'moment';

(function (angular, _) {
    'use strict';

    var mod = angular.module('helioscope.projects.services',
                                ['helioscope.projects.services.scenarios',
                                 'helioscope.projects.services.designs',
                                 'helioscope.services',
                                'helioscope.users.teams',
                                 'ui.map']);

    mod.factory('newProjectDlg', function ($modal, $state) {

        var opts = {
            templateUrl: require('helioscope/app/projects/partials/projects.new.html'),
            controller: 'NewProjectCtrl',
            windowClass: 'project-modal',
            resolve: {
                googleMaps: () => loadGoogleMaps(),
                user: ['Authenticator', function (Authenticator) {
                    return Authenticator.user();
                }],
                profiles: ['Profile', 'Authenticator', function (Profile, Authenticator) {
                    var user = Authenticator.user();
                    return Profile.query({type: 'project', email: user.email}).$promise;
                }]
            }
        };

        const expiredModalOptions = {
            templateUrl: require('helioscope/app/users/partials/expired-modal.html'),
            controller: 'ExpiredModalCtrl',
            windowClass: 'expired-modal',
            size: 'lg',
            resolve: {
                showProjectLimitHeader: () => true, // Added this to indicate out which route the expired modal was triggered from
                trialExtensionCount: ['Authenticator', (Authenticator) => Authenticator.user().$trialExtensionCount()],
            },
        };

        const billingRedirectModalOptions = {
            templateUrl: require('helioscope/app/users/partials/billing-redirect-modal.html'),
            controller: 'BillingRedirectModalCtrl',
            windowClass: 'billing-redirect-modal',
            resolve: {
                user: ['Authenticator', (Authenticator) => Authenticator.user()],
            },
        };

        return {
            openDialog: async function(scope) {
                const projectLimitReached = await scope.user().hasUsersProjectLimitReached();

                if (projectLimitReached && scope.user().activeSubscription() && scope.user().team.should_enforce_consumption) {
                    // eslint-disable-next-line no-nested-ternary
                    const subscriptionType = $rootScope.user().subscription
                        ? $rootScope.user().subscription.plan_type === 'year'
                            ? 'annual'
                            : 'monthly'
                        : null;
                    analytics.track('paywall.consumption_enforcement.open', {
                        referrer: 'create project',
                        model_type: {
                            user_type: $rootScope.user().team_admin ? 'admin' : 'non-admin',
                            subscription_type: subscriptionType,
                        },
                    });
                    $modal.open(billingRedirectModalOptions);
                } else if (projectLimitReached && scope.user().isOnTrial()) {
                    $modal.open(expiredModalOptions);
                } else {
                    const modalInstance = $modal.open(opts);

                    modalInstance.result.then(function (project) {
                        if (project) {
                            $state.transitionTo('projects.detail.designs', { project_id: project.project_id });
                            analytics.track('projects.new', {
                                location: {
                                    latitude: project.location.latitude,
                                    longitude: project.location.longitude,
                                },
                                project_id: project.project_id,
                                team_id: $rootScope.user().team_id,
                            });

                            Team.limitsAndUsage({ team_id: scope.user().team_id }).$promise.then(updatedLimitsAndUsage => {
                                const {subscription_limits: subscriptionLimits} = updatedLimitsAndUsage;
                                const user = scope.user();

                                if ( user.current_period_end && subscriptionLimits && subscriptionLimits.project_count === subscriptionLimits.project_limit) {
                                    const now = moment();
                                    const projectLimitsReachedTrackingObject = {
                                        team_id: user.team_id,
                                        project_id: project.project_id,
                                        days_remaining_in_subscription: user.current_period_end.diff(now, 'days'),
                                    };
                                    analytics.track('project.consumption_limit_reached', projectLimitsReachedTrackingObject);
                                }
                            })
                        }
                    });
                }
            }
        }
    });

    mod.controller('NewProjectCtrl', function ($scope, Project, $modalInstance, Messager, $log, $timeout, profiles, user) {

        $modalInstance.opened.then(function () {
            $timeout(function () {
                $scope.isOpen = true;
            });
        });

        $scope.data = {};
        $scope.newProject = {
            profile_id: user.default_profile_id
        };
        $scope.profiles = profiles;

        $scope.$watch('data.myMap', function (data) {
            if (data) {
                var marker = new google.maps.Marker({
                    map: $scope.data.myMap,
                    icon: require('helioscope/images/cross-hairs.gif'),
                });

                marker.bindTo('position', $scope.data.myMap, 'center');

                $scope.geocoder = new google.maps.Geocoder();
            }
        });

        var location = randomSolarLocation();

        $scope.mapOptions = {
            center: new google.maps.LatLng(location.lat, location.lng),
            zoom: location.zoom,
            mapTypeId: google.maps.MapTypeId.HYBRID,
            disableDoubleClickZoom: true,
            draggableCursor: 'default',
            tilt: 0,
            controlSize: 28,
            streetViewControl: false,
            clickableIcons: false,
        };

        $scope.createProject = function (newProject) {
            const PROJECT_CREATE_FAILURE_STATUS = 'project_limit_reached';

            var notify = Messager.load("Creating new project"),
                project = new Project(newProject),
                center = $scope.data.myMap.getCenter();

            if (!$scope.marker) {
                notify.error('Project location not valid');
                return;
            }

            $scope.isLoading = true;
            delete $scope.errors;

            project.location = {
                latitude:  center.lat(),
                longitude: center.lng()
            };

            project.$save(function onSuccess(project) {
                notify.success("Project created successfully");
                $scope.isLoading = false;
                $modalInstance.close(project);
            }, function onError(resp) {
                $scope.isLoading = false;

                const isAtProjectLimit = resp.data.status === PROJECT_CREATE_FAILURE_STATUS;

                let errorString = "Project could not be created"

                if (isAtProjectLimit) {
                    errorString = "Error creating project: Project limit reached";
                }

                notify.error(errorString);
                $scope.errors = resp.data.errors;
                $log.warn(resp);
            });
        };

        $scope.close = function close() {
            $modalInstance.close();
        };

        $scope.geocode = function (address) {
            if (address === undefined || address.length === 0) {
                return;
            }

            $scope.geocoder.geocode({ 'address': address}, function (results, status) {
                if ($scope.marker) {
                    $scope.marker.setMap(null);
                }

                if (status === google.maps.GeocoderStatus.OK) {
                    $scope.data.myMap.panTo(results[0].geometry.location);
                    $scope.data.myMap.setZoom(18);

                    $scope.marker = new google.maps.Marker({
                        position: results[0].geometry.location,
                        map: $scope.data.myMap,
                        title: address,
                        clickable: false,
                    });

                } else {
                    $scope.marker = null;

                    Messager.error("Geocode was not successful because of: " + status);
                }
            });
        };

        $scope.throttledGeocoder = _.debounce(function () {
            $scope.geocode($scope.newProject.address);
        }, 1000);
    });


    mod.factory('EditProjectDlg', function ($modal) {

        return function (project) {
            var opts = {
                templateUrl: require('helioscope/app/projects/partials/project.edit.html'),
                controller: 'EditProjectCtrl',
                resolve: {
                    project: function () { return project; },
                    acConfigs: ['acConfigLibrary', function (ac) { return ac(); }],
                    profiles: ['Profile', 'Authenticator', function (Profile, Authenticator) {
                        var user = Authenticator.user();
                        return Profile.query({type: 'project', email: user.email}).$promise;
                    }]
                }
            };

            return function editProjectModal() {
                return $modal.open(opts);
            };

        };
    });

    mod.controller('EditProjectCtrl', function ($scope, $modalInstance, Messager, project, Authenticator, $log, acConfigs, profiles) {

        $scope.projectData = angular.copy(project);
        $scope.project = project;
        $scope.acConfigs = acConfigs;
        $scope.profiles = profiles;

        $modalInstance.opened.then(function () { $scope.isOpen = true; });
        $scope.isOwner = function () { return Authenticator.user().user_id === project.creator_id; };

        $scope.save = function () {
            var backup = angular.copy(project);
            $scope.isLoading = true;
            delete $scope.errors;
            angular.extend(project, $scope.projectData);

            if (backup.profile_id !== project.profile_id) {
                analytics.track(
                    'profile.project_profile_change',
                    {
                        project_id: project.project_id,
                        old_profile_id: backup.profile_id,
                        new_profile_id: project.profile_id,
                    },
                );
            }

            project.$update(function onSuccess() {
                Messager.success("Changes saved successfully.");
                $scope.isLoading = false;
                $modalInstance.close();
            }, function onError(response) {
                $log.warn(response);
                Messager.error("Changes could not be saved");
                $scope.isLoading = false;
                angular.extend(project, backup);
                $scope.errors = response.data.errors;
            });
        };

        $scope.cancel = function () {
            $modalInstance.close();
        };

    });

    mod.factory('ShareProjectDlg', function ($modal) {

        return function (project) {
            var opts = {
                templateUrl: require('helioscope/app/projects/partials/project.users.share.html'),
                controller: 'ShareProjectCtrl',
                windowClass: 'project-modal',
                resolve: {project: function () { return project; }},
            };

            return function shareProjectModal() {
                return $modal.open(opts);
            };

        };
    });

    mod.controller('ShareProjectCtrl', ['$scope', '$modalInstance', 'project', 'helioscopeConfig',
        function ($scope, $modalInstance, project, parameters) {
            $scope.url = parameters.url_root + "/projects/share/" + project.share_hash;
            $scope.cancel = function () {
                $modalInstance.close();
            };

        }]);

}(angular, _));
