/* global angular:true, jQuery:true */
import { Messager, $log, $compile } from 'helioscope/app/utilities/ng';
import { KEY } from 'helioscope/app/utilities/helpers';
import * as analytics from 'helioscope/app/utilities/analytics';

const mod = angular.module('helioscope.directives', []);

mod.directive('dontPropagate', () => ({
    link(scope, element) {
        $(element).click((event) => {
            event.preventDefault();
            event.stopPropagation();
        });
    },
}));

mod.directive('ngMakeFocus', ['$timeout', function ($timeout) {
    return {
        link: function (scope, element, attrs) {
            scope.$watch(attrs.ngMakeFocus, function (val) {
                if (angular.isDefined(val) && val) {
                    $timeout(function () {element[0].select(); });
                }
            }, true);

            element.bind('blur', function () {
                if (angular.isDefined(attrs.ngFocusLost)) {
                    scope.$apply(attrs.ngFocusLost);
                }
            });
        }
    };
}]);

mod.directive("widget", function () {
    return {
        restrict: 'E',
        transclude: true,
        controller: ['$element', function ($element) {
            var block = $element.find('span.widget-header-control');
            this.addElement = function (control) {
                block.append(control);
            };
        }],
        scope: {'icon': '@', 'title': '@', 'classes': '@', 'id': '@'},
        template: '<div class="widget stacked {{classes}}" id="{{id}}">' +
                    '  <div class="widget-header">' +
                        '<i class="fa {{icon}}"></i>' +
                        '<h3>{{title}}</h3>' +
                        '<span class="widget-header-control pull-right">' +
                        '</span>' +
                    '</div>' +
                    '<div class="widget-content" ng-transclude></div></div>',
        link: function(scope, element, attrs) {
            $(element).attr('id', '');
        }
    };
}).directive('widgetElement', function () {

    return {
        require: '^widget',
        restrict: 'C',
        // scope: { icon:'@', href:'@', 'classes':'@'},
        link: function (scope, element, attrs, widgetCtrl) {
            widgetCtrl.addElement(element);
        },
        replace: true
    };
});



mod.directive('responsiveToggle', ['$location', function ($location) {
    var openElement = null,
        closeMenu   = angular.noop;
    return {
        restrict: 'CA',
        link: function (scope, element, attrs) {
            var target = $(attrs.target);

            scope.$watch('$location.path', function () {closeMenu(); });

            element.bind('click', function (event) {
                event.preventDefault();
                event.stopPropagation();
                var elementWasOpen = (element === openElement);
                if (!!openElement) {
                    closeMenu();
                }
                if (!elementWasOpen) {
                    target.addClass('in').css({'height': 'auto'});
                    element.removeClass('collapsed');
                    openElement = element;

                    closeMenu = function (event) {
                        if (event) {
                            event.preventDefault();
                            event.stopPropagation();
                            element.addClass('collapsed');
                        }
                        element.unbind('click', closeMenu);
                        target.removeClass('in').css({'height': '0'});
                        closeMenu   = angular.noop;
                        openElement = null;
                    };
                    element.bind('click', closeMenu);
                }
            });
        }
    };
}]);

mod.directive('staticMap', function () {
    return {restrict: 'EA',
        // scope: {'location':'@', 'resolution':'@'},
        template: "<img src='https://maps.google.com/maps/api/staticmap?" +
                    "center={{location}}&zoom=18&size={{resolution}}&maptype=hybrid&sensor=false'></img>",
        link: function (scope, element, attrs) {
            scope.location = attrs.location;
            scope.resolution = attrs.resolution || '400x400';
        },
        replace: true
        };
});

mod.directive('btnLoading', function () {
    const iconString = '<i class="fa fa-spinner fa-spin"> </i> ';
    return {
        link: function (scope, element, attrs) {
            const html = element.html();
            scope.$watch(
                function () {
                    return scope.$eval(attrs.btnLoading);
                },
                function (value) {
                    if (value) {
                        if (!attrs.hasOwnProperty('ngDisabled')) {
                            element.addClass('disabled').attr('disabled', 'disabled');
                        }

                        element.html(iconString + (element.data('loading-text') || 'Loading...'));
                    } else {
                        if (!attrs.hasOwnProperty('ngDisabled')) {
                            element.removeClass('disabled').removeAttr('disabled');
                        }

                        element.html(html);
                        $compile(element.contents())(scope);
                    }
                }
            );
        }
    };
});

mod.directive('asyncClick', function () {
    var iconString = '<i class="fa fa-spinner fa-spin"> </i> ';
    return {
        link: ($scope, element, attrs) => {

            element.on('click', async (e) =>{
                e.preventDefault();

                // keep width
                element.width(element.width());

                const prev = element.html();
                element.html(iconString);

                element.attr('disabled', true);
                try {
                    await $scope.$eval(attrs.asyncClick);
                } catch (err) {
                    throw err;
                } finally {
                    element.width('');
                    element.html(prev);
                    element.attr('disabled', false);
                }
            });
        }
    }
});

mod.directive('appendText', [function () {

    return {
        link: function (scope, element, attrs) {
            var appender = attrs.appendText,
                clearable = (attrs.appendTextClearable === 'true');

            element.on('keyup', function () {
                if (clearable && element[0].value.length <= 1) {
                    // use <= to 1 in case the user deleted by highlighting
                    // and then pressing a character
                    element.off('keyup');
                    return;
                } else if (element[0].value.indexOf(appender) === -1) {
                    scope.$apply(function () {
                        var length = element[0].value.length;
                        element[0].value += attrs.appendText;
                        element[0].setSelectionRange(length, length);
                    });
                }
            });
        }
    };

}]);

mod.directive('stopClick', function () {
    return {
        link: function (scope, element, attrs) {
            element.on('click', function (e) {
                e.stopPropagation();
            });
        }
    };
});

mod.directive('distance', ['Authenticator', function (Authenticator) {
    // Note: this is very similar to hsDistance in filters.js.
    const conversionMap = {
        ft: 3.28084,
        m: 1.0,
        mi: 0.00062137273,
        km: 0.001,
    };
    const unitMap = {
        ft: 'mi',
        m: 'km',
    };

    return {
        restrict: 'A', // only activate on element attribute
        require: '?ngModel', // get a hold of NgModelController
        link: function (scope, element, attrs, ngModel) {
            let unit = Authenticator.user().preferences.units.distance || 'm';
            if (attrs.distance === 'long') {
                unit = unitMap[unit];
            }
            const conversion = conversionMap[unit];

            ngModel.$parsers.push(function (val) {
                return val / conversion;
            });

            ngModel.$formatters.push(function (val) {
                return Math.round(val * conversion * 10000.) / 10000.;
            });
        }
    };
}]);

mod.directive('fileButton', ['$compile', function ($compile) {

    var SNAKE_CASE_REGEXP = /[A-Z]/g;
    function snake_case(name, separator) {
        separator = separator || '-';
        return name.replace(SNAKE_CASE_REGEXP, function (letter, pos) {
            return (pos ? separator : '') + letter.toLowerCase();
        });
    }

    return {
        restrict: 'A',
        link: function (scope, element, attrs) {

            var inputElement = angular.element('<input type="file" style="display:none;">');

            angular.forEach(attrs, function (attr, key) {
                if (key[0] !== '$' && key !== 'fileButton') {
                    inputElement.attr(snake_case(key), attr);
                }
            });

            element.parent().append(inputElement);
            $compile(inputElement)(scope);

            element.on('click', function () {
                inputElement.click();
            });
        }
    };
}]);

mod.directive("keyboardIncrement", function () {
    var defaults = {
        step: 1,
        shiftStep: 5,
        min: undefined,
        max: undefined,
        wrap: false,
    };

    function getPrecision(str) {
        var segs = str.split(".");
        return segs.length > 1 ? segs[1].length : 0;
    }

    return {
        restrict: 'A',
        require: '?ngModel', // get a hold of NgModelController
        link: function (scope, element, attrs, ngModel) {

            var config = angular.extend({}, defaults, scope.$eval(attrs.keyboardIncrement) || {}),
                max = config.max,
                min = config.min,
                wrap = (config.wrap === true),
                stepPrecision = getPrecision(config.step.toString());

            function handleKeypress(evt) {

                var code = evt.keyCode || evt.which,
                    step = evt.shiftKey ? config.shiftStep : config.step,
                    operator,
                    newVal,
                    precisionAdj,
                    currentVal = ngModel.$viewValue.toString();

                if (code === KEY.UP) {
                    operator = 1;
                } else if (code === KEY.DOWN) {
                    operator = -1;
                } else {
                    return;
                }

                if (ngModel.$viewValue === "" || ngModel.$viewValue === undefined) {
                    newVal = operator === 1 ? min : max;
                } else {

                    precisionAdj = Math.pow(10, Math.max(getPrecision(currentVal) + 1, stepPrecision));
                    newVal = Math.round(precisionAdj * (Number(currentVal) + operator * step)) / precisionAdj;
                }

                if (max !== undefined && newVal > max) {
                    if (wrap) {
                        newVal = min + (newVal - max);
                    } else {
                        newVal = max;
                    }
                } else if (min !== undefined && newVal < min) {
                    if (wrap) {
                        newVal = max - (min - newVal);
                    } else {
                        newVal = min;
                    }
                }

                ngModel.$setViewValue(newVal);
                ngModel.$validate();

                if (ngModel.$valid) {
                    ngModel.$render();
                } else {
                    ngModel.$setViewValue(currentVal);
                    if (evt.shiftKey) {
                        evt.shiftKey = false;
                        handleKeypress(evt);
                    }
                }

                evt.stopPropagation();
                return false;
            }

            angular.element(element).on('keydown', handleKeypress);

        }
    };
});

// Button for deleting generic resource entities
mod.directive('btnDeleteEntity', ['$log', 'Messager', function($log, messager) {
    return {
        restrict: 'E',
        scope: {'entity': '=', 'label': '='},
        template: '<a class="btn btn-danger" ng-click="delete(label, entity)" btn-loading="isLoading" data-loading-text=""><i class="fa fa-trash"></i></a>',
        controller: function ($scope) {
            $scope.delete = function(label, entity) {
                var notification = messager.load('Deleting entity ' + label + ' ' + entity.name);

                $scope.isLoading = true;

                entity.$delete(function () {
                    notification.success('Successfully deleted ' + label);
                    $scope.isLoading = false;
                }, function (response) {
                    notification.error('Could not delete ' + entity.name);
                    $log.warn(response);
                    $scope.isLoading = false;
                });
            }
        }
    }
}]);

/**
 * modify an input element to automatically round data for display when the form is not in use
 * also, let's you specificy a scalar (e.g. so users input a percentage in 100s, but it's stored
 * in it's raw format)
 */
mod.directive('roundedInput', function roundedInput() {
    return {
        terminal: true,
        restrict: 'A',
        require: '?ngModel',
        link: function (scope, element, attrs, ngModelCtrl) {
            const { precision = 0, scale = 1 } = scope.$eval(attrs.roundedInput);

            const format = (val) => angular.isNumber(val) ? parseFloat((val * scale).toFixed(precision)) : val;

            // remove precision on blur
            element.bind('blur', () => ngModelCtrl.$valid && element.val(format(ngModelCtrl.$modelValue)));

            // add precision on focus
            element.bind('focus', () => angular.isNumber(ngModelCtrl.$modelValue) && element.val(ngModelCtrl.$modelValue * scale));

            // push a formatter so the model knows how to render
            ngModelCtrl.$formatters.push(format);

            // remove any applied scale-up, needs to start validation chain to interact cleanly
            // with the number ranges
            ngModelCtrl.$parsers = [val => parseFloat(val) / scale].concat(ngModelCtrl.$parsers);
        },
    };
});

mod.directive('clickTrack', () => ({
    restrict: 'A',
    link: ($scope, element, attrs) => {
        element.click(() => {
            analytics.track(attrs.clickTrack, attrs.trackData ? $scope.$eval(attrs.trackData) : {});
        });
    },
}));
