import Logger from 'js-logger';

import { $q, $http, Messager, $rootScope } from 'helioscope/app/utilities/ng';
import * as io from 'helioscope/app/utilities/io';
import * as analytics from 'helioscope/app/utilities/analytics';
import { loadClipper } from 'helioscope/app/utilities/geometry/clipper';

import { Design } from 'helioscope/app/designer/Design';

import { loadSingleLineDiagram } from './loaders';

const logger = Logger.get('singleline/async/download_sld');

export async function downloadSLD(designId) {
    const SingleLineDiagram = await loadSingleLineDiagram();

    const notification = Messager.load('Downloading SLD');

    try {
        const [design, fieldComponentsResponse] = await $q.all([
            Design.get({ design_id: designId }).$promise.then(des => des.loadDependencies()),
            $http.get(`/api/field_components/${designId}`),
            loadClipper(),
        ]);

        design.initializeDesignScene();
        const { components } = design.loadFieldComponents(fieldComponentsResponse.data);
        const interconnect = Design.ensureInterconnect(components);
        const summary = design.wiring_zones.map(wz => wz.wiringSummary());
        const diagram = new SingleLineDiagram(interconnect, summary);
        const dxf = diagram.exportDXF();

        io.downloadBlob(dxf, `helioscope_${designId}_sld.dxf`);

        analytics.track('sld.export', {
            design_id: design.design_id,
            project_id: design.project.project_id,
            team_id: $rootScope.user().team_id,
        });
    } catch (err) {
        notification.error('Error downloading SLD');
        logger.error(`Error downloading SLD: ${err}`);
    } finally {
        notification.close();
    }
}
