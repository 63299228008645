import _ from 'lodash';
import * as THREE from 'three';

import { arrayItemWrap } from 'helioscope/app/utilities/helpers';


function iterateCirclePoints(phiSteps, radius, fn) {
    const phiDirs = _.range(phiSteps).map(i => {
        const rad = i * Math.PI * 2.0 / phiSteps;
        return new THREE.Vector3(Math.cos(rad), Math.sin(rad), 0);
    });

    for (let i = 0; i < phiSteps; ++i) {
        const pt = (new THREE.Vector3())
            .copy(phiDirs[i])
            .multiplyScalar(radius);

        fn(pt);
    }
}

function iterateCylinderQuads(phiSteps, radius, height, fn) {
    const phiDirs = _.range(phiSteps).map(i => {
        const rad = i * Math.PI * 2.0 / phiSteps;
        return new THREE.Vector3(Math.cos(rad), Math.sin(rad), 0);
    });

    const vech = new THREE.Vector3(0, 0, height);

    for (let i = 0; i < phiSteps; ++i) {
        const pt1 = (new THREE.Vector3())
            .copy(phiDirs[i])
            .multiplyScalar(radius)
            .add(vech);
        const pt2 = (new THREE.Vector3())
            .copy(arrayItemWrap(phiDirs, i + 1))
            .multiplyScalar(radius)
            .add(vech);
        const pt3 = (new THREE.Vector3())
            .copy(phiDirs[i])
            .multiplyScalar(radius);
        const pt4 = (new THREE.Vector3())
            .copy(arrayItemWrap(phiDirs, i + 1))
            .multiplyScalar(radius);

        fn(pt1, pt2, pt3, pt4);
    }
}

function iterateSphereQuads(thetaSteps, phiSteps, radius, fn) {
    const phiDirs = _.range(phiSteps).map(i => {
        const rad = i * Math.PI * 2.0 / phiSteps;
        return new THREE.Vector3(Math.cos(rad), Math.sin(rad), 0);
    });

    const thetaDirs = _.range(thetaSteps + 1).map(i => {
        const rad = (i * Math.PI / thetaSteps) - (Math.PI * 0.5);
        return new THREE.Vector3(Math.cos(rad), 0, Math.sin(rad));
    });

    for (let j = 0; j < thetaSteps; ++j) {
        const h0 = thetaDirs[j].z * radius;
        const r0 = thetaDirs[j].x * radius;
        const vech0 = new THREE.Vector3(0, 0, h0);
        const h1 = thetaDirs[j + 1].z * radius;
        const r1 = thetaDirs[j + 1].x * radius;
        const vech1 = new THREE.Vector3(0, 0, h1);
        for (let i = 0; i < phiSteps; ++i) {
            const pt1 = (new THREE.Vector3())
                .copy(phiDirs[i])
                .multiplyScalar(r1)
                .add(vech1);
            const pt2 = (new THREE.Vector3())
                .copy(arrayItemWrap(phiDirs, i + 1))
                .multiplyScalar(r1)
                .add(vech1);
            const pt3 = (new THREE.Vector3())
                .copy(phiDirs[i])
                .multiplyScalar(r0)
                .add(vech0);
            const pt4 = (new THREE.Vector3())
                .copy(arrayItemWrap(phiDirs, i + 1))
                .multiplyScalar(r0)
                .add(vech0);

            fn(pt1, pt2, pt3, pt4);
        }
    }
}

function iterateConeQuads(phiSteps, baseRadius, height, fn) {
    const phiDirs = _.range(phiSteps).map(i => {
        const rad = i * Math.PI * 2.0 / phiSteps;
        return new THREE.Vector3(Math.cos(rad), Math.sin(rad), 0);
    });

    const heightDirs = [height, 0.7 * height, 0.5 * height, 0.3 * height, 0];

    for (let j = 0; j < heightDirs.length; j++) {
        for (let i = 0; i < phiSteps; i++) {
            const h0 = new THREE.Vector3(0, 0, heightDirs[j]);
            const h1 = new THREE.Vector3(0, 0, heightDirs[j + 1]);
            const pt1 = (new THREE.Vector3())
                .copy(phiDirs[i])
                .multiplyScalar((height - heightDirs[j]) * baseRadius)
                .add(h0);

            const pt2 = (new THREE.Vector3())
                .copy(arrayItemWrap(phiDirs, i + 1))
                .multiplyScalar((height - heightDirs[j]) * baseRadius)
                .add(h0);

            const pt3 = (new THREE.Vector3())
                .copy(phiDirs[i])
                .multiplyScalar((height - arrayItemWrap(heightDirs, j + 1)) * baseRadius)
                .add(h1);

            const pt4 = (new THREE.Vector3())
                .copy(arrayItemWrap(phiDirs, i + 1))
                .multiplyScalar((height - arrayItemWrap(heightDirs, j + 1)) * baseRadius)
                .add(h1);

            fn(pt1, pt2, pt3, pt4);
        }
    }
}

function iterateConeTris(phiSteps, baseRadius, height, fn) {
    const phiDirs = _.range(phiSteps).map(i => {
        const rad = i * Math.PI * 2.0 / phiSteps;
        return new THREE.Vector3(Math.cos(rad), Math.sin(rad), 0);
    });

    for (let i = 0; i < phiSteps; i++) {
        const pt1 = (new THREE.Vector3())
            .copy(phiDirs[i])
            .multiplyScalar(baseRadius);

        const pt2 = (new THREE.Vector3())
            .copy(arrayItemWrap(phiDirs, i + 1))
            .multiplyScalar(baseRadius);

        const pt3 = new THREE.Vector3(0, 0, height);

        fn(pt1, pt2, pt3);
    }
}

const _shapeIterCache = {};

export function cachedIterateSphereQuads(thetaSteps, phiSteps, radius, fn) {
    const key = `SPHERE_${thetaSteps}_${phiSteps}_${radius}`;
    if (!_shapeIterCache[key]) {
        const arr = [];
        iterateSphereQuads(thetaSteps, phiSteps, radius,
            (p1, p2, p3, p4) => {
                arr.push([p1, p2, p3, p4]);
            });
        _shapeIterCache[key] = arr;
    }

    for (const i of _shapeIterCache[key]) {
        fn(i[0], i[1], i[2], i[3]);
    }
}

export function cachedIterateCylinderQuads(phiSteps, radius, height, fn) {
    const key = `CYLINDER_${phiSteps}_${radius}_${height}`;
    if (!_shapeIterCache[key]) {
        const arr = [];
        iterateCylinderQuads(phiSteps, radius, height,
            (p1, p2, p3, p4) => {
                arr.push([p1, p2, p3, p4]);
            });
        _shapeIterCache[key] = arr;
    }

    for (const i of _shapeIterCache[key]) {
        fn(i[0], i[1], i[2], i[3]);
    }
}

export function cachedIterateConeQuads(phiSteps, baseRadius, height, fn) {
    const key = `CONE_QUAD_${phiSteps}_${baseRadius}_${height}`;
    if (!_shapeIterCache[key]) {
        const arr = [];
        iterateConeQuads(phiSteps, baseRadius, height,
            (p1, p2, p3, p4) => {
                arr.push([p1, p2, p3, p4]);
            });
        _shapeIterCache[key] = arr;
    }

    for (const i of _shapeIterCache[key]) {
        fn(i[0], i[1], i[2], i[3]);
    }
}

export function cachedIterateConeTris(phiSteps, baseRadius, height, fn) {
    const key = `CONE_TRI_${phiSteps}_${baseRadius}_${height}`;
    if (!_shapeIterCache[key]) {
        const arr = [];
        iterateConeTris(phiSteps, baseRadius, height,
            (p1, p2, p3) => {
                arr.push([p1, p2, p3]);
            });
        _shapeIterCache[key] = arr;
    }

    for (const i of _shapeIterCache[key]) {
        fn(i[0], i[1], i[2]);
    }
}

export function cachedIterateCirclePoints(phiSteps, radius, fn) {
    const key = `CIRCLE_${phiSteps}_${radius}`;
    if (!_shapeIterCache[key]) {
        const arr = [];
        iterateCirclePoints(phiSteps, radius,
            (pt) => {
                arr.push(pt);
            });
        _shapeIterCache[key] = arr;
    }

    for (const i of _shapeIterCache[key]) {
        fn(i);
    }
}
