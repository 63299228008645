/* global angular:true */
import { loadGoogleMaps } from 'helioscope/app/utilities/maps';

import { WEATHER_CONSTS } from 'reports/models/weather_source.ts';

(function (angular) {
    'use strict';

    angular.module('helioscope.libraries', ['helioscope.libraries.config',
                                            'helioscope.libraries.directives',
                                            'helioscope.libraries.resources',
                                            'helioscope.libraries.controllers',
                                            'helioscope.libraries.controllers.wires',
                                            'helioscope.libraries.state_builder',
                                            'helioscope.libraries.services']);

    var mod = angular.module('helioscope.libraries.config', ['ui.router', 'helioscope.users.auth',
                                                             'helioscope.libraries.resources',
                                                             'helioscope.libraries.state_builder',
                                                             'ui.bootstrap.tooltip']);
    // libraryStateProvider
    mod.config(['$stateProvider', 'auth.accessLevels', 'libraryStateProvider',
                function ($stateProvider, accessLevels, libraryStateProvider) {

            libraryStateProvider.patchStateProvider($stateProvider);

            $stateProvider.
                state('library', {
                    url: '/library',
                    controller: 'ProjectCtrl',
                    template: '<div ui-view></div>',
                    'abstract': true,
                    resolve: {
                        user: ['Authenticator', function (a) {return a.authorize(accessLevels.user); }]
                    }
                }).
                libraryState({
                    name: 'modules',
                    baseName: 'library',
                    resource: 'Module',
                    id: 'module_id',
                    characterizationResource: 'ModuleCharacterization',
                    characterizationId: 'module_characterization_id',
                    list: {
                        templateUrl: require('helioscope/app/libraries/partials/modules/list.html'),
                        config: 'ModuleGridConfig',
                        data: {
                            helpOverlayUrl: 'helioscope/app/libraries/partials/modules/help.html'
                        }
                    },
                    characterizations: {
                        templateUrl: require('helioscope/app/libraries/partials/modules/characterization.modal.html')
                    },
                    characterizationDetail: {
                        templateUrl: require('helioscope/app/libraries/partials/modules/characterization.modal.detail.html')
                    }
                }).
                modalState('library.modules.upload', {
                    url: '/upload',
                    modalOptionsFactory: [function () {
                        return {
                            windowClass: 'full-screen-modal',
                            controller: 'ModuleUploadCtrl',
                            templateUrl: require('helioscope/app/libraries/partials/modules/upload.html'),
                        };
                    }]
                }).
                libraryState({
                    name: 'components',
                    baseName: 'library',
                    resource: 'PowerDevice',
                    id: 'power_device_id',
                    characterizationResource: 'PowerDeviceCharacterization',
                    characterizationId: 'power_device_characterization_id',
                    list: {
                        templateUrl: require('helioscope/app/libraries/partials/inverters/list.html'),
                        config: 'InverterGridConfig'
                    },
                    characterizations: {
                        templateUrl: require('helioscope/app/libraries/partials/inverters/characterization.modal.html')
                    },
                    characterizationDetail: {
                        templateUrl: require('helioscope/app/libraries/partials/inverters/characterization.modal.detail.html')
                    }
                }).
                state('library.wires', {
                    url: '/wires',
                    controller: 'WireListCtrl',
                    templateUrl: require('helioscope/app/libraries/partials/wires.list.html'),
                    resolve: {
                        'wires': ['WireLibrary', function (w) {return w(); }]
                    },
                    data: {
                        helpOverlayUrl: 'helioscope/app/libraries/partials/wires.help.html'
                    }
                }).
                state('library.meteo', {
                    url: '/meteo',
                    controller: 'MeteoCtrl',
                    templateUrl: require('helioscope/app/libraries/partials/meteo/list.html'),
                    resolve: {
                        google: () => loadGoogleMaps(),
                        MarkerClusterer: async () => {
                            await import('helioscope/libs/marker-clusterer/markerclusterer_compiled');
                            return window.MarkerClusterer;
                        },
                        'prospectorBounds': [function () {
                            return require('helioscope/app/libraries/static/prospector.json');
                         }],
                        'psmBounds': [function() {
                            return require('helioscope/app/libraries/static/psm.json');
                        }]
                    },
                }).
                modalState('library.meteo.upload', {
                    url: '/upload',
                    modalOptionsFactory: [function () {
                        return {
                            controller: 'MeteoUploadCtrl',
                            templateUrl: require('helioscope/app/libraries/partials/meteo/upload.html'),
                        };
                    }]
                }).
                state('library.meteo.detail', {
                    url: '/{weather_source_id}?lat&lng',
                    controller: 'MeteoDetailCtrl',
                    resolve: {
                        'weather_source': ['$q', 'WeatherSource', '$stateParams', function ($q, WeatherSource, $stateParams) {
                            var deferred = $q.defer();

                            function prospectorId(lat, lon) {
                                // return a unique id for a prospector file based on latitude and
                                //longitude

                                lon = String(parseInt(Math.round(-lon * 100), 10));
                                lon = (lon.length < 5 ? '0' : '') + lon;
                                lat = String(parseInt(Math.round(lat * 100), 10));

                                return lon + lat;
                            }


                            function fakeProspector(lat, lng) {
                                return new WeatherSource({
                                    name: "10KM Grid (" + lat + "," + lng + ")",
                                    external_id: prospectorId(lat, lng),
                                    source_type: 'prospector',
                                    source: 'NREL',
                                    url: 'http://maps.nrel.gov/prospector',
                                    location: {
                                        latitude: parseFloat(lat),
                                        longitude: parseFloat(lng),
                                    },
                                    weather_datasets: [
                                        { weather_dataset_id: -1, name: 'TMY' },
                                        { weather_dataset_id: -2, name: 'TGY' },
                                        { weather_dataset_id: -3, name: 'TDY' },
                                        { weather_dataset_id: -4, name: '2009' },
                                        { weather_dataset_id: -5, name: '2008' },
                                        { weather_dataset_id: -6, name: '2007' },
                                        { weather_dataset_id: -7, name: '2006' },
                                        { weather_dataset_id: -8, name: '2005' },
                                        { weather_dataset_id: -9, name: '2004' },
                                        { weather_dataset_id: -10, name: '2003' },
                                        { weather_dataset_id: -11, name: '2002' },
                                        { weather_dataset_id: -12, name: '2001' },
                                        { weather_dataset_id: -13, name: '2000' },
                                        { weather_dataset_id: -14, name: '1999' },
                                        { weather_dataset_id: -15, name: '1998' },
                                    ]
                                });
                            }

                            function fakePsm3(lat, lng) {
                                const gy = Math.round((parseFloat(lat) - WEATHER_CONSTS.PsmLatZero) / WEATHER_CONSTS.PsmRes);
                                const gx = Math.round((parseFloat(lng) - WEATHER_CONSTS.PsmLonZero) / WEATHER_CONSTS.PsmRes);

                                return new WeatherSource({
                                    name: "0.04\u00B0 Grid (" + parseFloat(lat).toFixed(2) + "," + parseFloat(lng).toFixed(2) + ")",
                                    external_id: `${gy}_${gx}`,
                                    source_type: 'psm3',
                                    source: 'NREL',
                                    url: 'https://nsrdb.nrel.gov/data-sets/us-data',
                                    location: {
                                        latitude: parseFloat(lat),
                                        longitude: parseFloat(lng),
                                    },
                                    weather_datasets: [
                                        { weather_dataset_id: -1, name: 'TMY' },
                                        { weather_dataset_id: -2, name: 'TGY' },
                                        { weather_dataset_id: -3, name: 'TDY' },
                                    ]
                                });
                            }

                            if ($stateParams.weather_source_id === 'prospector') {
                                deferred.resolve(fakeProspector($stateParams.lat, $stateParams.lng));
                            } else if ($stateParams.weather_source_id === 'psm3') {
                                deferred.resolve(fakePsm3($stateParams.lat, $stateParams.lng));
                            } else {
                                WeatherSource.get($stateParams,
                                    function onSuccess(ws) {
                                        deferred.resolve(ws);
                                    }, function onFail(resp) {
                                        deferred.reject({
                                            type: resp.status,
                                            message: "Could not load that weather file"
                                        });
                                    });
                            }

                            return deferred.promise;
                        }]
                    }
                });
        }]);


    mod.value('ModuleGridConfig', {
        sortInfo: {
            fields: ['manufacturer', 'power'],
            directions: ['asc', 'asc']
        },
        columnDefs: [
            {
                field: 'favorite',
                displayName: '',
                width: 25,
                cellTemplate: '<div class="ngCellText star-column" ng-class="col.colIndex()" ' +
                              'ng-click="updateFavorite(row.entity)"><a><i class="fa" ' +
                              'ng-class="{\'fa-star\': row.entity[col.field], ' +
                              '\'fa-star-o\': !row.entity[col.field]}"></i></a></div>',
            },
            { field: 'name', displayName: 'Name', width: '*' },
            { field: 'manufacturer', displayName: 'Manufacturer', width: '**' },
            { field: 'power', displayName: 'Power', width: '*' },
            {
                field: 'cell_technology_name',
                displayName: 'Technology',
                cellFilter: 'hsType',
                visible: true,
                width: '*',
            },
            { field: 'operatingConditions()', displayName: 'Vmp, Imp', visible: true, width: '*', sortable: false },
            {
                field: 'last_update',
                displayName: 'Last Updated',
                visible: false,
                width: '*',
                cellFilter: "date: 'short'",
            },
            { field: 'start_year', displayName: 'Start Year', visible: false, width: '*' },
            { field: 'end_year', displayName: 'End Year', visible: false, width: '*' },
            { field: 'teamName()', displayName: 'Uploaded By', visible: true, width: '*', sortable: true },
        ]
    });

    mod.value('InverterGridConfig', {
        sortInfo: {
            fields: ['manufacturer', 'max_power'],
            directions: ['asc', 'asc']
        },
        columnDefs: [
            {
                field: 'favorite',
                displayName: '',
                width: 25,
                cellTemplate: '<div class="ngCellText  star-column" ng-class="col.colIndex()" ' +
                            'ng-click="updateFavorite(row.entity)"><a><i class="fa" ' +
                            'ng-class="{\'fa-star\': row.entity[col.field], ' +
                            '\'fa-star-o\': !row.entity[col.field]}"></i></a></div>',
            },
            { field: 'name', displayName: 'Name', width: '**' },
            { field: 'manufacturer', displayName: 'Manufacturer', width: '**' },
            { field: 'max_power', displayName: 'Max Power', cellFilter: 'number:0', width: '*' },
            { field: 'min_voltage', displayName: 'Min Voltage', width: '*' },
            { field: 'max_voltage', displayName: 'Max Voltage', width: '*' },
            { field: 'teamName()', displayName: 'Uploaded By', width: '*', sortable: true },
        ]
    });


}(angular));
