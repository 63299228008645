import Q from 'q';

import { PubSub } from 'helioscope/app/designer/events';
import { $http, Messager } from 'helioscope/app/utilities/ng';
import * as pusher from 'helioscope/app/utilities/pusher';
import { v4 as uuidv4 } from 'uuid';

const MessagesConfig = {
    startProgress: 'Similar Obstruction Detection in progress...',
    detectionComplete: 'Detection Complete.',
    detectionFailed: 'Similar Obstruction Detection failed. Please try again.',
    detectionCanceled: 'Similar Obstruction Detection canceled.',
    noDetectionsFound: 'No Similar Keepouts Detected.',
    limitReachedCTA: 'To create the remaining keepouts, please rerun the detection.',
    designTooLarge: 'The design area is too large, so Similar Obstruction Detection was applied to only one field segment. Please run detection on additional field segments to complete the process.',
};

export class SimilarObstructionDetectionHelper extends PubSub {
    constructor(dispatcher) {
        super();
        this.dispatcher = dispatcher;
        this.channelID = null;
        this.isSODinProgress = false;
        this.cancelPromise = null;
        this.messageDeferred = null;
    }

    createChannelID() {
        this.channelID = uuidv4();
    }

    async startSOD(keepout, fieldSegment) {
        if (!this.channelID) {
            this.createChannelID();
        }

        const apiUrl = '/api/keepouts/detect_similar_obstructions';
        await $http.post(apiUrl, {
            pusher_channel: this.channelID,
            keepout_id: keepout.keepout_id,
            field_segment_id: fieldSegment.field_segment_id,
            design_id: this.dispatcher.design.design_id,
            imagery_source: this.dispatcher.renderer.tileLayer.id,
        });

        const aggregatedResult = {
            detections: [],
            scores: [],
        };

        this.isSODinProgress = true;
        this.dispatcher.publish('SODInProgress');
        const messageDeferred = Q.defer();
        Messager.fakeProgressLoader(MessagesConfig.startProgress, messageDeferred.promise);

        this.messageDeferred = messageDeferred;

        let limitReached = false;
        let limit = 0;
        let totalDetections = 0;
        let usedBoundingFieldSegmentOnly = false
        try {
            this.cancelPromise = await pusher.promiseFromChannel(this.channelID, async (data) => {
                limitReached = data.limit_reached;
                limit = data.detection_limit;
                totalDetections = data.total_detections;
                usedBoundingFieldSegmentOnly = data.used_bounding_field_segment;
                if (data.detections && Array.isArray(data.detections)) {
                    aggregatedResult.detections.push(...data.detections);
                }
                if (data.scores && Array.isArray(data.scores)) {
                    aggregatedResult.scores.push(...data.scores);
                }
            });

            const detectionCount = aggregatedResult.detections.length;
            this._notifyCompletion(MessagesConfig.detectionComplete, detectionCount, {limitReached, limit, totalDetections, usedBoundingFieldSegmentOnly});
            if (detectionCount) {
                this.dispatcher.publish('SODSuccess', { sodResponse: aggregatedResult, keepout });
            }
        } catch (failureMessage) {
            this._notifyFailure(MessagesConfig.detectionFailed);
            this.dispatcher.publish('SODFailure', failureMessage);
        } finally {
            this.isSODinProgress = false;
            this.dispatcher.publish('SODCompleted');
        }
    }

    cancelSOD() {
        if (this.cancelPromise) {
            this.cancelPromise.cancel();
        }
        this._cancelProgress(MessagesConfig.detectionCanceled);
        this.dispatcher.publish('SODCanceled', {});
    }

    _notifyCompletion(message, detectionCount, {limitReached, limit, totalDetections, usedBoundingFieldSegmentOnly}) {
        if (usedBoundingFieldSegmentOnly){
            Messager.info(MessagesConfig.designTooLarge);
        }
        if (detectionCount > 0) {
            const textBody = limitReached ?
                // eslint-disable-next-line max-len
                `${totalDetections} detections were found, but only ${limit} can be processed at a time. ${MessagesConfig.limitReachedCTA}` :
                `${detectionCount} detections found.`;
            this.messageDeferred.resolve({
                title: message,
                text: textBody,
            });
        } else {
            this.messageDeferred.resolve({
                title: MessagesConfig.noDetectionsFound,
            });
        }
    }

    _notifyFailure(errorMessage) {
        this.messageDeferred.reject({
            title: errorMessage,
        });
    }

    _cancelProgress(cancelMessage) {
        this.messageDeferred.reject(cancelMessage);
    }
}
