import _ from 'lodash';
import * as THREE from 'three';

import {
    ARC_TOLERANCE,
    CLIPPER_SCALAR,
    CLIPPER_INV_SCALAR,
    getClipper } from './clipper';

import { circularDistance } from './math';

import { PriorityQueue } from '../algorithm/priority';

export function cross2(v1, v2) {
    return v1.x * v2.y - v2.x * v1.y;
}

export const COLINEAR = Symbol('COLINEAR');

export class LineSegment2 {
    constructor(point1, point2) {
        this.point1 = point1;
        this.point2 = point2;
        this.delta = this.point2.clone().sub(this.point1);
    }

    clone() {
        return new LineSegment2(this.point1.clone(), this.point2.clone());
    }

    intersectLineLine(segment) {
        const det = segment.delta.y * this.delta.x - segment.delta.x * this.delta.y;
        if (det === 0.0) {
            const d2x = segment.point1.x - this.point1.x;
            const d2y = segment.point1.y - this.point1.y;
            const det2 = d2y * this.delta.x - d2x * this.delta.y;

            if (det2 === 0.0) {
                return COLINEAR;
            }

            return null;
        }

        const dpx = this.point1.x - segment.point1.x;
        const dpy = this.point1.y - segment.point1.y;
        const u0 = (segment.delta.x * dpy - segment.delta.y * dpx) / det;
        return this.delta.clone().multiplyScalar(u0).add(this.point1);
    }

    pointBetweenEndpoints(point) {
        // inclusive
        const d1 = point.clone().sub(this.point1);
        const d2 = point.clone().sub(this.point2);
        if (d1.dot(d2) <= 0) return true;
        return false;
    }

    intersectSegmentSegment(segment) {
        // inclusive
        const intersect = this.intersectLineLine(segment);
        if (!intersect) return intersect;

        if (intersect === COLINEAR) {
            const d1 = segment.point1.clone().sub(this.point1);
            const d2 = segment.point1.clone().sub(this.point2);
            const d3 = segment.point2.clone().sub(this.point1);
            const d4 = segment.point2.clone().sub(this.point2);
            if (d1.dot(d2) > 0 && d3.dot(d4) > 0 && d1.dot(d3) > 0) return null;
            return COLINEAR;
        }

        if (!this.pointBetweenEndpoints(intersect)) return null;
        if (!segment.pointBetweenEndpoints(intersect)) return null;

        return intersect;
    }

    nearestPointOnLine(point) {
        const vec = (new THREE.Vector2()).subVectors(point, this.point1);
        const proj = vec.dot(this.delta) / this.delta.length();
        return (new THREE.Vector2()).addVectors(this.point1, this.delta.clone().normalize().multiplyScalar(proj));
    }

    vectorFromPointToLine(point) {
        const intersection = this.nearestPointOnLine(point);
        return (new THREE.Vector2()).subVectors(intersection, point);
    }

    distanceFromPoint(point) {
        return this.vectorFromPointToLine(point).length();
    }

    getNormalLine(passPoint = this.point1) {
        const dir = new THREE.Vector2(-this.delta.y, this.delta.x);
        return new LineSegment2(passPoint, (new THREE.Vector2()).addVectors(passPoint, dir));
    }
}

export class Triangle2 {
    constructor(vertex1, vertex2, vertex3) {
        this.vertex1 = vertex1;
        this.vertex2 = vertex2;
        this.vertex3 = vertex3;

        this.segment12 = new LineSegment2(this.vertex1, this.vertex2);
        this.segment23 = new LineSegment2(this.vertex2, this.vertex3);
        this.segment31 = new LineSegment2(this.vertex3, this.vertex1);

        const orient = cross2(this.segment12.delta, this.segment23.delta);
        if (orient < 0) this.cw = true;
    }

    getCentroid() {
        const centroid = this.vertex1.clone().add(this.vertex2).add(this.vertex3).multiplyScalar(1.0 / 3.0);
        return centroid;
    }

    clipSegment(segment) {
        // does not check for degenerate segments
        const pt1in = this.containsPointInclusive(segment.point1);
        const pt2in = this.containsPointInclusive(segment.point2);
        if (pt1in && pt2in) return segment.clone();

        const i0 = segment.intersectSegmentSegment(this.segment12);
        const i1 = segment.intersectSegmentSegment(this.segment23);
        const i2 = segment.intersectSegmentSegment(this.segment31);

        const testPts = [];
        if (pt1in) testPts.push(segment.point1.clone());
        if (pt2in) testPts.push(segment.point2.clone());
        if (i0 && i0 !== COLINEAR) testPts.push(i0);
        if (i1 && i1 !== COLINEAR) testPts.push(i1);
        if (i2 && i2 !== COLINEAR) testPts.push(i2);

        const eps = 0.0000001;

        const out1 = testPts[0];
        let out2 = null;
        for (let i = 1; i < testPts.length; ++i) {
            if (testPts[i].distanceToSquared(out1) > eps) {
                out2 = testPts[i];
                break;
            }
        }

        if (!out2) return null;
        return new LineSegment2(out1, out2);
    }

    containsPointInclusive(pt) {
        // inclusive
        // not sure if this is faster than a barycentric method in 2d but should be insignificant either way

        if (this.cw) {
            const c1 = cross2(this.segment12.delta, pt.clone().sub(this.vertex1));
            if (c1 > 0.0) return false;
            const c2 = cross2(this.segment23.delta, pt.clone().sub(this.vertex2));
            if (c2 > 0.0) return false;
            const c3 = cross2(this.segment31.delta, pt.clone().sub(this.vertex3));
            if (c3 > 0.0) return false;

            return true;
        } else {
            const c1 = cross2(this.segment12.delta, pt.clone().sub(this.vertex1));
            if (c1 < 0.0) return false;
            const c2 = cross2(this.segment23.delta, pt.clone().sub(this.vertex2));
            if (c2 < 0.0) return false;
            const c3 = cross2(this.segment31.delta, pt.clone().sub(this.vertex3));
            if (c3 < 0.0) return false;

            return true;
        }
    }
}

export class Polygon2 {
    constructor(path) {
        this.path = _.map(path, i => new THREE.Vector2(i.x, i.y));
    }

    area() {
        return Math.abs(this.signedArea());
    }

    signedArea() {
        const len = this.path.length;
        let area = 0;
        let lastPoint = this.path[len - 1];

        for (let i = 0; i < len; i += 1) {
            const point = this.path[i];
            area += (lastPoint.x * point.y - point.x * lastPoint.y);
            lastPoint = point;
        }

        return area * 0.5;
    }

    orientation() {
        return this.signedArea() >= 0;
    }

    complex() {
        const len = this.path.length;
        for (let i = 0; i < len; i++) {
            for (let j = i + 2; j < len; j++) {
                const d = circularDistance(i, j, len);
                if (d <= 1) continue;

                const s1 = this.segmentAtIndex(i);
                const s2 = this.segmentAtIndex(j);
                if (s1.intersectSegmentSegment(s2)) {
                    return true;
                }
            }
        }

        return false;
    }

    minPairDistance() {
        const len = this.path.length;
        let distSq = Number.POSITIVE_INFINITY;
        for (let i = 0; i < len; i++) {
            for (let j = i + 1; j < len; j++) {
                distSq = Math.min(distSq, this.path[i].distanceToSquared(this.path[j]));
            }
        }

        const dist = Math.sqrt(distSq);
        return dist;
    }

    reverse() {
        _.reverse(this.path);
        return this;
    }

    shift(shift = 1) {
        const len = this.path.length;
        this.path = this.path.slice(len - shift).concat(this.path.slice(0, len - shift));
        return this;
    }

    wrapIndex(i) {
        return i % this.path.length;
    }

    segmentAtIndex(i) {
        return new LineSegment2(this.path[i], this.path[this.wrapIndex(i + 1)]);
    }

    pathCopy() {
        return _.map(this.path, i => i.clone());
    }
}

function pointToClipper(pt) {
    return { X: pt.x * CLIPPER_SCALAR, Y: pt.y * CLIPPER_SCALAR };
}

function pathToClipper(path) {
    return path.map(i => ({ X: i.x * CLIPPER_SCALAR, Y: i.y * CLIPPER_SCALAR }));
}

function multiPathToClipper(paths) {
    return paths.map(i => pathToClipper(i));
}

function pathToThree(path) {
    return path.map(i => new THREE.Vector2(i.X * CLIPPER_INV_SCALAR, i.Y * CLIPPER_INV_SCALAR));
}

function multiPathToThree(paths) {
    return paths.map(i => pathToThree(i));
}

export class ClipperMultiPoly {
    static fromMultiPolygon2(poly) {
        return new ClipperMultiPoly(multiPathToClipper(poly.paths));
    }

    constructor(paths) {
        this.paths = paths;
    }

    toMultiPolygon2() {
        return new MultiPolygon2(multiPathToThree(this.paths));
    }

    simplify() {
        const clipper = getClipper();
        const inClipper = this.paths;
        const outClipper = clipper.Clipper.SimplifyPolygons(inClipper, clipper.PolyFillType.pftNonZero);
        return new ClipperMultiPoly(outClipper);
    }

    difference(other) {
        const clipper = getClipper();
        const cpr = new clipper.Clipper();

        const solutionPaths = [];
        const clipType = clipper.ClipType.ctDifference;
        const fillType = clipper.PolyFillType.pftNonZero;

        cpr.AddPaths(this.paths, clipper.PolyType.ptSubject, true);
        cpr.AddPaths(other.paths, clipper.PolyType.ptClip, true);
        cpr.Execute(clipType, solutionPaths, fillType, fillType);

        const simplePaths = clipper.Clipper.SimplifyPolygons(solutionPaths, clipper.PolyFillType.pftNonZero);
        return new ClipperMultiPoly(simplePaths);
    }

    union(other) {
        const clipper = getClipper();
        const cpr = new clipper.Clipper();

        const solutionPaths = [];
        const clipType = clipper.ClipType.ctUnion;
        const fillType = clipper.PolyFillType.pftNonZero;

        const inPaths = this.paths.concat(other.paths);
        cpr.AddPaths(inPaths, clipper.PolyType.ptSubject, true);
        cpr.Execute(clipType, solutionPaths, fillType, fillType);

        const simplePaths = clipper.Clipper.SimplifyPolygons(solutionPaths, clipper.PolyFillType.pftNonZero);
        return new ClipperMultiPoly(simplePaths);
    }

    intersect(other) {
        const clipper = getClipper();
        const cpr = new clipper.Clipper();

        const solutionPaths = [];
        const clipType = clipper.ClipType.ctIntersection;
        const fillType = clipper.PolyFillType.pftNonZero;

        // for (const i of others.paths) {
        //     if (i.length <= 2) {}
        // }

        cpr.AddPaths(this.paths, clipper.PolyType.ptSubject, true);
        cpr.AddPaths(other.paths, clipper.PolyType.ptClip, true);
        cpr.Execute(clipType, solutionPaths, fillType, fillType);

        return new ClipperMultiPoly(solutionPaths);
    }

    buffer(bufferDistance = 0) {
        const clipper = getClipper();
        const offset = new clipper.ClipperOffset();

        // in theory clipper.JS.Lighten() could provide a similar functionality, but it seems much
        // less robust, particularly for 'straight line' keepouts where it simplifies the points away
        offset.ArcTolerance = ARC_TOLERANCE;

        const solution = [];
        const pathsO = [];
        const pathsC = [];

        for (const i of this.paths) {
            if (i.length <= 2) {
                pathsO.push(i);
            } else {
                pathsC.push(i);
            }
        }

        if (pathsO.length) {
            offset.AddPaths(pathsO, clipper.JoinType.jtRound, clipper.EndType.etOpenPolygon);
        }

        const simplified = clipper.Clipper.SimplifyPolygons(pathsC, clipper.PolyFillType.pftNonZero);
        offset.AddPaths(simplified, clipper.JoinType.jtRound, clipper.EndType.etClosedPolygon);

        offset.Execute(solution, bufferDistance * CLIPPER_SCALAR);
        return new ClipperMultiPoly(solution);
    }

    containsPoint(pt) {
        const clipper = getClipper();

        for (const path of this.paths) {
            if (clipper.Clipper.PointInPolygon(pointToClipper(pt), path) !== 0) {
                return true;
            }
        }

        return false;
    }
}

export class MultiPolygon2 {
    constructor(paths) {
        this.paths = paths;
        this.subPolys = paths.map(i => new Polygon2(i));
    }

    toClipper() {
        return ClipperMultiPoly.fromMultiPolygon2(this);
    }
}

export class AABB2 {
    constructor() {
        this.empty = true;
        this.xMin = Number.POSITIVE_INFINITY;
        this.xMax = Number.NEGATIVE_INFINITY;
        this.yMin = Number.POSITIVE_INFINITY;
        this.yMax = Number.NEGATIVE_INFINITY;
    }

    clone() {
        const cpy = new AABB2();
        cpy.empty = this.empty;
        cpy.xMin = this.xMin;
        cpy.xMax = this.xMax;
        cpy.yMin = this.yMin;
        cpy.yMax = this.yMax;

        return cpy;
    }

    setBoundingBox(xMin, xMax, yMin, yMax) {
        this.empty = false;
        this.xMin = xMin;
        this.xMax = xMax;
        this.yMin = yMin;
        this.yMax = yMax;

        return this;
    }

    fromBoundingBoxes(boxes) {
        for (const box of boxes) {
            if (!box.empty) {
                this.xMin = Math.min(this.xMin, box.xMin);
                this.xMax = Math.max(this.xMax, box.xMax);
                this.yMin = Math.min(this.yMin, box.yMin);
                this.yMax = Math.max(this.yMax, box.yMax);
            }
        }
        this.empty = false;

        return this;
    }

    fromPoints(points) {
        for (const point of points) {
            this.xMin = Math.min(this.xMin, point.x);
            this.xMax = Math.max(this.xMax, point.x);
            this.yMin = Math.min(this.yMin, point.y);
            this.yMax = Math.max(this.yMax, point.y);
        }
        this.empty = false;

        return this;
    }

    getCornerPath() {
        return [
            new THREE.Vector2(this.xMin, this.yMin),
            new THREE.Vector2(this.xMax, this.yMin),
            new THREE.Vector2(this.xMax, this.yMax),
            new THREE.Vector2(this.xMin, this.yMax),
        ];
    }

    getBoxCentroid() {
        const boxCentroid = new THREE.Vector2(
            (this.xMin + this.xMax) * 0.5,
            (this.yMin + this.yMax) * 0.5,
        );

        return boxCentroid;
    }

    contains(pt) {
        return this.xMin <= pt.x && pt.x <= this.xMax &&
                this.yMin <= pt.y && pt.y <= this.yMax;
    }

    distanceTo(pt) {
        return Math.sqrt(this.distanceToSquared(pt));
    }

    distanceToSquared(pt) {
        let dx = 0;
        let dy = 0;

        if (pt.x < this.xMin) dx = this.xMin - pt.x;
        else if (pt.x > this.xMax) dx = this.xMax - pt.x;

        if (pt.y < this.yMin) dy = this.yMin - pt.y;
        else if (pt.y > this.yMax) dy = this.yMax - pt.y;

        return dx * dx + dy * dy;
    }

    overlaps(other) {
        const xMinMax = Math.min(this.xMax, other.xMax);
        const xMaxMin = Math.max(this.xMin, other.xMin);
        if (xMinMax - xMaxMin < 0) return false;

        const yMinMax = Math.min(this.yMax, other.yMax);
        const yMaxMin = Math.max(this.yMin, other.yMin);
        if (yMinMax - yMaxMin < 0) return false;

        return true;
    }

    offset(delta) {
        this.xMin += delta.x;
        this.xMax += delta.x;
        this.yMin += delta.y;
        this.yMax += delta.y;
        return this;
    }

    expand(delta) {
        this.xMin -= delta.x;
        this.xMax += delta.x;
        this.yMin -= delta.y;
        this.yMax += delta.y;
        return this;
    }

    get width() {
        return this.xMax - this.xMin;
    }

    get height() {
        return this.yMax - this.yMin;
    }
}


export const KD_SPLIT_X = Symbol('KD_SPLIT_X');
export const KD_SPLIT_Y = Symbol('KD_SPLIT_Y');
export const KD_SPLIT_SMEQ = Symbol('KD_SPLIT_SMEQ');
export const KD_SPLIT_GR = Symbol('KD_SPLIT_GR');

class KDNode2 {
    constructor(tree, depth, side) {
        this.tree = tree;
        this.depth = depth;
        this.splitSide = side;
    }

    build(primitives) {
        this.bbox = this.tree.nodeAABB(primitives);

        if (primitives.length <= this.tree.leafCount || this.depth >= this.tree.maxDepth) {
            this.leafChildren = primitives;
            return;
        }

        const centers = primitives.map(i => this.tree.leafPosition(i));
        const caabb = (new AABB2()).fromPoints(centers);
        const { xMin, xMax, yMin, yMax } = caabb;

        const xSide = xMax - xMin;
        const ySide = yMax - yMin;

        const longestSide = Math.max(xSide, ySide);

        const subPrimitives1 = [];
        const subPrimitives2 = [];

        for (let i = 0; i < primitives.length; ++i) {
            const primitive = primitives[i];
            const center = centers[i];

            if (xSide === longestSide) {
                this.splitDim = KD_SPLIT_X;
                this.splitValue = (xMin + xMax) * 0.5;
                if (center.x <= this.splitValue) {
                    subPrimitives1.push(primitive);
                } else {
                    subPrimitives2.push(primitive);
                }
            } else {
                this.splitDim = KD_SPLIT_Y;
                this.splitValue = (yMin + yMax) * 0.5;
                if (center.y <= this.splitValue) {
                    subPrimitives1.push(primitive);
                } else {
                    subPrimitives2.push(primitive);
                }
            }
        }

        const subNode1 = new KDNode2(this.tree, this.depth + 1, KD_SPLIT_SMEQ);
        const subNode2 = new KDNode2(this.tree, this.depth + 1, KD_SPLIT_GR);

        if (subPrimitives2.length === 0) {
            // degenerate split
            const split = Math.floor(subPrimitives1.length * 0.5);
            const half2 = subPrimitives1.splice(split);
            subNode1.build(subPrimitives1);
            subNode2.build(half2);
        } else {
            subNode1.build(subPrimitives1);
            subNode2.build(subPrimitives2);
        }

        this.nodeChildren = [subNode1, subNode2];
    }
}

class KNNWorkingSet {
    constructor(tree, ksize, position) {
        this.tree = tree;
        this.ksize = ksize;
        this.limitDistSq = Number.POSITIVE_INFINITY;

        this.queueFn = i => this.tree.leafPosition(i).distanceToSquared(position);
        this.queue = new PriorityQueue(this.queueFn);
    }

    insert(leaf) {
        this.queue.insert(leaf);
        if (this.queue.size() > this.ksize) this.queue.pop();

        if (this.queue.size() === this.ksize) {
            const top = this.queue.peek();
            this.limitDistSq = this.queueFn(top);
        }
    }

    items() {
        return this.queue.heap;
    }
}

export class KDTree2 {
    constructor(maxDepth = 15, leafCount = 2) {
        this.maxDepth = maxDepth;
        this.leafCount = leafCount;
    }

    build(primitives) {
        this.primitives = primitives.slice();
        this.root = new KDNode2(this, 0);
        this.root.build(this.primitives);
    }

    nodeAABB(primitives) {
        return (new AABB2()).fromPoints(primitives.map(i => this.leafPosition(i)));
    }

    leafPosition(primitive) {
        return primitive;
    }

    nearestNeighbor(position) {
        // could do this a simpler way with unordered traversal
        // and using plane distance instead of aabb distance
        const stack = [this.root];
        let bestDistSq = Number.POSITIVE_INFINITY;
        let bestObj;

        while (stack.length) {
            const curr = stack.pop();

            if (curr.bbox.distanceToSquared(position) >= bestDistSq) continue;

            if (curr.leafChildren) {
                for (const i of curr.leafChildren) {
                    const distSq = position.distanceToSquared(this.leafPosition(i));
                    if (distSq < bestDistSq) {
                        bestDistSq = distSq;
                        bestObj = i;
                    }
                }
            } else if (curr.nodeChildren) {
                const d0 = curr.nodeChildren[0].bbox.distanceToSquared(position);
                const d1 = curr.nodeChildren[1].bbox.distanceToSquared(position);
                if (d0 < d1) {
                    stack.push(curr.nodeChildren[1], curr.nodeChildren[0]);
                } else {
                    stack.push(curr.nodeChildren[0], curr.nodeChildren[1]);
                }
            }
        }

        return bestObj;
    }

    kNearestNeighbor(position, ksize) {
        // could do this a simpler way with unordered traversal
        // and using plane distance instead of aabb distance
        const stack = [this.root];
        const ws = new KNNWorkingSet(this, ksize, position);

        while (stack.length) {
            const curr = stack.pop();

            if (curr.bbox.distanceToSquared(position) >= ws.limitDistSq) continue;

            if (curr.leafChildren) {
                for (const i of curr.leafChildren) {
                    const distSq = position.distanceToSquared(this.leafPosition(i));
                    if (distSq < ws.limitDistSq) ws.insert(i);
                }
            } else if (curr.nodeChildren) {
                const d0 = curr.nodeChildren[0].bbox.distanceToSquared(position);
                const d1 = curr.nodeChildren[1].bbox.distanceToSquared(position);
                if (d0 < d1) {
                    stack.push(curr.nodeChildren[1], curr.nodeChildren[0]);
                } else {
                    stack.push(curr.nodeChildren[0], curr.nodeChildren[1]);
                }
            }
        }

        return ws.items();
    }

    traverseByAABB(aabb, fn) {
        const stack = [this.root];
        while (stack.length) {
            const curr = stack.pop();
            if (!curr.bbox.overlaps(aabb)) continue;

            if (curr.leafChildren) {
                for (const i of curr.leafChildren) {
                    fn(i);
                }
            } else if (curr.nodeChildren) {
                stack.push(...curr.nodeChildren);
            }
        }
    }

    traverseByPoint(pt, fn) {
        const stack = [this.root];
        while (stack.length) {
            const curr = stack.pop();
            if (!curr.bbox.contains(pt)) continue;

            if (curr.leafChildren) {
                for (const i of curr.leafChildren) {
                    fn(i);
                }
            } else if (curr.nodeChildren) {
                stack.push(...curr.nodeChildren);
            }
        }
    }
}

export class KDTreeSingle2 extends KDTree2 {
    constructor() {
        super(999999, 1);
    }

    traverseIntersections(fn) {
        // simultaneous traversal
        if (this.root.leafChildren) return;

        const visited = new Set();
        const stack = [[this.root.nodeChildren[0], this.root.nodeChildren[1]]];

        while (stack.length) {
            const curr = stack.pop();
            const c1 = curr[0];
            const c2 = curr[1];

            if (c1.nodeChildren && !visited.has(c1)) {
                const c1n1 = c1.nodeChildren[0];
                const c1n2 = c1.nodeChildren[1];
                stack.push([c1n1, c1n2]);
                visited.add(c1);
            }

            if (c2.nodeChildren && !visited.has(c2)) {
                const c2n1 = c2.nodeChildren[0];
                const c2n2 = c2.nodeChildren[1];
                stack.push([c2n1, c2n2]);
                visited.add(c2);
            }

            if (!c1.bbox.overlaps(c2.bbox)) continue;

            if (c1.leafChildren && c2.leafChildren) {
                fn(c1.leafChildren[0], c2.leafChildren[0]);
            } else if (c1.nodeChildren && c2.nodeChildren) {
                const c1n1 = c1.nodeChildren[0];
                const c1n2 = c1.nodeChildren[1];
                const c2n1 = c2.nodeChildren[0];
                const c2n2 = c2.nodeChildren[1];
                stack.push([c1n1, c2n1]);
                stack.push([c1n1, c2n2]);
                stack.push([c1n2, c2n1]);
                stack.push([c1n2, c2n2]);
            } else if (c1.leafChildren) {
                const c2n1 = c2.nodeChildren[0];
                const c2n2 = c2.nodeChildren[1];
                stack.push([c1, c2n1]);
                stack.push([c1, c2n2]);
            } else if (c2.leafChildren) {
                const c1n1 = c1.nodeChildren[0];
                const c1n2 = c1.nodeChildren[1];
                stack.push([c2, c1n1]);
                stack.push([c2, c1n2]);
            }
        }
    }
}
