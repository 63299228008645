/* global document:true, window:true, google:true, _:true */
import Logger from 'js-logger';
import * as Q from 'q';

import * as analytics from 'helioscope/app/utilities/analytics';

import { asyncLoadScript, noop } from 'helioscope/app/utilities/helpers';
import { mapCredentials } from 'reports/angular-bridge.ts';
import {
    GoogleTileService,
    BingTileService,
    NearmapTileService,
    ScaledZoomMapType,
} from './scaled_layer';

const logger = Logger.get('maps');

export const GOOGLE_MAP_CONFIG = {
    deleteUrl: require('helioscope/app/utilities/maps/delete.png'),
    undoUrl: '//maps.gstatic.com/mapfiles/undo_poly.png',
};


export function loadGoogleMaps(authQueryKey = mapCredentials.queryKey, authQueryValue = mapCredentials.queryVal) {
    if (window.google && window.google.maps) {
        return Q.when(window.google);
    } else if (loadGoogleMaps.$deferred === undefined) {
        const urlArgs = `${authQueryKey}=${authQueryValue}&libraries=geometry&callback=googleMapsLoaded`;
        const scriptUrl = `//maps.google.com/maps/api/js?${urlArgs}`;

        const deferred = Q.defer();
        loadGoogleMaps.$deferred = deferred;
        window.googleMapsLoaded = () => {
            logger.info('[Google Maps] loaded successfully');
            deferred.resolve(window.google);
        };

        analytics.track('gmaps.load_javascript');
        asyncLoadScript(scriptUrl);
    }

    return loadGoogleMaps.$deferred.promise;
}


export const MAP_TILE_LAYERS = [
    { id: 'google_satellite', TileService: GoogleTileService, imagery: 'satellite', name: 'Google', supportsSOD: true },
    { id: 'bing_satellite', TileService: BingTileService, imagery: 'satellite', name: 'Bing', supportsSOD: true },
    { id: 'google_street', TileService: GoogleTileService, imagery: 'street', name: 'Google Street', supportsSOD: false },
    { id: 'nearmap_satellite', TileService: NearmapTileService, imagery: 'satellite', name: 'Nearmap', supportsSOD: true },
];


function addImageryLayers(map, tileLayers, initialLayerId) {
    const mapLayers = {};

    for (const layerData of tileLayers) {
        const layer = new ScaledZoomMapType(map, new layerData.TileService(layerData.imagery), layerData.name);
        map.mapTypes.set(layerData.id, layer);
        mapLayers[layerData.id] = layer;
    }

    map.mapTypeControlOptions.mapTypeIds = _.keys(mapLayers);
    map.setMapTypeId(initialLayerId);
    mapLayers[initialLayerId].activate();

    google.maps.event.addListener(map, 'maptypeid_changed', () => {
        // cache purging is necessary to solve UI latency issues when switching between
        // alternative map types.
        const layer = mapLayers[map.getMapTypeId()];

        if (layer === null) {
            return;
        }

        if (layer.clearCache) {
            layer.clearCache();
        }

        layer.activate();
    });


    return mapLayers;
}


function getDefaultMapOptions(startLocation, showControls = true) {
    return {
        center: new google.maps.LatLng(startLocation.lat, startLocation.lng),
        zoom: (startLocation.zoom || 20),
        mapTypeId: google.maps.MapTypeId.HYBRID,
        tilt: 0,
        mapTypeControl: showControls,
        mapTypeControlOptions: {
            position: google.maps.ControlPosition.TOP_RIGHT,
            style: google.maps.MapTypeControlStyle.DROPDOWN_MENU,
            mapTypeIds: [],
        },
        panControl: false,
        panControlOptions: {
            position: google.maps.ControlPosition.TOP_RIGHT,
        },

        zoomControl: showControls,
        zoomControlOptions: {
            style: google.maps.ZoomControlStyle.LARGE,
            position: google.maps.ControlPosition.TOP_RIGHT,
        },
        scaleControl: showControls,
        scaleControlOptions: {
            position: google.maps.ControlPosition.RIGHT_BOTTOM,
        },
        streetViewControl: false,
        clickableIcons: false,
        styles: [
            {
                featureType: 'poi',
                stylers: [
                    { visibility: 'off' },
                ],
            },
        ],
    };
}


function addProjectionHelpers(map) {
    const overlay = new google.maps.OverlayView();
    overlay.draw = noop;
    overlay.setMap(map);

    map.fromLatLngToContainerPixel = (location) => {
        const projection = overlay.getProjection();
        if (projection) {
            return projection.fromLatLngToContainerPixel(location);
        }
    };

    map.fromContainerPixelToLatLng = (pixel) => {
        const projection = overlay.getProjection();
        if (projection) {
            return projection.fromContainerPixelToLatLng(pixel);
        }
    };

    return map;
}

export function createGoogleMap(domId,
    {
        center = { lat: 37.7878102, lng: -122.3988355 },
        showControls = true,
        initialLayerId = 'google_satellite',
    } = {}) {
    const mapOptions = getDefaultMapOptions(center, showControls);
    const domElement = document.getElementById(domId);
    if (!domElement) {
        throw Error(`DOM element ${domId} not found. Can't instantiate Google Maps`);
    }

    const map = new google.maps.Map(domElement, mapOptions);
    const mapLayers = addImageryLayers(map, MAP_TILE_LAYERS, initialLayerId);
    addProjectionHelpers(map);

    return { map, mapLayers };
}
