import * as THREE from 'three';
import { PasteAction } from 'helioscope/app/designer/actions';
import { Vector, intersectPathsMulti, generateCirclePath } from 'helioscope/app/utilities/geometry';
import { makeWireGeometry, pathToSegmentPoints } from 'helioscope/app/apollo/GLHelpers';
import { PrimitiveMeshStroke } from 'helioscope/app/apollo/Primitives';
import { RendererOptions } from 'helioscope/app/apollo/RendererOptions';
import { KEY } from 'helioscope/app/utilities/helpers';
import { SurfaceCursorHelper, containerPoint, zoomView } from './InteractHelpers';
import { FieldSegment } from '../designer/field_segment';
import { Keepout } from '../designer/keepout';
import { EntityPremade } from '../designer/premade';

export class InteractToolBoxSelect {
    constructor(dRenderer) {
        this.dRenderer = dRenderer;
        this.cursorHelper = new SurfaceCursorHelper(dRenderer);
        this.pasteAction = new PasteAction(dRenderer);
    }

    toolMouseDown(event) {
        const pt = containerPoint(this.dRenderer, event);
        const cursorPosition = this.cursorHelper.computeCursorPositionNoSurface(pt);
        if (!cursorPosition) {
            return;
        }
        this.selectStart = cursorPosition.groundPoint;
    }

    toolMouseUp(event) {
        const selectedEntities = this.selectEntitiesInsideBox();
        this.handleBoxSelection(selectedEntities, event);
        this.cleanUp();
        this.dRenderer.dispatcher.deactivateSelectMode();
    }

    getCameraAngles() {
        return {
            phi: this.dRenderer.cameraPhi,
            theta: this.dRenderer.cameraTheta,
        };
    }

    calculateSelectionBoxPoints(startPoint, endPoint) {
        const { phi, theta } = this.getCameraAngles();

        const diagonal = new THREE.Vector2().subVectors(endPoint, startPoint);
        const diagonalParallelToCameraX = diagonal.clone().rotateAround(new THREE.Vector2(0, 0), -phi);

        const alpha = diagonalParallelToCameraX.angle();
        const horizontalLength = diagonal.length() * Math.cos(alpha);
        const deltaX = horizontalLength * Math.cos(phi);
        const deltaY = horizontalLength * Math.sin(phi);

        const parallelPoint = new THREE.Vector2(startPoint.x + deltaX, startPoint.y + deltaY);
        const transversePoint = new THREE.Vector2(endPoint.x - deltaX, endPoint.y - deltaY);

        const topDownOffset = 0.1;
        const deltaX2 = deltaX * Math.sin(theta) * topDownOffset;
        const deltaY2 = deltaY * Math.sin(theta) * topDownOffset;

        const parallelPoint3D = new THREE.Vector2(parallelPoint.x + deltaX2, parallelPoint.y + deltaY2);
        const transversePoint3D = new THREE.Vector2(transversePoint.x - deltaX2, transversePoint.y - deltaY2);
        return { transversePoint3D, parallelPoint3D };
    }

    toolMouseMove(event) {
        const pt = containerPoint(this.dRenderer, event);
        const cursorPosition = this.cursorHelper.computeCursorPositionNoSurface(pt);
        if (!cursorPosition) {
            return;
        }
        this.selectEnd = cursorPosition.groundPoint;

        if (this.selectStart) {
            const { transversePoint3D, parallelPoint3D } = this.calculateSelectionBoxPoints(
                this.selectStart,
                this.selectEnd,
            );
            this.boxPath = [
                Vector.fromObject(this.selectStart),
                Vector.fromObject(parallelPoint3D),
                Vector.fromObject(this.selectEnd),
                Vector.fromObject(transversePoint3D),
            ];
            this.redrawBox(this.boxPath);
        }
    }

    selectEntitiesInsideBox() {
        if (!this.boxPath) {
            return [];
        }
        const intersectedObjects = new Set();
        for (const child of this.dRenderer.selectionLayerScene.children) {
            let path = [];
            const entity = child.userData.interactData.object;
            if (entity instanceof FieldSegment || entity instanceof Keepout) {
                path = [...entity.geometry.path];
                if (path.length === 2) {
                    // Clipper library requires at least 3 points to work.
                    // In cases where the keepout is a line, we add 2 more points to make it a rectangle.
                    const firstPoint = path[0];
                    const secondPoint = path[1];
                    const offset = 0.01;
                    path = [
                        firstPoint,
                        new Vector(firstPoint.x + offset, firstPoint.y, firstPoint.z),
                        new Vector(secondPoint.x + offset, secondPoint.y + offset, secondPoint.z),
                        new Vector(secondPoint.x, secondPoint.y + offset, secondPoint.z),
                    ];
                }
            } else if (entity instanceof EntityPremade) {
                const center = entity.geometry.parameters.position;
                const radius = entity.geometry.parameters.top_radius;
                path = generateCirclePath(center, radius);
            }

            const intersection = path.length > 0 ? intersectPathsMulti([path], [this.boxPath]) : [];
            if (intersection.length > 0) {
                intersectedObjects.add(child.userData.interactData.object);
            }
        }
        return [...intersectedObjects];
    }

    handleBoxSelection(selectedEntities, event) {
        if (selectedEntities.length === 0) {
            this.dRenderer.dispatcher.deselectEntity();
            return;
        }
        if (event.shiftKey) {
            this.dRenderer.dispatcher.selectEntity(selectedEntities, {}, true);
            return;
        }
        this.dRenderer.dispatcher.deselectEntity();
        this.dRenderer.dispatcher.selectEntity(selectedEntities, {}, true);
    }

    toolMouseOut() {
        this.cursorHelper.clearCreateCursor();
    }

    toolMouseWheel(event) {
        zoomView(this.dRenderer, event);
    }

    toolDblClick() { }

    deactivateTool() {
        this.cursorHelper.clearCreateCursor();
        this.cursorHelper.clearSurfaceCursorPrimitives();
        this.clearCreation();
        this.cleanUp();
        // If we call activateSelectMode here, it will be called twice, so we need to set it to false manually
        this.dRenderer.dispatcher.selectMode = false;
    }

    clearCreation() {
        this.redrawInstance();
    }

    computeCreateGeometry(clientPt) {
        this.cursorHelper.computeCursorPosition(clientPt);
    }

    redrawInstance() { }

    getLineOptions(path, strokeColor = RendererOptions.moduleControlOptions.selectionOutlineColor) {
        return {
            geometry: makeWireGeometry(pathToSegmentPoints(path)),
            material: this.dRenderer.inlineShaderMaterial('vertexShaderWire', 'fragmentShaderWire'),
            scene: this.dRenderer.editSurfaceLayer,
            depthOffset: this.dRenderer.tinyZOffset,
            screenSpace: false,
            strokeColor,
            strokeWeight: 1.0,
        };
    }

    redrawBox(boxPath) {
        if (this.boxPrimitive) {
            this.boxPrimitive.clearInstances();
            this.boxPrimitive = null;
        }

        if (boxPath) {
            this.boxPrimitive = this.dRenderer.renderPrimitive(PrimitiveMeshStroke, this.getLineOptions(boxPath));
        }

        this.dRenderer.dirtyFrame();
    }

    cleanUp() {
        this.selectStart = null;
        this.selectEnd = null;
        this.redrawBox(null);
        this.boxPath = null;
    }

    toolKeyDown(event) {
        if (event.keyCode === KEY.ESC) {
            this.dRenderer.dispatcher.deactivateSelectMode();
        }
    }
}
