/* global angular:true, _:true */
import { $http, $q, $timeout, SolarTime, ngRefs as ng } from 'helioscope/app/utilities/ng';
import { Vector, GeoPoint } from 'helioscope/app/utilities/geometry';
import { RelationalBase, relationship, deserializeObject } from 'helioscope/app/relational';
import { Design } from 'helioscope/app/designer/Design';

export class Project extends RelationalBase {
    static relationName = 'Project';
}

Project.configureRelationships({
    ac_config: relationship('AcConfig'),
    profile: relationship('Profile'),
    ashrae_weather: relationship('AshraeWeather'),
    'geometry.pcc_location': deserializeObject(Vector),
    location: deserializeObject(GeoPoint),
    creator: relationship('User'),
    last_modified_by_user: relationship('User'),
});

Project.createEndpoint('/api/projects/:project_id', { project_id: '@project_id' }, {
    update: { method: 'PUT', isArray: false },
    removeUser: { method: 'DELETE', isArray: false, url: '/api/projects/:project_id/users/:user_id' },
    addUser: { method: 'POST', isArray: false, url: '/api/projects/:project_id/users/:user_id' },
    share: { method: 'GET', isArray: false, url: '/api/projects/share/:share_hash' },
});


export class Scenario extends RelationalBase {
    static relationName = 'Scenario';

    hasSpectral() {
        const modules = this.module_characterizations.map(x => x.module);
        return this.use_spectral_adjustment && _.some(modules, mod => mod && mod.cell_technology_name === 'cdte');
    }

    get soiling() {
        return [
            this.jan_soiling,
            this.feb_soiling,
            this.mar_soiling,
            this.apr_soiling,
            this.may_soiling,
            this.jun_soiling,
            this.jul_soiling,
            this.aug_soiling,
            this.sep_soiling,
            this.oct_soiling,
            this.nov_soiling,
            this.dec_soiling,
        ];
    }
}

Scenario.configureRelationships({
    weather_dataset: relationship('WeatherDataset'),
    project: relationship('Project', { backref: 'scenarios' }),
    sketchup_model: relationship('SketchupModel'),
    horizon: relationship('Horizon'),
});

Scenario.createEndpoint('/api/condition_sets/:scenario_id', { scenario_id: '@scenario_id' },
                        { update: { method: 'PUT', isArray: false, params: { scenarioId: '@scenario_id' } } });


export class SketchupModel extends RelationalBase {
    static relationName = 'SketchupModel';

    static b64toBlob(b64Data, contentType = '', sliceSize = 512) {
        var byteCharacters = ng.$window.atob(b64Data),
            byteArrays = [],
            offset,
            slice,
            byteNumbers,
            i;

        for (offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            slice = byteCharacters.slice(offset, offset + sliceSize);

            byteNumbers = new Array(slice.length);

            for (i = 0; i < slice.length; i += 1) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            byteArrays.push(new ng.$window.Uint8Array(byteNumbers));
        }

        return new ng.$window.Blob(byteArrays, {type: contentType});
    }

    static imageryEndpoint(metadata) {
        var suffix = metadata.image_overlay === true ? 'upload_overlay' : 'upload_render';
        return '/api/sketchup_models/' + metadata.sketchup_model_id + '/' + suffix;
    }

    static uploadImage(data) {
        var config = {
                'headers': {
                    'bounds': angular.toJson(data.metadata.bounds),
                    'render-timestamp': data.metadata.timestamp,
                    'render-filename': data.file.filename,
                    'Content-Type': 'image/png'
                }
            },
            deferred = $q.defer(),
            retries = 3,
            url = SketchupModel.imageryEndpoint(data.metadata),
            payload;

        try {
            payload = SketchupModel.b64toBlob(data.file.content, 'image/png');
            config.headers['Content-Transfer-Encoding'] = 'binary';
            config.responseType = 'blob';
        } catch (e) {
            //blob transfers are not supported on this browser

            // sanitize base64 data for http (+ and / ar disallowed)
            payload = data.file.content.replace('+', '-').replace('/', '_');
            config.headers['Content-Transfer-Encoding'] = 'base64';
            config.responseType = 'text';
        }

        function tryUpload(retries) {
            $http.post(url, payload, config).then(
                function (result) {
                    deferred.resolve(result);
                },
                function (resp) {
                    if (retries === 0) {
                        deferred.reject(resp);
                    } else {
                        $timeout(function () {tryUpload(retries - 1); }, 2500);
                    }
                }
            );
        }

        tryUpload(retries);

        return deferred.promise;
    }
}

SketchupModel.configureRelationships({
    project: relationship(Project, { backref: 'sketchup_models' }),
});

SketchupModel.createEndpoint('/api/sketchup_models/:sketchup_model_id', {sketchup_model_id: '@sketchup_model_id'},
                             {'update': {method: 'PUT', isArray: false, params: {sketchup_model_id: '@sketchup_model_id'}}})

class Horizon extends RelationalBase {
    static relationName = 'Horizon';
}
Horizon.uploadUrl = '/api/horizons/upload';
Horizon.configureRelationships({
    project: relationship(Project, { backref: 'horizons' }),
});
Horizon.createEndpoint('/api/horizons/:horizon_id', {horizon_id: '@horizon_id'});


export class Simulation extends RelationalBase {
    static relationName = 'Simulation';

    async loadDependencies() {
        if (this.design_id && !this.design) {
            await Design.get({ design_id: this.design_id }).$promise;
        }

        if (this.scenario_id && !this.scenario) {
            await Scenario.get({ scenario_id: this.scenario_id }).$promise;
        }

        return this;
    }
}
Simulation.configureRelationships({
    design: relationship('Design', { backref: 'simulations' }),
    scenario: relationship('Scenario', { backref: 'simulations' }),
});

Simulation.createEndpoint(
    '/api/simulations/:simulation_id',
    { simulation_id: '@simulation_id' },
    {
        find_optimal_tilt_azimuth: {
            method: 'POST', isArray: false, url: '/api/simulations/find_optimal_tilt_azimuth',
        },
        global_single_diode_hourly: {
            method: 'POST', isArray: false, url: '/api/simulations/global_single_diode_hourly',
        },
    },
);

const mod = angular.module('helioscope.projects.resources', []);
mod.factory('Project', () => Project);
mod.factory('Scenario', () => Scenario);
mod.factory('SketchupModel', () => SketchupModel);
mod.factory('Horizon', () => Horizon);
mod.factory('Simulation', () => Simulation);
