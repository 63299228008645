import _ from 'lodash';
import { arrayUnorderedEqual } from 'helioscope/app/utilities/helpers';
import { $q } from 'helioscope/app/utilities/ng';


/**
 * object that stores an undo/redo delta, relying on the caller
 * to explicitly define load/rollback functions that return promises
 *
 * these should always be run synchronously
 */
export class StateDelta {
    constructor(opts) {
        this.loadText = opts.loadText;
        this.loadFn = opts.loadFn;

        this.rollbackText = opts.rollbackText;
        this.rollbackFn = opts.rollbackFn;

        this.timestamp = (opts.timestamp || (new Date()).getTime());
    }

    load() { return this.loadFn(this.resource); }
    rollback() { return this.rollbackFn(this.resource); }
    undoMessage() { return this.rollbackText; }
    redoMessage() { return this.loadText; }
    attemptMerge() { return false; }
}

export class PropertyChangeDelta extends StateDelta {
    constructor(opts) {
        const callback = opts.callback || (x => x);

        super({
            timestamp: opts.timestamp,
            loadFn: () => {
                const res = [];
                for (const change of this.getChanges()) {
                    _.set(this.resource, change.path, change.newVal);
                    res.push(callback(this.resource, change.path, change.newVal, change.oldVal));
                }

                return $q.all(res).then(() => this.resource);
            },
            rollbackFn: () => {
                const res = [];
                // Run the rollback in reverse in case the order matters
                // For instance, merged sets of linked changes are generally a good idea
                // to run in reverse order
                for (const change of this.getChanges().reverse()) {
                    _.set(this.resource, change.path, change.oldVal);
                    res.push(callback(this.resource, change.path, change.oldVal, change.newVal));
                }

                // update lastValue in viewChangeListeners if the undo callback function is provided
                if (this.undoCallback && typeof this.undoCallback === 'function') {
                    for (const change of this.getPrimaryChanges()) {
                        this.undoCallback(change);
                    }
                }

                return $q.all(res).then(() => this.resource);
            },
        });
        this.resource = opts.resource;
        this.persistChanges = opts.persistChanges !== undefined ? opts.persistChanges : true;

        this.loadMessage = opts.loadMessage;
        this.rollbackMessage = opts.rollbackMessage;

        this.linkedChanges = [];

        // whether this delta can be merged with later deltas to the same property path
        // should be true unless explcitly set to false
        this.mergeable = !(opts.mergeable === false);

        this.undoCallback = opts.undoCallback;
    }

    getPrimaryChanges() {
        throw Error('Not implemented');
    }

    getChanges() {
        return [...this.getPrimaryChanges(), ...this.linkedChanges];
    }

    setLinkedChanges(linkedChanges) {
        this.linkedChanges = linkedChanges;
    }
}

export function makeDelta(deltaOpts) {
    return deltaOpts.changes.length > 1 ?
        new MultiPropertyChangeDelta(deltaOpts) :
        new SinglePropertyChangeDelta({ ...deltaOpts, changes: undefined, change: deltaOpts.changes[0] });
}


/**
 * object that stores an undo/redo delta, for a given property path
 * on an object
 *
 * implements the same high level interface as the StateDelta for
 * moving through the stack, but simplifies the process of changing a
 * single field
 */
export class SinglePropertyChangeDelta extends PropertyChangeDelta {
    constructor(opts) {
        super(opts);

        this.path = opts.change.path;
        this.name = opts.name || _.capitalize(this.path.replace('_', ' '));
        this.filter = opts.filter || (x => x);
        this.change = opts.change;
    }

    getPrimaryChanges() {
        return [this.change];
    }

    sameItem(otherDelta) {
        return (otherDelta &&
            this.resource === otherDelta.resource &&
            this.path === otherDelta.path);
    }

    attemptMerge(otherDelta) {
        if (this.mergeable && otherDelta.mergeable && this.sameItem(otherDelta)) {
            this.change.newVal = otherDelta.change.newVal;
            // If the other delta is a non-null value, then we should persist the changes
            this.persistChanges = otherDelta.persistChanges;
            this.linkedChanges = [...this.linkedChanges, ...otherDelta.linkedChanges];
            return true;
        }

        return false;
    }

    undoMessage() {
        return (
            this.rollbackMessage
            || `Undo ${this.name} from: ${this.filter(this.change.newVal)} to ${this.filter(this.change.oldVal)}`
        );
    }

    redoMessage() {
        return (
            this.loadMessage
            || `Redo ${this.name} from: ${this.filter(this.change.oldVal)} to ${this.filter(this.change.newVal)}`
        );
    }
}

/**
 * object that stores an undo/redo delta, for multiple property paths
 * on an object
 */
export class MultiPropertyChangeDelta extends PropertyChangeDelta {
    constructor(opts) {
        super(opts);

        this.changes = opts.changes;
    }

    getPrimaryChanges() {
        return this.changes;
    }

    attemptMerge(_otherDelta) {
        // Don't attempt merge for multi-property changes. This might make sense to attempt during
        // a future optimization, but for now, multi-property actions are generally associated with
        // complex actions, such as fit field segment to LIDAR, which are unlikely to happen 2+ times
        // within the 5 second debounce interval.
        return false;
    }

    undoMessage() { return this.rollbackMessage; }

    redoMessage() { return this.loadMessage; }
}
