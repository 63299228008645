import { $log, $rootScope, Messager } from 'helioscope/app/utilities/ng';
import { calculateSolarAngle } from 'helioscope/app/utilities/solar';
import * as SolarTime from 'helioscope/app/utilities/solar/solar_time';
import { defaultAzimuth, toRadians } from 'helioscope/app/utilities/geometry';
import { FieldSegment } from './FieldSegment';

function calculateInitialTilt(fieldSegment, profile) {
    if (profile.tilt_strategy === 'fixed') {
        return profile.tilt;
    } else if (profile.tilt_strategy === 'latitude') {
        return Math.abs(fieldSegment.design.project.location.latitude);
    }

    const lat = Math.abs(fieldSegment.design.project.location.latitude);

    if (lat <= 25) {
        return lat * 0.87;
    } else if (lat <= 50) {
        return lat * 0.76 + 3.1;
    }
    return 45;
}

function setInitialRowSpacing(fieldSegment, profile) {
    switch (profile.row_spacing_strategy) {
    case 'fixed':
        fieldSegment.row_spacing = profile.row_spacing;
        break;
    case 'span_to_rise':
        fieldSegment.spanToRise = profile.span_to_rise;
        break;
    case 'gcr':
        fieldSegment.groundCoverageRatio = profile.gcr;
        break;
    case 'shading':
        timeOfDayRowSpacing(
            fieldSegment,
            SolarTime.userLocalDate(profile.row_spacing_start_time),
            SolarTime.userLocalDate(profile.row_spacing_end_time)
        );
        break;
    default:
        $log.warn('Unknown row spacing strategy', profile.row_spacing_strategy);
    }
}

/**
 * calculate a zero shade row spacing for a field segment based on a time window.
 *
 * an improved algorithm would step through the periods and take the max of all the spaces
 */
export function timeOfDayRowSpacing(fieldSegment, startTime, endTime, baseDate = startTime) {
    const location = fieldSegment.design.project.location;
    const timeZone = fieldSegment.design.project.time_zone_offset;

    const solarStartTime = SolarTime.combineDateTime(baseDate, startTime);
    const solarEndTime = SolarTime.combineDateTime(baseDate, endTime);

    if (solarStartTime > solarEndTime) {
        Messager.error('Start time must be earlier than end time');
    }


    const startAngle = calculateSolarAngle(SolarTime.utcDate(startTime, timeZone), location);
    const endAngle = calculateSolarAngle(SolarTime.utcDate(endTime, timeZone), location);

    const frameVector = fieldSegment.layoutEngine().frameVector();
    const totalRise = frameVector.z;

    const startAzimuth = toRadians(fieldSegment.azimuth - startAngle.azimuth);
    const endAzimuth = toRadians(fieldSegment.azimuth - endAngle.azimuth);

    const startSpacing = totalRise * Math.cos(startAzimuth) / Math.tan(toRadians(startAngle.apparentElevation));
    const endSpacing = totalRise * Math.cos(endAzimuth) / Math.tan(toRadians(endAngle.apparentElevation));

    let recommendedSpacing;

    if (_.isNumber(startSpacing) || _.isNumber(endSpacing)) {
        recommendedSpacing = Math.max(startSpacing || 0, endSpacing || 0, 0);
    }

    return recommendedSpacing;
}

export function getDefaultShadeTimes(design, profile) {
    const startTime = SolarTime.userLocalDate(_.get(profile, 'row_spacing_start_time', design.shade_keepouts_start));
    const endTime = SolarTime.userLocalDate(_.get(profile, 'row_spacing_end_time', design.shade_keepouts_end));

    return { startTime, endTime };
}

export function makeFieldSegment(design, profile) {
    const fieldSegments = design.field_segments;
    const lastFS = _.clone(fieldSegments[fieldSegments.length - 1]);

    // defaults then preferences then most recent
    const settings = _.merge(
        {
            shadow_caster: true,
        },
        profile,
        {// set azimuth here so that the lastFS overwrites it
            azimuth: defaultAzimuth(design),
            geometry: { path: [] },
        },
        lastFS,
        {
            design_id: design.design_id,
            description: `Field Segment ${(fieldSegments.length + 1)}`,
            wiring_priority: fieldSegments.length,
            max_size: 0, // don't copy the max size when creating a new field segment,
            wiring_zone_id: (lastFS && lastFS.wiring_zone_id) || design.wiring_zones[0].wiring_zone_id,
            field_segment_id: null,  // must be null to overrate any previous field segment ids when using _.merge
            data: {},
        });

    // clear racking -- merge won't do what we want
    settings.racking = {};

    const fieldSegment = new FieldSegment(settings);
    if (lastFS === undefined && profile && profile.module) {
        fieldSegment.tilt = calculateInitialTilt(fieldSegment, profile);
        setInitialRowSpacing(fieldSegment, profile);
    }

    if (lastFS && lastFS.rack_type === 'rack' && lastFS.independent_tilt_enabled && !$rootScope.user().hasIndependentTiltAccess) {
        fieldSegment.independent_tilt_enabled = null;
        fieldSegment.independent_tilt_surface_azimuth = null;
        fieldSegment.independent_tilt_surface_tilt = null;
    }

    return fieldSegment;
}

export function notUsingDefaultCharacterization(fieldSegment) {
    return (
        fieldSegment.module_characterization && (
            fieldSegment.module_characterization_id !==
            fieldSegment.module_characterization.module.defaultCharacterizationId()
        )
    );
}
