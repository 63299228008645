import Logger from 'js-logger';
import LogRocket from 'logrocket';
import { some, get } from 'lodash';
import Q from 'q';

import Raven from 'raven-js';
import angularPlugin from 'raven-js/plugins/angular';

import { helioscopeConfig } from 'helioscope/app/config';
import { $http } from 'helioscope/app/utilities/ng';
import { requestHeaders } from 'helioscope/app/utilities/relational/resource';

const logger = Logger.get('bug_reports');


// list of endpoints to automatically filter out (Because of large request sizes)
const FILTER_ENDPOINTS = [
    '/api/field_components/',
    '/pdf',
];

// max size of requests to forward to logrocket
const MAX_REQUEST_FORWARDING_SIZE = 50 * 1000; // 50KB

function logRocketFilter(request) {
    // tag the original body size always
    const bodySize = get(request, 'body.length', 0);
    request.headers['logrocket-body-size'] = bodySize;

    if (request.url && some(FILTER_ENDPOINTS, endpoint => request.url.indexOf(endpoint) !== -1)) {
        logger.debug(`removing matched url body ${request.url}:${bodySize / 1000}kB`);
        request.headers['logrocket-truncated'] = true;
        request.body = null;
    } else if (bodySize > MAX_REQUEST_FORWARDING_SIZE) {
        logger.debug(`truncating unmatched url body ${request.url}:${bodySize / 1000}kB`);
        request.headers['logrocket-truncated'] = true;
        request.body = request.body.slice(0, MAX_REQUEST_FORWARDING_SIZE);
    }

    return request;
}

export function getLogRocketURL() {
    const deferred = Q.defer();

    getLogRocket().then(logRocket => {
        logRocket.getSessionURL(sessionURL => {
            deferred.resolve(sessionURL);
        });
    });

    return deferred.promise;
}

let LOG_ROCKET_LOADED = false;
export function getLogRocket() {
    if (!LOG_ROCKET_LOADED) {
        LogRocket.init('folsom-labs/helioscope', {
            release: helioscopeConfig.version,
            network: {
                responseSanitizer: logRocketFilter,
                requestSanitizer: logRocketFilter,
            },
            mergeIframes: true,
        });


        LOG_ROCKET_LOADED = true;
        logger.info(`HelioScope version ${helioscopeConfig.version}`);
    }
    return Promise.resolve(LogRocket);
}

export async function install() {
    if (helioscopeConfig.capture_exceptions === false) {
        installStub();
        return;
    }

    installRaven();
    const consoleHandler = Logger.createDefaultHandler();

    Logger.setHandler((messages, context) => {
        consoleHandler(messages, context);

        // forward to Raven
        if (context.level.value >= Logger.ERROR.value) {
            const [message, ...rest] = messages;
            captureMessage(message, {
                logger: context.name,
                level: context.level.name,
                extra: rest,
            });
        }
    });

    if (helioscopeConfig.log_rocket) {
        requestHeaders['Log-Rocket-URL'] = await getLogRocketURL();
    }
}

// install to ng's $http handler.
// todo: remove me when we switch everything to fetchJSON()
export async function installAngularHooks() {
    if (helioscopeConfig.log_rocket) {
        $http.defaults.headers.common['Log-Rocket-URL'] = await getLogRocketURL();
    }
}

async function installRaven() {
    Raven.config(helioscopeConfig.PUBLIC_API_KEYS.SENTRY_JS,
        {
            release: helioscopeConfig.version,
            environment: helioscopeConfig.sentry_env,
            ignoreErrors: [
                'querySelectorAll',
            ],
        })
        .addPlugin(angularPlugin, angular)
        .install();

    if (helioscopeConfig.log_rocket) {
        const logRocketURL = await getLogRocketURL();
        Raven.setDataCallback(data => {
            data.extra.sessionURL = logRocketURL;
            return data;
        });
    }
}

export async function identify(user) {
    const params = {
        email: user.email,
        id: user.user_id,
    };

    if (helioscopeConfig.capture_exceptions === false) {
        console.log('identify ' + JSON.stringify(params)); // eslint-disable-line
        return;
    }

    Raven.setUserContext(params);

    if (helioscopeConfig.log_rocket) {
        const logRocket = await getLogRocket();
        logRocket.identify(user.user_id, {
            name: user.full_name,
            email: user.email,
        });
    }
}

export function captureMessage(message, data = {}) {
    Raven.captureMessage(message, data);
}

export function captureException(exc, opts = {}) {
    if (helioscopeConfig.capture_exceptions === true) {
        Raven.captureException(exc, opts);
    }
}

function installStub() {
    angular.module('ngRaven', []);
}
