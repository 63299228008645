import Logger from 'js-logger';

import { moduleCache } from './caching';
import { Module, RackingStructure, RackingFrame } from './racking';
import {
    createRowBounds,
    DEFAULT_CHARACTERIZATION,
    enforceMaxSize,
    pointsFindMinYMaxY,
    RACKING_EPSILON,
    rackingSpaceTransforms,
    removeModulesInLocation,
    removeModulesInPath,
    transformRacking,
} from './common';
import { FieldSegment } from '../FieldSegment';

import {
    differencePaths,
    findRectangles,
    Matrix,
    Vector,
} from 'helioscope/app/utilities/geometry';
import { flatten, getValueOrDefault } from 'helioscope/app/utilities/helpers';
import * as statsd from 'helioscope/app/utilities/statsd';

const logger = Logger.get('racking');


/*

  racking space

  up = +Y
  right = +X

  top left
  +----------------+
  |                |
  +----------------+

  +----------------+
  |                |
  +----------------+

  +----------------+
  |                |
  +----------------+  Y = 0

*/

// useful abstraction as simple planar field segments will be replaced
// by multi polygonal complex geometry objects eventually
// layout will still be done on regions on planar faces on a per region basis
export class LayoutRegion {
    constructor(paths, surface, params) {
        this.paths = paths;
        this.surface = surface;

        this.updateParameters(params);
    }

    updateParameters(params) {
        this.params = {};

        this.params.racking = params.racking;

        this.params.layout_start = params.layout_start;
        this.params.module_tilt = getValueOrDefault(params, 'tilt', FieldSegment.DefaultPropertyValues);
        this.params.racking_rotation = getValueOrDefault(params, 'rotation', FieldSegment.DefaultPropertyValues);
        this.params.orientation = getValueOrDefault(params, 'orientation', FieldSegment.DefaultPropertyValues);
        this.params.alignment = getValueOrDefault(params, 'alignment', FieldSegment.DefaultPropertyValues);
        this.params.row_spacing = getValueOrDefault(params, 'row_spacing', FieldSegment.DefaultPropertyValues);
        this.params.module_spacing = getValueOrDefault(params, 'module_spacing', FieldSegment.DefaultPropertyValues);

        // frame spacing defaults to module spacing when undefined or 0
        this.params.frame_spacing = params.frame_spacing || this.params.module_spacing;

        this.params.dome_spacing = getValueOrDefault(params, 'dome_spacing', FieldSegment.DefaultPropertyValues);

        // 0 is not a valid value for bank_depth or bank_width
        this.params.bank_depth = params.bank_depth || FieldSegment.getDefaultValue('bank_depth');
        this.params.bank_width = params.bank_width || FieldSegment.getDefaultValue('bank_width');

        this.params.module_characterization = params.module_characterization;
        this.params.reference_height = getValueOrDefault(params, 'reference_height', FieldSegment.DefaultPropertyValues);
    }

    get module_characterization() {
        return this.params.module_characterization || DEFAULT_CHARACTERIZATION;
    }
}

/*

  layout info for rects

                      leftToRight ->
                  +-----------------------+
                  |\                       \
               ^  | \   \ topToBot          \
  surfaceToTop |  |  \   V                   \
                  |   \                       \
                  +----+-----------------------+
      surfaceToBot ->

*/

function rackSpaceRectInfo(rect, surface, params) {
    const surfaceTiltRot = Matrix.rotateX(surface.surfaceTilt());
    const rackSurfaceAxis = surfaceTiltRot.transform(new Vector(0, 0, 1));
    const rackSurfaceRot = Matrix.rotateAxis(-params.racking_rotation, rackSurfaceAxis);
    const rackSpaceRot = Matrix.rotateZ(params.racking_rotation);
    const tform = surfaceTiltRot.transform(rackSurfaceRot).transform(rackSpaceRot); // so weird

    const rawRackX = tform.transform(new Vector(1, 0, 0));
    const surfaceY = tform.transform(new Vector(0, 1, 0));

    const rackX = rawRackX.scale(1, 0, 1).normalize();
    const rackY = new Vector(0, 1, 0);
    const rackZ = rackX.cross(rackY);

    const modT = Matrix.rotateX(params.module_tilt);
    const sideT = modT.transform(new Vector(0, rect.y, 0));

    const leftToRight = rackX.scale(rect.x);
    const topToBot = rackY.scale(-sideT.y).add(rackZ.scale(-sideT.z));

    const topToBotProj = rackY.dot(topToBot);
    const surfaceYProj = rackY.dot(surfaceY);
    const surfaceToBot = surfaceY.scale(topToBotProj / surfaceYProj);
    const surfaceToTop = surfaceToBot.subtract(topToBot);
    const rotateAxis = rackZ;

    // TODO: MT: redo racking space transforms
    // these transforms are a hack around the fact that Y in racking space is always flush with the ground
    // and the fact that layouts are done based on ground projected rectangles.
    // what we really want is to arbitrary racking space transforms but
    // to do that we need to look at all the things that use the current transforms.
    const groundExt = new Vector(
        leftToRight.scale(1, 1, 0).length(), surfaceToBot.scale(1, 1, 0).length());

    return { groundExt, leftToRight, topToBot, surfaceToBot, surfaceToTop, rotateAxis };
}

class FrameLayoutBase {
    constructor(surface, params) {
        this.surface = surface;
        this.params = params;
        this.offsetCache = {};
    }

    moduleVector2D() {
        if (!this.cachedModVector2D) {
            this.cachedModVector2D = Vector.fromObject((this.params.module_characterization ||
                DEFAULT_CHARACTERIZATION).vector);

            if (this.params.orientation === 'vertical') {
                this.cachedModVector2D = new Vector(this.cachedModVector2D.y, this.cachedModVector2D.x, 0);
            }
        }

        return this.cachedModVector2D;
    }

    frameVector2D() {
        if (!this.cachedFrameVector2D) {
            const { params } = this;
            const modVector = this.moduleVector2D();

            const modSpacing = params.module_spacing;
            this.cachedFrameVector2D = new Vector(
                (modVector.x + modSpacing) * params.bank_width - modSpacing,
                (modVector.y + modSpacing) * params.bank_depth - modSpacing,
                0);
        }

        return this.cachedFrameVector2D;
    }

    frameSpacing() {
        return this.params.frame_spacing || this.params.module_spacing || 0;
    }

    moduleRackSpaceInfo() {
        if (!this.cachedModInfo) {
            const { params, surface } = this;
            const modVector = this.moduleVector2D();
            this.cachedModInfo = rackSpaceRectInfo(modVector, surface, params);

            const spacingVector = new Vector(params.module_spacing, params.module_spacing);
            const spacedModVector = modVector.add(spacingVector);
            const spacedInfo = rackSpaceRectInfo(spacedModVector, this.surface, this.params);

            this.cachedModInfo.spacedGroundExt = spacedInfo.groundExt;
            this.cachedModInfo.spacedTopToBot = spacedInfo.topToBot;
            this.cachedModInfo.spacedLeftToRight = spacedInfo.leftToRight;
            this.cachedModInfo.spacedSurfaceToBot = spacedInfo.surfaceToBot;
            this.cachedModInfo.spacedSurfaceToTop = spacedInfo.surfaceToTop;
        }

        return this.cachedModInfo;
    }

    frameRackSpaceInfo() {
        if (!this.cachedFrameInfo) {
            const frameVector = this.frameVector2D();

            this.cachedFrameInfo = rackSpaceRectInfo(frameVector, this.surface, this.params);

            const spacingVector = new Vector(this.frameSpacing(), 0);
            const spacedFrameVector = frameVector.add(spacingVector);
            const spacedInfo = rackSpaceRectInfo(spacedFrameVector, this.surface, this.params);

            this.cachedFrameInfo.spacedGroundExt = spacedInfo.groundExt;
            this.cachedFrameInfo.spacedTopToBot = spacedInfo.topToBot;
            this.cachedFrameInfo.spacedLeftToRight = spacedInfo.leftToRight;

            this.cachedFrameInfo.blockAlignWidth = this.params.frame_spacing ?
                this.cachedFrameInfo.spacedLeftToRight.x : this.moduleRackSpaceInfo().spacedLeftToRight.x;
        }

        return this.cachedFrameInfo;
    }

    createModule(topLeft, rest) {
        const mod = new Module(
            this.surface,
            topLeft.getCopy(),
            rest,
        );

        return mod;
    }

    createFrame(surfaceTopLeft) {
        const { surfaceToTop, topToBot, leftToRight } = this.frameRackSpaceInfo();
        const { spacedTopToBot, spacedLeftToRight } = this.moduleRackSpaceInfo();

        const p1 = surfaceTopLeft.add(surfaceToTop);
        const p2 = p1.add(leftToRight);
        const p3 = p1.add(leftToRight).add(topToBot);
        const p4 = p1.add(topToBot);
        const frame = new RackingFrame([p1, p2, p3, p4]);

        const modules = [];
        for (let i = 0; i < this.params.bank_depth; ++i) {
            for (let j = 0; j < this.params.bank_width; ++j) {
                const modPoint = p1
                    .add(spacedTopToBot.scale(i))
                    .add(spacedLeftToRight.scale(j));
                modules.push(this.createModule(modPoint));
            }
        }

        frame.setModules(modules);
        return frame;
    }

    modulePathOffsets(rotation) {
        // in world space, not rack space
        if (!this.offsetCache[rotation]) {
            const { rotateAxis, topToBot, leftToRight } = this.moduleRackSpaceInfo();
            const { returnMatrix } = rackingSpaceTransforms(this.surface, this.params);

            const rotT = Matrix.rotateAxis(rotation, rotateAxis);
            const topToBotT = rotT.transform(topToBot);
            const leftToRightT = rotT.transform(leftToRight);

            // top left, top right, bottom right, bottom left
            const offsets = [
                new Vector(0, 0, 0),
                leftToRightT,
                leftToRightT.add(topToBotT),
                topToBotT,
            ];

            const offsetsT = returnMatrix.get33().transform(offsets);
            this.offsetCache[rotation] = offsetsT;
        }

        return this.offsetCache[rotation];
    }

    modulePathCreator() {
        // in world space, not rack space
        this.baseOffsets = this.modulePathOffsets(0);

        return ({ topLeft, rotation = 0 } = {}) => {
            if (rotation === 0) {
                const offsetsT = this.baseOffsets;

                return [
                    topLeft.getCopy(),
                    topLeft.add(offsetsT[1]),
                    topLeft.add(offsetsT[2]),
                    topLeft.add(offsetsT[3]),
                ];
            } else {
                const offsetsT = this.modulePathOffsets(rotation);

                return [
                    topLeft.add(offsetsT[0]),
                    topLeft.add(offsetsT[1]),
                    topLeft.add(offsetsT[2]),
                    topLeft.add(offsetsT[3]),
                ];
            }
        };
    }
}

class FrameLayoutDual extends FrameLayoutBase {
    frameVector2D() {
        if (!this.cachedFrameVector2D) {
            const { params } = this;
            const { surfaceToBot, spacedSurfaceToBot } = this.moduleRackSpaceInfo();
            const modVector = this.moduleVector2D();

            const modSpacing = params.module_spacing;
            this.cachedFrameVector2D = new Vector(
                (modVector.x + modSpacing) * params.bank_width - modSpacing,
                spacedSurfaceToBot.length() * (params.bank_depth - 1) * 2 + params.dome_spacing
                + surfaceToBot.length() * 2,
                0);
        }

        return this.cachedFrameVector2D;
    }

    frameRackSpaceInfo() {
        if (!this.cachedFrameInfo) {
            this.moduleRackSpaceInfo();

            // temporary override
            const temp = this.params;
            this.params = _.assign({}, this.params, { module_tilt: 0 });
            super.frameRackSpaceInfo();
            this.params = temp;
        }

        return this.cachedFrameInfo;
    }

    createFrame(surfaceTopLeft) {
        const frameInfo = this.frameRackSpaceInfo();
        const modInfo = this.moduleRackSpaceInfo();

        const { leftToRight, topToBot, surfaceToTop, spacedLeftToRight, spacedTopToBot,
            spacedSurfaceToBot, spacedSurfaceToTop } = modInfo;

        const p1 = surfaceTopLeft;
        const p2 = p1.add(frameInfo.leftToRight);
        const p3 = p1.add(frameInfo.leftToRight).add(frameInfo.surfaceToBot);
        const p4 = p1.add(frameInfo.surfaceToBot);
        const frame = new RackingFrame([p1, p2, p3, p4]);

        const rotBotToTop = this.rotateMatrix().transform(topToBot).scale(-1);

        const westStart = p1.add(leftToRight).add(rotBotToTop);
        const eastStart = p1
            .add(modInfo.spacedSurfaceToBot.scale(this.params.bank_depth - 1))
            .add(modInfo.surfaceToBot)
            .add(modInfo.surfaceToBot.normalize().scale(this.params.dome_spacing))
            .add(spacedSurfaceToTop.scale(this.params.bank_depth - 1))
            .add(surfaceToTop);

        const modules = [];
        for (let i = 0; i < this.params.bank_depth; ++i) {
            for (let j = 0; j < this.params.bank_width; ++j) {
                const westPoint = westStart
                    .add(spacedLeftToRight.scale(j))
                    .add(spacedSurfaceToBot.scale(i))
                    .add(spacedSurfaceToTop.scale(i));
                modules.push(this.createModule(westPoint, { rotation: 180 }));

                const eastPoint = eastStart.add(spacedLeftToRight.scale(j)).add(spacedTopToBot.scale(i));
                modules.push(this.createModule(eastPoint, {}));
            }
        }

        frame.setModules(modules);
        return frame;
    }

    rotateMatrix() {
        if (!this.cachedRotateMatrix) {
            const { surfaceToTop } = this.moduleRackSpaceInfo();
            if (!surfaceToTop.lengthSq()) {
                this.cachedRotateMatrix = Matrix.rotateAxis(180, new Vector(0, 0, 1));
            } else {
                this.cachedRotateMatrix = Matrix.rotateAxis(180, surfaceToTop.normalize());
            }
        }

        return this.cachedRotateMatrix;
    }
}

class FrameLayoutTracker extends FrameLayoutBase {
    createFrame(surfaceTopLeft) {
        const { surfaceToTop, topToBot, leftToRight } = this.frameRackSpaceInfo();
        const { spacedTopToBot, spacedLeftToRight } = this.moduleRackSpaceInfo();

        const eps = 0.001;
        const displaceHeight = eps + topToBot.length() * 0.5;
        const displaceVec = new Vector(0, 0, displaceHeight);

        const p1 = surfaceTopLeft.add(surfaceToTop).add(displaceVec);
        const p2 = p1.add(leftToRight);
        const p3 = p1.add(leftToRight).add(topToBot);
        const p4 = p1.add(topToBot);
        const frame = new RackingFrame([p1, p2, p3, p4]);

        const trackerPoint = p1.add(p4).scale(0.5);

        const modules = [];
        for (let i = 0; i < this.params.bank_depth; ++i) {
            for (let j = 0; j < this.params.bank_width; ++j) {
                const modPoint = p1
                    .add(spacedTopToBot.scale(i))
                    .add(spacedLeftToRight.scale(j));
                modules.push(this.createModule(modPoint, { trackerPoint: trackerPoint.getCopy() }));
            }
        }

        frame.setModules(modules);
        return frame;
    }

    // debug code for seeing tracker transforms in designer
    // modulePathCreator() {
    //     // in world space, not rack space
    //     this.baseOffsets = this.modulePathOffsets(0);

    //     return ({ topLeft, trackerPoint, rotation = 0 } = {}) => {
    //         const offsetsT = this.baseOffsets;

    //         const azdir = this.surface.azimuthDirection(rotation);
    //         const rotaxis = azdir.cross(new Vector(0, 0, 1)).normalize();
    //         const rotmtx = Matrix.rotateAxis(-this.surface.tilt, rotaxis);

    //         const trackerOffsets = [
    //             topLeft.getCopy().subtract(trackerPoint),
    //             topLeft.add(offsetsT[1]).subtract(trackerPoint),
    //             topLeft.add(offsetsT[2]).subtract(trackerPoint),
    //             topLeft.add(offsetsT[3]).subtract(trackerPoint),
    //         ];

    //         const trackerOffsetsT = _.map(trackerOffsets, i => rotmtx.transform(i));
    //         const path = _.map(trackerOffsetsT, i => trackerPoint.add(i));

    //         console.log(rotaxis);
    //         console.log(topLeft);
    //         console.log(trackerPoint);
    //         console.log(path[0]);

    //         return path;
    //     };
    // }
    //
    //
}

class LayoutEngineBase {
    constructor(region) {
        this.region = region;
    }

    @statsd.instrument('generateLayout')
    generateLayout() {
        const { params, surface } = this.region;
        const transforms = this.rackingSpaceTransforms();

        if (!params.module_characterization) {
            logger.warn('No module selected, no racking will be generated');
            return [];
        }

        // manual modules + auto modules
        const manualRacks = (params.racking && params.racking.manual_modules) ?
            this.generateManualRackStructures(params.racking.manual_modules) : [];
        const autoRacks = this.generateAutoRackStructures();

        // remove toggled and overlapped modules
        const removedPts = surface.geometry.removed_module_locations;
        if (removedPts && removedPts.length) {
            removeModulesInLocation({ moduleCache, racking: autoRacks, locations: removedPts, transforms });
        }

        const manualModules = _(manualRacks)
            .map(rack => rack.getModules())
            .flatten()
            .value();

        if (manualModules && manualModules.length) {
            const removePaths = _.map(manualModules, i => i.path);
            removeModulesInPath({ moduleCache, racking: autoRacks, paths: removePaths, transforms });
        }

        const constrainedRacks = enforceMaxSize(surface, autoRacks, manualModules.length);

        const rackStructs = manualRacks.concat(constrainedRacks);

        return rackStructs;
    }

    layoutParams() {
        return this.region.params;
    }

    frameLayout() {
        if (!this.cachedFrameLayout) {
            this.cachedFrameLayout = new FrameLayoutBase(this.region.surface, this.layoutParams());
        }

        return this.cachedFrameLayout;
    }

    manualFrameLayout() {
        return null;
    }

    calculateMaxModules() {
        const { transformMatrix } = this.rackingSpaceTransforms();
        const { params, surface } = this.region;

        const pathsW = surface.geometry.path;
        if (!pathsW || pathsW.length === 0) {
            return 0;
        }
        // set region.paths because that is what's used in calculateRackingRectangles()
        this.region.paths = [pathsW];

        const rackingrects = this.calculateRackingRectangles();

        const { alignment } = this.layoutParams();
        const blockAlignment = (alignment === 'block') ? this.layoutStart : null;

        const frames = _.sumBy(rackingrects, i => this.rackingRowInfo(i, blockAlignment).count);

        return frames * params.bank_depth * params.bank_width;
    }

    generateManualRackStructures(manualOpts) {
        const layoutPaths = this.region.paths;
        const rackStructs = [];

        let k = 0;
        let validRack;

        for (const opt of manualOpts) {
            const frameLayout = this.manualFrameLayout(opt);
            if (!frameLayout) continue;

            const worldPoint = Vector.fromObject(opt.top_left);
            const surfacePoint = this.region.surface.pointOnSurface(worldPoint);
            const startPoint = new Vector(worldPoint.x, worldPoint.y, surfacePoint.z);

            const frames = [frameLayout.createFrame(startPoint)];

            const racking = new RackingStructure(
                [
                    frames[0].path[0].getCopy(), // topLeft
                    frames[0].path[1].getCopy(), // topRight,
                    frames[0].path[2].getCopy(), // bottomRight,
                    frames[0].path[3].getCopy(), // bottomLeft
                ],
                frames);

            const pathCreator = frameLayout.modulePathCreator();
            const modules = racking.getModules({ includeRemoved: true });

            validRack = true;

            for (let j = 0; j < modules.length; ++j) {
                const module = modules[j];
                module.createPath(pathCreator);
                module.manual = true;
                module.manual_idx = k;
                module.manual_orientation = opt.orientation;

                const diff = differencePaths([module.path], layoutPaths);
                if (diff.length) {
                    validRack = false;
                    break;
                }
            }

            if (!validRack) continue;

            ++k;
            rackStructs.push(racking);
        }

        return rackStructs;
    }

    generateAutoRackStructures() {
        const rackingrects = this.calculateRackingRectangles();

        const { alignment } = this.layoutParams();
        const blockAlignment = (alignment === 'block') ? this.layoutStart : null;

        const rackStructs =
            _(rackingrects)
                .map(rect => this.createRackingStructure(rect, blockAlignment))
                .compact()
                .value();

        const { returnMatrix } = this.rackingSpaceTransforms();
        transformRacking(rackStructs, returnMatrix);

        const pathCreator = this.frameLayout().modulePathCreator();
        for (let i = 0; i < rackStructs.length; i++) {
            const modules = rackStructs[i].getModules({ includeRemoved: true });
            for (let j = 0; j < modules.length; j++) {
                const module = modules[j];
                module.createPath(pathCreator);
            }
        }

        return rackStructs;
    }

    rackingRowInfo(rect, blockAlignment = null) {
        const { groundExt, spacedGroundExt, spacedLeftToRight, blockAlignWidth } =
            this.frameLayout().frameRackSpaceInfo();

        if (blockAlignment) {
            const blockDelta = rect.topLeft.x - blockAlignment.x;
            const alignedDelta = Math.ceil(blockDelta / blockAlignWidth) * blockAlignWidth;
            rect.topLeft.x = blockAlignment.x + alignedDelta;
        }

        const groundSpacing = spacedGroundExt.x - groundExt.x;
        const maxWidth = Math.max(0, rect.bottomRight.x - rect.topLeft.x + groundSpacing);
        const count = Math.floor(maxWidth / spacedGroundExt.x);

        const actualWidth = count * spacedGroundExt.x - groundSpacing;
        const alignGap = maxWidth - actualWidth - groundSpacing - RACKING_EPSILON;
        const { alignment } = this.layoutParams();

        let startAdj = new Vector(0, 0, 0);
        if (alignment === 'right') {
            startAdj = spacedLeftToRight.normalize().scale(alignGap).getCopy2();
        } else if (alignment === 'center') {
            startAdj = spacedLeftToRight.normalize().scale(alignGap * 0.5).getCopy2();
        }

        const rectStart = rect.topLeft;
        const alignedStart = rectStart.add(startAdj);

        return { alignedStart, count };
    }

    createRackingStructure(rect, blockAlignment = null) {
        const { alignedStart, count } = this.rackingRowInfo(rect, blockAlignment);

        const { returnMatrix } = this.rackingSpaceTransforms();
        const worldPoint = returnMatrix.transform(alignedStart);
        const surfacePoint = this.region.surface.pointOnSurface(worldPoint);
        const startPoint = new Vector(alignedStart.x, alignedStart.y, surfacePoint.z);

        const { spacedLeftToRight } = this.frameLayout().frameRackSpaceInfo();

        const frames = [];
        for (let i = 0; i < count; ++i) {
            const framePoint = startPoint.add(spacedLeftToRight.scale(i));
            frames.push(this.frameLayout().createFrame(framePoint));
        }

        if (!count) return null;

        const racking = new RackingStructure(
            [
                frames[0].path[0].getCopy(),         // topLeft
                frames[count - 1].path[1].getCopy(), // topRight,
                frames[count - 1].path[2].getCopy(), // bottomRight,
                frames[0].path[3].getCopy(),         // bottomLeft
            ],
            frames);

        return racking;
    }

    rackingSpaceTransforms() {
        if (!this.cachedTransforms) {
            this.cachedTransforms = rackingSpaceTransforms(this.region.surface, this.layoutParams());
        }

        return this.cachedTransforms;
    }

    moduleVector() {
        const { groundExt, surfaceToTop } = this.frameLayout().moduleRackSpaceInfo();
        return new Vector(groundExt.x, groundExt.y, surfaceToTop.length());
    }

    frameVector() {
        const { groundExt, surfaceToTop } = this.frameLayout().frameRackSpaceInfo();
        return new Vector(groundExt.x, groundExt.y, surfaceToTop.length());
    }

    modulePathCreator() {
        return this.frameLayout().modulePathCreator();
    }

    optimizeStartPoint(pathsT) {
        const iterCount = 10;
        const { groundExt } = this.frameLayout().frameRackSpaceInfo();
        const rowSpacing = this.region.params.row_spacing;

        const points = flatten(pathsT);
        const { maxY } = pointsFindMinYMaxY(points);
        let currY = maxY - RACKING_EPSILON;
        let topLeft = null;
        let maxFrameCount = 0;
        const resolution = (groundExt.y + rowSpacing) / iterCount;
        while (maxY - currY < (groundExt.y + rowSpacing)) {
            const bounds = createRowBounds(pathsT, currY, groundExt.y, rowSpacing);
            const rectangles = findRectangles(pathsT, bounds);

            const frameCount = _.sumBy(rectangles, rect => this.rackingRowInfo(rect).count);

            if (frameCount > maxFrameCount) {
                topLeft = rectangles[0].topLeft;
                maxFrameCount = frameCount;
            }

            currY -= resolution;
        }

        if (topLeft) {
            topLeft.x += RACKING_EPSILON;
            return topLeft;
        }

        logger.warn('Could not fit a rack into field segment');
        return points.find(pt => pt.y === maxY);
    }

    getLayoutStart(pathsT) {
        const { surface, params } = this.region;
        const { transformMatrix, returnMatrix } = this.rackingSpaceTransforms();

        const layoutStart = params.layout_start || surface.geometry.layout_start;

        if (layoutStart) {
            const point = layoutStart.transform(transformMatrix);
            const maxY = _(pathsT).flatten()
                .map('y')
                .max();

            const { groundExt } = this.frameLayout().frameRackSpaceInfo();
            const totalYGap = groundExt.y + params.row_spacing;
            point.y = maxY - (maxY - point.y) % totalYGap;

            return point;
        } else {
            const point = this.optimizeStartPoint(pathsT);
            surface.geometry.layout_start = point.transform(returnMatrix);
            return point;
        }
    }

    calculateRackingRectangles() {
        const { transformMatrix } = this.rackingSpaceTransforms();

        const region = this.region;
        const { params } = region;

        let pathsT = transformMatrix.transform(region.paths);
        if (pathsT.length === 0) {
            pathsT = [[new Vector(0, 0, 0), new Vector(0, 0, 0), new Vector(0, 0, 0)]];
        }
        const rowSpacing = params.row_spacing;
        const { groundExt } = this.frameLayout().frameRackSpaceInfo();

        this.layoutStart = this.getLayoutStart(pathsT);

        const bounds = createRowBounds(pathsT, this.layoutStart.y, groundExt.y, rowSpacing);
        const rectangles = findRectangles(pathsT, bounds);

        return rectangles;
    }

    findModuleIntersections(racking, location, args) {
        const found = moduleCache.getRackCache(racking, this.rackingSpaceTransforms()).findByPoints([location], args);
        if (!found.length) return null;

        const sorted = _.sortBy(found, i => (i.module.removed ? 1 : 0));
        const first = _.first(sorted);
        return { ...first, removed: !!first.module.removed };
    }

    createFakeStructure(path, modules) {
        const fakeFrame = new RackingFrame(path, modules);
        return new RackingStructure(path, [fakeFrame]);
    }
}

export class FixedTiltEngineV2 extends LayoutEngineBase {
}

export class NewFlushMountEngine extends LayoutEngineBase {
    layoutParams() {
        if (!this.cachedParams) {
            const { params } = this.region;

            // hack until we can get proper racking space transforms
            const override = (params.racking_rotation === 90) ? { module_tilt: 15 } : {};
            this.cachedParams = _.assign({}, params, override);
        }

        return this.cachedParams;
    }

    manualFrameLayout(opt) {
        if (!this.cachedManualLayout) {
            this.cachedManualLayout = {};
        }

        const orientation = opt.orientation || this.layoutParams().orientation;
        const cachekey = `${opt.rotation}_${orientation}`;
        if (!this.cachedManualLayout[cachekey]) {
            this.cachedManualLayout[cachekey] = new FrameLayoutBase(
                this.region.surface,
                _.assign({}, this.layoutParams(),
                    {
                        bank_width: 1,
                        bank_depth: 1,
                        frame_spacing: 0,
                        module_spacing: 0,
                        racking_rotation: opt.rotation,
                        orientation,
                    },
                    (opt.rotation === 0) ? {} : { module_tilt: 0 },
                ));
        }

        return this.cachedManualLayout[cachekey];
    }
}

export class NewDualTiltEngine extends LayoutEngineBase {
    layoutParams() {
        if (!this.cachedParams) {
            const { params } = this.region;
            this.cachedParams = _.assign(
                {}, params,
                {
                    racking_rotation: params.racking_rotation - 90,
                });
        }

        return this.cachedParams;
    }

    frameLayout() {
        if (!this.cachedFrameLayout) {
            this.cachedFrameLayout = new FrameLayoutDual(this.region.surface, this.layoutParams());
        }

        return this.cachedFrameLayout;
    }

    calculateMaxModules() {
        const moduleCount = super.calculateMaxModules();

        // multiply by 2 since the modules in each frame are mirrored to face east and west
        return moduleCount * 2;
    }
}

export class NewSingleAxisTrackerEngine extends LayoutEngineBase {
    generateAutoRackStructures() {
        const rackStructs = super.generateAutoRackStructures();

        for (const rackStruct of rackStructs) {
            rackStruct.tracker_pivot = this._generateTrackerPivot(rackStruct.path);
        }

        return rackStructs;
    }


    createFakeStructure(path, modules) {
        const rackStruct = super.createFakeStructure(path, modules);
        rackStruct.tracker_pivot = this._generateTrackerPivot(path);

        return rackStruct
    }

    layoutParams() {
        if (!this.cachedParams) {
            const { params } = this.region;

            const override = {
                module_tilt: 0,

                // for trackers the azimuth should be north/south axis aligned
                racking_rotation: params.racking_rotation - 90,
            };

            this.cachedParams = _.assign({}, params, override);
        }

        return this.cachedParams;
    }

    frameLayout() {
        if (!this.cachedFrameLayout) {
            this.cachedFrameLayout = new FrameLayoutTracker(this.region.surface, this.layoutParams());
        }

        return this.cachedFrameLayout;
    }

    _generateTrackerPivot(path) {
        return path[0].add(path[2]).scale(0.5);
    }
}
