export const MapConfig = {
    mapDiv: 'map_canvas',

    fieldSegment: {
        baseZIndex: 0,
        base: {
            fillColor: '#0000FF',
            strokeColor: '#0000FF',
            opacity: 0.35,
            strokeWeight: 2,
            editable: false,
            draggable: false,
        },
        parentOverride: {
            fillColor: '#00FFDD',
        },
        active: {
            fillColor: '#EEEE33',
            strokeColor: '#EEEE33',
            editable: true,
            draggable: false,
        },
        hover: {
            fillColor: 'gold',
            strokeColor: 'gold',
        },
        draggable: {
            draggable: true,
            editable: false,
            fillColor: 'darkorange',
        },
        dragEnd: {
            fillColor: '#EEEE33',
            strokeColor: '#EEEE33',
            editable: true,
            draggable: false,
        },
        selectedDraggable: {
            fillColor: '#EEEE33',
            strokeColor: '#EEEE33',
            editable: false,
            draggable: true,
        },
    },

    module: {
        base: {
            strokeColor: '#222255',
            fillColor: '#4444BB',
            zIndex: 10003,
            clickable: false,
            strokeWeight: 1,
            fillOpacity: 0.70,
            strokeOpacity: 0.5,
        },
        racking: {
            strokeWeight: 1.4,
            strokeColor: '#111122',
            zIndex: 10001,
            fillOpacity: 0.2,
            strokeOpacity: 0.75,
            clickable: false,
            editable: false,
        },
        frames: {
            fillOpacity: 0.4,
            strokeColor: '#111144',
            zIndex: 10002,
            strokeWeight: 1.3,
            strokeOpacity: 0.85,
        },
        active: {
            strokeColor: '#9999FF',
            fillColor: 'gold',
        },
        wired: {
            fillColor: '#4444FF',
            fillOpacity: 0.90,
        },
    },
    keepout: {
        baseZIndex: 100,
        base: {
            fillColor: 'darkorange',
            strokeColor: 'darkorange',
            opacity: 0.75,
            strokeWeight: 2,
            draggable: true,
            editable: false,
        },
        hover: {
            fillColor: 'gold',
            strokeColor: 'gold',
        },
        active: {
            fillColor: 'gold',
            strokeColor: 'gold',
            draggable: true,
            editable: true,
        },
        selectedDraggable: {
            fillColor: 'gold',
            strokeColor: 'gold',
            draggable: true,
            editable: false,
        },
    },
    premade: {
        baseZIndex: 100,
        base: {
            fillColor: '#22cc22',
            strokeColor: 'darkgreen',
            opacity: 0.75,
            strokeWeight: 2,
            draggable: false,
            editable: false,
        },
        active: {
            fillColor: '#eeff44',
            editable: true,
        },
        selectedDraggable: {
            fillColor: '#eeff44',
            editable: false,
        },
    },
    overlay: {
        base: {
            editable: false,
            renderOnTop: false,
        },
        active: {
            editable: true,
            renderOnTop: true,
        },
        selectedDraggable: {
            editable: false,
            renderOnTop: false,
        },
    },
    shadow: {
        fillColor: 'black',
        strokeColor: 'black',
        opacity: 0.35,
        editable: false,
        clickable: false,
        strokeWeight: 0,
    },
    setback: {
        opacity: 0.25,
        fillColor: 'darkorange',
        strokeWeight: 0,
        editable: false,
        clickable: false,
    },
    string: {
        strokeColor: '#00AA00',
        strokeWeight: 0.6,
        zIndex: 10006,
        strokeOpacity: 1.0,
        editable: false,
        clickable: false,
    },
    bus: {
        strokeColor: '#22BB22',
        strokeWeight: 2,
        zIndex: 10006,
        strokeOpacity: 1.0,
        editable: false,
        clickable: false,
    },
    acRun: {
        strokeColor: '#CC6600',
        strokeWeight: 3,
        zIndex: 10007,
        strokeOpacity: 1.0,
        editable: false,
        clickable: false,
    },
    acBranch: {
        strokeColor: '#CC6600',
        strokeWeight: 0.6,
        zIndex: 10007,
        strokeOpacity: 1.0,
        editable: false,
        clickable: false,
    },

    inverter: {
        title: 'Inverter',
        icon: {
            url: require('helioscope/app/designer/static/inverter-icon.png'),
            size: [30, 30], // new google.maps.Size(30, 30),
            origin: [0, 0], // new google.maps.Point(0, 0),
            anchor: [25, 25], // new google.maps.Point(25, 25),
            scaledSize: [30, 30], // new google.maps.Size(30, 30),
        },
        zIndex: 10007,
        draggable: true,
    },

    combiner: {
        title: 'Combiner',
        icon: {
            url: require('helioscope/app/designer/static/combiner-icon.jpeg'),
            size: [10, 13],
            origin: [0, 0],
            anchor: [5, 6],
            scaledSize: [10, 13],
        },
        draggable: true,
        zIndex: 10008,
    },
    interconnect: {
        title: 'Interconnect',
        draggable: true,
        zIndex: 10010,
    },
    acPanel: {
        title: 'AC Panel',
        icon: {
            url: require('helioscope/app/designer/static/panel-icon.jpeg'),
            size: [10, 13],
            origin: [0, 0],
            anchor: [5, 6],
            scaledSize: [10, 13],
        },
        draggable: true,
        zIndex: 10009,
    },

};
