/* global angular:true, _:true */

angular.module('helioscope.admin', ['helioscope.admin.config',
                                    'helioscope.admin.directives',
                                    'helioscope.admin.extensions',
                                    'helioscope.admin.webinars',
                                    ]);

const mod = angular.module('helioscope.admin.config', []);

mod.config(['$stateProvider', function ($stateProvider) {
    $stateProvider
        .state('admin', {
            url: '/admin',
            templateUrl: require('helioscope/app/admin/partials/index.html'),
            abstract: true,
            resolve: {
                user: ['Authenticator', 'auth.accessLevels', function (A, al) { return A.authorize(al.admin); }],
            },
        })
        .state('admin.extensions', {
            url: '/extensions',
            templateUrl: require('helioscope/app/admin/partials/extensions.index.html'),
        });
}]);
