import _ from 'lodash';
import * as THREE from 'three';

import {
    Bounds,
    differencePaths,
    intersectPathsMulti,
    Vector,
} from 'helioscope/app/utilities/geometry';
import { KEY } from 'helioscope/app/utilities/helpers';
import {
    moduleCache,
    getToggleModuleDeltaMulti,
} from 'helioscope/app/designer/field_segment';
import { RendererOptions } from 'helioscope/app/apollo/RendererOptions';

import { makeWireGeometry, pathToPolygonPoints } from './GLHelpers';
import { PrimitiveMeshStroke } from './Primitives';
import {
    containerPoint,
    interactGroundPoint,
    interactRay,
    panThreshold,
    snapDistance,
    SurfaceCursorHelper,
    zoomView,
} from './InteractHelpers';
import { DragActionCameraPan, DragActionCameraRotate } from './DragCameraActions';

export function getOverlappedModules(fs, newModules, options) {
    const manualModules = [];
    const autoModules = [];

    const transforms = fs.layoutEngine().rackingSpaceTransforms();
    const moduleLookup = moduleCache.getRackCache(fs.getRacks(), transforms);
    const rackStructs = fs.layoutEngine().generateManualRackStructures(newModules);
    for (const rack of rackStructs) {
        for (const module of rack.getModules()) {
            const results = moduleLookup.findByBox(module.path[0], module.path[2], options);
            for (const i of results) {
                if (i.module.manual) manualModules.push(i.module);
                else autoModules.push(i.module);
            }
        }
    }

    return { manualModules, autoModules };
}

export function applyRemoveModules(dispatcher, surface, modules) {
    const manualDeletes = [];
    const toggleLocations = [];

    for (const module of modules) {
        if (module.manual) manualDeletes.push(module);
        else toggleLocations.push(moduleCenter(module));
    }

    const changes = [];

    // delete manual modules
    if (manualDeletes.length) {
        const oldModules = manualModuleProperty(surface);
        const cpyModules = _.map(oldModules, i => _.assign({}, i));

        for (const i of manualDeletes) cpyModules[i.manual_idx] = null;
        const newModules = _.compact(cpyModules);

        changes.push({
            path: 'racking.manual_modules',
            oldVal: oldModules,
            newVal: newModules,
        });
    }

    // toggle off auto modules
    if (toggleLocations.length) {
        const { oldVal, newVal } = getToggleModuleDeltaMulti(surface, toggleLocations);

        changes.push({
            path: 'geometry.removed_module_locations',
            oldVal,
            newVal,
        });
    }

    if (changes.length) {
        dispatcher.createMultiPropertyChange({
            resource: surface,
            changes,
            mergeable: false,
            loadMessage: 'Delete modules',
            rollbackMessage: 'Restore deleted modules',
        });
    }
}

function moduleCenter(module) {
    return module.path[0].add(module.path[2]).scale(0.5);
}

function projectThreeVector2(vec, dir) {
    const norm = (new THREE.Vector2()).copy(dir).normalize();
    const dot = norm.dot(vec);
    return norm.multiplyScalar(dot);
}

function manualModuleProperty(fs) {
    if (fs.racking && fs.racking.manual_modules) return fs.racking.manual_modules;
    return [];
}


class SurfaceSnapHelper {
    constructor(options) {
        this.snapThreshold = options.snapThreshold;
        this.snapThresholdSq = this.snapThreshold * this.snapThreshold;

        this.clearData();
    }

    clearData() {
        this.sources = [];
        this.targets = [];
    }

    registerSourcePoint(point, filter = null) {
        this.sources.push({ point, filter });
    }

    registerSourceSegmentX(pos, start, end, filter = null) {
        this.sources.push({ segmentX: pos, start, end, filter });
    }

    registerSourceSegmentY(pos, start, end, filter = null) {
        this.sources.push({ segmentY: pos, start, end, filter });
    }

    registerTargetPoint(point, basePoint, type = null) {
        this.targets.push({ point, basePoint, type });
    }

    registerTargetSegmentX(pos, start, end, basePos, baseStart, baseEnd, type = null) {
        this.targets.push({ segmentX: pos, start, end, basePos, baseStart, baseEnd, type });
    }

    registerTargetSegmentY(pos, start, end, basePos, baseStart, baseEnd, type = null) {
        this.targets.push({ segmentY: pos, start, end, basePos, baseStart, baseEnd, type });
    }

    axisSegmentPointOverlap(seg1, seg2) {
        if ((seg1.start < seg2.start && seg1.end < seg2.start) ||
            (seg1.start > seg2.end && seg1.end > seg2.end)) {
            return false;
        }

        return true;
    }

    computeSnap() {
        let minDistSq = Number.POSITIVE_INFINITY;
        let minSource = null;
        let minTarget = null;

        const updateSnap = (distsq, src, tgt) => {
            if (distsq < this.snapThresholdSq && distsq < minDistSq) {
                minDistSq = distsq;
                minSource = src;
                minTarget = tgt;
            }
        };

        const pointsSrc = _.filter(this.sources, i => i.point);
        const pointsTgt = _.filter(this.targets, i => i.point);

        for (const src of pointsSrc) {
            for (const tgt of pointsTgt) {
                if (src.filter && !_.includes(src.filter, tgt.type)) continue;
                const distsq = src.point.subtract(tgt.point).lengthSq();
                updateSnap(distsq, src, tgt);
            }
        }

        if (!minTarget) {
            const segmentsXSrc = _.filter(this.sources, i => i.segmentX);
            const segmentsXTgt = _.filter(this.targets, i => i.segmentX);

            for (const src of segmentsXSrc) {
                for (const tgt of segmentsXTgt) {
                    if (src.filter && !_.includes(src.filter, tgt.type)) continue;
                    if (!this.axisSegmentPointOverlap(src, tgt)) continue;
                    const dist = src.segmentX - tgt.segmentX;
                    const distsq = dist * dist;
                    updateSnap(distsq, src, tgt);
                }
            }

            const segmentsYSrc = _.filter(this.sources, i => i.segmentY);
            const segmentsYTgt = _.filter(this.targets, i => i.segmentY);

            for (const src of segmentsYSrc) {
                for (const tgt of segmentsYTgt) {
                    if (src.filter && !_.includes(src.filter, tgt.type)) continue;
                    if (!this.axisSegmentPointOverlap(src, tgt)) continue;
                    const dist = src.segmentY - tgt.segmentY;
                    const distsq = dist * dist;
                    updateSnap(distsq, src, tgt);
                }
            }
        }

        this.minSource = minSource;
        this.minTarget = minTarget;
    }
}

//  target snap types
//
//     minPoint
//
//     1   2      3   4
//     +---+------+---+
//     |   |  E1  |   |
//  12 +---+------+---+ 5
//     |   |      |   |
//     | E4|      |E2 |
//     |   |      |   |
//  11 +---+------+---+ 6
//     |   |  E3  |   |
//     +---+------+---+
//     10  9      8   7
//
//             maxPoint
//

const SnapTypes = {
    POINT_1: Symbol('POINT_1'),
    POINT_2: Symbol('POINT_2'),
    POINT_3: Symbol('POINT_3'),
    POINT_4: Symbol('POINT_4'),
    POINT_5: Symbol('POINT_5'),
    POINT_6: Symbol('POINT_6'),
    POINT_7: Symbol('POINT_7'),
    POINT_8: Symbol('POINT_8'),
    POINT_9: Symbol('POINT_9'),
    POINT_10: Symbol('POINT_10'),
    POINT_11: Symbol('POINT_11'),
    POINT_12: Symbol('POINT_12'),
    EDGE_1: Symbol('EDGE_1'),
    EDGE_2: Symbol('EDGE_2'),
    EDGE_3: Symbol('EDGE_3'),
    EDGE_4: Symbol('EDGE_4'),
};

// source snap filters
//
//   minPoint
//
//   P1  E1  P2
//   +--------+
//   |        |
// E4|        |E2
//   |        |
//   +--------+
//   P4  E3  P3
//
//     maxPoint
//

const SnapFilters = {
    POINT_1: [SnapTypes.POINT_5, SnapTypes.POINT_7, SnapTypes.POINT_9],
    POINT_2: [SnapTypes.POINT_8, SnapTypes.POINT_10, SnapTypes.POINT_12],
    POINT_3: [SnapTypes.POINT_11, SnapTypes.POINT_1, SnapTypes.POINT_3],
    POINT_4: [SnapTypes.POINT_2, SnapTypes.POINT_4, SnapTypes.POINT_6],
    EDGE_1: [SnapTypes.EDGE_3],
    EDGE_2: [SnapTypes.EDGE_4],
    EDGE_3: [SnapTypes.EDGE_1],
    EDGE_4: [SnapTypes.EDGE_2],
};

class ModuleSnapHelper extends SurfaceSnapHelper {
    computeSnap() {
        super.computeSnap();

        this.snapDelta = null;

        const { minTarget, minSource } = this;

        if (!minTarget) return;

        const { returnMatrix } = this.transforms;

        if (minTarget.point) {
            this.snapGuideSegments = [
                returnMatrix.transform(minTarget.point),
                returnMatrix.transform(minTarget.basePoint),
            ];

            const snapDelta = this.minTarget.point.subtract(minSource.point);
            this.snapDelta = this.moveDelta.add(returnMatrix.get33().transform(snapDelta));
        } else if (this.minTarget.segmentX) {
            const snapDelta = new Vector(minTarget.segmentX - minSource.segmentX, 0);
            this.snapDelta = this.moveDelta.add(returnMatrix.get33().transform(snapDelta));

            this.snapGuideSegments = [
                returnMatrix.transform(new Vector(minTarget.segmentX, minTarget.start)),
                returnMatrix.transform(new Vector(minTarget.segmentX, minTarget.end)),
                returnMatrix.transform(new Vector(minTarget.basePos, minTarget.baseStart)),
                returnMatrix.transform(new Vector(minTarget.basePos, minTarget.baseEnd)),
            ];
        } else if (this.minTarget.segmentY) {
            const snapDelta = new Vector(0, minTarget.segmentY - minSource.segmentY);
            this.snapDelta = this.moveDelta.add(returnMatrix.get33().transform(snapDelta));

            this.snapGuideSegments = [
                returnMatrix.transform(new Vector(minTarget.start, minTarget.segmentY)),
                returnMatrix.transform(new Vector(minTarget.end, minTarget.segmentY)),
                returnMatrix.transform(new Vector(minTarget.baseStart, minTarget.basePos)),
                returnMatrix.transform(new Vector(minTarget.baseEnd, minTarget.basePos)),
            ];
        }
    }

    registerPointSnapInputs(surface, point) {
        this.clearData();

        this.surface = surface;
        this.moveDelta = new Vector(0, 0);

        this.registerBasicSnapInfo();

        const { transformMatrix } = this.transforms;

        const rackSpacePoint = transformMatrix.transform(new Vector(point.x, point.y));
        const rackSpaceBounds = new Bounds([rackSpacePoint]);

        this.registerSourcePoint(rackSpacePoint, SnapFilters.POINT_1);
        this.registerSourcePoint(rackSpacePoint, SnapFilters.POINT_2);
        this.registerSourcePoint(rackSpacePoint, SnapFilters.POINT_3);
        this.registerSourcePoint(rackSpacePoint, SnapFilters.POINT_4);

        this.registerSourceSegmentX(rackSpacePoint.x, rackSpacePoint.y, rackSpacePoint.y, SnapFilters.EDGE_4);
        this.registerSourceSegmentX(rackSpacePoint.x, rackSpacePoint.y, rackSpacePoint.y, SnapFilters.EDGE_2);
        this.registerSourceSegmentY(rackSpacePoint.y, rackSpacePoint.x, rackSpacePoint.x, SnapFilters.EDGE_1);
        this.registerSourceSegmentY(rackSpacePoint.y, rackSpacePoint.x, rackSpacePoint.x, SnapFilters.EDGE_3);

        this.registerSnapTargetsInBounds(rackSpaceBounds);
    }

    registerModuleSnapInputs(surface, sourceModules, moveDelta = new Vector(0, 0, 0), excludeSourceModules = true) {
        this.clearData();

        this.surface = surface;
        this.moveDelta = moveDelta;

        this.registerBasicSnapInfo();

        const { transformMatrix } = this.transforms;

        const rackSpacePts = [];
        for (const module of sourceModules) {
            _.each(module.path, i => rackSpacePts.push(transformMatrix.transform(i.add(moveDelta))));
        }

        const rackSpaceBounds = new Bounds(rackSpacePts);

        const rackSpaceSrcPath = [
            new Vector(rackSpaceBounds.minPoint.x, rackSpaceBounds.minPoint.y),
            new Vector(rackSpaceBounds.minPoint.x, rackSpaceBounds.maxPoint.y),
            new Vector(rackSpaceBounds.maxPoint.x, rackSpaceBounds.minPoint.y),
            new Vector(rackSpaceBounds.maxPoint.x, rackSpaceBounds.maxPoint.y),
        ];

        this.registerSourcePoint(rackSpaceSrcPath[0], SnapFilters.POINT_1);
        this.registerSourcePoint(rackSpaceSrcPath[1], SnapFilters.POINT_4);
        this.registerSourcePoint(rackSpaceSrcPath[2], SnapFilters.POINT_2);
        this.registerSourcePoint(rackSpaceSrcPath[3], SnapFilters.POINT_3);

        const boundsSrc = new Bounds(rackSpaceSrcPath);
        this.registerSourceSegmentX(
            boundsSrc.minPoint.x, boundsSrc.minPoint.y, boundsSrc.maxPoint.y, SnapFilters.EDGE_4);
        this.registerSourceSegmentX(
            boundsSrc.maxPoint.x, boundsSrc.minPoint.y, boundsSrc.maxPoint.y, SnapFilters.EDGE_2);
        this.registerSourceSegmentY(
            boundsSrc.minPoint.y, boundsSrc.minPoint.x, boundsSrc.maxPoint.x, SnapFilters.EDGE_1);
        this.registerSourceSegmentY(
            boundsSrc.maxPoint.y, boundsSrc.minPoint.x, boundsSrc.maxPoint.x, SnapFilters.EDGE_3);

        this.registerSnapTargetsInBounds(rackSpaceBounds, excludeSourceModules ? sourceModules : []);
    }

    registerBasicSnapInfo() {
        const layoutEngine = this.surface.layoutEngine();
        const layoutParams = layoutEngine.layoutParams();
        this.transforms = layoutEngine.rackingSpaceTransforms();
        this.moduleLookup = moduleCache.getRackCache(this.surface.getRacks(), this.transforms);

        const lookupSpacing = Math.max(
            layoutParams.row_spacing || 0,
            layoutParams.frame_spacing || 0,
            layoutParams.module_spacing || 0);

        const lookupEpsilon = 0.001;
        this.lookupPadding = lookupEpsilon + this.snapThreshold + lookupSpacing;

        this.snapSpacing = layoutParams.module_spacing || 0.001;
    }

    registerSnapTargetsInBounds(rackSpaceBounds, excludeModuleOpts = []) {
        const { moduleLookup, lookupPadding } = this;
        const { returnMatrix } = this.transforms;

        const rackSpaceMin = rackSpaceBounds.minPoint.subtract(new Vector(lookupPadding, lookupPadding));
        const rackSpaceMax = rackSpaceBounds.maxPoint.add(new Vector(lookupPadding, lookupPadding));
        const results = moduleLookup.findByBox(
            returnMatrix.transform(rackSpaceMin), returnMatrix.transform(rackSpaceMax));
        const targetModules = _.filter(results, i => !_.includes(excludeModuleOpts, i.module));

        for (const result of targetModules) {
            this.registerModuleSnapTargets(result.module);
        }
    }

    registerModuleSnapTargets(module) {
        const rackSpaceTgtPath = _.map(module.path, i => this.transforms.transformMatrix.transform(i));

        const boundsTgt = new Bounds(rackSpaceTgtPath);
        const boundsTgtBase = boundsTgt.clone();
        boundsTgt.expand(this.snapSpacing, this.snapSpacing);

        const pt1 = new Vector(boundsTgtBase.minPoint.x, boundsTgtBase.minPoint.y);
        const pt2 = new Vector(boundsTgtBase.maxPoint.x, boundsTgtBase.minPoint.y);
        const pt3 = new Vector(boundsTgtBase.maxPoint.x, boundsTgtBase.maxPoint.y);
        const pt4 = new Vector(boundsTgtBase.minPoint.x, boundsTgtBase.maxPoint.y);

        this.registerTargetPoint(pt1.add(new Vector(-this.snapSpacing, 0)), pt1, SnapTypes.POINT_12);
        this.registerTargetPoint(pt1.add(new Vector(-this.snapSpacing, -this.snapSpacing)), pt1, SnapTypes.POINT_1);
        this.registerTargetPoint(pt1.add(new Vector(0, -this.snapSpacing)), pt1, SnapTypes.POINT_2);
        this.registerTargetPoint(pt2.add(new Vector(0, -this.snapSpacing)), pt2, SnapTypes.POINT_3);
        this.registerTargetPoint(pt2.add(new Vector(this.snapSpacing, -this.snapSpacing)), pt2, SnapTypes.POINT_4);
        this.registerTargetPoint(pt2.add(new Vector(this.snapSpacing, 0)), pt2, SnapTypes.POINT_5);
        this.registerTargetPoint(pt3.add(new Vector(this.snapSpacing, 0)), pt3, SnapTypes.POINT_6);
        this.registerTargetPoint(pt3.add(new Vector(this.snapSpacing, this.snapSpacing)), pt3, SnapTypes.POINT_7);
        this.registerTargetPoint(pt3.add(new Vector(0, this.snapSpacing)), pt3, SnapTypes.POINT_8);
        this.registerTargetPoint(pt4.add(new Vector(0, this.snapSpacing)), pt4, SnapTypes.POINT_9);
        this.registerTargetPoint(pt4.add(new Vector(-this.snapSpacing, this.snapSpacing)), pt4, SnapTypes.POINT_10);
        this.registerTargetPoint(pt4.add(new Vector(-this.snapSpacing, 0)), pt4, SnapTypes.POINT_11);

        this.registerTargetSegmentX(
            boundsTgt.minPoint.x, boundsTgt.minPoint.y, boundsTgt.maxPoint.y,
            boundsTgtBase.minPoint.x, boundsTgtBase.minPoint.y, boundsTgtBase.maxPoint.y, SnapTypes.EDGE_4);
        this.registerTargetSegmentX(
            boundsTgt.maxPoint.x, boundsTgt.minPoint.y, boundsTgt.maxPoint.y,
            boundsTgtBase.maxPoint.x, boundsTgtBase.minPoint.y, boundsTgtBase.maxPoint.y, SnapTypes.EDGE_2);
        this.registerTargetSegmentY(
            boundsTgt.minPoint.y, boundsTgt.minPoint.x, boundsTgt.maxPoint.x,
            boundsTgtBase.minPoint.y, boundsTgtBase.minPoint.x, boundsTgtBase.maxPoint.x, SnapTypes.EDGE_1);
        this.registerTargetSegmentY(
            boundsTgt.maxPoint.y, boundsTgt.minPoint.x, boundsTgt.maxPoint.x,
            boundsTgtBase.maxPoint.y, boundsTgtBase.minPoint.x, boundsTgtBase.maxPoint.x, SnapTypes.EDGE_3);
    }
}

export class DragActionMoveWiringComponent {
    constructor(dRenderer, event, moveObject) {
        this.dRenderer = dRenderer;
        this.moveObject = moveObject;

        const pt = containerPoint(this.dRenderer, event);
        const gp = interactGroundPoint(this.dRenderer, pt);

        this.downGroundPoint = gp;

        const { dispatcher } = this.dRenderer;
        const { interactData } = this.moveObject.userData;
        const wiringComponent = interactData.component;

        if (interactData.component.component_type === 'inverter') {
            dispatcher.publish('Inverter:dragstart', { inverter: wiringComponent });
        } else if (interactData.component.component_type === 'combiner') {
            dispatcher.publish('Combiner:dragstart', { combiner: wiringComponent });
        } else if (interactData.component.component_type === 'interconnect') {
            dispatcher.publish('Interconnect:dragstart', { interconnect: wiringComponent });
        } else if (interactData.component.component_type === 'ac_panel') {
            dispatcher.publish('Combiner:dragstart', { combiner: wiringComponent });
        }
    }

    dragMouseMove(event) {
        if (!this.downGroundPoint) return;

        // TODO: MT: consider visual feedback here -- draw vertical line to ground level marker?
        const pt = containerPoint(this.dRenderer, event);
        const gp = interactGroundPoint(this.dRenderer, pt);
        const delta = (new THREE.Vector3()).subVectors(gp, this.downGroundPoint);

        const { interactData } = this.moveObject.userData;
        const renderable = this.dRenderer.objectRenderMap.get(interactData.component);
        if (renderable) {
            renderable.offsetRenderablePosition(delta);
        }
    }

    dragMouseUp(event) {
        if (!this.downGroundPoint) return;

        const pt = containerPoint(this.dRenderer, event);
        const gp = interactGroundPoint(this.dRenderer, pt);
        const delta = (new THREE.Vector3()).subVectors(gp, this.downGroundPoint);

        const { dispatcher, design } = this.dRenderer;
        const { interactData } = this.moveObject.userData;
        const wiringComponent = interactData.component;

        const location = wiringComponent.location.add(delta);
        if (wiringComponent.component_type === 'inverter') {
            dispatcher.publish('Inverter:dragend', { inverter: wiringComponent, location });
        } else if (wiringComponent.component_type === 'combiner') {
            dispatcher.publish('Combiner:dragend', { combiner: wiringComponent, location });
        } else if (interactData.component.component_type === 'interconnect') {
            dispatcher.publish('Interconnect:dragend', { interconnect: wiringComponent, location, design });
        } else if (interactData.component.component_type === 'ac_panel') {
            dispatcher.publish('Combiner:dragend', { combiner: wiringComponent, location });
        }
    }

    dragMouseOut() {
    }
}

class DragActionSelectModules {
    constructor(dRenderer, event, parentTool, addSelect = false) {
        // TODO: MT: rethink selection model of modules (and components) may want a general global model for it
        this.dRenderer = dRenderer;
        this.parentTool = parentTool;
        this.selectedSurface = this.parentTool.selectedSurface;
        this.originalSelection = addSelect ? (this.parentTool.selectedModules || []) : [];

        this.cursorHelper = new SurfaceCursorHelper(this.dRenderer);

        const pt = containerPoint(this.dRenderer, event);
        const { surfacePoint } = this.cursorHelper.computeCursorPosition(pt, this.selectedSurface);

        this.selectStart = surfacePoint;
        this.selectEnd = surfacePoint;

        this.updateSelection();
    }

    dragMouseMove(event) {
        const pt = containerPoint(this.dRenderer, event);
        const { surfacePoint } = this.cursorHelper.computeCursorPosition(pt, this.selectedSurface);

        this.selectEnd = surfacePoint;

        this.updateSelection();
    }

    dragMouseUp(event) {
        this.dragMouseMove(event);

        this.selectStart = null;
        this.selectEnd = null;

        this.redrawBox(null);
    }

    dragMouseOut() {
        this.redrawBox(null);
    }

    updateSelection() {
        const selectDelta = (new THREE.Vector2()).subVectors(this.selectEnd, this.selectStart);

        if (selectDelta.lengthSq() === 0) {
            this.redrawBox(null);
        } else {
            const transverseDir = (new THREE.Vector2()).copy(this.selectedSurface.azimuthDirection());
            const parallelDir = (new THREE.Vector2()).copy(this.selectedSurface.azimuthDirection(-90));

            const parallel = Vector.fromObject(projectThreeVector2(selectDelta, parallelDir));
            const transverse = Vector.fromObject(projectThreeVector2(selectDelta, transverseDir));

            const groundPath = [
                Vector.fromObject(this.selectStart),
                Vector.fromObject(this.selectStart).add(parallel),
                Vector.fromObject(this.selectStart).add(parallel).add(transverse),
                Vector.fromObject(this.selectStart).add(transverse),
            ];

            const boxPath = _.map(groundPath, i => this.selectedSurface.pointOnSurface(i));
            this.redrawBox(boxPath);
        }

        const transforms = this.selectedSurface.layoutEngine().rackingSpaceTransforms();
        const moduleLookup = moduleCache.getRackCache(this.selectedSurface.getRacks(), transforms);
        const results = moduleLookup.findByBox(this.selectStart, this.selectEnd);

        this.parentTool.setSelection(_.union(this.originalSelection, _.map(results, i => i.module)));
        this.parentTool.redrawSelection(true);
    }

    redrawBox(boxPath) {
        if (this.boxPrimitive) {
            this.boxPrimitive.clearInstances();
            this.boxPrimitive = null;
        }

        if (boxPath) {
            const lineOptions = {
                geometry: makeWireGeometry(pathToPolygonPoints(boxPath)),
                material: this.dRenderer.inlineShaderMaterial('vertexShaderWire', 'fragmentShaderWire'),
                scene: this.dRenderer.editSurfaceLayer,
                depthOffset: this.dRenderer.tinyZOffset,
                screenSpace: false,
                strokeColor: RendererOptions.moduleControlOptions.selectionOutlineColor,
                strokeWeight: 1.0,
            };
            this.boxPrimitive = this.dRenderer.renderPrimitive(PrimitiveMeshStroke, lineOptions);
        }

        this.dRenderer.dirtyFrame();
    }
}

class DragActionMoveModules {
    constructor(dRenderer, event, parentTool) {
        this.dRenderer = dRenderer;
        this.parentTool = parentTool;
        this.selectedSurface = this.parentTool.selectedSurface;

        this.cursorHelper = new SurfaceCursorHelper(this.dRenderer);

        const pt = containerPoint(this.dRenderer, event);
        const { surfacePoint } = this.cursorHelper.computeCursorPosition(pt, this.selectedSurface);

        this.dragStart = surfacePoint;
        this.dragEnd = surfacePoint;

        this.cloneMove = event.altKey;

        this.updateMovement();
    }

    dragRedraw() {
    }

    dragMouseMove(event) {
        const pt = containerPoint(this.dRenderer, event);
        const { surfacePoint } = this.cursorHelper.computeCursorPosition(pt, this.selectedSurface);

        this.dragEnd = surfacePoint;
        this.snapActive = event.shiftKey;

        this.updateMovement();
    }

    dragMouseUp(event) {
        this.dragMouseMove(event);

        this.applyMoveModules();

        this.dragStart = null;
        this.dragEnd = null;

        this.redrawMovement(null);
        this.redrawSnap(null);

        this.parentTool.setSelection();
        this.parentTool.redrawSelection(true);
    }

    dragMouseOut() {
        this.dragEnd = null;

        this.redrawMovement(null);
        this.redrawSnap(null);
    }

    dragKeyDown(event) {
        if (event.keyCode === KEY.ESC) {
            this.redrawMovement(null);
            this.dRenderer.cancelDragAction();
            return true;
        }

        return false;
    }

    validModuleMove(module, moveDelta) {
        const boundaryPaths = this.selectedSurface.layoutPaths();
        const movePath = _.map(module.path, i => moveDelta.add(i));

        const diff = differencePaths([movePath], boundaryPaths);
        if (diff.length) return false;
        return true;
    }

    applyMoveModules() {
        if (!this.moveDelta) return;

        const effDelta = new Vector(this.moveDelta.x, this.moveDelta.y);

        const oldModules = manualModuleProperty(this.selectedSurface);
        const removeModules = [];
        const addModules = [];
        const toggleLocations = [];
        const movedModules = [];

        for (const module of this.parentTool.selectedModules) {
            if (module.manual) {
                const removed = oldModules[module.manual_idx];
                if (!this.cloneMove) removeModules.push(removed);
                if (this.validModuleMove(module, effDelta)) {
                    const newmodule = {
                        top_left: effDelta.add(module.topLeft),
                        rotation: removed.rotation,
                        orientation: removed.orientation,
                    };
                    addModules.push(newmodule);
                    movedModules.push(module);
                }
            } else {
                if (!this.cloneMove) toggleLocations.push(moduleCenter(module));
                if (this.validModuleMove(module, effDelta)) {
                    const newmodule = {
                        top_left: effDelta.add(module.topLeft),
                        rotation: 0,
                        orientation: this.selectedSurface.orientation,
                    };
                    addModules.push(newmodule);
                    movedModules.push(module);
                }
            }
        }

        const { autoModules, manualModules } = getOverlappedModules(this.selectedSurface, addModules);
        const overlappedRaw = _.map(manualModules, i => oldModules[i.manual_idx]);
        const removeFinal = _.uniq(removeModules.concat(overlappedRaw));

        const copyModules = _.map(_.difference(oldModules, removeFinal), i => _.assign({}, i));
        const newModules = copyModules.concat(addModules);

        for (const module of autoModules) toggleLocations.push(moduleCenter(module));
        const toggleDelta = getToggleModuleDeltaMulti(this.selectedSurface, toggleLocations);

        const changes = [{
            path: 'racking.manual_modules',
            oldVal: oldModules,
            newVal: newModules,
        }];

        if (toggleDelta) {
            changes.push({
                path: 'geometry.removed_module_locations',
                oldVal: toggleDelta.oldVal,
                newVal: toggleDelta.newVal,
            });
        }

        this.dRenderer.dispatcher.createMultiPropertyChange({
            resource: this.selectedSurface,
            mergeable: false,
            loadMessage: `Redo manual module movement on ${this.selectedSurface}`,
            rollbackMessage: `Undo manual module movement on ${this.selectedSurface}`,
            changes,
        });
    }

    updateMovement() {
        const moveDelta = (new THREE.Vector3()).subVectors(this.dragEnd, this.dragStart);
        this.moveDelta = this.snapMovement(moveDelta);

        this.redrawMovement(this.moveDelta);
    }

    snapMovement(moveDelta) {
        if (!this.snapActive) {
            this.redrawSnap(null);
            return moveDelta;
        }

        const snapThreshold = snapDistance(this.dRenderer);
        const sourceModules = this.parentTool.selectedModules;

        this.snapHelper = new ModuleSnapHelper({ snapThreshold });
        this.snapHelper.registerModuleSnapInputs(this.selectedSurface, sourceModules, moveDelta, !this.cloneMove);
        this.snapHelper.computeSnap();

        this.redrawSnap(this.snapHelper);

        if (this.snapHelper.snapDelta) return this.snapHelper.snapDelta;
        return moveDelta;
    }

    redrawMovement(moveDelta) {
        if (this.movementPrimitive) {
            this.movementPrimitive.clearInstances();
            this.movementPrimitive = null;
        }

        if (moveDelta && this.parentTool.selectedModules.length) {
            const segmentPts = [];
            for (const module of this.parentTool.selectedModules) {
                segmentPts.push(...pathToPolygonPoints(module.path));
            }

            const offsetPts = _.map(segmentPts, i => i.add(moveDelta));

            const lineOptions = {
                geometry: makeWireGeometry(offsetPts),
                material: this.dRenderer.inlineShaderMaterial('vertexShaderWire', 'fragmentShaderWire'),
                scene: this.dRenderer.editSurfaceLayer,
                depthOffset: this.dRenderer.tinyZOffset,
                screenSpace: false,
                strokeColor: RendererOptions.moduleControlOptions.moduleOutlineColor,
                strokeWeight: 2.0,
            };
            this.movementPrimitive = this.dRenderer.renderPrimitive(PrimitiveMeshStroke, lineOptions);
        }

        this.dRenderer.dirtyFrame();
    }

    redrawSnap(snapHelper) {
        if (this.snapPrimitive) {
            this.snapPrimitive.clearInstances();
            this.snapPrimitive = null;
        }

        if (snapHelper && snapHelper.snapGuideSegments) {
            const lineOptions = {
                geometry: makeWireGeometry(snapHelper.snapGuideSegments),
                material: this.dRenderer.inlineShaderMaterial('vertexShaderWire', 'fragmentShaderWire'),
                scene: this.dRenderer.editSurfaceLayer,
                depthOffset: this.dRenderer.tinyZOffset * 2.0,
                screenSpace: false,
                strokeColor: '#ff4444',
                strokeWeight: 1.0,
            };
            this.snapPrimitive = this.dRenderer.renderPrimitive(PrimitiveMeshStroke, lineOptions);
        }

        this.dRenderer.dirtyFrame();
    }
}

export class InteractToolManualModuleAdd {
    constructor(dRenderer, options = {}) {
        this.dRenderer = dRenderer;
        this.cursorHelper = new SurfaceCursorHelper(dRenderer);
        this.addMode = null;
        this.addOrientation = options.orientation || 'horizontal';
        this.single = options.single;

        this.selectedSurface = this.dRenderer.dispatcher.selectedEntity;

        this.unsub = this.dRenderer.dispatcher.subscribe('resourceUpdated',
            (dispatcher, opts) => { this.handleEntityUpdated(dispatcher, opts); });
    }

    toolRedraw() {
        this.cursorHelper.redrawCreateCursor();
    }

    toolMouseDown(event) {
        const pt = containerPoint(this.dRenderer, event);
        this.downPoint = pt;
        this.panDownEvent = event;

        this.forceSnap = event.shiftKey;

        this.validAction = event.button === 0;
    }

    toolMouseUp(event) {
        const orgDownPoint = this.downPoint;
        this.downPoint = null;
        this.panDownEvent = null;

        if (event.button !== 0 || !this.validAction || !orgDownPoint) return;

        this.computeSurfacePoint(orgDownPoint);

        let finish = false;

        if (this.surfacePoint) {
            if (!this.addMode) {
                if (this.forceSnap) this.snapModuleAdd();
                this.addStartPoint = (new THREE.Vector3()).copy(this.surfacePoint);
                this.addEndPoint = (new THREE.Vector3()).copy(this.surfacePoint);
                if (this.single) finish = true;
                else this.addMode = 'END_POINT';
            } else if (this.addMode === 'END_POINT') {
                this.addEndPoint = (new THREE.Vector3()).copy(this.surfacePoint);
                finish = true;
            }
        }

        if (finish) {
            this.computeAddModules(this.addStartPoint, this.addEndPoint);

            if (this.addModuleOpts && this.validEditSurface()) {
                this.applyAddModules(this.addModuleOpts);
            }

            this.clearCreation();
        } else {
            this.toolMouseMove(event);
        }

        this.redrawInstance();
    }

    toolMouseMove(event) {
        const pt = containerPoint(this.dRenderer, event);

        if (this.panDownEvent) {
            if (panThreshold(this.downPoint, pt)) {
                if (event.shiftKey) {
                    this.dRenderer.activateDragAction(new DragActionCameraRotate(this.dRenderer, this.panDownEvent));
                } else {
                    this.dRenderer.activateDragAction(new DragActionCameraPan(this.dRenderer, this.panDownEvent));
                }
                this.cursorHelper.clearCreateCursor();
                this.panDownEvent = null;
                return;
            }
        }

        this.computeSurfacePoint(pt);

        if (!this.addMode && event.shiftKey) {
            this.snapModuleAdd();
        }

        if (this.surfacePoint) {
            this.addGuidePoint = (new THREE.Vector3()).copy(this.surfacePoint);
            this.cursorHelper.redrawCreateCursor();

            if (this.addMode === 'END_POINT') {
                this.addEndPoint = (new THREE.Vector3()).copy(this.surfacePoint);
            }
        } else {
            this.addGuidePoint = null;
            this.cursorHelper.clearCreateCursor();
        }

        this.processMouseUp = true;

        if (this.addMode) {
            this.computeAddModules(this.addStartPoint, this.addEndPoint);
        } else {
            this.computeAddModules(this.addGuidePoint, this.addGuidePoint);
        }

        this.redrawInstance();
    }

    toolMouseOut() {
        this.cursorHelper.clearCreateCursor();
    }

    toolMouseWheel(event) {
        zoomView(this.dRenderer, event);
    }

    toolDblClick() {
    }

    toolKeyDown(event) {
        if (event.keyCode === KEY.ESC) {
            if (this.addMode) {
                this.clearCreation();
            } else if (this.toolEscapeHandler) {
                this.toolEscapeHandler();
            }
            return true;
        }

        return false;
    }

    deactivateTool() {
        this.cursorHelper.clearCreateCursor();
        this.clearCreation();

        this.unsub();
    }

    handleEntityUpdated(dispatcher, opts) {
        if (opts.resource === this.selectedSurface) {
            this.clearCreation();
        }
    }

    clearCreation() {
        this.addMode = null;
        this.addModuleOpts = null;
        this.addModuleSegments = null;
        this.addModulePath = null;

        this.redrawInstance();
    }

    validEditSurface() {
        const { renderUpdater } = this.dRenderer.dispatcher;
        return !renderUpdater.dirtyEntities.has(this.selectedSurface);
    }

    toggleOrientation() {
        if (this.addOrientation === 'horizontal') this.addOrientation = 'vertical';
        else this.addOrientation = 'horizontal';

        if (this.addMode) {
            this.computeAddModules(this.addStartPoint, this.addEndPoint);
        } else {
            this.computeAddModules(this.addGuidePoint, this.addGuidePoint);
        }

        this.redrawInstance();
    }

    applyAddModules(moduleOpts) {
        const { dispatcher } = this.dRenderer;
        const { selectedSurface } = this;

        const oldModules = manualModuleProperty(selectedSurface);

        const { autoModules, manualModules } = getOverlappedModules(this.selectedSurface, moduleOpts);
        const removeModules = _.map(manualModules, i => oldModules[i.manual_idx]);

        const copyModules = (_.map(_.difference(oldModules, _.uniq(removeModules)), i => _.assign({}, i)));
        const newModules = copyModules.concat(moduleOpts);

        const toggleLocations = _.map(autoModules, i => moduleCenter(i));
        const toggleDelta = getToggleModuleDeltaMulti(this.selectedSurface, toggleLocations);

        const changes = [{
            path: 'racking.manual_modules',
            oldVal: oldModules,
            newVal: newModules,
        }];

        if (toggleDelta) {
            changes.push({
                path: 'geometry.removed_module_locations',
                oldVal: toggleDelta.oldVal,
                newVal: toggleDelta.newVal,
            });
        }

        dispatcher.createMultiPropertyChange({
            changes,
            resource: selectedSurface,
            mergeable: false,
            loadMessage: `Redo manual module add on ${selectedSurface}`,
            rollbackMessage: `Undo manual module add on ${selectedSurface}`,
        });
    }

    computeSurfacePoint(clientPt) {
        const ray = interactRay(this.dRenderer, clientPt);
        if (!ray) return;

        const focusSurface = (!this.addMode) ? null : this.selectedSurface;
        const { surfacePoint, surface } = this.cursorHelper.computeCursorPosition(clientPt, focusSurface);
        this.surfacePoint = (surface === this.selectedSurface) ? surfacePoint : null;
    }

    snapModuleAdd() {
        if (!this.surfacePoint) return;

        const snapThreshold = snapDistance(this.dRenderer);

        this.snapHelper = new ModuleSnapHelper({ snapThreshold });
        if (this.single) {
            const addGeometry = this.getAddGeometry(this.surfacePoint, this.surfacePoint);
            const rackStructs = this.computeWorkingRacks(addGeometry);
            if (rackStructs && rackStructs.length && rackStructs[0].getModules().length) {
                const dummyPath = rackStructs[0].getModules()[0].path;
                this.snapHelper.registerModuleSnapInputs(this.selectedSurface, [{ path: dummyPath }]);
            }
        } else {
            this.snapHelper.registerPointSnapInputs(this.selectedSurface, this.surfacePoint);
        }
        this.snapHelper.computeSnap();

        if (this.snapHelper.snapDelta) {
            this.surfacePoint = this.surfacePoint.add(this.snapHelper.snapDelta);
            this.cursorHelper.forceCursorSurfacePosition(this.surfacePoint, this.selectedSurface);
        }
    }

    getAddGeometry(startPoint, endPoint) {
        if (!startPoint || !endPoint) return {};

        const layoutPadding = 0.001;

        const { selectedSurface } = this;

        const rotation = 0;
        const orientation = this.addOrientation;
        const transverseDir = (new THREE.Vector2()).copy(selectedSurface.azimuthDirection(rotation));
        const parallelDir = (new THREE.Vector2()).copy(selectedSurface.azimuthDirection(rotation - 90));

        const pointDelta = (new THREE.Vector2()).subVectors(endPoint, startPoint);
        let parallel = Vector.fromObject(projectThreeVector2(pointDelta, parallelDir));
        let transverse = Vector.fromObject(projectThreeVector2(pointDelta, transverseDir));

        if (parallel.lengthSq() === 0) parallel = Vector.fromObject(parallelDir).scale(layoutPadding);
        if (transverse.lengthSq() === 0) transverse = Vector.fromObject(transverseDir).scale(layoutPadding);

        const measureParams = _.assign(
            { module_characterization: selectedSurface.module_characterization },
            selectedSurface, { rotation, orientation }, this.getParamOverrides());
        const measureEngine = selectedSurface.layoutEngine([], measureParams);

        const { groundExt, spacedGroundExt } = measureEngine.frameLayout().frameRackSpaceInfo();

        if (parallel.length() < spacedGroundExt.x + layoutPadding) {
            parallel = parallel.normalize().scale(spacedGroundExt.x + layoutPadding);
        }

        if (transverse.length() < spacedGroundExt.y + layoutPadding) {
            transverse = transverse.normalize().scale(spacedGroundExt.y + layoutPadding);
        }

        const parallelSign = (parallelDir.dot(parallel) < 0) ? -1 : 1;
        const transverseSign = (transverseDir.dot(transverse) < 0) ? -1 : 1;

        const signedParallel = Vector.fromObject(parallelDir).scale(parallelSign * layoutPadding);
        const signedTransverse = Vector.fromObject(transverseDir).scale(transverseSign * layoutPadding);

        const modPoint = Vector.fromObject(startPoint)
            .subtract(signedParallel.scale(0.5))
            .subtract(signedTransverse.scale(0.5));

        const layoutPath = [
            Vector.fromObject(modPoint),
            Vector.fromObject(modPoint)
                .add(parallel)
                .add(signedParallel),
            Vector.fromObject(modPoint)
                .add(parallel)
                .add(transverse)
                .add(signedParallel)
                .add(signedTransverse),
            Vector.fromObject(modPoint)
                .add(transverse)
                .add(signedTransverse),
        ];

        const parallelSpacing = spacedGroundExt.x - groundExt.x;
        const transverseSpacing = measureEngine.layoutParams().row_spacing;

        const offsetX = (parallelSign < 0 ? parallelSpacing : 0);
        const offsetY = -(transverseSign < 0 ? transverseSpacing : 0);
        const offsetVector = (new Vector(offsetX, offsetY)).rotate(-rotation);

        const { returnMatrix } = this.selectedSurface.layoutEngine().rackingSpaceTransforms();
        const offsetWorld = returnMatrix.get33().transform(offsetVector);

        const layoutStart = Vector.fromObject(startPoint).add(offsetWorld);

        return { layoutPath, layoutStart, layoutRotation: rotation, layoutOrientation: orientation };
    }

    getParamOverrides() {
        return {
            bank_depth: 1,
            bank_width: 1,
            frame_spacing: null,
            row_spacing: this.selectedSurface.module_spacing,
            alignment: 'block',
        };
    }

    computeWorkingRacks(addGeometry) {
        const { layoutPath, layoutStart, layoutRotation, layoutOrientation } = addGeometry;
        if (!layoutPath) return null;

        const { selectedSurface } = this;

        const layoutParams = _.assign(
            { module_characterization: selectedSurface.module_characterization },
            selectedSurface,
            {
                rotation: layoutRotation,
                orientation: layoutOrientation,
                layout_start: layoutStart,
            }, this.getParamOverrides());

        const paths = intersectPathsMulti([layoutPath], selectedSurface.layoutPaths());

        const workingEngine = selectedSurface.layoutEngine(paths, layoutParams);
        return workingEngine.generateAutoRackStructures();
    }

    computeAddModules(startPoint, endPoint) {
        this.addModuleSegments = null;
        this.addModuleOpts = null;
        this.addModulePath = null;

        const addGeometry = this.getAddGeometry(startPoint, endPoint);
        const rackStructs = this.computeWorkingRacks(addGeometry);

        if (!rackStructs) return;

        if (this.addMode) this.addModulePath = addGeometry.layoutPath;

        this.addModulePaths = [];
        this.addModuleSegments = [];
        this.addModuleOpts = [];

        for (const rack of rackStructs) {
            for (const module of rack.getModules()) {
                this.addModulePaths.push(module.path);
                this.addModuleSegments.push(...pathToPolygonPoints(module.path));
                this.addModuleOpts.push({
                    top_left: module.topLeft,
                    rotation: addGeometry.layoutRotation,
                    orientation: addGeometry.layoutOrientation,
                });
            }
        }
    }

    redrawInstance() {
        if (this.modulesPrimitive) {
            this.modulesPrimitive.clearInstances();
            this.modulesPrimitive = null;
        }

        if (this.boxPrimitive) {
            this.boxPrimitive.clearInstances();
            this.boxPrimitive = null;
        }

        if (this.addModuleSegments) {
            const lineOptions = {
                geometry: makeWireGeometry(this.addModuleSegments),
                material: this.dRenderer.inlineShaderMaterial('vertexShaderWire', 'fragmentShaderWire'),
                scene: this.dRenderer.editSurfaceLayer,
                depthOffset: this.dRenderer.tinyZOffset,
                screenSpace: false,
                strokeColor: this.addMode ?
                    RendererOptions.moduleControlOptions.moduleOutlineColor :
                    RendererOptions.moduleControlOptions.selectionOutlineColor,
                strokeWeight: 2.0,
            };
            this.modulesPrimitive = this.dRenderer.renderPrimitive(PrimitiveMeshStroke, lineOptions);
        }

        if (this.addModulePath) {
            const boxPath = _.map(this.addModulePath, i => this.selectedSurface.pointOnSurface(i));
            const lineOptions = {
                geometry: makeWireGeometry(pathToPolygonPoints(boxPath)),
                material: this.dRenderer.inlineShaderMaterial('vertexShaderWire', 'fragmentShaderWire'),
                scene: this.dRenderer.editSurfaceLayer,
                depthOffset: this.dRenderer.tinyZOffset,
                screenSpace: false,
                strokeColor: RendererOptions.moduleControlOptions.selectionOutlineColor,
                strokeWeight: 1.0,
            };
            this.boxPrimitive = this.dRenderer.renderPrimitive(PrimitiveMeshStroke, lineOptions);
        }
    }
}

export class InteractToolManualModuleEdit {
    constructor(dRenderer) {
        this.dRenderer = dRenderer;
        this.selectedModules = [];

        this.selectedSurface = this.dRenderer.dispatcher.selectedEntity;
        this.cursorHelper = new SurfaceCursorHelper(this.dRenderer);

        this.unsub = this.dRenderer.dispatcher.subscribe('resourceUpdated',
            (dispatcher, opts) => { this.handleEntityUpdated(dispatcher, opts); });
    }

    toolRedraw() {
    }

    toolMouseDown(event) {
        const pt = containerPoint(this.dRenderer, event);
        this.downEvent = event;
        this.downPoint = pt;
        this.panDownEvent = event;

        const { surfacePoint, surface } = this.cursorHelper.computeCursorPosition(pt);

        if (this.validEditSurface(surface)) {
            this.computeCursorModule(surfacePoint);
            this.panDownEvent = null;

            if (event.button === 0) {
                if (this.cursorModule && _.includes(this.selectedModules, this.cursorModule)) {
                    this.dRenderer.activateDragAction(
                        new DragActionMoveModules(this.dRenderer, event, this),
                    );
                } else {
                    this.dRenderer.activateDragAction(
                        new DragActionSelectModules(this.dRenderer, event, this, event.shiftKey),
                    );
                }
            } else if (event.button === 2) {
                if (this.cursorModule) {
                    if (_.includes(this.selectedModules, this.cursorModule)) {
                        applyRemoveModules(this.dRenderer.dispatcher, this.selectedSurface, this.selectedModules);
                    } else {
                        applyRemoveModules(this.dRenderer.dispatcher, this.selectedSurface, [this.cursorModule]);
                    }

                    this.computeCursorModule(null);
                    this.redrawSelection();
                }
            }
        } else {
            this.computeCursorModule(null);
        }
    }

    toolMouseUp(event) {
        this.downPoint = null;
        this.panDownEvent = null;

        if (event.button !== 0) return;

        this.computeCursorModule(null);
        this.redrawSelection();
    }

    toolMouseMove(event) {
        const pt = containerPoint(this.dRenderer, event);

        if (this.panDownEvent) {
            if (panThreshold(this.downPoint, pt)) {
                if (event.shiftKey) {
                    this.dRenderer.activateDragAction(new DragActionCameraRotate(this.dRenderer, this.panDownEvent));
                } else {
                    this.dRenderer.activateDragAction(new DragActionCameraPan(this.dRenderer, this.panDownEvent));
                }
                this.panDownEvent = null;
                return;
            }
        }

        const { surfacePoint, surface } = this.cursorHelper.computeCursorPosition(pt);

        if (this.validEditSurface(surface)) {
            this.computeCursorModule(surfacePoint);
        } else {
            this.computeCursorModule(null);
        }

        this.redrawSelection();
    }

    toolMouseOut() {
    }

    toolMouseWheel(event) {
        zoomView(this.dRenderer, event);
    }

    toolDblClick() {
    }

    toolKeyDown(event) {
        if (event.keyCode === KEY.ESC) {
            if (this.selectedModules.length || this.dragMode) {
                this.clearEditing();
            } else if (this.toolEscapeHandler) {
                this.toolEscapeHandler();
            }
            return true;
        }

        return false;
    }

    deactivateTool() {
        this.clearEditing();
        this.unsub();
    }

    handleEntityUpdated(dispatcher, opts) {
        if (opts.resource === this.selectedSurface) {
            this.clearEditing();
        }
    }

    clearEditing() {
        this.selectedModules = [];
        this.dragMode = null;

        this.computeCursorModule(null);
        this.redrawSelection();
    }

    validEditSurface(surface) {
        const { renderUpdater } = this.dRenderer.dispatcher;
        return (
            surface &&
            surface === this.selectedSurface &&
            !renderUpdater.dirtyEntities.has(surface));
    }

    computeCursorModule(surfacePoint) {
        this.cursorModule = null;
        this.moduleMouseOffset = null;

        if (!surfacePoint) return;

        const transforms = this.selectedSurface.layoutEngine().rackingSpaceTransforms();
        const moduleLookup = moduleCache.getRackCache(this.selectedSurface.getRacks(), transforms);
        const results = moduleLookup.findByPoints([surfacePoint]);

        const module = results.length ? results[0].module : null;
        this.cursorModule = module;

        if (this.cursorModule) {
            this.moduleMouseOffset = Vector.fromObject(module.topLeft).subtract(surfacePoint);
            this.modulePathDelta = _.map(this.cursorModule.path, i => Vector.fromObject(i).subtract(surfacePoint));
        }
    }

    setSelection(modules) {
        this.selectedModules = modules || [];
    }

    redrawSelection(noCursor = false) {
        if (this.selectionPrimitive) {
            this.selectionPrimitive.clearInstances();
            this.selectionPrimitive = null;
        }

        if (this.cursorModulePrimitive) {
            this.cursorModulePrimitive.clearInstances();
            this.cursorModulePrimitive = null;
        }

        if (this.selectedModules.length) {
            const segmentPts = [];
            for (const module of this.selectedModules) {
                segmentPts.push(...pathToPolygonPoints(module.path));
            }

            const lineOptions = {
                geometry: makeWireGeometry(segmentPts),
                material: this.dRenderer.inlineShaderMaterial('vertexShaderWire', 'fragmentShaderWire'),
                scene: this.dRenderer.editSurfaceLayer,
                depthOffset: this.dRenderer.tinyZOffset,
                screenSpace: false,
                strokeColor: RendererOptions.moduleControlOptions.moduleOutlineColor,
                strokeWeight: 2.0,
            };
            this.selectionPrimitive = this.dRenderer.renderPrimitive(PrimitiveMeshStroke, lineOptions);
        }

        if (!noCursor && this.cursorModule && this.cursorModule.path) {
            const lineOptions = {
                geometry: makeWireGeometry(pathToPolygonPoints(this.cursorModule.path)),
                material: this.dRenderer.inlineShaderMaterial('vertexShaderWire', 'fragmentShaderWire'),
                scene: this.dRenderer.editSurfaceLayer,
                depthOffset: this.dRenderer.tinyZOffset * 2.0,
                screenSpace: false,
                strokeColor: RendererOptions.moduleControlOptions.selectionOutlineColor,
                strokeWeight: 2.0,
            };
            this.cursorModulePrimitive = this.dRenderer.renderPrimitive(PrimitiveMeshStroke, lineOptions);
        }

        this.dRenderer.dirtyFrame();
    }
}
