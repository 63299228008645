import { fastPow2 } from 'helioscope/app/utilities/geometry/math';
import { GeoPoint, RADIUS_OF_EARTH } from 'helioscope/app/utilities/geometry';
/**
 * Ported from http://www.maptiler.org/google-maps-coordinates-tile-bounds-projection/
 */

export class GlobalMercator {
    /**
    TMS Global Mercator Profile
    ---------------------------

    Functions necessary for generation of tiles in Spherical Mercator projection,
    EPSG:900913 (EPSG:gOOglE, Google Maps Global Mercator), EPSG:3785, OSGEO:41001.

    Such tiles are compatible with Google Maps, Microsoft Virtual Earth, Yahoo Maps,
    UK Ordnance Survey OpenSpace API, ...
    and you can overlay them on top of base maps of those web mapping applications.

    Pixel and tile coordinates are in TMS notation (origin [0,0] in bottom-left).

    What coordinate conversions do we need for TMS Global Mercator tiles::

         LatLon      <->       Meters      <->     Pixels    <->       Tile

     WGS84 coordinates   Spherical Mercator  Pixels in pyramid  Tiles in pyramid
         lat/lon            XY in metres     XY pixels Z zoom      XYZ from TMS
        EPSG:4326           EPSG:900913
         .----.              ---------               --                TMS
        /      \     <->     |       |     <->     /----/    <->      Google
        \      /             |       |           /--------/          QuadTree
         -----               ---------         /------------/
       KML, public         WebMapService         Web Clients      TileMapService

    What is the coordinate extent of Earth in EPSG:900913?

      [-20037508.342789244, -20037508.342789244, 20037508.342789244, 20037508.342789244]
      Constant 20037508.342789244 comes from the circumference of the Earth in meters,
      which is 40 thousand kilometers, the coordinate origin is in the middle of extent.
      In fact you can calculate the constant as: 2 * Math.PI * 6378137 / 2.0
      $ echo 180 85 | gdaltransform -s_srs EPSG:4326 -t_srs EPSG:900913
      Polar areas with abs(latitude) bigger then 85.05112878 are clipped off.

    What are zoom level constants (pixels/meter) for pyramid with EPSG:900913?

      whole region is on top of pyramid (zoom=0) covered by 256x256 pixels tile,
      every lower zoom level resolution is always divided by two
      initialresolution = 20037508.342789244 * 2 / 256 = 156543.03392804062

    What is the difference between TMS and Google Maps/QuadTree tile name convention?

      The tile raster itself is the same (equal extent, projection, pixel size),
      there is just different identification of the same raster tile.
      Tiles in TMS are counted from [0,0] in the bottom-left corner, id is XYZ.
      Google placed the origin [0,0] to the top-left corner, reference is XYZ.
      Microsoft is referencing tiles by a QuadTree name, defined on the website:
      http://msdn2.microsoft.com/en-us/library/bb259689.aspx

    The lat/lon coordinates are using WGS84 datum, yeh?

      Yes, all lat/lon we are mentioning should use WGS84 Geodetic Datum.
      Well, the web clients like Google Maps are projecting those coordinates by
      Spherical Mercator, so in fact lat/lon coordinates on sphere are treated as if
      the were on the WGS84 ellipsoid.

      From MSDN documentation:
      To simplify the calculations, we use the spherical form of projection, not
      the ellipsoidal form. Since the projection is used only for map display,
      and not for displaying numeric coordinates, we don't need the extra precision
      of an ellipsoidal projection. The spherical projection causes approximately
      0.33 percent scale distortion in the Y direction, which is not visually noticable.

    How do I create a raster in EPSG:900913 and convert coordinates with PROJ.4?

      You can use standard GIS tools like gdalwarp, cs2cs or gdaltransform.
      All of the tools supports -t_srs 'epsg:900913'.

      For other GIS programs check the exact definition of the projection:
      More info at http://spatialreference.org/ref/user/google-projection/
      The same projection is degined as EPSG:3785. WKT definition is in the official
      EPSG database.

      Proj4 Text:
        +proj=merc +a=6378137 +b=6378137 +lat_ts=0.0 +lon_0=0.0 +x_0=0.0 +y_0=0
        +k=1.0 +units=m +nadgrids=@null +no_defs

      Human readable WKT format of EPGS:900913:
         PROJCS["Google Maps Global Mercator",
             GEOGCS["WGS 84",
                 DATUM["WGS_1984",
                     SPHEROID["WGS 84",6378137,298.2572235630016,
                         AUTHORITY["EPSG","7030"]],
                     AUTHORITY["EPSG","6326"]],
                 PRIMEM["Greenwich",0],
                 UNIT["degree",0.0174532925199433],
                 AUTHORITY["EPSG","4326"]],
             PROJECTION["Mercator_1SP"],
             PARAMETER["central_meridian",0],
             PARAMETER["scale_factor",1],
             PARAMETER["false_easting",0],
             PARAMETER["false_northing",0],
             UNIT["metre",1,
                 AUTHORITY["EPSG","9001"]]]
    */

    /**
     * Initialize the TMS Global Mercator pyramid
     */
    constructor(tileSize = 256) {
        this.tileSize = tileSize;
        this.initialresolution = 2 * Math.PI * RADIUS_OF_EARTH / this.tileSize;
        // 156543.03392804062 for tileSize 256 pixels
        this.originShift = Math.PI * RADIUS_OF_EARTH;
        // 20037508.342789244
    }

    /**
     * Converts given lat/lon in WGS84 Datum to XY in Spherical Mercator EPSG:900913
     */
    latLonToMeters(lat, lon) {
        const mx = lon * this.originShift / 180.0;
        let my = Math.log(Math.tan((90 + lat) * Math.PI / 360.0)) / (Math.PI / 180.0);

        my = my * this.originShift / 180.0;
        return { x: mx, y: my };
    }

    latLonToTile(lat, lon, zoom) {
        const meters = this.latLonToMeters(lat, lon);
        return this.metersToTile(meters.x, meters.y, zoom);
    }

    /**
     * Converts XY point from Spherical Mercator EPSG:900913 to lat/lon in WGS84 Datum
     */
    metersToLatLon(mx, my) {
        const lon = (mx / this.originShift) * 180.0;
        let lat = (my / this.originShift) * 180.0;


        // Note: the code in the reference library does not have the leading
        // negative for this, but compared to our own equivalent function
        // (helioscope.utilities.maps.tile_to_latlong), the latitude is exactly
        // negative for what it should
        lat = -180 / Math.PI * (2 * Math.atan(Math.exp(lat * Math.PI / 180.0)) - Math.PI / 2.0);
        return new GeoPoint(lat, lon);
    }

    /**
     * Converts pixel coordinates in given zoom level of pyramid to EPSG:900913
     */
    pixelsToMeters(px, py, zoom) {
        const res = this.resolution(zoom);
        const mx = px * res - this.originShift;
        const my = py * res - this.originShift;
        return { x: mx, y: my };
    }

   /**
    * Converts EPSG:900913 to pyramid pixel coordinates in given zoom level"
    */
    metersToPixels(mx, my, zoom) {
        const res = this.resolution(zoom);
        const px = (mx + this.originShift) / res;
        const py = (my + this.originShift) / res;
        return { x: px | 0, y: py | 0 };
    }

    /**
     * Returns a tile covering region in given pixel coordinates
     */
    pixelsToTile(px, py) {
        const tx = (Math.ceil(px / this.tileSize) - 1) | 0;
        const ty = (Math.ceil(py / this.tileSize) - 1) | 0;
        return { x: tx, y: ty };
    }
    /**
     * Move the origin of pixel coordinates to top-left corner
     */
    pixelsToRaster(px, py, zoom) {
        const mapSize = this.tileSize << zoom;
        return { x: px, y: mapSize - py };
    }

   /**
    * Returns tile for given mercator coordinates
    */
    metersToTile(mx, my, zoom) {
        const { x, y } = this.metersToPixels(mx, my, zoom);
        return this.pixelsToTile(x, y);
    }

    /**
     * Returns bounds of the given tile in EPSG:900913 coordinates
     */
    tileBounds(tx, ty, zoom) {
        const { x: minx, y: miny } = this.pixelsToMeters(tx * this.tileSize, ty * this.tileSize, zoom);
        const { x: maxx, y: maxy } = this.pixelsToMeters((tx + 1) * this.tileSize, (ty + 1) * this.tileSize, zoom);
        return { minx, miny, maxx, maxy };
    }

    /**
     * Returns bounds of the given tile in latutude/longitude using WGS84 datum
     */
    tileLatLonBounds(tx, ty, zoom) {
        const bounds = this.tileBounds(tx, ty, zoom);
        const min = this.metersToLatLon(bounds.minx, bounds.miny);
        const max = this.metersToLatLon(bounds.maxx, bounds.maxy);

        return { min, max };
    }

    /**
     * resolution (meters/pixel) for given zoom level (measured at Equator)
     */
    resolution(zoom) {
        return this.initialresolution / fastPow2(zoom);
    }

    zoomForPixelSize(pixelSize) {
        // Maximal scaledown zoom of the pyramid closest to the pixelSize.
        for (const i of _.range(30)) {
            if (pixelSize > this.resolution(i)) {
                return i !== 0 ? i - 1 : 0;  // We don't want to scale up
            }
        }

        return 0;
    }

    /**
     * Converts TMS tile coordinates to Google Tile coordinates
     */
    googleTile(tx, ty, zoom) {
        // coordinate origin is moved from bottom-left to top-left corner of the extent
        return { x: tx, y: fastPow2(zoom) - 1 - ty };
    }
}

export const DefaultGlobalMercator = new GlobalMercator();
export function latLonToGoogle(lat, lon, zoom) {
    const coords = DefaultGlobalMercator.latLonToTile(lat, lon, zoom);
    return DefaultGlobalMercator.googleTile(coords.x, coords.y, zoom);
}
