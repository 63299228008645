/* global google:true */
import { CanvasLayer } from './CanvasLayer';
import { convertColorAndOpacity } from 'helioscope/app/utilities/colors';
import { undefinedOr } from 'helioscope/app/utilities/helpers';
import { Vector, Bounds } from 'helioscope/app/utilities/geometry';
import { MapGeometry } from '../geometry';

const PADDING_SIZE = 500;
const RENDER_FRAME_RATE = 20;  // technically it's not a frame rate because it's used in a debounce

export class CanvasShape {
    constructor(options) {
        this.options = {};
        this.setOptions(options);
    }

    /**
     * store the base polygon layer on the map property to mimic google maps objects
     */
    setMap(polygonLayer) {
        if (polygonLayer === null && this.map) {
            this.map.removeShape(this);
            delete this.map;
        } else if (polygonLayer) {
            this.map = this.options.map = polygonLayer;
            this.map.addShape(this);
        }
    }

    setOptions(opts) {
        this.options = _.assign(this.options, opts);

        if (this.options.paths) {
            this.paths = this.options.paths;
        } else if (this.options.path) {
            this.paths = [this.options.path];
        }

        this.paths = _.filter(this.paths, path => path.length > 1);

        this.fillStyle = convertColorAndOpacity(this.options.fillColor || '#000',
                                                undefinedOr(this.options.fillOpacity, this.options.opacity));
        this.zIndex = this.options.zIndex;

        // the lineWidth is visibly suffering from low resolution,
        // may need to adjust based on map scaling? or otherwise improve the resolution of the canvas layer
        this.lineWidth = undefinedOr(opts.strokeWeight, 1) / 20;

        if (this.lineWidth === 0) {
            // seems to be the only way to actully hide the line
            this.strokeStyle = 'rgba(0,0,0,0)';
        } else {
            this.strokeStyle = convertColorAndOpacity(this.options.strokeColor || '#000',
                                                      undefinedOr(this.options.strokeOpacity, this.options.opacity));
        }

        this.visible = undefinedOr(opts.visible, true);
        this.setMap(this.options.map);
    }

    render(_context) {
        throw Error('Not implemented');
    }
}


export class CanvasPolygon extends CanvasShape {
    render(context) {
        if (!this.visible) {
            return;
        }

        context.beginPath();

        // Use indexed loops instead of for..of for performance in transpiled
        // babel (forces iterators). Native for..of may change which
        // implementation is fastest
        for (let i = 0; i < this.paths.length; i++) {
            const [start, ...segments] = this.paths[i];

            context.moveTo(start.x, start.y);

            for (let j = 0; j < segments.length; j++) {
                const pt = segments[j];
                context.lineTo(pt.x, pt.y);
            }

            context.closePath();
        }

        context.fillStyle = this.fillStyle;
        context.strokeStyle = this.strokeStyle;
        context.lineWidth = this.lineWidth;

        context.fill();
        context.stroke();

        context.save();

        if (this.text && this.paths.length > 0) {
            const { x, y } = Bounds.pathMidPoint(this.paths[0]);
            context.fillStyle = 'white';
            context.font = '0.2px serif';
            context.translate(x, y);
            context.scale(1, -1);
            context.fillText(this.text, 0, 0);
            context.restore();
        }
    }
}

export class CanvasPolyline extends CanvasShape {
    render(context) {
        if (!this.visible) {
            return;
        }

        context.beginPath();

        // Use indexed loops instead of for..of for performance in transpiled
        // babel (forces iterators). Native for..of may change which
        // implementation is fastest
        for (let i = 0; i < this.paths.length; i++) {
            const [start, ...segments] = this.paths[i];

            context.moveTo(start.x, start.y);

            for (let j = 0; j < segments.length; j++) {
                const pt = segments[j];
                context.lineTo(pt.x, pt.y);
            }
        }

        context.strokeStyle = this.strokeStyle;
        context.lineWidth = this.lineWidth;
        context.stroke();
    }
}


export class CanvasPolygonLayer {
    /**
     * note this default setting for MapPane puts the rendering strictly above the default
     * Google Maps Polygon/Overlay, and below the markers
     */
    constructor(map, center, paneName = 'overlayShadow') {
        this.canvasLayer = new CanvasLayer({
            map,
            updateHandler: () => {
                this.update();
                this.scheduleRender();
            },
            animate: false,
            resolutionScale: window.devicePixelRatio || 1,
            resizeHandler: () => {
                this.buffer.width = this.canvas.width + 2 * PADDING_SIZE;
                this.buffer.height = this.canvas.height + 2 * PADDING_SIZE;
                this.canvasLayer.repositionCanvas_();
                this.render();
            },
            paneName,
        });

        this.map = map;

        this.resolutionScale = window.devicePixelRatio || 1;
        if (center) {
            this.setCenter(center);
        }
        this.context = this.canvas.getContext('2d');
        this.buffer = document.createElement('canvas');
        this.bufferContext = this.buffer.getContext('2d');

        this.shapes = [];
        this.hadRemoved = false;
        this.hadAdd = false;
    }

    scheduleRender = _.debounce(() => this.render(), 1000 / RENDER_FRAME_RATE);

    get canvas() {
        return this.canvasLayer.canvas;
    }

    setCenter(center) {
        this.center = center;

        google.maps.event.addListenerOnce(this.map, 'idle', () => {
            this.canvasLayer.scheduleUpdate();
            this.renderToBuffer();
        });
    }

    update() {
        if (this.bufferOffset === undefined) {
            return;
        }

        const context = this.context;
        const map = this.map;
        const canvas = this.canvas;
        const { width, height } = canvas;

        context.setTransform(1, 0, 0, 1, 0, 0);
        context.clearRect(0, 0, width, height);

        const offset = MapGeometry.gridOffsets(this.center, this.canvasLayer.getTopLeft());
        const scale = Math.pow(2, map.zoom) * this.resolutionScale;

        // scale to the buffer's map zoom level
        context.scale(scale / this.bufferZoomScalar, scale / this.bufferZoomScalar);

        // move to the buffer's location
        context.translate(this.bufferScalar.x * (this.bufferOffset.x - offset.x),
                          this.bufferScalar.y * (this.bufferOffset.y - offset.y));

        context.drawImage(this.buffer, -PADDING_SIZE, -PADDING_SIZE);
    }

    renderToBuffer() {
        const map = this.map;
        const mapProjection = map.getProjection();

        if (!mapProjection) {
            this.canvasLayer.scheduleUpdate();
            return;
        }

        const topLeft = this.canvasLayer.getTopLeft();
        if (!topLeft) {
            return;
        }

        const buffer = this.buffer;
        const bufferContext = this.bufferContext;
        const { width, height } = buffer;

        bufferContext.setTransform(1, 0, 0, 1, 0, 0);
        bufferContext.clearRect(0, 0, width, height);

        bufferContext.translate(PADDING_SIZE, PADDING_SIZE);

        const distanceScalar = this.calculateScaling(topLeft);

        const meterOffsets = MapGeometry.gridOffsets(this.center, topLeft);

        // Scale Based on the current zoom Level
        const zoomScalar = Math.pow(2, map.zoom) * this.resolutionScale;

        // this combined scalar adjusts for both zoom level and converts the
        // cartesion coordinates to meters, scaled for the current top left
        // top left of the viewport.  This linear scale adjustment is only
        // accurate locally (<10 miles or so), which is why it most be
        // recalculated for new viewports)
        const combinedScalar = distanceScalar.scale(zoomScalar);

        bufferContext.scale(combinedScalar.x, combinedScalar.y);
        bufferContext.translate(-meterOffsets.x, -meterOffsets.y);

        if (this.hadAdd) {
            this.shapes = _.sortBy(this.shapes, 'zIndex');
            this.hadAdd = false;
        }

        if (!this.hadRemoved) {
            for (let i = 0; i < this.shapes.length; i++) {
                this.shapes[i].render(this.bufferContext);
            }
        } else {
            this.hadRemoved = false;

            const oldShapes = this.shapes;

            // there were removed modules, so while passing through the loop
            // filter them out, only rendering things that are still on the map
            // and creating a new loop with only 'active' shapes
            this.shapes = [];

            for (let i = 0; i < oldShapes.length; i++) {
                const shape = oldShapes[i];

                if (shape.__onMap) {
                    shape.render(this.bufferContext);
                    this.shapes.push(shape);
                }
            }
        }

        this.bufferOffset = meterOffsets;
        this.bufferZoomScalar = zoomScalar;
        this.bufferScalar = combinedScalar;
    }

    calculateScaling(location, delta = 1) {
        const projection = this.map.getProjection();

        const referencePoint = projection.fromLatLngToPoint(location);

        const yDelta = projection.fromLatLngToPoint(
            google.maps.geometry.spherical.computeOffset(location, delta, 0)
        );

        const xDelta = projection.fromLatLngToPoint(
            google.maps.geometry.spherical.computeOffset(location, delta, 90)
        );

        return new Vector(
            (xDelta.x - referencePoint.x) / delta,
            (yDelta.y - referencePoint.y) / delta,
            1,
        );
    }

    render() {
        this.renderToBuffer();
        this.update();
    }

    addShape(shape) {
        if (!shape.__onMap) {
            shape.__onMap = true;
            this.shapes.push(shape);
        }

        // if the user updated the zIndex inplace, we need to make sure to
        // mark the stack dirty and re-sort.  Note: this is probably unnecessary
        // in HelioScope, but makes for 'correctness' in the implementation.
        this.hadAdd = true;

        this.scheduleRender();
    }

    removeShape(shape) {
        if (shape.__onMap) {
            shape.__onMap = false;
            this.hadRemoved = true;
            this.scheduleRender();
        }
    }

    createPolygon(polygonOptions) {
        polygonOptions.map = this;
        return new CanvasPolygon(polygonOptions);
    }

    createPolyline(polylineOptions) {
        polylineOptions.map = this;
        return new CanvasPolyline(polylineOptions);
    }

    clearPolygons() {
        this.shapes.length = 0;
        this.scheduleRender();
    }
}
