/* global window:true, angular:true, _:true, document:true */

(function (angular, _) {
    'use strict';

    var mod = angular.module('helioscope.documentation.directives', ['ngMathJax']);

    mod.directive('docs', ['MathJax', '$log', function (MathJax, $log) {
        return {
            template: '<div ng-transclude></div>',
            restrict: 'EA',
            transclude: true,
            controller: ['$scope', function ($scope) {
                $scope.chapters = [];
                this.addChapter = function (chapter) {
                    $scope.chapters.push(chapter);
                    this.refresh();
                };
                var refresher = null,
                    doMath = _.debounce(function () {
                        MathJax.typeset(function () {
                            $log.log("Done!");
                        });
                    }, 100);

                this.registerHandler = function (r) {
                    refresher = r;
                };


                this.refresh = function () {
                    refresher();
                    doMath();
                };

            }]
            // link: function () {
            // }
        };
    }]);

    mod.directive('docNavigation', ['$window', function ($window) {
        return {
            templateUrl: require('helioscope/app/documentation/partials/directives.docs.sidebar.html'),
            restrict: 'EA',
            require: '^docs',
            transclude: true,
            controller: ['$scope', function ($scope) {
                $scope.allSections = [];
                $scope.refresh = function () {
                    $scope.allSections = $scope.getChapters();
                };

                $scope.getChapters = function () {
                    var chaps = [];
                    angular.forEach($scope.chapters, function (chapter) {
                        chaps.push(chapter);
                        angular.forEach(chapter.sections, function (section) {
                            chaps.push(section);
                        });
                    });
                    return chaps;
                };

                $scope.gotoChapter = function (chapter) {
                    window.scrollTo(0, chapter.element[0].offsetTop);
                };

            }],
            link: function (scope, element, attrs, docCtrl) {
                scope.chapters = scope.$parent.chapters;

                var fixedOffset = element[0].offsetTop - 10,
                    target = angular.element($window);

                docCtrl.registerHandler(scope.refresh);

                function scrollUpdate() {
                    // if pageYOffset is defined use it, otherwise use other crap for IE
                    var offset, iebody;

                    if (angular.isDefined($window.pageYOffset)) {
                        offset = $window.pageYOffset;
                    } else {
                        iebody = (document.compatMode && document.compatMode !== "BackCompat") ? document.documentElement : document.body;
                        offset = iebody.scrollTop;
                    }

                    if (offset > fixedOffset) {
                        element.css({top: offset - fixedOffset});
                    } else {
                        element.css({top: 0});
                    }

                    offset += 75;
                    scope.activeItem = _.find(scope.allSections, function (s) {
                        if (s.sections && s.sections[0]) {
                            return s.element[0].offsetTop <= offset && offset < s.sections[0].element[0].offsetTop;
                        }
                        return s.element[0].offsetTop <= offset && offset < s.element[0].offsetTop + s.element[0].offsetHeight;
                    });
                    scope.$apply();
                }

                target.bind('scroll', _.throttle(scrollUpdate, 300));
            }
        };
    }]);

    mod.directive('docChapter', [function () {
        return {
            templateUrl: require('helioscope/app/documentation/partials/directives.docs.chapter.html'),
            require: '^docs',
            restrict: 'EA',
            transclude: true,
            scope: {title: '@', hideHeading: '@'},
            controller: ['$scope', function ($scope) {
                $scope.sections = [];
                this.addSection = function (section) {
                    $scope.sections.push(section);
                    $scope.$parent.refresh();
                };
            }],
            link: function (scope, element, attrs, docCtrl) {
                scope.hideHeading = (attrs.hideHeading !== undefined);
                docCtrl.addChapter({'title': scope.title, 'element': element, 'sections': scope.sections, 'chapter': true});
            }
        };
    }]);

    mod.directive('docSection', [function () {
        return {
            templateUrl: require('helioscope/app/documentation/partials/directives.docs.section.html'),
            require: '^docChapter',
            restrict: 'EA',
            transclude: true,
            scope: {docSection: '@'},
            link: function (scope, element, attrs, chapterControl) {
                scope.title = scope.docSection;
                chapterControl.addSection({'title': scope.docSection, 'element': element, 'chapter': false});
            }
        };
    }]);

    mod.directive('scrollTarget', ['$timeout', '$uiViewScroll', function ($timeout, $uiViewScroll) {
        return {
            restrict: 'EA',
            scope: {'scrollTarget': '='},
            link: function (scope, element, attrs) {

                scope.$watch('scrollTarget', function (val) {
                    if (val) {
                        $uiViewScroll(element);

                        $timeout(function () {
                            $uiViewScroll(element);
                        }, 150);
                    }
                });
            }
        };
    }]);

}(angular, _));
