import { configureRelationalResources } from 'helioscope/app/utilities/relational';

const httpConfig = {
    stripTrailingSlashes: false,
};


export const {
    RelationalBase,
    baseResourceFactory,
    relationship,
    deserializeObject,
} = configureRelationalResources(httpConfig);
