/* global angular:true, google:true */

import { $q, $log } from 'helioscope/app/utilities/ng';
import {createContextMenu, getBoundInfoBox} from './components';

const ZOOM_SCALE = {
    17: 0.6,
    18: 0.75,
    19: 0.85,
    20: 0.95,
    21: 1,
    22: 1,
    minZoom: 17,
    maxZoom: 22,
};

function averagePosition(pt1, pt2) {
    return new google.maps.LatLng((pt1.lat() + pt2.lat()) / 2, (pt1.lng() + pt2.lng()) / 2);
}

function distance(pt1, pt2) {
    return google.maps.geometry.spherical.computeDistanceBetween(pt1, pt2);
}

function heading(pt1, pt2) {
    return ((google.maps.geometry.spherical.computeHeading(pt1, pt2) + 360) % 360);
}

/**
 * get the ratio of pixels-per-meter from the map viewport
 */
function pixelScaleFactor(map) {
    //
    const bounds = map.getBounds();
    if (!bounds) {
        return undefined;
    }

    const meters = distance(bounds.getNorthEast(), bounds.getSouthWest());
    const nePx = map.fromLatLngToContainerPixel(bounds.getNorthEast());

    if (nePx === undefined) {
        // the map projection isn't defined yet
        return undefined;
    }

    const swPx = map.fromLatLngToContainerPixel(bounds.getSouthWest());
    const pixels = Math.sqrt(Math.pow(nePx.x - swPx.x, 2) + Math.pow(nePx.y - swPx.y, 2));

    return pixels / meters;
}

export class PolygonLabelHandler {
    constructor(polygon) {
        this.config = polygon.extensions.edges;
        this.positionNotifier = $q.defer();

        this.polygon = polygon;
        this.setMap(polygon.getMap());

        if (this.config.contextMenu) {
            this.contextMenu = createContextMenu(this.map, this.config.contextMenu);
        } else {
            this.contextMenu = angular.noop;
        }

        this._isVisible = true;
        this.setPath(polygon.getPath());
    }

    addZoomListener() {
        const handler = () => {
            try {
                this.pixelScaleFactor = pixelScaleFactor(this.map);
                this.updateLabelPositions();
            } catch (err) {
                // this should only happen if the the polygon has been hidden after the draw labels command
                $log.warn("Couldn't draw labels");
            }
        };

        this.zoomListener = google.maps.event.addListener(this.map, 'zoom_changed', handler);
        google.maps.event.addListenerOnce(this.map, 'projection_changed', handler);
        google.maps.event.addListenerOnce(this.map, 'idle', handler);

        this.pixelScaleFactor = pixelScaleFactor(this.map);

        return this.zoomListener;
    }

    addShiftListener() {
        this.shiftListener = google.maps.event.addListener(this.polygon, 'mousemove', () => {
            if (!this.shift && this.polygon.editable) {
                this.shiftLabels(true);

                google.maps.event.addListenerOnce(this.polygon, 'mouseout', () => {
                    _.delay(() => this.shiftLabels(false), 500);
                });
            }
        });

        return this.shiftListener;
    }

    clear() {
        if (this.labels === undefined) {
            return;
        }

        this.labels.forEach(label => label.close());
        this.labels.clear();
    }

    setMap(map) {
        if (this.map === map) {
            return;
        }

        if (this.zoomListener !== undefined) {
            google.maps.event.removeListener(this.zoomListener);
        }

        if (this.shiftListener !== undefined) {
            google.maps.event.removeListener(this.shiftListener);
        }


        this.clear();
        this.map = map;

        if (this.map) {
            this.addZoomListener();
            this.addShiftListener();
        }
    }

    setPath(path) {
        this.clear();

        this.path = path;
        this.labels = this.createDistanceLabels();
        this.processChanges();
    }

    createDistanceLabels() {
        const path = this.path;
        const length = path.getLength();
        const labels = new google.maps.MVCArray();

        if (length < 2) {
            // if the path is less than two, no labels
            angular.noop();
        } else if (length === 2) {
            // if two elements, don't make a return label
            labels.setAt(0, this.makeDistanceLabel(0));
        } else {
            path.forEach((latLng, idx) => {
                angular.noop(latLng);
                labels.setAt(idx, this.makeDistanceLabel(idx));
            });
        }

        return labels;
    }

    getScopeVars(index) {
        const pt1 = this.path.getAt(index);
        const pt2 = this.path.getAt((index + 1) % this.path.getLength());

        return {
            distance: pt1 && pt2 ? distance(pt1, pt2) : 0,
            heading: pt1 && pt2 ? heading(pt1, pt2) : 0,
            averagePosition: pt1 && pt2 ? averagePosition(pt1, pt2) : 0,
        };
    }

    makeDistanceLabel(index) {
        const scopeVars = this.getScopeVars(index);
        const mapLabel = getBoundInfoBox({
            content: '<div class="tooltip-inner">{{distance|hsDistance:1}}</div>',
            scopeVars: scopeVars,
            boxClass: 'map-tooltip tooltip',
            maxWidth: 0,
            position: scopeVars.averagePosition,
            map: this.map,
            closeBoxURL: '',
            draggable: true,
            zIndex: this.config.zIndex,
            pane: 'overlayMouseTarget',
            contextMenu: (this.config.contextMenu !== undefined),
            enableEventPropagation: true,
            disableAutoPan: true,
            visible: this._isVisible,
        });

        google.maps.event.addDomListenerOnce(mapLabel, 'domready', () => {
            this.updateLabelPosition(mapLabel);
        });


        mapLabel.eventBus.then(
            angular.noop,  // success
            angular.noop,  // error
            (evt) => {
                switch (evt.name) {
                case 'new_position':
                    this.positionNotifier.notify(index, evt.position);
                    break;
                case 'context_menu':
                    this.contextMenu(evt.event, evt.scopeVars);
                    break;
                default:
                    break;
                }
            }
        );

        mapLabel.open(this.map);

        return mapLabel;
    }

    updateDistanceLabel(index) {
        const label = this.labels.getAt(index);

        if (label === undefined) {
            return;
        }

        const scopeVars = this.getScopeVars(index);
        label.$update(scopeVars);
        if (scopeVars.distance > 0 && this._isVisible) {
            label.setVisible(true);
        } else {
            label.setVisible(false);
        }

        this.updateLabelPosition(label);
    }

    processChanges() {
        this.updateSignedArea();

        if (this.config.showFinalLabel === false) {
            const length = this.labels.getLength();
            if (length > 1) {
                this.labels.getAt(length - 1).setVisible(false);
            }
        }
    }

    hide() {
        this.labels.forEach(label => label.hide());
        this._isVisible = false;
    }

    show() {
        this.labels.forEach(label => label.show());
        this._isVisible = true;
    }

    updateSignedArea() {
        const last = this.signedArea || 0;

        this.signedArea = google.maps.geometry.spherical.computeSignedArea(this.path);

        if (this.shift && this.signedArea * last <= 0) {
            // is this polygon being shifted & did the orientation of the polygon change?
            _.defer(() => this.updateLabelPositions());
        }

        return this.signedArea;
    }

    shiftLabels(shift) {
        this.shift = (shift === true);

        if (this.shift) {
            this.updateLabelPositions(true);
        } else {
            this.updateLabelPositions(true);
        }
    }

    updateLabelPositions(animate) {
        this.labels.forEach(label => this.updateLabelPosition(label, animate));
    }

    getZoomScale() {
        return Math.log(parseFloat(this.config.labelScaleFactor) + 1.5) * ZOOM_SCALE[Math.min(ZOOM_SCALE.maxZoom, Math.max(this.map.getZoom(), ZOOM_SCALE.minZoom))];
    }

    updateLabelPosition(label, animate) {
        const div = label.getContent();
        const scaleFactor = this.getZoomScale();

        const pixelDistance = label.$scope.distance * this.pixelScaleFactor;

        // if the line segment is too small for the label, shift it anyway
        const shift = (this.shift || (pixelDistance < (div.offsetWidth * scaleFactor) && this.config.shiftShortSegments));

        // translate the shape to the center of the element and rotate it
        const translation = 'translate(-' + Math.round(div.offsetWidth / 2) + 'px,-' + Math.round(div.offsetHeight / 2) + 'px)';
        const rotation = 'rotate(' + Math.round((label.$scope.heading % 180) - 90) + 'deg)';

        const outsideDirection = (label.$scope.heading >= 0 && label.$scope.heading < 180 ? 1 : -1) * (this.signedArea > 0 ? 1 : -1);
        const shiftTranslation = shift ? 'translateY(' + outsideDirection * (6 + Math.round(div.offsetHeight * scaleFactor / 2)) + 'px)' : '';

        const scale = 'scale(' + scaleFactor + ',' + scaleFactor + ')';

        const animation = animate ? '0.5s ease-in-out' : '';

        const transformation = translation + ' ' + rotation + ' ' + shiftTranslation + ' ' + scale;

        label.setOptions({
            position: label.$scope.averagePosition,
            boxStyle: {
                '-moz-transform': transformation, /* Chrome, Safari, Opera */
                '-ms-transform': transformation, /* IE 9 */
                '-webkit-transform': transformation, /* Chrome, Safari, Opera */
                'transform': transformation,
                '-webkit-transition': animation,
                '-moz-transition': animation,
                '-o-transition': animation,
                'transition': animation,
            },
        });
    }


    addPoint(path, index) {
        const length = path.getLength();

        if (length === 2) {
            this.labels.insertAt(0, this.makeDistanceLabel(0));
        } else if (length === 3) {
            this.labels.insertAt(1, this.makeDistanceLabel(1));
            this.labels.insertAt(2, this.makeDistanceLabel(2));
        }
        if (length > 3) {
            this.labels.insertAt(index, this.makeDistanceLabel(index));
            this.updateDistanceLabel((index + length - 1) % length);
        }

        this.processChanges();
    }

    setPoint(path, index) {
        const length = path.getLength();

        if (length > 2) {
            this.updateDistanceLabel(index);
            this.updateDistanceLabel((index + length - 1) % length);
        } else if (length === 2) {
            this.updateDistanceLabel(0);
        }

        this.processChanges();
    }

    removePoint(path, index) {
        const length = path.getLength();
        const pt = this.labels.removeAt(index);

        if (pt === undefined) {
            return;
        }
        pt.close();

        this.updateDistanceLabel(index % length);
        this.updateDistanceLabel((index + length - 1) % length);

        if (length === 2) {
            // if there are exactly two points, no point on return segment
            this.labels.removeAt(1).close();
        }

        this.processChanges();
    }

    showContextMenu(event, edge) {
        this.contextMenu(event, this.getScopeVars(edge));
    }
}
