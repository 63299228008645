import { sortBy, uniq } from 'lodash';


import {
    Bounds,
    Plane,
    Vector,
    pathIntersects as pathIntersects_,
    pointInPolygon as pointInPolygon_,
} from 'helioscope/app/utilities/geometry';

import { THE_GROUND } from 'helioscope/app/designer/field_segment/PhysicalSurface';

const pathIntersects = (path1, path2) => (
    (path1 === THE_GROUND) || (path2 === THE_GROUND) || pathIntersects_(path1, path2)
);
const pointInPolygon = (pt, path) => (path === THE_GROUND) || pointInPolygon_(pt, path);

const MINIMUM_HEIGHT = 1e-6;
export class StackableScene {
    constructor() {
        this.rootNode = new StackableNode(THE_GROUND);

        this.rootNode.updateGeometry();
        this.rootNode.updateSurfacePlane(null, 0);
    }

    get children() {
        return this.rootNode.children;
    }

    /**
     * remove a node from the scen and propagate the geometry changes
     * returns all the nodes affected by this change
     */
    removeNode(node) {
        if (node.parent) {
            node.parent.removeChild(node);
        }

        this.remove2dRelationships(node);

        const affected = [];
        const children = [...node.children]; // TODO: maybe an unnecessary copy

        for (const child of children) {
            node.parent.addChild(child);
            affected.push(...this.updateNode(child));
        }

        return affected;
    }

    /**
     * update a node in the scene and propagate any position changes
     * returns all the nodes that were shifted by this (And always the original node, even if it didn't move)
     */
    updateNode(node) {
        this.updateNodeGeometry(node);

        this.updatePosition(node, true);

        const potentiallyAffected = uniq([
            ...node.contains2d, // nodes that now are descendents
            ...node.allChildren(), // nodes that were descendents
        ]);

        const affectedNodes = [node];

        // sort array by area, descending; this ensures that locations are updated
        // according to the bottommost segments first
        for (const child of sortBy(potentiallyAffected, x => -x.groundArea)) {
            // update and check for changes
            //
            if (this.updatePosition(child)) {
                affectedNodes.push(child);
            }
        }

        return affectedNodes;
    }

    updateNodeGeometry(node) {
        if (!node.updateGeometry()) {
            return; // no need to update the 2d relationships
        }

        this.remove2dRelationships(node);
        const { contains, containedBy, intersects } = this.find2DRelationships(node);

        node.intersects2d = new Set(intersects);
        for (const intersectee of intersects) {
            intersectee.intersects2d.add(node);
        }

        node.contains2d = new Set(contains);
        for (const contained of contains) {
            contained.containedBy2d.add(node);
        }

        node.containedBy2d = new Set(containedBy);
        for (const container of containedBy) {
            container.contains2d.add(node);
        }
    }

    remove2dRelationships(node) {
        for (const oldIntersector of node.intersects2d) {
            oldIntersector.intersects2d.delete(node);
        }

        for (const oldContainer of node.contains2d) {
            oldContainer.containedBy2d.delete(node);
        }

        for (const oldContainedBy of node.containedBy2d) {
            oldContainedBy.contains2d.delete(node);
        }
    }

    find2DRelationships(targetNode, ancestors = this.children, searched = new Set()) {
        const intersects = [];
        const contains = [];
        const containedBy = [];

        for (const node of ancestors) {
            if (searched.has(node)) {
                continue;
            }

            searched.add(node);

            if (node === targetNode) {
                // no need to search the targetNode, just its children
            } else if (!node.bounds.intersects(targetNode.bounds)) {
                continue;
            } else {
                const hasIntersection = pathIntersects(node.path, targetNode.path);
                const containsTarget = (
                    !node.surface.sceneLeafOnly &&
                    !hasIntersection &&
                    pointInPolygon(targetNode.path[0], node.path));
                const containedByTarget = (
                    !targetNode.surface.sceneLeafOnly &&
                    !(hasIntersection || containsTarget) &&
                    pointInPolygon(node.path[0], targetNode.path));

                if (containedByTarget) {
                    contains.push(node, ...node.contains2d);
                    continue;
                } else if (containsTarget) {
                    containedBy.push(node);
                } else if (hasIntersection) {
                    intersects.push(node);
                } else {
                    continue;
                }
            }

            const {
                intersects: intersects_,
                containedBy: containedBy_,
                contains: contains_,
            } = this.find2DRelationships(targetNode, node.children, searched);

            containedBy.push(...containedBy_);
            intersects.push(...intersects_);
            contains.push(...contains_);
        }

        return {
            intersects, contains, containedBy,
        };
    }


    /**
     * get the best potential parents of a site. The best parents are the
     * tallest (first) then smallest (second) node that has no children that
     * enclose the area.
     *
     * Taller is better because the surface should always be on top
     * Smaller is better, because it enables nesting of areas without explicit
     * height differences
     */
    getBestParent(node) {
        let bestParent = this.rootNode;
        let bestHeight = 0;

        const referencePoint = node.surface.referencePoint();

        for (const parent of this.getPotentialParents(node)) {
            const baseHeight = node.calculateBaseHeight(parent, referencePoint);

            if (baseHeight > bestHeight) {
                bestParent = parent;
                bestHeight = baseHeight;
            } else if (baseHeight === bestHeight && parent.groundArea < bestParent.groundArea) {
                bestParent = parent;
            }
        }

        return { bestParent, bestHeight };
    }

    /**
     * potential parents are any nodes that contain the child and dont have any
     * children that contain the child
     */
    getPotentialParents(targetNode) {
        const potentialParents = new Set(targetNode.containedBy2d);

        for (const node of targetNode.containedBy2d) {
            potentialParents.delete(node.parent);
        }

        return potentialParents;
    }

    updatePosition(node, forceUpdate = false) {
        const { bestParent, bestHeight } = this.getBestParent(node);

        const parentChanged = node.parent !== bestParent;
        const heightChanged = node.baseHeight !== bestHeight;

        if (parentChanged || heightChanged || forceUpdate) {
            node.updateSurfacePlane(bestParent, bestHeight);
            return true;
        }

        return false;
    }
}


/**
 * class to describe a graph of stackable surfaces every surface should have
 * exactly one parent, but can have many children
 *
 * surfaces contained need to be able to calculate a 'baseHeight' from any other
 * surface, where the baseHeight is where the reference height would be on that
 * surface (to pick the appropriate tilted surface if there are several)
 */
export class StackableNode {
    constructor(physicalSurface) {
        this.children = new Set(); // thing contained by this node

        this.intersects2d = new Set();
        this.contains2d = new Set();
        this.containedBy2d = new Set();

        this.surface = physicalSurface;
        this.parent = null;
        this.baseHeight = null; // TODO: should this come from preexisting data if possible?

        this.isTheGround = (physicalSurface === THE_GROUND);
    }

    updateGeometry() {
        // relies on paths being immutable to save recalculating 2d relationships
        if (this.surface.geometry.path !== this.path) {
            this.groundArea = this.surface.groundArea();

            // external things should use the comparison convenience functions below
            this.path = this.surface.geometry.path;
            this.bounds = new Bounds(this.surface.geometry.path);

            return true;
        }

        return false;
    }

    addChild(child) {
        if (child.parent) {
            child.parent.removeChild(child);
        }

        child.parent = this;
        this.children.add(child);

        // child.createReferencePlane();
    }

    removeChild(child) {
        return this.children.delete(child);
    }


    // calculate the potential base height if using a parent
    calculateBaseHeight(parent, referencePoint = this.surface.referencePoint()) {
        return parent.plane.pointFromXY(referencePoint).z;
    }

    /**
     * reposition the plane in 3d space, given a new base height
     */
    updateSurfacePlane(parent, baseHeight) {
        if (parent) {
            parent.addChild(this);
        }

        this.baseHeight = baseHeight;

        const { x, y } = this.surface.referencePoint();

        this.plane = Plane.fromOrientation(
            this.surface.surfaceTilt(),
            this.surface.surfaceAzimuth(),
            // shift the plane up slightly so that zero height things don't z-fight with their parents
            new Vector(x, y, this.baseHeight + this.surface.referenceHeight + MINIMUM_HEIGHT),
        );

        this.path3d = this.plane.pathFromXYs(this.path); // used for shadows
    }

    numParents() { // only really used for zIndexing
        if (this.parent === null || this.parent.isTheGround) {
            return 0;
        }

        return this.parent.numParents() + 1;
    }

    ancestorOf(otherNode) {
        let parent = this.parent;
        do {
            if (parent === otherNode) {
                return true;
            }

            parent = parent.parent;
        } while (parent);

        return false;
    }

    allChildren() {
        const rtn = [];

        for (const child of this.children) {
            rtn.push(child, ...child.allChildren());
        }

        return rtn;
    }
}
