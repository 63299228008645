import _ from 'lodash';
import * as THREE from 'three';

import { $document } from 'helioscope/app/utilities/ng';
import { Plane, Vector } from 'helioscope/app/utilities/geometry';
import {
    compileBoundElement,
    genFireFoxClickFilter,
    loadTemplate,
} from 'helioscope/app/utilities/helpers';
import { PrimitiveMeshStroke, PrimitiveMeshFill } from './Primitives';
import { BRIGHT_GREEN, RendererOptions } from './RendererOptions';
import { makePhysicalSurfaceGeometrySolid, makeWireGeometry, pathToPolygonPoints } from './GLHelpers';
import { MapConfig } from '../designer/MapConfig';
import { EntityPremade } from '../designer/premade';
import { FieldSegment } from '../designer/field_segment';
import { Keepout } from '../designer/keepout';
import { calculateRelativePositionToCursor } from '../designer/actions';

function atan2Vec(vec) {
    return Math.atan2(vec.y, vec.x);
}

function radDiff(rad1, rad2) {
    const diff = Math.abs(rad1 - rad2);
    if (diff > Math.PI) {
        return 2.0 * Math.PI - diff;
    }

    return diff;
}

export function addSvgContainer(svgGroup, imageDimensions) {
    /* Takes an SVG group, like <g transform="rotate 45"><path>...</path></g>,
       and wraps it with the <svg>...</svg> container. */
    const { width, height } = imageDimensions;
    // MOVE and ROTATE cursors need to pass a custom viewBox argument
    const { viewBox = `0 0 ${width} ${height}` } = imageDimensions;
    /* eslint-disable max-len */
    return `
        <svg xmlns="http://www.w3.org/2000/svg" height="${height}" width="${width}" viewBox="${viewBox}" fill="none">${svgGroup}</svg>`;
    /* eslint-enable max-len */
}

export function groupAndRotateSvgPaths(svgPaths, degreesToRotate, imageDimensions) {
    /* Takes one, or more, SVG <path>...</path> elements, wraps them with the
       group <g>...</g> element, and rotates the group of paths. */
    const { width, height } = imageDimensions;
    // the dynamic <g transform="rotate(...)"> is from https://felt.com/blog/dynamic-cursor-rotation-css
    return `
            <g transform="rotate(${degreesToRotate} ${height / 2} ${width / 2})">${svgPaths}</g>
        `;
}

export function svgStringToCursorUrl(svgString, imageDimensions) {
    /* Takes an SVG string, like <svg>...</svg>, and turns it into a URI
       which can be used for setting a custom cursor. */
    const { width, height } = imageDimensions;
    // window.btoa docs at https://developer.mozilla.org/en-US/docs/Web/API/Window/btoa
    const ascii = window.btoa(svgString);
    // thanks https://www.svgviewer.dev/svg-to-data-uri for URI format
    return `url('data:image/svg+xml;base64,${ascii}') ${width / 2} ${height / 2}, auto`;
}

export function drawHoverZoneSquareFill(renderer, options) {
    const scene = renderer.interactLayer;

    const strokeOptions = _.assign({
        geometry: renderer.shapeBuilder.quadFillWithRotation(options.width, options.width, options.rotationRadians),
        material: renderer.inlineShaderMaterial('vertexShaderBasic', 'fragmentShaderBasic'),
        scene,
    }, options);

    return renderer.renderPrimitive(PrimitiveMeshStroke, strokeOptions);
}

export function drawHandleCircleFill(renderer, options) {
    const scene = renderer.interactLayer;

    const fillOptions = _.assign({
        geometry: renderer.shapeBuilder.circleFill(options.radius, options.segments),
        material: renderer.inlineShaderMaterial('vertexShaderBasic', 'fragmentShaderBasic'),
        scene,
    }, options);

    return renderer.renderPrimitive(PrimitiveMeshFill, fillOptions);
}

export function drawHandleCircleStroke(renderer, options) {
    const scene = renderer.interactLayer;

    const strokeGeometry = renderer.shapeBuilder.circleStroke(options.radius, options.segments);
    const strokeMaterial = renderer.inlineShaderMaterial('vertexShaderWire', 'fragmentShaderWire');

    const strokeOptions = _.assign({
        geometry: strokeGeometry,
        material: strokeMaterial,
        scene,
    }, options);

    return renderer.renderPrimitive(PrimitiveMeshStroke, strokeOptions);
}

export function drawHandleCircleStrokeInstanced(renderer, options) {
    const scene = renderer.interactLayer;

    const strokeGeometry = renderer.shapeBuilder.circleStrokeInstanced(options.radius, options.segments);
    const strokeMaterial = renderer.inlineShaderMaterial('vertexShaderWireInstanced', 'fragmentShaderWire');

    const strokeOptions = _.assign({
        geometry: strokeGeometry,
        material: strokeMaterial,
        scene,
    }, options);

    return renderer.renderPrimitive(PrimitiveMeshStroke, strokeOptions);
}

export function intersectRays2D(origin1, dir1, origin2, dir2) {
    // solve linear system
    const denom = (dir2.y * dir1.x) - (dir2.x * dir1.y);
    if (denom === 0) return null;
    const ka = origin1.y - origin2.y;
    const kb = origin1.x - origin2.x;
    const numer = (dir2.x * ka) - (dir2.y * kb);
    const kt = numer / denom;

    return new THREE.Vector2(origin1.x + (kt * dir1.x), origin1.y + (kt * dir1.y));
}

export function snapPointToEdges(point, edges) {
    let position = point;
    let minDistSq = Number.POSITIVE_INFINITY;
    let inside = false;
    let rotation = 0;
    let normal = new Vector(0, 1);

    for (const edge of edges) {
        const { pt1, pt2 } = edge;
        const edgeVec = pt2.subtract(pt1);
        const edgeLen = edgeVec.length();
        const edgeVecNorm = edgeVec.scale(1 / edgeLen);
        const compVec = point.subtract(pt1);
        const projNorm = edgeVecNorm.dot(compVec);
        if (projNorm > 0 && projNorm < edgeLen) {
            const edgeProj = pt1.add(edgeVecNorm.scale(projNorm));
            const edgeDistVec = point.subtract(edgeProj);
            if (edgeDistVec.lengthSq() < minDistSq) {
                minDistSq = edgeDistVec.lengthSq();
                position = edgeProj;
                rotation = Math.atan2(edgeVecNorm.y, edgeVecNorm.x);

                const orientation = compVec.x * edgeVec.y - compVec.y * edgeVec.x;
                inside = (orientation <= 0);

                if (inside) normal = new Vector(edgeVecNorm.y, -edgeVecNorm.x);
                else normal = new Vector(-edgeVecNorm.y, edgeVecNorm.x);
            }
        }
    }

    return { position, inside, rotation, normal };
}

export function snapDistance(dRenderer) {
    // close path distance depends on zoom level
    return dRenderer.viewportScale * 12.5;
}

export function snapVector(edgeOrigin, edgeDir, adjPoint) {
    const snapAngle = Math.PI * (45.0 / 180.0);
    const edgeDirAngle = Math.atan2(edgeDir.y, edgeDir.x);
    const adjVector = (new THREE.Vector3()).subVectors(adjPoint, edgeOrigin);
    const adjAngle = Math.atan2(adjVector.y, adjVector.x);
    const adjSnapAngle = edgeDirAngle + Math.round((adjAngle - edgeDirAngle) / snapAngle) * snapAngle;
    const adjSnapUnitVector = new THREE.Vector3(Math.cos(adjSnapAngle), Math.sin(adjSnapAngle), 0);
    adjVector.projectOnVector(adjSnapUnitVector);
    return adjVector;
}

export function computeAnglePointSnap(currPoint, snapSegA1, snapSegB1, snapSegA2, snapSegB2, threshold) {
    // try to snap our moving edges to the adjacent fixed edges
    const snapDir1 = snapSegB1.clone().sub(snapSegA1);
    const snapVector1 = snapVector(snapSegA1, snapDir1, currPoint);

    const snapDir2 = snapSegB2.clone().sub(snapSegA2);
    const snapVector2 = snapVector(snapSegA2, snapDir2, currPoint);

    const orgVector1Gr = (new THREE.Vector2()).subVectors(currPoint, snapSegA1);
    const orgVector2Gr = (new THREE.Vector2()).subVectors(currPoint, snapSegA2);
    const snapDiff1 = radDiff(atan2Vec(snapVector1), atan2Vec(orgVector1Gr));
    const snapDiff2 = radDiff(atan2Vec(snapVector2), atan2Vec(orgVector2Gr));
    const snapPointGr = (snapDiff1 < snapDiff2) ?
        (new THREE.Vector2()).addVectors(snapSegA1, snapVector1) :
        (new THREE.Vector2()).addVectors(snapSegA2, snapVector2);

    const snapSegA1Gr = (new THREE.Vector2()).copy(snapSegA1);
    const snapVector1Gr = (new THREE.Vector2()).copy(snapVector1);
    const snapSegA2Gr = (new THREE.Vector2()).copy(snapSegA2);
    const snapVector2Gr = (new THREE.Vector2()).copy(snapVector2);

    const intersect = intersectRays2D(snapSegA1Gr, snapVector1Gr, snapSegA2Gr, snapVector2Gr);
    if (intersect &&
        (new THREE.Vector2()).subVectors(snapPointGr, intersect).length() < threshold) {
        return intersect;
    }

    return snapPointGr;
}

export function bestParentSurface(path, surfaces) {
    const pathCopy = toGeometryUtilVectorPath2D(path);
    const parentSurfaces = _.filter(surfaces, ps => ps.containsPath(pathCopy));
    return _.minBy(parentSurfaces, ps => ps.groundArea());
}

export function bestParentSurfaceRay(path, ray, surfaces) {
    const pathCopy = path ? toGeometryUtilVectorPath2D(path) : null;
    const parentSurfaces = _.filter(surfaces,
        ps => {
            const surfacePoint = raySurfaceIntersect(ps, ray);
            if (surfacePoint && pathCopy) {
                pathCopy[pathCopy.length - 1] = Vector.fromObject(surfacePoint);
                return ps.containsPath(pathCopy);
            } else if (surfacePoint) {
                return surfacePoint;
            }
            return false;
        });

    return _.minBy(parentSurfaces, ps => ps.groundArea());
}

export function raySurfaceIntersect(ps, ray, ignoreContain = false) {
    const plane = Plane.fromOrientation(ps.surfaceTilt(), ps.surfaceAzimuth(), ps.geometry.path_3d[0]);
    const surfacePoint = plane.projectPoint(Vector.fromObject(ray.origin), ray.dir);
    if (ignoreContain || ps.containsPoint(surfacePoint)) return surfacePoint;
    return null;
}

export function rayGroundPlaneIntersect(ray, groundZ = 0) {
    const tparam = (ray.origin.z - groundZ) / ray.dir.z;
    const gp = (new THREE.Vector3())
        .copy(ray.dir)
        .multiplyScalar(-tparam)
        .add(ray.origin);
    return gp;
}

export function hitTestRendererObjects(renderer, pt) {
    const mainIntersects = renderer.objectIntersectMain(pt);
    const firstMainIntersect = _.find(mainIntersects || [], i => i.object.userData);
    const mainObject = firstMainIntersect ? firstMainIntersect.object : null;
    const intersectPoint = firstMainIntersect ? firstMainIntersect.point : interactGroundPoint(renderer, pt);

    const interactIntersects = renderer.objectIntersectInteract(pt);
    const firstInteractIntersect = _.find(interactIntersects || [], i => i.object.userData);
    const interactObject = firstInteractIntersect ? firstInteractIntersect.object : null;

    return { mainObject, intersectPoint, interactObject };
}

export function hitTestInteractObjects(renderer, pt) {
    const interactIntersects = renderer.objectIntersectInteract(pt);
    const firstInteractIntersect = _.find(interactIntersects || [], i => i.object.userData);
    return firstInteractIntersect ? firstInteractIntersect.object : null;
}

export function interactRay(dRenderer, pt, minZ = 0.05) {
    const ray = dRenderer.transformClientToWorldRay(pt);

    // don't let users draw at too oblique an angle
    if (minZ === 0.0 || ray.dir.z < -minZ) return ray;
    return null;
}

export function interactGroundPoint(dRenderer, pt, groundZ = 0) {
    const ray = interactRay(dRenderer, pt);
    if (ray) return rayGroundPlaneIntersect(ray, groundZ);
    return null;
}

export function containerPoint(dRenderer, event) {
    const rect = dRenderer.container.getBoundingClientRect();
    return new THREE.Vector2(event.clientX - rect.left, event.clientY - rect.top);
}

export function toGeometryUtilVectorPath3D(path) {
    return _.map(path, (pt) => new Vector(pt.x, pt.y, pt.z));
}

export function toGeometryUtilVectorPath2D(path) {
    return _.map(path, (pt) => new Vector(pt.x, pt.y, 0));
}

export function autoPanCamera(renderer, clientPt, callback) {
    const cameraDelta = new THREE.Vector3(0, 0, 0);
    const dragPanSpeed = 20.0;
    const edgeThreshold = 20;
    const clientSize = new THREE.Vector2(window.innerWidth, window.innerHeight);

    if (clientPt.x < edgeThreshold) {
        cameraDelta.add(
            (new THREE.Vector3()).copy(renderer.cameraPanRight)
                .multiplyScalar(renderer.viewportScale * dragPanSpeed));
    } else if (clientPt.x > clientSize.x - edgeThreshold) {
        cameraDelta.add(
            (new THREE.Vector3()).copy(renderer.cameraPanRight)
                .multiplyScalar(-renderer.viewportScale * dragPanSpeed));
    }

    if (clientPt.y < edgeThreshold) {
        cameraDelta.add(
            (new THREE.Vector3()).copy(renderer.cameraPanForward)
                .multiplyScalar(renderer.viewportScale * dragPanSpeed));
    } else if (clientPt.y > clientSize.y - edgeThreshold) {
        cameraDelta.add(
            (new THREE.Vector3()).copy(renderer.cameraPanForward)
                .multiplyScalar(-renderer.viewportScale * dragPanSpeed));
    }

    if (cameraDelta.x || cameraDelta.y) {
        renderer.cameraCenter.add(cameraDelta);
        renderer.recomputePrimaryCamera();
        renderer.dirtyFrame();

        const dummyFn = _.debounce(() => {
            dummyFn.cancel();
            callback();
        }, 100);
        dummyFn();
        return dummyFn;
    }

    return null;
}

export function panThreshold(downPoint, currPoint, dist = 20) {
    const panDelta = (new THREE.Vector2()).subVectors(downPoint, currPoint);
    return panDelta.length() > dist;
}

export function zoomView(renderer, event) {
    const pt = containerPoint(renderer, event);
    const orgRay = renderer.transformClientToWorldRay(pt);
    const maxDelta = 120;

    // zoom in and out
    const delta = _.clamp((event.wheelDelta) ? -event.wheelDelta : (event.deltaY * 120), -maxDelta, maxDelta);
    renderer.viewportScale = _.clamp(
        renderer.viewportScale * (1.05 ** (delta * 0.01)),
        RendererOptions.viewOptions.minZoom, RendererOptions.viewOptions.maxZoom);
    renderer.recomputePrimaryCamera();

    // keep point under mouse fixed after zooming if primaryCamerayType is Orthographic
    if (renderer.cameraTheta > -Math.PI * 0.49 && renderer.primaryCameraType !== 'Perspective') {
        const newRay = renderer.transformClientToWorldRay(pt);
        const newRayProjOrigin = rayGroundPlaneIntersect(newRay, orgRay.origin.z);
        renderer.cameraCenter
            .add((new THREE.Vector3())
                .subVectors(orgRay.origin, newRayProjOrigin));
        renderer.cameraCenter.z = 0;
        renderer.recomputePrimaryCamera();
        renderer.broadcastCameraSettings();
    }

    renderer.dirtyFrame();
}

export function publishUpdatePath(renderer, surface, objectType, rawPath) {
    const { dispatcher } = renderer;

    const oldPath = _.map(surface.geometry.path, i => i.getCopy());
    const newPath = toGeometryUtilVectorPath2D(rawPath);
    if (objectType === 'FieldSegment') {
        dispatcher.publish('FieldSegment:updatePath',
            { fieldSegment: surface, oldPath, newPath });
        surface.geometry.path = newPath;
    } else if (objectType === 'Keepout') {
        dispatcher.publish('Keepout:updatePath',
            { keepout: surface, oldPath, newPath });
        surface.geometry.path = newPath;
    }

    renderer.renderKeepout(surface);
}

export function createContextMenu(dRenderer, options, pt) {
    const element = angular.element('<div class="map-overlay-container">');
    const { classes } = options;

    element.addClass(classes);

    if (options.html) {
        element.html(options.html);
        compileBoundElement(element, options);
    } else {
        loadTemplate(options.templateUrl).then((html) => {
            element.html(html);
            compileBoundElement(element, options);
        });
    }

    const container = $document.find('#apolloTopContainer');
    container.append(element);

    element.css({
        left: `${(pt.x)}px`,
        top: `${(pt.y)}px`,
    });

    const filterFunc = genFireFoxClickFilter();
    const closeMenuFn = (ev) => {
        if (filterFunc(ev)) {
            return;
        }
        dRenderer.container.removeEventListener('mousedown', closeMenuFn, false);
        element.unbind('click.contextMenu');
        $document.unbind('click.contextMenu');
        $document.unbind('rightclick.contextMenu');
        $document.unbind('keydown.contextMenu');
        element.remove();
    };

    dRenderer.container.addEventListener('mousedown', closeMenuFn, false);
    element.on('click.contextMenu', closeMenuFn);
    $document.on('click.contextMenu', closeMenuFn);
    $document.on('rightclick.contextMenu', closeMenuFn);
    $document.on('keydown.contextMenu', closeMenuFn);
}

export function highestSurfaceAndPoint(renderer, ray) {
    let maxPoint = rayGroundPlaneIntersect(ray);
    let maxSurface = null;
    for (const ps of renderer.design.physicalSurfaces()) {
        const surfacePoint = raySurfaceIntersect(ps, ray);
        if (surfacePoint && surfacePoint.z > maxPoint.z) {
            maxPoint = surfacePoint;
            maxSurface = ps;
        }
    }

    return { point: maxPoint, surface: maxSurface };
}

export function highestSurfacePoint(renderer, ray) {
    const { point } = highestSurfaceAndPoint(renderer, ray);
    return point;
}

export class SurfaceCursorHelper {
    constructor(dRenderer) {
        this.dRenderer = dRenderer;
        this.primitives = [];
    }

    clearCursorPosition() {
        this.cursorTopPoint = null;
        this.cursorGroundPoint = null;
    }

    forceCursorPosition(topPoint) {
        this.cursorTopPoint = (new THREE.Vector3()).copy(topPoint);
        this.cursorGroundPoint = (new THREE.Vector3()).copy(this.cursorTopPoint);
        this.cursorGroundPoint.z = 0;
    }

    forceCursorSurfacePosition(point, focusSurface) {
        this.cursorTopPoint = (new THREE.Vector3()).copy(focusSurface.pointOnSurface(point));
        this.cursorGroundPoint = (new THREE.Vector3()).copy(this.cursorTopPoint);
        this.cursorGroundPoint.z = 0;
    }

    computeCursorPosition(clientPt, focusSurface) {
        this.clearCursorPosition();

        const ray = interactRay(this.dRenderer, clientPt);
        if (!ray) return null;

        let cursorSurface = null;

        if (focusSurface) {
            const point = raySurfaceIntersect(focusSurface, ray, true);
            this.cursorTopPoint = point;
            cursorSurface = focusSurface;
        } else {
            const { point, surface } = highestSurfaceAndPoint(this.dRenderer, ray);
            this.cursorTopPoint = point;
            cursorSurface = surface;
        }

        this.cursorGroundPoint = (new THREE.Vector3()).copy(this.cursorTopPoint);
        this.cursorGroundPoint.z = 0;

        return { surfacePoint: this.cursorTopPoint, groundPoint: this.cursorGroundPoint, surface: cursorSurface };
    }
    computeCursorPositionNoSurface(clientPt) {
        this.clearCursorPosition();

        const ray = interactRay(this.dRenderer, clientPt);
        if (!ray) return null;

        const point = rayGroundPlaneIntersect(ray);

        this.cursorGroundPoint = (new THREE.Vector3()).copy(point);
        this.cursorGroundPoint.z = 0;

        return { surfacePoint: this.cursorTopPoint, groundPoint: this.cursorGroundPoint };
    }

    clearSurfaceCursorPrimitives() {
        for (const primitive of this.primitives) {
            primitive.clearInstances();
        }
        this.primitives = [];

        this.dRenderer.dirtyFrame();
    }

    getSurfaceRenderingOptions(surface) {
        if (surface instanceof FieldSegment) {
            return MapConfig.fieldSegment.base;
        } else if (surface instanceof Keepout) {
            return MapConfig.keepout.base;
        } else if (surface instanceof EntityPremade) {
            return MapConfig.premade.base;
        }
        return null;
    }

    renderSurfacesAtCursor(surfaces, cursorPoint) {
        this.computeCursorPosition(cursorPoint);
        this.clearSurfaceCursorPrimitives();

        if (this.cursorGroundPoint == null) return;

        const newPositions = calculateRelativePositionToCursor(surfaces, this.cursorGroundPoint);

        for (let i = 0; i < surfaces.length; i++) {
            const surface = surfaces[i];
            const worldDistanceToCursor = new Vector(
                newPositions[i].x - surface.centroid().x,
                newPositions[i].y - surface.centroid().y,
            );

            if (surface instanceof EntityPremade) {
                this.renderPremadeAtCursor(surface, newPositions[i]);
            } else {
                this.renderEdgePrimitive(surface, worldDistanceToCursor);
                this.renderSolidPrimitive(surface, worldDistanceToCursor);
            }
        }
    }

    renderEdgePrimitive(surface, worldDistanceToCursor) {
        const base3DPath = surface.geometry.base_3d.map(loc => worldDistanceToCursor.add(loc));
        const path3DPath = surface.geometry.path_3d.map(loc => worldDistanceToCursor.add(loc));
        const surfaceRenderingOptions = this.getSurfaceRenderingOptions(surface);

        const createEdgeOptions = (path) => ({
            geometry: makeWireGeometry(pathToPolygonPoints(path)),
            material: this.dRenderer.inlineShaderMaterial('vertexShaderWire', 'fragmentShaderWire'),
            scene: this.dRenderer.editSurfaceLayer,
            depthOffset: this.dRenderer.tinyZOffset,
            screenSpace: false,
            strokeColor: surfaceRenderingOptions.strokeColor,
            strokeWeight: surfaceRenderingOptions.strokeWeight,
        });

        this.primitives.push(this.dRenderer.renderPrimitive(PrimitiveMeshStroke, createEdgeOptions(base3DPath)));
        this.primitives.push(this.dRenderer.renderPrimitive(PrimitiveMeshStroke, createEdgeOptions(path3DPath)));

        for (let i = 0; i < base3DPath.length; i++) {
            const verticalLine = [base3DPath[i], path3DPath[i]];
            this.primitives.push(this.dRenderer.renderPrimitive(PrimitiveMeshStroke, createEdgeOptions(verticalLine)));
        }
    }

    renderSolidPrimitive(surface, worldDistanceToCursor) {
        const geometryShiftedToCursor = {
            ...surface.geometry,
            path_3d: surface.geometry.path_3d.map(loc => worldDistanceToCursor.add(loc)),
            base_3d: surface.geometry.base_3d.map(loc => worldDistanceToCursor.add(loc)),
        };
        const surfaceRenderingOptions = this.getSurfaceRenderingOptions(surface);
        const solidOptions = {
            geometry: makePhysicalSurfaceGeometrySolid(geometryShiftedToCursor),
            material: this.dRenderer.inlineShaderMaterial('vertexShaderNormal', 'fragmentShaderNormal'),
            depthOffset: -this.dRenderer.tinyZOffset,
            renderOrder: surface instanceof Keepout ? 110 : 100,
            scene: this.dRenderer.editSurfaceLayer,
            fillColor: surfaceRenderingOptions.fillColor,
            opacity: surfaceRenderingOptions.opacity,
        };

        this.primitives.push(this.dRenderer.renderPrimitive(PrimitiveMeshFill, solidOptions));
    }

    renderPremadeAtCursor(premade, worldDistanceToCursor) {
        const surfaceRenderingOptions = this.getSurfaceRenderingOptions(premade);
        const { top_radius: topRadius, bot_height: botHeight, position_3d: position3d } = premade.geometry.parameters;
        const spherePrimitiveGeometry = this.dRenderer.shapeBuilder.sphereSolid(
            12,
            24,
            premade.geometry.parameters.top_radius,
        );

        const spherePrimitive = this.dRenderer.renderPrimitive(
            PrimitiveMeshFill,
            {
                geometry: spherePrimitiveGeometry,
                material: this.dRenderer.inlineShaderMaterial('vertexShaderNormal', 'fragmentShaderNormal'),
                scene: this.dRenderer.editSurfaceLayer,
                fillColor: surfaceRenderingOptions.fillColor,
            },
        );
        worldDistanceToCursor.z = botHeight + topRadius + position3d.z;
        spherePrimitive.setRenderPosition(worldDistanceToCursor);
        this.primitives.push(spherePrimitive);

        const cylinderPrimitiveGeometry = this.dRenderer.shapeBuilder.cylinderSolid(12, 0.25, botHeight + topRadius);
        const cylinderPrimitive = this.dRenderer.renderPrimitive(
            PrimitiveMeshFill,
            {
                geometry: cylinderPrimitiveGeometry,
                material: this.dRenderer.inlineShaderMaterial('vertexShaderNormal', 'fragmentShaderNormal'),
                scene: this.dRenderer.editSurfaceLayer,
                fillColor: surfaceRenderingOptions.fillColor,
            },
        );
        worldDistanceToCursor.z = position3d.z;
        cylinderPrimitive.setRenderPosition(worldDistanceToCursor);
        this.primitives.push(cylinderPrimitive);
    }

    clearCreateCursor() {
        this.redrawCreateCursor(null, null);
    }

    redrawCreateCursor(topPoint = this.cursorTopPoint, groundPoint = this.cursorGroundPoint) {
        if (this.topPrimitive) {
            this.topPrimitive.clearInstances();
            this.topPrimitive = null;
        }

        if (this.groundPrimitive) {
            this.groundPrimitive.clearInstances();
            this.groundPrimitive = null;
        }

        if (this.linePrimitive) {
            this.linePrimitive.clearInstances();
            this.linePrimitive = null;
        }

        const scene = this.dRenderer.interactLayer;

        const fillGeometry = this.dRenderer.shapeBuilder.circleFill(
            RendererOptions.createPathOptions.cursorRadius,
            RendererOptions.createPathOptions.cursorSegments,
        );

        const fillOptions = {
            geometry: fillGeometry,
            material: this.dRenderer.inlineShaderMaterial('vertexShaderBasic', 'fragmentShaderBasic'),
            screenSpace: true,
            fillColor: RendererOptions.createPathOptions.cursorColor,
            scene,
        };

        if (topPoint) {
            this.topPrimitive = this.dRenderer.renderPrimitive(PrimitiveMeshFill, fillOptions);
            const clientPt = this.dRenderer.transformWorldToClient((new THREE.Vector3()).copy(topPoint));
            this.topPrimitive.setRenderPosition(new THREE.Vector3(clientPt.x, clientPt.y, 0));
        }

        if (groundPoint) {
            this.groundPrimitive = this.dRenderer.renderPrimitive(PrimitiveMeshFill, fillOptions);
            const clientPt = this.dRenderer.transformWorldToClient((new THREE.Vector3()).copy(groundPoint));
            this.groundPrimitive.setRenderPosition(new THREE.Vector3(clientPt.x, clientPt.y, 0));
        }

        if (topPoint && groundPoint) {
            const clientPt1 = this.dRenderer.transformWorldToClient((new THREE.Vector3()).copy(topPoint));
            const clientPt2 = this.dRenderer.transformWorldToClient((new THREE.Vector3()).copy(groundPoint));
            const points = [
                new THREE.Vector3(clientPt1.x, clientPt1.y, 0),
                new THREE.Vector3(clientPt2.x, clientPt2.y, 0),
            ];
            const lineOptions = {
                geometry: makeWireGeometry(points),
                material: this.dRenderer.inlineShaderMaterial('vertexShaderWire', 'fragmentShaderWire'),
                scene: this.dRenderer.interactLayer,
                screenSpace: true,
                strokeColor: BRIGHT_GREEN,
                strokeWeight: 1.0,
            };
            this.linePrimitive = this.dRenderer.renderPrimitive(PrimitiveMeshStroke, lineOptions);
        }

        this.dRenderer.dirtyFrame();
    }
}
