(function (angular, _) {
    'use strict';

    var mod = angular.module('helioscope.libraries.controllers.wires',
                            ['helioscope.services', 'ngGrid']);


    mod.controller('WireListCtrl', ['$scope', 'wires', '$state', '$timeout', '$filter', 'Authenticator', 'Messager', '$log',
                                    function ($scope, wires, $state, $timeout, $filter, Authenticator, messager, $log) {

      var user = angular.copy(Authenticator.user());
      user.preferences = user.preferences || {};
      user.preferences.wiring = user.preferences.wiring || {};

      var wireFilter = $filter('wireFilter');
      $scope.wiringPreferences = angular.copy(user.preferences.wiring);

      $scope.filteredWires = wires;

      $scope.updateFilter = function(){
          $scope.filteredWires = wireFilter(wires, $scope.wiringPreferences);
        };

      $scope.updateFilter();

      $scope.gridOptions = {
        data: 'filteredWires',
        showFilter:false,
        multiSelect:false,
        enableColumnResize: true,
        filterOptions :{useExternalFilter: true },
        sortInfo: {fields:['material', 'resistivity'], directions:['asc','asc']},
        columnDefs: [{field:'name', displayName:'Size'},
                     {field:'material', displayName:'Material'},
                     {field:'units', displayName:'Units', cellFilter:'hsType'},
                     {field:'resistivity', displayName:'Resistivity (Ω/m)'}]
      };

      $scope.allWires = function(wire_id){
        var wire = _.find(wires, function(w){return w.wire_gauge_id === wire_id;});
        if($scope.filteredWires.indexOf(wire) !== -1){
          return $scope.filteredWires;
        }else{
          return [wire].concat($scope.filteredWires);
        }
      };

      $scope.cancel = function(){
        $scope.wiringPreferences = angular.copy(user.preferences.wiring);
        $scope.updateFilter();
      };

      $scope.submit = function(){
        $scope.isLoading=true;
        var notification = messager.load("Saving wiring preferences...");
        angular.extend(user.preferences.wiring, $scope.wiringPreferences);
        user.$updatePreferences().then(
            function success(u){
                notification.success("Successfully saved wiring preferences");
                angular.extend(user.preferences.wiring, u.preferences.wiring);
                $scope.isLoading = false;
            }, function fail(resp){
                $log.log(resp);
                notification.error("Could not save wiring preferences");
                // angular.extend(user, backup);
                $scope.isLoading = false;
            }
        );
      };


    }]);

})(angular, _);
