/* global angular:true, _:true */
import { RelationalBase, relationship } from 'helioscope/app/relational';
import { $http } from 'helioscope/app/utilities/ng';

export class Logo extends RelationalBase {
    static relationName = 'Logo';

    static requestUploadUrl(userId, filename) {
        return $http.post('/api/logos/request_upload', {
            user_id: userId,
            filename: filename,
        });
    }
}

Logo.MAX_FILE_SIZE = 10000000; // 10MB
Logo.MAX_FILE_COUNT = 6; //

Logo.configureRelationships({
    user: relationship('User', { backref: 'logos' }),
});

Logo.createEndpoint('/api/logos/:logo_id', { logo_id: '@logo_id', email: '@user.email' });

(function mod(angular) {
    'use strict';

    var mod = angular.module('helioscope.users.logos', ['ui',
                                                        'angularFileUpload.Extensions']);

    mod.factory('Logo', () => Logo);
    mod.factory('logoGalleryDlg', function ($modal) {
        return function openGallery(user) {
            return $modal.open({
                size : 'lg',
                resolve: {
                    user: function () { return user; },

                    // get a fresh array of the users logos from the database
                    logos: function (Logo) { return Logo.query({email: user.email}).$promise; }
                },
                templateUrl: require('helioscope/app/users/partials/logos/gallery-modal.html'),
                controller: 'logoGalleryController as galleryCtrl'
            });
        };
    });

    mod.controller('logoGalleryController',  function ($log, user, Messager, FileUploaderS3) {
        var self = this;

        self.user = user;
        self.uploader = createUploader();

        self.emptySlots = function () {
            return _.times(self.numEmptySlots());
        };

        self.numEmptySlots = function () {
            return Math.max(0, (Logo.MAX_FILE_COUNT - self.uploader.queue.length - user.logos.length));
        };

        self.selectLogo = function (logo) {
            var backup = user.default_logo_id;

            user.default_logo_id = logo.logo_id;

            user.$update().then(function onSucces() {
                angular.noop();
            }, function onError(err) {
                $log.error(err);
                Messager.error("Could not update logo");
                user.default_logo_id = backup;
            });
        };

        self.deleteLogo = function (logo) {
            logo.$delete().then(function onSucces() {
                if (user.default_logo_id === logo.logo_id) {
                    delete user.default_logo_id;
                }
            }, function onError(err) {
                $log.error(err);
                Messager.error("Could not delete logo");
            });
        };

        function createUploader() {
            var uploader = new FileUploaderS3({
                queueLimit: Logo.MAX_FILE_COUNT,
                removeAfterUpload: true,
                filters: [
                    {
                        name: 'maxSize',
                        fn  : function (item) {
                            return item.size < Logo.MAX_FILE_SIZE;
                        }
                    },
                    {
                        name : 'maxCount',
                        fn  : function () {
                            return user.logos.length + self.uploader.queue.length < Logo.MAX_FILE_COUNT;
                        }
                    }
                ]
            });

            uploader.onAfterAddingFile = function (fileItem) {
                var filename = fileItem.file.name;

                fileItem.notification = Messager.load("Uploading " + filename);

                // ask the server to give us a signed URL
                Logo.requestUploadUrl(
                    user.user_id,
                    filename
                ).then(function (resp) {
                    fileItem.url = resp.data.put_url;
                    fileItem.upload();
                }, function () {
                    fileItem.notification.error("Could not upload " + filename);
                });

                return true;
            };

            uploader.onSuccessItem = function (fileItem) {
                var logo = new Logo({
                    'user_id': user.user_id,
                    'filename': fileItem.file.name
                });

                logo.$save().then(
                    function onSuccess() {
                        fileItem.notification.success("Uploaded " + fileItem.file.name);
                        // auto-select the logo if this is the first one uploaded
                        var logos = self.user.logos;
                        if (logos && logos.length === 1) {
                            self.selectLogo(logo);
                        }
                    },
                    function onError(err) {
                        if (err.status === 409) {
                            // conflict, but file just got clobbered, could use better handling
                            fileItem.notification.success("Uploaded " + fileItem.file.name);
                        } else {
                            fileItem.notification.error("Could not upload " + fileItem.file.name);
                        }
                    }
                );
            };

            uploader.onErrorItem = function (fileItem) {
                fileItem.notification.error("Could not upload " + fileItem.file.name);
            };

            uploader.onWhenAddingFileFailed = function (item, filter) {
                if (filter.name === 'maxSize') {
                    Messager.error(item.name + " is larger than 10MB, please choose a smaller file");
                } else if (filter.name === 'maxCount') {
                    Messager.error("Can have at most " + Logo.MAX_FILE_COUNT + " logos in your gallery");
                } else {
                    Messager.error("Could not upload " + item.name);
                }
            };

            return uploader;
        }

    });

    mod.directive('designLogoOverlay', function () {
        return {
            restrict: 'EA',
            // this relies on designer CSS to approrpriately display the logo only when
            // rendering in pdf mode
            template: '<div id="logo-overlay" style="background-image: url({{logo_url}})"></div>',
            scope: {},
            controller: function ($scope, Authenticator, Logo) {
                var user = Authenticator.user();

                if (user.useCustomerBranding()) {
                    Logo.get({ logo_id: user.default_logo_id }).$promise.then(function (logo) {
                        $scope.logo_url = logo.get_url;
                    });
                } else {
                    $scope.logo_url = require('helioscope/app/designer/static/helioscope_shadowed.png');
                }
            }
        };
    });
}(angular, _));
