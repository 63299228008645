import { debounce, get } from 'lodash';
import { interceptors } from 'helioscope/app/utilities/relational/resource';
import { $location, $rootScope } from 'helioscope/app/utilities/ng';

export function registerInterceptors() {
    const digest = debounce(() => {
        if (!$rootScope.$$phase) $rootScope.$apply();
    }, 200);

    interceptors.push({
        response: digest,
        responseError: (response) => {
            switch (response.status) {
            case 401:
                $rootScope.$broadcast('event:auth-loginRequired', { redirectUrl: $location.$$path });
                break;
            case 403:
                if (get(response, 'data.error', '') === 'session_reuse') {
                    $rootScope.$broadcast('event:session-locked', response.data);
                } else {
                    $rootScope.$broadcast('event:auth-forbidden');
                }
                break;
            default:
                break;
            }

            digest();
        },
    });
}
