import Logger from 'js-logger';
import { uniq } from 'lodash';

import { flMap } from 'helioscope/app/utilities/containers';

import { FieldSegment } from 'helioscope/app/designer/field_segment';
import { Keepout } from 'helioscope/app/designer/keepout';

import { StackableNode, StackableScene } from './stackable';
import { ShadowManager } from './shadows';

import { LayoutManager } from './layouts';

const logger = Logger.get('design_scene');

export class DesignScene {

    constructor(design) {
        this.design = design;

        // a mapping of physical surfaces, to stackable nodes
        // we keep the stackable nodes on the design scene because they have
        // optimized comparison functions based on the surface geometry
        this.nodes = flMap((surface) => new StackableNode(surface));

        this.stackable = new StackableScene();

        // the rootNode is a 'The ground' singleton that contains all the children,
        // make sure it's loaded into the node tree
        this.nodes.set(this.stackable.rootNode.surface, this.stackable.rootNode);

        this.layoutManager = new LayoutManager(this);
        this.shadowManager = new ShadowManager(this);
    }

    initializeGeometry() {
        // initialize the geometry in the scene, attempting to preserve whatever derived geomtery
        // was alrady stored on the server

        for (const surface of this.design.field_segments.concat(this.design.keepouts)) {
            // attempt to initialize each plane directly from the derived geometry
            surface.initializePlanes();

            // defer updating the geometry on design load so as not to change a design in place
            this.updateSurface(surface, { populateGeometry: false });
        }

        for (const entity of this.design.entity_premades) {
            const surface = entity.proxyStackableSurface();
            surface.initialize();

            this.updateSurface(surface, { populateGeometry: false });
        }
    }

    getNode(surface) {
        return this.nodes.get(surface);
    }

    numParents(surface) {
        const node = this.getNode(surface);

        // error case only invoked when gettnig a zInddex for the ground,
        // this only matters for 2D Renderer
        return node ? node.numParents() : 0;
    }

    parentSurface(surface) {
        const node = this.getNode(surface);
        if (node.parent) {
            return node.parent.surface;
        } else {
            return null;
        }
    }

    childSurfaces(surface) {
        const node = this.getNode(surface);
        return [...node.children].map(child => child.surface);
    }

    siblingSurfaces(surface) {
        const node = this.getNode(surface);

        const rtn = [];

        for (const child of node.parent.children) {
            if (child === node) continue;

            rtn.push(child.surface);
        }

        return rtn;
    }

    intersectingSurfaces(surface) {
        const node = this.getNode(surface);

        const rtn = [];

        for (const intersector of node.intersects2d) {
            rtn.push(intersector.surface);
        }

        return rtn;
    }

    updateGeometry(surface, { populateGeometry = true, remove = false } = {}) {
        const node = this.getNode(surface);
        const affectedNodes = remove ? this.stackable.removeNode(node) : this.stackable.updateNode(node);

        if (populateGeometry || !surface.hasDerivedGeometry()) {
            // should only be overriding updateGeometry with hasDerivedGeometry
            // for either test designs, or onLoad for old designs where we need the surface
            // geometry to display
            return this.populateSurfaceGeometry(affectedNodes);
        } else {
            return affectedNodes.map(nd => nd.surface);
        }
    }

    /**
     * add, update, or remove a surface from the scene, and return the necessary changes throughout
     * the system as a result of the change.
     *
     * By default this will populate the geometry back on the physical surfaces for any changes
     * based on the objects 2d path, and calculated 3d geometry. but this is turned off by default
     * when initially loading a design to simplify backwards compatibility: if algorithms change on
     * the client, the initial load will keep the original derived geometry (from the 3d paths on
     * PhysicalSurface.geometry)
     */
    updateSurface(surface, { populateGeometry = true, remove = false } = {}) {
        const newGeometry = this.updateGeometry(surface, { populateGeometry, remove });

        const newShadows = [
            ...(remove ? this.shadowManager.removeSurface(surface) : []),
            ...this.shadowManager.updateSurface(newGeometry),
        ];

        const newLayout = [
            ...(remove ? this.layoutManager.removeSurface(surface) : []),
            ...this.layoutManager.updateSurface(
                uniq(newGeometry.concat(this.design.shade_keepouts ? newShadows : []))
            ),
        ];

        if (remove) {
            this.nodes.delete(surface);
        }

        // new geometry is already added to the objects, but new layout is done lazily
        let changed = uniq(newLayout.concat(newGeometry).concat(newShadows));

        if (remove) {
            changed = changed.filter(sur => sur !== surface);
        }

        logger.debug(`updating ${changed.length} surfaces in total`);

        return changed.map(physicalSurface => ([
            physicalSurface,
            {
                updateLayout: (physicalSurface instanceof FieldSegment) && newLayout.includes(physicalSurface),
                updateGeometry: newGeometry.includes(physicalSurface),

                // note, at this point things are probably fast enough that we can clean out the
                // render pipeline and make these automatic at some point
                updateShadows: newShadows.includes(physicalSurface),
                updateSetbacks: !(physicalSurface instanceof Keepout) && newLayout.includes(physicalSurface),
            },
        ]));
    }

    /**
     * populate derived node geometry back to a physical surface
     */
    populateSurfaceGeometry(nodes) {
        const updatedSurfaces = [];

        for (const node of nodes) {
            const physicalSurface = node.surface;
            physicalSurface.updatePlanes(node.plane, node.parent.plane);

            updatedSurfaces.push(physicalSurface);
        }

        return updatedSurfaces;
    }

    layoutRegion(surface) {
        return this.layoutManager.getLayoutRegion(surface);
    }

    layoutPaths(surface) {
        return this.layoutManager.getLayoutPath(surface);
    }

    shadowPaths(surface) {
        return this.shadowManager.getShadowPath(surface);
    }
}
