/* eslint-disable id-length */

import { getDayOfYear } from './solar_time';
import { toRadians, toDegrees } from 'helioscope/app/utilities/geometry/math';

/**
 * calculate the solar angles for a given location based on the PVLib pvl_ephemeris.pvl_ephemeris
 * function
 */
export function calculateSolarAngle(time, location, pressure = 101325, temperature = 12) {
    const latitude = location.latitude;
    const longitude = -location.longitude;
    const year = time.getUTCFullYear();
    const hour = time.getUTCHours();
    const minute = time.getUTCMinutes();
    const second = time.getUTCSeconds();
    const dayOfYear = getDayOfYear(time, 0);
    const decHours = hour + minute / 60.0 + second / 3600.0;
    const abber = 20 / 3600.0;
    const latRadians = toRadians(latitude);
    const universalDate = dayOfYear;
    const universalHour = decHours;
        // + var.Location.TZ - .5  dont  need time zone because already in global time
        // + 60/float(60)/2

    const yr = year - 1900;
    const yrBegin = 365 * yr + Math.floor((yr - 1) / 4.0); // ca- 0.5;
    const eZero = yrBegin + universalDate;
    const t = eZero / 36525.0;
    const gmst0 = 6 / 24.0 + 38 / 1440.0 + (45.836 + 8640184.542 * t + 0.0929 * Math.pow(t, 2)) / 86400.0;
    const gmst1 = 360 * (gmst0 - Math.floor(gmst0));
    const gmstI = (gmst1 + 360 * (1.0027379093 * universalHour / 24.0) % 360);
    const locAst = (360 + gmstI - longitude) % 360;
    const epochDate = eZero + universalHour / 24.0;
    const t1 = epochDate / 36525.0;
    const obliquityR = toRadians(23.452294 - 0.0130125 * t1 - 1.64e-06 * Math.pow(t1, 2) + 5.03e-07 * Math.pow(t1, 3));
    const mlPerigree = 281.22083 + 4.70684e-05 * epochDate + 0.000453 * Math.pow(t1, 2) + 3e-06 * Math.pow(t1, 3);
    const meanAnom = (358.47583 + 0.985600267 * epochDate - 0.00015 * Math.pow(t1, 2) - 3e-06 * Math.pow(t1, 3)) % 360;
    const eccen = 0.01675104 - 4.18e-05 * t1 - 1.26e-07 * Math.pow(t1, 2);
    let eccenAnom = meanAnom;
    let e = 0;


    // print e, eccenAnom
    while (Math.abs(eccenAnom - e) > 0.0001) {
        e = eccenAnom;
        eccenAnom = meanAnom + toDegrees(eccen) * Math.sin(toRadians(e));
    }


    const trueAnom = (
        2 * toDegrees(Math.atan2(Math.pow(((1 + eccen) / (1 - eccen)), 0.5)
                    * Math.tan(toRadians(eccenAnom) / 2.0), 1)) % 360
    );

    const ecLonRad = toRadians(mlPerigree + trueAnom % 360 - abber);
    const decRad = Math.asin(Math.sin(obliquityR) * (Math.sin(ecLonRad)));
    // dec = toDegrees(decRad)

    const rtAscen = toDegrees(Math.atan2(Math.cos(obliquityR) * ((Math.sin(ecLonRad))), Math.cos(ecLonRad)));

    let hrAngle = locAst - rtAscen;
    const hrAngleRad = toRadians(hrAngle);

    hrAngle = hrAngle - (360 * ((Math.abs(hrAngle) > 180)));
    let sunAz = toDegrees(Math.atan2(-1 * Math.sin(hrAngleRad), Math.cos(latRadians)
                        * (Math.tan(decRad)) - Math.sin(latRadians) * (Math.cos(hrAngleRad))));

    sunAz = sunAz + (sunAz < 0) * 360;
    const sunEl = toDegrees(Math.asin(
        (Math.cos(latRadians) * (Math.cos(decRad)) * Math.cos(hrAngleRad) + Math.sin(latRadians) * Math.sin(decRad))
    ));
    const solarTime = (180 + hrAngle) / 15.0;
    const tanEl = Math.tan(toRadians(sunEl));

    let refract;

    if (sunEl > 5 && sunEl <= 85) {
        refract = (58.1 / tanEl - 0.07 / Math.pow(tanEl, 3) + 8.6e-05 / Math.pow(tanEl, 5));
    } else if (sunEl > -0.575 && sunEl <= 5) {
        refract = sunEl * (-518.2 + sunEl * (103.4 + sunEl * (-12.79 + sunEl * (0.711)))) + 1735;
    } else if (sunEl > -1 && sunEl <= -0.575) {
        refract = -20.774 / tanEl;
    } else {
        refract = 0;
    }

    refract = refract * (283 / (273.0 + temperature)) * pressure / 101325.0 / 3600.0;
    const sunZen = Math.min(90 - sunEl, 90);
    const apparentSunEl = sunEl + refract;

    return {
        elevation: sunEl,
        azimuth: sunAz,
        zenith: sunZen,
        apparentElevation: apparentSunEl,
        solarTime,
    };
}
