const mod = angular.module('helioscope.projects.services.designs',
                            ['helioscope.services']);


mod.factory('newDesignDlg', ['$modal', function ($modal) {
    return function (project) {
        const opts = {
            templateUrl: require('helioscope/app/projects/partials/designs/new.html'),
            controller: 'NewDesignCtrl',
            resolve: { project() { return project; },
                currentDesigns() { return project.designs; } },
        };

        return $modal.open(opts);
    };
}]);

mod.controller('NewDesignCtrl', ['$scope', '$modalInstance', 'project', 'Design', 'Messager', 'currentDesigns', '$state', '$log',
    function ($scope, $modalInstance, project, Design, Messager, currentDesigns, $state, $log) {
        $modalInstance.opened.then(() => { $scope.isOpen = true; });

        $scope.cancel = function () {
            $modalInstance.close();
        };

        $scope.newDesign = {
            project_id: project.project_id,
            description: `Design ${currentDesigns.length + 1}`,
        };
        $scope.currentDesigns = currentDesigns;

        $scope.createDesign = function (newDesign) {
            const notify = Messager.load('Creating new design');
            const design = new Design(newDesign);

            $scope.isLoading = true;
            delete $scope.errors;

            design.$save((design) => {
                notify.success('Design created successfully');
                $scope.isLoading = false;
                $modalInstance.close(design);
                $state.transitionTo('designer.design.field_segments', { design_id: design.design_id });
            }, (resp) => {
                notify.error('Design could not be created');
                $log.warn(resp);
                $scope.isLoading = false;

                $scope.errors = resp.data;
            });
        };
    }]);

mod.controller('NewDesignFormCtrl', ['$scope', function ($scope) {
    const copyPattern = / \(copy\)| \(copy \d+\)/;
    const numberPattern = /\d+/;

    // TODO: use createUniqueDescription from helioscope/app/utilities/helpers.js

    function copyDescription(description, existing) {
        const copyMatch = copyPattern.exec(description);
        if (copyMatch != null && copyMatch.length === 1) {
            const numMatch = numberPattern.exec(copyMatch[0]);
            if (numMatch != null && numMatch.length === 1) {
                return `${description.substring(0, description.length - copyMatch[0].length)} (copy ${numMatch[0] * 1 + 1})`;
            } else {
                return `${description.substring(0, description.length - copyMatch[0].length)} (copy 1)`;
            }
        }

        return `${description} (copy)`;
    }

    $scope.$watch('newDesign.clone_design_id', (val) => {
        if (val && !$scope.form.description.$dirty) {
            const copyDesign = _.find($scope.currentDesigns, (d) => d.design_id == val);
            $scope.newDesign.description = copyDescription(copyDesign.description, $scope.currentDesigns);
        }
    });
}]);


mod.factory('EditDesignDlg', ['$modal', function ($modal) {
    return function (design) {
        const opts = {
            templateUrl: require('helioscope/app/projects/partials/designs/edit.html'),
            controller: 'EditDesignCtrl',
            backdrop: true,
            resolve: { design() { return design; } },
        };
        return $modal.open(opts);
    };
}]);


mod.controller('EditDesignCtrl', ['$scope', '$modalInstance', 'Messager', 'design', 'Authenticator', 'Design', '$log',
    function ($scope, $modalInstance, messager, design, Authenticator, Design, $log) {
        $modalInstance.opened.then(() => { $scope.isOpen = true; });
        $scope.designData = angular.copy(design);
        $scope.designData.originalDescription = design.description;

        $scope.save = function () {
            $scope.isLoading = true;
            $scope.errors = [];

            const backup = angular.copy(design);
            angular.extend(design, $scope.designData);

            design.$update((d) => {
                messager.success('Changes saved successfully.');
                const description = d.description;
                angular.extend(design, backup, { description });

                $scope.isLoading = false;
                $modalInstance.close();
            }, (response) => {
                $log.warn(response);
                messager.error('Changes could not be saved');
                $scope.isLoading = false;
                $scope.errors = response.data.errors;
                angular.extend(design, backup);
            });
        };

        $scope.cancel = function () {
            $modalInstance.close();
        };
    }]);

mod.factory('getColorWheel', () => {
  // http://colorbrewer2.org/
  // http://bl.ocks.org/mbostock/5577023
  // old Colors ["0x222299CC", "0x45ADA8CC", "0x9999BBCC", "0x547980CC", "0xBBBBCCCC", "0x99B2B7CC", "0x666699CC", "0x90E0A0CC",  "0xB5BED9CC", "0xD9CEB2CC", "0xE5FCC2CC"];

    const colors = {
        3: ['0x9ecae1cc', '0x3182bdcc', '0xdeebf7cc'],
        4: ['0xbdd7e7cc', '0x6baed6cc', '0x2171b5cc', '0xeff3ffcc'],
        5: ['0xbdd7e7cc', '0x6baed6cc', '0x3182bdcc', '0x08519ccc', '0xeff3ffcc'],
        6: ['0xc6dbefcc', '0x9ecae1cc', '0x6baed6cc', '0x3182bdcc', '0x08519ccc', '0xeff3ffcc'],
        7: ['0xc6dbefcc', '0x9ecae1cc', '0x6baed6cc', '0x4292c6cc', '0x2171b5cc', '0x084594cc', '0xeff3ffcc'],
        8: ['0xdeebf7cc', '0xc6dbefcc', '0x9ecae1cc', '0x6baed6cc', '0x4292c6cc', '0x2171b5cc', '0x084594cc', '0xf7fbffcc'],
        9: ['0xdeebf7cc', '0xc6dbefcc', '0x9ecae1cc', '0x6baed6cc', '0x4292c6cc', '0x2171b5cc', '0x08519ccc', '0x08306bcc', '0xf7fbffcc'],
    };

    return function (count) {
        const _colorArray = colors[Math.max(3, Math.min(count, 9))];
        let _current = -1;

        return function () {
            _current += 1;
            return _colorArray[_current % _colorArray.length];
        };
    };
});
