import Logger from 'js-logger';
import * as analytics from 'helioscope/app/utilities/analytics';
import * as IOHelpers from 'helioscope/app/utilities/io';
import { FileUploaderS3, Messager, $rootScope } from 'helioscope/app/utilities/ng';
import { getDimensions } from 'reports/modules/files/uploads.ts';
import {
    generateQuadPoints,
    isImageExtension,
    isKMLExtension,
    MAX_FILE_SIZE_MB_LARGE,
    MAX_FILE_SIZE_MB_STANDARD,
    MEGABYTES,
} from './helpers';
import { Overlay } from './overlays';
import { StateDelta } from '../persistence';
import { S3File } from '../s3files';


const logger = Logger.get('overlays/controllers');

class FileSizeError extends Error {
    constructor(filename, largeOverlayUploadsEnabled) {
        super(
            // eslint-disable-next-line max-len
            `${filename} exceeds the ${largeOverlayUploadsEnabled ? MAX_FILE_SIZE_MB_LARGE : MAX_FILE_SIZE_MB_STANDARD} ` +
            'MB limit. Please reduce the file size and try again.',
        );
    }
}

class FileTypeError extends Error {
    constructor(filename) {
        super(`${filename} is not a supported file type. Accepted types are jpg, jpeg, png, kmz, kml.`);
    }
}

export class OverlaysDesignerCtrl {
    constructor(design, dispatcher, $scope) {
        'ngInject';

        this.dispatcher = dispatcher;
        this.design_id = design.design_id;
        this.project_id = design.project.project_id;
        this.uploader = this.createUploader();
        this.overlays = design.project.overlays;
        this._sortOverlays();

        this.sortableOptions = {
            forcePlaceholderSize: true,
            placeholder: 'placeholder',
            stop: (evt, ui) => {
                // The angular "UI Sortable" library updates the overlay model in response to drag/drop events, then it
                // calls this callback. ui-sortable provides data in "ui.item.sortable" about the drag/drop event:
                // https://www.npmjs.com/package/angular-ui-sortable#canceling
                const { dropindex: newIndex, index: oldIndex, model: overlay } = ui.item.sortable;
                // newIndex is undefined if the overlay is dropped in the starting location (no change)
                if (newIndex != null && newIndex !== oldIndex) {
                    this.updateOrder(overlay, newIndex, oldIndex);
                }
            },
        };

        this.largeOverlayUploadsEnabled = $rootScope.user().role.can_upload_large_files;
        this.overlaySizeLimit = this.largeOverlayUploadsEnabled ? MAX_FILE_SIZE_MB_LARGE : MAX_FILE_SIZE_MB_STANDARD;

        const updateListener = dispatcher.subscribe(
            'Overlays:updateOrder',
            ((_dispatcher, overlays) => this.updateOverlaysOrder(overlays)),
        );
        $scope.$on('$destroy', updateListener);

        const createListener = dispatcher.subscribe(
            'Overlays:create',
            ((_dispatcher, overlay) => this.addOverlay(overlay)),
        );
        $scope.$on('$destroy', createListener);

        const deleteListener = dispatcher.subscribe(
            'Overlays:delete',
            ((_dispatcher, overlay) => this.removeOverlay(overlay)),
        );
        $scope.$on('$destroy', deleteListener);
    }

    updateOverlaysOrder(updatedOverlays) {
        updatedOverlays.map(updatedOverlay => {
            const overlayToUpdate = this.overlays.find(overlay => overlay.overlay_id === updatedOverlay.overlay_id);
            overlayToUpdate.order = updatedOverlay.order;
            return overlayToUpdate;
        });
        this._sortOverlays();
    }

    addOverlay(overlayToAdd) {
        this.overlays.push(overlayToAdd);
        this._sortOverlays();
    }

    removeOverlay(overlayToRemove) {
        this.overlays = this.overlays.filter(overlay => overlay.overlay_id !== overlayToRemove.overlay_id);
        this._sortOverlays();
    }

    _sortOverlays() {
        this.overlays.sort((a, b) => b.order - a.order);
    }

    createImageOverlay(s3FileData) {
        const width = this.dispatcher.renderer.clientSize.width;
        const height = s3FileData.meta.height / s3FileData.meta.width;
        const scale = this.dispatcher.renderer.viewportScale;
        const center = this.dispatcher.renderer.cameraCenter;

        const overlay = new Overlay({
            file: s3FileData,
            filename: s3FileData.filename,
            overlay_parameter: {
                quad_points: generateQuadPoints(width, height, scale, center),
                rotation_angle: 0,
                opacity: 1,
            },
            project_id: this.project_id,
            visible: true,
        });

        return overlay;
    }

    createKMLOverlay(s3FileData) {
        const overlay = new Overlay({
            file: s3FileData,
            filename: s3FileData.filename,
            project_id: this.project_id,
            visible: true,
        });

        return overlay;
    }

    async createS3File(fileItem, extension) {
        let s3file = null;
        if (isKMLExtension(extension)) {
            s3file = await S3File.createS3FileFromFileItem(fileItem);
        } else if (isImageExtension(extension)) {
            s3file = await S3File.createS3FileFromFileItem(
                fileItem,
                fi => getDimensions(fi._file),
            );
        }
        return s3file;
    }

    createOverlay(s3file, extension) {
        let overlay = null;
        if (isKMLExtension(extension)) {
            overlay = this.createKMLOverlay(s3file);
        } else if (isImageExtension(extension)) {
            overlay = this.createImageOverlay(s3file);
        }
        return overlay;
    }

    createUploader() {
        const uploader = new FileUploaderS3();
        uploader.onAfterAddingFile = async (fileItem) => {
            const filename = fileItem.file.name;
            let isSuccess = true;

            try {
                const fileSize = fileItem.file.size;
                const maxFileSize = MEGABYTES *
                    (this.largeOverlayUploadsEnabled ? MAX_FILE_SIZE_MB_LARGE : MAX_FILE_SIZE_MB_STANDARD);
                const extension = IOHelpers.getExtension(fileItem.file.name);

                fileItem.notification = Messager.load(`Uploading ${filename}`);

                if (fileSize > maxFileSize) {
                    throw new FileSizeError(filename, this.largeOverlayUploadsEnabled);
                } else if (!isKMLExtension(extension) && !isImageExtension(extension)) {
                    throw new FileTypeError(filename);
                }

                let overlay = null;
                let s3file = null;
                try {
                    s3file = await this.createS3File(fileItem, extension);
                } catch (err) {
                    const message = err.data.size[0];
                    throw new Error(message);
                }
                overlay = this.createOverlay(s3file, extension);

                const dispatcher = this.dispatcher;
                try {
                    await dispatcher.stateHandler.createObject(
                        overlay,
                        this.changeConfig(overlay, dispatcher),
                    );
                } catch (err) {
                    // This catch block is needed because StateHandler returns
                    // the rejected promise in `return $q.reject(err)`, even though
                    // it processes and displays the error via the onError call.
                }
                fileItem.notification.close();
            } catch (err) {
                isSuccess = false;
                fileItem.notification.error(err.message, {
                    title: 'File Upload Error',
                });
            } finally {
                analytics.track('overlays.upload', {
                    filename,
                    project_id: this.project_id,
                    design_id: this.design_id,
                    team_id: $rootScope.user().team_id,
                    success: isSuccess,
                });
            }
        };

        return uploader;
    }

    changeConfig(overlay, dispatcher) {
        return {
            create: {
                text: `Create Overlay: ${overlay.filename}`,
                preflight: () => {
                    delete overlay.overlay_id;
                },
                onSuccess: async (ol) => {
                    Messager.success(`Successfully created ${ol.filename}`);
                    dispatcher.publish('Overlays:create', ol);

                    if (isKMLExtension(ol.file.extension)) {
                        // Parsing the KM document is necessary to get the bounds used for zooming to the overlay
                        await ol.loadDocument();

                        if (ol.isEmptyKML()) {
                            Messager.error(ol.getEmptyKMLMessage());
                            return;
                        }
                    }

                    // Selection triggers a render so no need to directly call renderOverlay
                    dispatcher.selectEntity(ol);
                    dispatcher.renderer.zoom(ol);
                },
                onError: (err) => {
                    const message = `Error creating ${overlay.filename}`;
                    const filenameErrMsg = err.data && err.data.filename && err.data.filename[0];
                    Messager.error(filenameErrMsg || message);
                    logger.warn(err);
                },
            },
            delete: {
                text: `Remove Overlay: ${overlay.filename}`,
                onSuccess: (ol) => {
                    Messager.success(`Successfully deleted ${ol.filename}`);
                    dispatcher.publish('Overlays:delete', ol);
                    dispatcher.renderer.clearOverlay(ol);
                },
                onError: (err) => {
                    Messager.error(`Error deleting ${overlay.filename}`);
                    logger.warn(err);
                },
            },
        };
    }

    deleteOverlay(overlay) {
        analytics.track('overlays.delete', {
            filename: overlay.filename,
            project_id: this.project_id,
            design_id: this.design_id,
            team_id: $rootScope.user().team_id,
        });

        const dispatcher = this.dispatcher;
        dispatcher.stateHandler.deleteObject(overlay, this.changeConfig(overlay, dispatcher));
    }

    formatOpacity(opacity) {
        // round is required to avoid floating-point display oddities, like 56.00000001%
        return Math.round(opacity * 100);
    }

    toggleVisibility(overlay) {
        // Deselect the overlay if currently selected and turning off visibility
        if (this.entitySelected(overlay) && overlay.visible) {
            this.dispatcher.deselectEntity();
        }

        this.dispatcher.createSinglePropertyChange({
            resource: overlay,
            path: 'visible',
            oldVal: overlay.visible,
            newVal: !overlay.visible,
            enableUndo: false,
        });
    }

    async navigateTo(overlay) {
        if (overlay.isEmptyKML()) {
            Messager.error(overlay.getEmptyKMLMessage());
            return;
        }

        this.dispatcher.renderer.zoom(overlay);

        analytics.track('overlays.navigateTo', {
            filename: overlay.filename,
            project_id: this.project_id,
            design_id: this.design_id,
            team_id: $rootScope.user().team_id,
        });
    }

    _updateOrderFunction = (overlay, index) => {
        const dispatcher = this.dispatcher;
        const data = {
            overlay_id: overlay.overlay_id,
            new_index: index,
        };

        return Overlay.updateOrder(data).$promise
            .then((updatedOverlays) => {
                updatedOverlays.forEach((updatedOverlay) => {
                    dispatcher.publish('Overlays:updateOrder', updatedOverlays);
                    dispatcher.renderer.renderOverlay({
                        overlay: updatedOverlay,
                        options: {
                            renderEditWidgets: this.entitySelected(updatedOverlay),
                            renderOnTop: this.entitySelected(updatedOverlay),
                        },
                        markDirty: true,
                    });
                });
                Messager.success('Updated overlay order');
            });
    }

    updateOrder(overlay, newIndex, oldIndex) {
        const loadFn = () => this._updateOrderFunction(overlay, newIndex);
        const rollbackFn = () => this._updateOrderFunction(overlay, oldIndex);

        const name = overlay.filename;
        const delta = new StateDelta({
            loadText: `Update Overlay order for ${name}`,
            loadFn,
            rollbackText: `Undo Overlay order update for ${name}`,
            rollbackFn,
        });

        const stateHandler = this.dispatcher.stateHandler;

        return stateHandler.updateQueue.flush()
            .then(loadFn).then(() => {
                stateHandler.addDelta(delta);
            });
    }

    entitySelected(overlay) {
        return this.dispatcher.selectedEntity === overlay;
    }

    toggleEntity(overlay) {
        if (this.entitySelected(overlay)) {
            this.dispatcher.deselectEntity();
        } else {
            this.dispatcher.selectEntity(overlay);
        }
    }

    isImageOverlay(overlay) {
        return isImageExtension(overlay.file.extension);
    }

    generateSizeToolTip() {
        let text = 'Overlays let you add imagery to your design. ';
        text += `Files must be ${this.overlaySizeLimit} MB or smaller.`;
        if (this.overlaySizeLimit === MAX_FILE_SIZE_MB_STANDARD) {
            text += ` Pro and Custom plans have a ${MAX_FILE_SIZE_MB_LARGE} MB limit.`;
        }
        return text;
    }
}

((angular) => {
    const module = angular.module('helioscope.designer.overlays',
        [
            'helioscope.services',
            'ui',
            'helioscope.projects.resources',
            'angularFileUpload',
        ]);

    module.factory('Overlay', () => Overlay);
})(angular);
