import _ from 'lodash';

import { Vector } from 'helioscope/app/utilities/geometry';
import { FieldSegmentContextMenu } from 'helioscope/app/designer/field_segment';
import { KeepoutContextMenu } from 'helioscope/app/designer/keepout';
import { PremadeContextMenu } from 'helioscope/app/designer/premade';

import { isFieldSegmentContextMenuDisabled } from 'helioscope/app/utilities/maps/components';
import {
    SurfaceCursorHelper,
    containerPoint,
    createContextMenu,
    hitTestRendererObjects,
    zoomView,
} from './InteractHelpers';
import {
    generateMoveCursor,
    generateResizeCursor,
    generateRotateCursor,
    generateScaleCursor,
} from './overlay/OverlayCursorHelpers';
import {
    DragActionMovePhysicalSurface,
    DragActionPremadeMove,
} from './InteractGeometry';
import { DragActionMoveWiringComponent } from './InteractComponent';

import { DragActionCameraPan, DragActionCameraRotate } from './DragCameraActions';
import { PasteAction } from '../designer/actions';
import { SelectionContextMenu, WorldContextMenu } from '../designer/controllers';
import { CursorConfig } from '../designer/CursorConfig';

export class InteractToolCameraPanZoomSelect {
    constructor(dRenderer) {
        this.hoverObject = null;
        this.dRenderer = dRenderer;
        this.initClickHandlers();
        this.initMouseOutHandlers();
        this.initMouseOverHandlers();
        this.initContextMenuHandlers();
        this.pasteAction = new PasteAction(dRenderer);
        this.cursorHelper = new SurfaceCursorHelper(dRenderer);
        this.initCursorRendering();
    }

    initClickHandlers() {
        const { dispatcher } = this.dRenderer;
        this.clickHandlers = {
            FieldSegment: (fieldSegment, isShiftClick) => {
                if (!isShiftClick) {
                    dispatcher.publish('FieldSegment:click', { fieldSegment });
                }
                dispatcher.selectEntity(fieldSegment, null, isShiftClick);
            },
            Keepout: (keepout, isShiftClick) => {
                dispatcher.selectEntity(keepout, null, isShiftClick);
            },
            Premade: (premade, isShiftClick) => {
                dispatcher.selectEntity(premade, null, isShiftClick);
            },
            None: () => {
                dispatcher.deselectEntity({ routeToFieldSegments: true });
            },
        };
    }

    initMouseOutHandlers() {
        const { dRenderer } = this;
        const { dispatcher } = dRenderer;
        this.mouseOutHandlers = {
            FieldSegment: (fieldSegment) => {
                dispatcher.publish('FieldSegment:mouseout', { fieldSegment, editable: false });
            },
            Keepout: (keepout) => {
                dispatcher.publish('Keepout:mouseout', { keepout, editable: false });
                dispatcher.highlightEntity(keepout, false);
            },
            Premade: (premade) => {
                dispatcher.highlightEntity(premade, false);
            },
            WidgetImageOverlayDragHandle: () => {
                dRenderer.setCursorStyle(CursorConfig.DEFAULT);
            },
            WidgetImageOverlayEdgeHandle: () => {
                dRenderer.setCursorStyle(CursorConfig.DEFAULT);
            },
            WidgetImageOverlayVertexHandle: () => {
                dRenderer.setCursorStyle(CursorConfig.DEFAULT);
            },
            WidgetImageOverlayRotationHandle: () => {
                dRenderer.setCursorStyle(CursorConfig.DEFAULT);
            },
        };
    }

    initMouseOverHandlers() {
        const { dRenderer } = this;
        const { dispatcher } = dRenderer;
        this.mouseOverHandlers = {
            FieldSegment: (fieldSegment) => {
                dispatcher.publish('FieldSegment:mouseover', { fieldSegment, editable: false });
            },
            Keepout: (keepout) => {
                dispatcher.publish('Keepout:mouseover', { keepout, editable: false });
                dispatcher.highlightEntity(keepout, true);
            },
            Premade: (premade) => {
                dispatcher.highlightEntity(premade, true);
            },
            WidgetImageOverlayDragHandle: () => {
                const cursor = generateMoveCursor();
                dRenderer.setCursorStyle(cursor);
            },
            WidgetImageOverlayEdgeHandle: (handleObject) => {
                const { index, overlay } = handleObject;
                const cursor = generateResizeCursor(overlay, index);
                dRenderer.setCursorStyle(cursor);
            },
            WidgetImageOverlayVertexHandle: (handleObject) => {
                const { index, overlay } = handleObject;
                const cursor = generateScaleCursor(overlay, index);
                dRenderer.setCursorStyle(cursor);
            },
            WidgetImageOverlayRotationHandle: ({ overlay }) => {
                const cursor = generateRotateCursor(overlay);
                dRenderer.setCursorStyle(cursor);
            },
        };
    }

    initContextMenuHandlers() {
        const psContextMenuFn = (interactData, pt, contextMenuCtrl) => {
            const locals = {
                location: new Vector(this.mouseIntersectPoint),
                dispatcher: this.dRenderer.dispatcher,
            };

            locals.fieldSegment = interactData.object;
            locals.keepout = interactData.object;
            locals.premade = interactData.object;

            const ctxmenu = _.assign({}, contextMenuCtrl, { locals });
            createContextMenu(this.dRenderer, ctxmenu, ({ x: pt.x - 15, y: pt.y - 10 }));
            return true;
        };

        this.contextMenuHandlers = {
            FieldSegment: psContextMenuFn,
            Keepout: psContextMenuFn,
            Premade: psContextMenuFn,
            World: psContextMenuFn,
            Selection: psContextMenuFn,
        };

        this.contextMenuControls = {
            FieldSegment: FieldSegmentContextMenu,
            Keepout: KeepoutContextMenu,
            Premade: PremadeContextMenu,
            World: WorldContextMenu,
            Selection: SelectionContextMenu,
        };
    }

    initCursorRendering() {
        const { dRenderer: { dispatcher, currentMousePosition } } = this;
        const surfaces = dispatcher.internalClipboard.readFromClipboard();
        if (dispatcher.isOnPasteMode) {
            this.cursorHelper.renderSurfacesAtCursor(surfaces, currentMousePosition);
        }
    }

    toolMouseDown(event) {
        const pt = containerPoint(this.dRenderer, event);
        this.recordIntersects(pt);

        if (this.dRenderer.dispatcher.isOnPasteMode) {
            this.pasteAction.pasteToCursor(pt);
            this.upClick = false;
            return;
        }

        if (event.shiftKey) {
            if (this.mouseMainObject) this.handleObjectMouseClick(event);
            this.dRenderer.activateDragAction(new DragActionCameraRotate(this.dRenderer, event));
            return;
        }

        if (this.handleEditWidgets(event)) {
            return;
        }

        if (this.handleDraggables(event)) {
            return;
        }

        if (event.type === 'contextmenu') {
            this.upClick = false;
            if (this.handleContextMenus(event, pt)) {
                return;
            }
        }

        this.handleCursorStyle(event);

        this.panDownEvent = event;
        this.downPoint = pt;
        this.upClick = true;
    }

    toolMouseUp(event) {
        this.handleCursorStyle(null);

        if (this.upClick) {
            const pt = containerPoint(this.dRenderer, event);
            this.recordIntersects(pt);
            this.handleObjectMouseClick(event);
            this.upClick = false;
        }

        this.panDownEvent = null;

        this.cancelPendingDrag();
    }

    toolMouseMove(event) {
        if (this.handlePendingDrag(event)) return;

        if (this.panDownEvent) {
            this.dRenderer.activateDragAction(new DragActionCameraPan(this.dRenderer, this.panDownEvent));
            this.panDownEvent = null;
            return;
        }

        this.initCursorRendering();

        const pt = containerPoint(this.dRenderer, event);
        // test for highlighting / selection
        this.recordIntersects(pt);
        this.handleObjectMouseOver();
    }

    toolMouseOut() {
        this.forceEndHover();
    }

    toolMouseWheel(event) {
        zoomView(this.dRenderer, event);
    }

    toolDblClick() {
    }

    deactivateTool() {
        this.cursorHelper.clearSurfaceCursorPrimitives();
        this.forceEndHover();
    }

    recordIntersects(pt) {
        const hitTest = hitTestRendererObjects(this.dRenderer, pt);
        this.mouseMainObject = hitTest.mainObject;
        this.mouseIntersectPoint = hitTest.intersectPoint;
        this.mouseInteractObject = hitTest.interactObject;
    }

    forceEndHover() {
        if (this.hoverObject) {
            const { type, object } = this.hoverObject;
            if (this.mouseOutHandlers[type]) this.mouseOutHandlers[type](object);
            this.dRenderer.dirtyFrame();
        }

        this.hoverObject = null;
    }

    handleObjectMouseClick(event) {
        if (this.mouseMainObject) {
            const { type, object } = this.mouseMainObject.userData.interactData;
            if (this.clickHandlers[type]) {
                this.clickHandlers[type](object, event.shiftKey);
            }
        } else {
            this.clickHandlers.None();
        }
    }

    getCurrHoverObject() {
        // Prefer returning the interactLayer (which contains widgets) because
        // it is positioned above the selectionLayer (which contains field
        // segments, etc.). This ensures that the user interacts with the
        // top-most object. Additionally, parts of objects that are not
        // currently interactable -- such as the portion of a field segment
        // beneath a widget -- will not respond (e.g. by changing color).
        const currHoverObject = this.mouseInteractObject || this.mouseMainObject;
        return currHoverObject ? currHoverObject.userData.interactData : null;
    }

    hoverObjectHasChanged(lastHoverObject, currHoverObject) {
        return lastHoverObject !== currHoverObject;
    }

    handleObjectMouseOver() {
        const lastHoverObject = this.hoverObject;
        const currHoverObject = this.getCurrHoverObject();

        // mouse has moved out of lastHoverObject
        if (lastHoverObject && this.hoverObjectHasChanged(lastHoverObject, currHoverObject)) {
            const { type, object } = lastHoverObject;
            if (this.mouseOutHandlers[type]) this.mouseOutHandlers[type](object);
            this.dRenderer.dirtyFrame();
        }

        // mouse has moved over currHoverObject
        if (currHoverObject && this.hoverObjectHasChanged(lastHoverObject, currHoverObject)) {
            const { type, object } = currHoverObject;
            if (this.mouseOverHandlers[type]) this.mouseOverHandlers[type](object);
            this.dRenderer.dirtyFrame();
        }

        this.hoverObject = currHoverObject;
    }

    handleEditWidgets(event) {
        if (this.mouseInteractObject) {
            const { interactData } = this.mouseInteractObject.userData;
            if (interactData && interactData.object) {
                return interactData.object.widgetMouseDown(event);
            }
        }

        return false;
    }

    handleContextMenus(event, pt) {
        // right click on empty space menu
        if (!this.mouseMainObject && !this.mouseInteractObject) {
            return this.contextMenuHandlers.World({}, pt, this.contextMenuControls.World);
        }

        // edge context menu
        if (this.mouseInteractObject) {
            const { interactData } = this.mouseInteractObject.userData;
            if (this.contextMenuHandlers[interactData.type]) {
                return this.contextMenuHandlers[interactData.type](interactData, pt);
            }
        }

        // entity selection context menus
        if (this.mouseMainObject) {
            const { interactData } = this.mouseMainObject.userData;
            const objType = interactData.type;
            const selectedEntities = this.dRenderer.dispatcher.selectedEntities;

            if (selectedEntities.size > 1 && selectedEntities.has(interactData.object)) {
                return this.contextMenuHandlers.Selection({}, pt, this.contextMenuControls.Selection);
            }

            if (objType === 'FieldSegment' && isFieldSegmentContextMenuDisabled()) {
                // subtle: we want to disable the menu but act as if menu was handled
                return true;
            }
            const handler = this.contextMenuHandlers[objType];
            if (handler) {
                return handler(interactData, pt, this.contextMenuControls[objType]);
            }
        }

        return false;
    }

    handleDraggables(event) {
        if (event.button === 0 && this.mouseMainObject) {
            const { interactData } = this.mouseMainObject.userData;
            if (interactData.draggableSurface || interactData.draggablePremade ||
                interactData.draggableComponent) {
                this.dragData = interactData;
                this.dragDownEvent = event;
            }
        }

        return false;
    }

    handlePendingDrag(event) {
        if (!this.dragData) return false;
        if (this.dragData.draggableSurface) {
            this.dRenderer.activateDragAction(
                new DragActionMovePhysicalSurface(this.dRenderer, event, this.mouseMainObject));
            this.panDownEvent = null;
        } else if (this.dragData.draggablePremade) {
            this.dRenderer.activateDragAction(
                new DragActionPremadeMove(this.dRenderer, event, this.mouseMainObject));
            this.panDownEvent = null;
        } else if (this.dragData.draggableComponent) {
            this.dRenderer.activateDragAction(
                new DragActionMoveWiringComponent(this.dRenderer, event, this.mouseMainObject));
            this.panDownEvent = null;
        }
        this.cancelPendingDrag();
        return true;
    }

    handleCursorStyle(event) {
        if (!event) {
            this.dRenderer.setCursorStyle(CursorConfig.DEFAULT);
            return;
        }
        if (!this.mouseMainObject && !this.mouseInteractObject) {
            this.dRenderer.setCursorStyle(CursorConfig.GRAB);
        }
        if (event.altKey && this.mouseMainObject) {
            this.dRenderer.setCursorStyle(CursorConfig.DUPLICATE);
        }
    }

    cancelPendingDrag() {
        this.dragData = null;
        this.dragDownEvent = null;
        this.downPoint = null;
    }
}

export class InteractToolCameraZoomSelect {
    constructor(dRenderer) {
        this.dRenderer = dRenderer;
    }

    toolMouseDown(event) {
        const pt = containerPoint(this.dRenderer, event);

        if (event.shiftKey) {
            this.dRenderer.activateDragAction(new DragActionCameraRotate(this.dRenderer, event));
            return;
        }

        this.panDownEvent = event;
        this.downPoint = pt;
        this.upClick = true;
    }

    toolMouseUp() {
        if (this.upClick) {
            this.upClick = false;
        }

        this.panDownEvent = null;

        this.cancelPendingDrag();
    }

    toolMouseMove() {
    }

    toolMouseOut() {
    }

    toolDblClick() {
    }

    toolMouseWheel(event) {
        zoomView(this.dRenderer, event);
    }

    cancelPendingDrag() {
        this.dragData = null;
        this.dragDownEvent = null;
        this.downPoint = null;
    }

    deactivateTool() {
        this.forceEndHover();
    }

    forceEndHover() {
        if (this.hoverObject) {
            const { type, object } = this.hoverObject;
            if (this.mouseOutHandlers[type]) this.mouseOutHandlers[type](object);
            this.dRenderer.dirtyFrame();
        }

        this.hoverObject = null;
    }
}
