import { helioscopeConfig } from 'reports/angular-bridge.ts';

const domainsWithBetaSubdomains = ['staging-helioscope.com', 'helioscope.com'];

const baseBillingUrl = () => `${betaRootUrlGenerator()}/settings/team/billing`;

export function betaRootUrlGenerator() {
    let betaRoot = helioscopeConfig.url_root;
    for (const domain of domainsWithBetaSubdomains) {
        const wwwSubdomain = `www.${domain}`;
        const appSubdomain = `app.${domain}`;
        if (helioscopeConfig.url_root.includes(wwwSubdomain)) {
            betaRoot = helioscopeConfig.url_root.replace(wwwSubdomain, `beta.${domain}`);
            break;
        } else if (helioscopeConfig.url_root.includes(appSubdomain)) {
            betaRoot = helioscopeConfig.url_root.replace(appSubdomain, `beta.${domain}`);
            break;
        }
    }
    return betaRoot + (!betaRoot.includes('beta') ? '/app' : '');
}

export function getBetaBillingURL(user, userAdministeringEmail = '', billingRouteParams={dialog: 'initial'}) {
    const params = new URLSearchParams({
        ...billingRouteParams,
        ...(userAdministeringEmail && { email: userAdministeringEmail })
    }).toString();

    return `${baseBillingUrl()}${params && '?'}${params}`;
}

export function getAddProjectModalURL(userAdministeringEmail = '') {
    const addProjectModalURL = `${baseBillingUrl()}?dialog=add_projects`

    if (userAdministeringEmail !== '') {
        return `${addProjectModalURL}&email=${userAdministeringEmail}`;
    } else {
        return addProjectModalURL;
    }
}

export function makeIframeAwareUrl(betaRelativePath, classicRelativePath) {
    const classicLibraryPath = helioscopeConfig.url_root + classicRelativePath;
    const betaLibraryPath = `${betaRootUrlGenerator() + betaRelativePath}`;

    return window.location.href.includes('forceAngular') ? betaLibraryPath : classicLibraryPath;
}

export function getBetaIntegrationsURL() {
    return `${betaRootUrlGenerator()}/settings/team/integrations`;
}

export function getBetaAccountOverviewURL() {
    return `${betaRootUrlGenerator()}/settings/user/changepassword`;
}

export function getBetaSignupURL() {
    return `${betaRootUrlGenerator()}/signup`;
}
