import { calculateSolarAngle } from './solar_angles';
import { cleanPath, pathOrientation, Plane, unionPaths, Vector } from 'helioscope/app/utilities/geometry';
import { utcDate } from './solar_time';

const MAX_SOLAR_ELEVATION = 2;

/**
 * create shadow polygons by projecting segments of each vertical 'wall' of a keepout onto a plane
 * and then merging them
 */
export function singleShadowPolygon(pathWithHeight, plane, solarAngle, { union = true } = {}) {
    if (!solarAngle.apparentElevation || solarAngle.apparentElevation <= 0) {
        return [];
    }
    const ray = Vector.createRay(Math.max(solarAngle.apparentElevation, MAX_SOLAR_ELEVATION), solarAngle.azimuth);
    const shadowPath = safeProjectPath(pathWithHeight, plane, ray);

    const shadeProjections = []; // may need to include the base path here;
    const length = pathWithHeight.length;

    for (let i = 1; i < length; i++) {
        // iterate through every edge of the polygon and project the face into a quad on the surface
        const shadowSegment = [pathWithHeight[i - 1], pathWithHeight[i], shadowPath[i], shadowPath[i - 1]];
        // Note: Potentially extraneous code, path orientations should always be positive
        shadeProjections.push(pathOrientation(shadowSegment) ? shadowSegment : shadowSegment.reverse());
    }

    if (shadowPath[0] && shadowPath[length - 1]) {
        // add the face back to the origin of the path to the collection of quad
        const shadowSegment = [pathWithHeight[length - 1], pathWithHeight[0], shadowPath[0], shadowPath[length - 1]];
        // Note: Potentially extraneous code, path orientations should always be positive
        shadeProjections.push(pathOrientation(shadowSegment) ? shadowSegment : shadowSegment.reverse());
    }

    if (union) {
        // by default merge all the quads into one polygon
        return cleanPath(unionPaths([pathWithHeight].concat(shadeProjections)));
    }
    return shadeProjections;
}

export function shadowPolygon3D(location, srcPath, destPath, destTilt, destAzimuth, startTime, endTime, timeZone) {
    const startTimeUTC = utcDate(startTime, timeZone).getTime();
    const endTimeUTC = utcDate(endTime, timeZone).getTime();
    const steps = Math.floor((endTimeUTC - startTimeUTC) / 1000 / 60 / 30); // target 30 minute steps

    const pathWithHeight = srcPath;

    const plane = Plane.fromOrientation(destTilt, destAzimuth, destPath[0]);

    // start path in the array, because for certain shapes, the gap in the middle breaks the polygon
    // union operation.  This ensures that everyhting is contiguous, because all the shadows branc
    // out from the path
    const shadowStep = (endTimeUTC - startTimeUTC) / (steps || 1);
    const shadows = [pathWithHeight];
    for (let i = 0; i <= steps; i++) {
        const shadowTime = new Date(startTimeUTC + i * shadowStep);
        const solarAngle = calculateSolarAngle(shadowTime, location);

        shadows.push(...singleShadowPolygon(pathWithHeight, plane, solarAngle, { union: false }));
    }

    return cleanPath(unionPaths(shadows));
}

export function shadowPolygon(location, path, height, heightReference,
                              startTime, endTime, timeZone, { tilt = 0, azimuth = 180 } = {}) {
    const startTimeUTC = utcDate(startTime, timeZone).getTime();
    const endTimeUTC = utcDate(endTime, timeZone).getTime();
    const steps = Math.floor((endTimeUTC - startTimeUTC) / 1000 / 60 / 30); // target 30 minute steps

    const plane = Plane.fromOrientation(tilt, azimuth, heightReference);

    const heightVec = new Vector(0, 0, height);
    const pathWithHeight = _.map(path, pt => pt.add(heightVec));

    // start path in the array, because for certain shapes, the gap in the middle breaks the polygon
    // union operation.  This ensures that everyhting is contiguous, because all the shadows branc
    // out from the path
    const shadows = [path];
    for (let i = 0; i <= steps; i++) {
        const shadowTime = new Date(startTimeUTC + i / (steps || 1) * (endTimeUTC - startTimeUTC));
        const solarAngle = calculateSolarAngle(shadowTime, location);

        shadows.push(...singleShadowPolygon(pathWithHeight, plane, solarAngle, { union: false }));
    }

    return cleanPath(unionPaths(shadows));
}

const DOWN = new Vector(0, 0, -1);


// the old shadow API would just return back the original pt if the projection was in the
// wrong direction
function safeProjectPath(pathWithHeight, plane, ray) {
    return _.map(pathWithHeight, pt => plane.projectPoint(pt, ray, DOWN) || pt);
}
