import * as io from 'helioscope/app/utilities/io';
import * as analytics from 'helioscope/app/utilities/analytics';
import { $rootScope } from 'helioscope/app/utilities/ng';

import { Design } from 'helioscope/app/designer/Design';

import { loadSingleLineDiagram } from './loaders';

const mod = angular.module('helioscope.singleline.directives', []);

mod.directive('singleline', () => (
    {
        restrict: 'EA',
        scope: {
            dispatcher: '=',
        },
        templateUrl: require('./partials/singleline.html'),
        link: async ($scope, element) => {
            const SingleLineDiagram = await loadSingleLineDiagram();

            function renderDiagram(design) {
                design.generateWiring();
                if (design.supportsSingleline()) {
                    const components = design.getComponents();
                    const interconnect = Design.ensureInterconnect(components);
                    const summary = design.wiring_zones.map(wz => wz.wiringSummary());
                    if (!$scope.diagram) {
                        const canvas = element[0].getElementsByTagName('canvas')[0];
                        $scope.diagram = new SingleLineDiagram(interconnect, summary, canvas);
                        analytics.track('sld.display', {
                            design_id: design.design_id,
                            project_id: design.project.project_id,
                            team_id: $rootScope.user().team_id,
                        });
                    } else {
                        $scope.diagram.setData(interconnect, summary);
                    }
                }
            }

            $scope.design = $scope.dispatcher.design;
            renderDiagram($scope.design);

            const wiringUpdatedListener = $scope.dispatcher.subscribe('WiringUpdated', (_dispatcher, { design }) => {
                $scope.design = design;
                renderDiagram(design);
            });

            $scope.$on('$destroy', () => {
                wiringUpdatedListener();
            });

            $scope.downloadSLD = (design) => {
                const dxf = $scope.diagram.exportDXF();
                io.downloadBlob(dxf, `helioscope_${design.design_id}_sld.dxf`);

                analytics.track('sld.export', {
                    design_id: design.design_id,
                    project_id: design.project.project_id,
                    team_id: $rootScope.user().team_id,
                });
            };
        },
    }),
);
