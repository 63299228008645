import { objectFromKeys } from 'helioscope/app/utilities/helpers';

const ALL_MONTHS = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

function getBaseResults() {
    return {
        acEnergy: 0,
        poaIrradiance: 0,
        shadedAcEnergy: 0,
        shadedIrradiance: 0,
        minShadedIrradiance: Number.POSITIVE_INFINITY,
    };
}

function getEmptyResults() {
    const base = getBaseResults();
    base.monthly = objectFromKeys(ALL_MONTHS, getBaseResults);

    return base;
}

function incrementSimpleSimResult(accumulator, incrementalResult) {
    accumulator.acEnergy += incrementalResult.acEnergy;
    accumulator.poaIrradiance += incrementalResult.poaIrradiance;

    accumulator.shadedAcEnergy += incrementalResult.shadedAcEnergy;
    accumulator.shadedIrradiance += incrementalResult.shadedIrradiance;

    accumulator.minShadedIrradiance = Math.min(accumulator.minShadedIrradiance, incrementalResult.shadedIrradiance);
}

function incrementSimResults(accumulator, incrementalResult) {
    incrementSimpleSimResult(accumulator, incrementalResult);

    if (accumulator.monthly && incrementalResult.monthly) {
        for (let i = 1; i <= 12; i++) {
            incrementSimpleSimResult(accumulator.monthly[i], incrementalResult.monthly[i]);
        }
    }
}

/**
 * helper function to aggregate module results and also monthly sub results
 *
 * normalize irradiance when aggregating accross modules
 * do not normalize irradiance when aggregating across time
 */
export function aggregateSimResults(moduleResults, normalizeIrradiance = true) {
    const results = getEmptyResults();

    for (const moduleResult of moduleResults) {
        incrementSimResults(results, moduleResult);
    }

    if (normalizeIrradiance) {
        const count = moduleResults.length || 1;
        results.poaIrradiance /= count;
        results.shadedIrradiance /= count;

        if (results.monthly) {
            for (let i = 1; i <= 12; i++) {
                results.monthly[i].poaIrradiance /= count;
                results.monthly[i].shadedIrradiance /= count;
            }
        }
    }

    return results;
}
