const mod = angular.module('helioscope.admin.webinars', ['helioscope.users.auth', 'ngCookies', 'ngMessages']);

mod.directive('webinarAlert', [function () {
    return {
        restrict: 'EA',
        scope: {},
        templateUrl: require('helioscope/app/admin/partials/webinar.alert.html'),
        controller: ['$cookies', '$scope', function ($cookies) {
            this.openWebinarUrl = function () {
                window.open('https://help-center.helioscope.com/hc/en-us/articles/19652032508691');
            };

            this.hidden = $cookies.get('hideWebinarAlert');

            this.close = function () {
                $cookies.put('hideWebinarAlert', true);
                this.hidden = true;
            };
        }],
        controllerAs: 'WebinarAlert',
    };
}]);
