/*global angular:true, _:true */

(function (angular, _) {
    'use strict';
    var mod = angular.module('helioscope.projects.controllers.sketchup', ['helioscope.services', 'ui', 'helioscope.projects.resources']);

    mod.controller('ProjectSketchupCtrl', ['$scope', 'Messager', 'SketchupModel', '$log', function ($scope, messager, SketchupModel, $log) {

        if (_.isUndefined($scope.projectData.sketchupModels)) {
            $scope.projectData.sketchupModels = SketchupModel.query({project_id: $scope.project.project_id});
        }

        $scope.deleteSketchup = function (sketchupModel) {
            if ($scope.projectData.sketchupModels.indexOf(sketchupModel) === -1) {
                messager.info("Could not delete Shade Profile");
                return;
            }

            var notification = messager.load("Deleting Shade Profile " + sketchupModel.name);

            sketchupModel.$delete(function () {
                notification.success("Successfully deleted Shade Profile");
                var index = $scope.projectData.sketchupModels.indexOf(sketchupModel);
                $scope.projectData.sketchupModels.splice(index, 1);

            }, function (response) {
                notification.error("Could not delete " + sketchupModel.name);
                $log.warn(response);
            });
        };


    }]);

    mod.controller('ProjectSketchupDetailCtrl', ['$scope', 'sketchupModel', function ($scope, sketchupModel) {
        $scope.sketchupModel = sketchupModel;

        $scope.select = function (render) {
            $scope.selectedRender = render;
        };
    }]);

    mod.controller('ProjectHorizonListCtrl', ['$scope', 'Horizon', function ($scope, Horizon) {
        if (!$scope.projectData.horizonsLoaded) {
            Horizon.query({project_id: $scope.project.project_id}, function () {
                $scope.projectData.horizonsLoaded = true;
            });
        }
    }]);

    mod.controller('ProjectHorizonDetailsCtrl', ['$scope', 'horizon', function ($scope, horizon) {
        $scope.horizon = horizon;
    }]);

    mod.controller('ProjectHorizonUploadCtrl', ['$scope', '$stateParams', 'FileUploader', 'Horizon', '$log',
                                        function ($scope, $stateParams, FileUploader, Horizon, $log) {
        $scope.filetypes = {
            'csv' : 'Comma Separated Values (.csv)',
            'hor' : 'PVSYST horizon angles file (.hor)'
        };

        $scope.uploader = new FileUploader({
            url: Horizon.uploadUrl,
            autoUpload: false
        });

        $scope.uploader.onAfterAddingFile = function (item) {
            item.filetype = !_.isUndefined(item.file.name) ? item.file.name.split('.').pop() : 'csv';
        };

        $scope.uploader.onBeforeUploadItem = function (item) {
            item.formData = [{
                filetype: item.filetype,
                project_id: $stateParams.project_id
            }];
        };

        $scope.uploader.onSuccessItem = function (item, response) {
            try {
                item.horizon = angular.fromJson(response);

                // registers new Horizon in scope bounded resource
                item.horizon = new Horizon(item.horizon);
            } catch (e) {
                $log.warn('Invalid response: ', e);
                item.isError = true;
                item.isSuccess = false;
                return;
            }
        };

        $scope.saveAll = function () {
            angular.forEach($scope.uploader.queue, function (item) {
                if (!item.isError) {
                    item.upload();
                }
            });
        };

    }]);
}(angular, _));
