import { $modal } from 'helioscope/app/utilities/ng';

// same as $modal.open, except it ensures that the model will not be opened more
// than once at a time
const _openModals = new Map();

export function open(opts, key = null) {
    if (!key) {
        key = opts.templateUrl || opts.template;
    }

    if (!key) {
        return $modal.open(opts);
    }

    if (_openModals.get(key)) {
        return null;
    }

    const modal = $modal.open(opts);
    _openModals.set(key, modal);

    modal.result.finally(() => {
        _openModals.delete(key);
    });

    return modal;
}
