import Logger from 'js-logger';

import { createBaseResourceFactory } from './resource';

const logger = Logger.get('relational');

let _baseResourceFactory;

function addResourceMethods(baseResourceFactory, Constructor, ...resourceArgs) {
    // if the third argument is undefined, add it
    if (resourceArgs.length === 2) {
        resourceArgs.push(null);
    }

    // add the relational object to the customized $resource call,
    // this will now serve as a base (automatically registering resources)
    if (resourceArgs.length === 4) {
        resourceArgs[4].BaseConstructor = Constructor;
    } else {
        resourceArgs.push({
            BaseConstructor: Constructor,
        });
    }

    // pass the adjusted args to the base resource which will construct the object
    baseResourceFactory(...resourceArgs);
}

export function configureRelationalResources(httpConfig) {
    const entityLibrary = {};
    const resourceLibrary = {};

    const baseResourceFactory = _baseResourceFactory = createBaseResourceFactory(httpConfig);
    const _resolvers = {};

    /**
     * get the a promise that resolves with the configuration for a relational resource,
     * if called with the configuration, resolve the promise
     */
    function getResource(name, resource = undefined) {
        let resolver = _resolvers[name];

        if (resolver === undefined) {
            resourceLibrary[name] = new Promise((resolve, reject) => {
                resolver = _resolvers[name] = resolve;

                // Every object should be instantiated within the first pass of the javascript,
                // so reject if the application makes it to the next tick and never resolved the
                // Resource we're looking for
                setTimeout(() => {
                    reject(`Did not find relational entity ${name} at compile time.`);
                }, 0);
            });
        }

        if (resource) {
            resolver(resource);
        }

        return resourceLibrary[name];
    }

    /**
     * place an object in the library
     */
    function _register(resourceName, id, obj) {
        // if(id)console.log("Registering " + resourceName +"<"+id+">");
        if (id !== undefined && id !== null) {
            const existing = entityLibrary[resourceName][id];
            if (existing !== undefined && existing !== obj) {
                // if there's an existing object, merge this one into it
                logger.warn(`Registering a preexisting object ${resourceName}`);
                $.extend(true, existing, obj);
            } else {
                entityLibrary[resourceName][id] = obj;
            }
        }
    }

    /**
     * remove an object from the library
     */
    function _deregister(resourceName, id) {
        // console.log("De-Registering " + resourceName + "<" + id + ">");
        delete entityLibrary[resourceName][id];
    }


    /**
     * relationship, should be configured in an object of the form:
     * `propertyPath: relationship('parent', {backref: 'children'})`
     * @param  {str, Relation}     the target foreign entity or it's name (use the name when the class isn't available)
     * @param  {Object} config     optional configuration
     *       @param {str} backref: the name for a backref on the foreign object
     *       @param {str} id:      the name of the field on this object used to look up the relation,
     *                             defaults to  the parent object key + '_id'
     *                             in the example above, the default would be `local_property_id`, which
     *                             when populated on this field will cause the relationship to be populated
     *                             if there is an object already loaded into the library
     */
    class Relationship {
        constructor(Target, { backref, id } = {}) {
            this.Relation = Target;
            this.backref = backref;
            this.localRelationIdName = id;
        }

        /**
         * needs to be set based on the mapping in configure relationships
         */
        setPropertyPath(propertyPath) {
            this.propertyPath = propertyPath;
            if (!this.localRelationIdName) {
                this.localRelationIdName = `${propertyPath}_id`;
            }
        }

        async buildRelationships(propertyPath, Primary) {
            if (this.Relation === undefined) {
                throw Error(`Undefined target for ${Primary.relationName}.${propertyPath}. ` +
                            'You may need to register the resource using string notation.');
            }

            this.setPropertyPath(propertyPath);

            if (typeof this.Relation === 'string') {
                try {
                    this.Relation = await getResource(this.Relation);
                } catch (err) {
                    logger.error(err);
                    return;
                }
            }

            const primaryConfig = Primary._relationConfig;

            // the relation constructor may not be instantiated yet depending on build order
            const relationConfig = this.Relation._relationConfig;

            if (relationConfig.options.cache === false) {
                logger.error(`Relationships cannot target ${this.Relation.relationName} it is not cached`);
                return;
            }

            const PrimaryConstructor = Primary;
            const RelationConstructor = this.Relation;

            primaryConfig.deserializers[this.propertyPath] = (
                Relationship.getRelationParser(RelationConstructor, relationConfig.id, this.localRelationIdName)
            );

            Object.defineProperty(PrimaryConstructor.prototype, this.propertyPath, {
                get: Relationship.getRelationMethod(relationConfig.name, this.localRelationIdName),
                configurable: false,
                enumerable: false,
            });

            if (this.backref) {
                relationConfig.deserializers[this.backref] = (
                    Relationship.getRelationArrayParser(PrimaryConstructor, this.localRelationIdName, relationConfig.id)
                );

                Object.defineProperty(RelationConstructor.prototype, this.backref, {
                    get: Relationship.getBackrefMethod(primaryConfig.name, this.localRelationIdName, relationConfig.id),
                    configurable: false,
                    enumerable: false,
                });
            }
        }

        /**
         * return a function that parses a raw json for a relationship into a tracked entity. In the
         * process ensure that the keys are sufficient to maintain the graph,  in case the API
         * only provides IDs on half of the relationship (e.g. with nested objects)
         * @param   RelationConstructor  the contructor for the related target entity
         * @param   relationIdName       the name of the id field on the related entity.  If the
         *                               related entity is part of a collection, this is the name of
         *                               the foreign key name to the primary object, otherwise it
         *                               is name of its primary key field
         * @param   primaryIdName        if the primary object is referencing a single object,
         *                               this is the name of the foreign key to the relations,
         *                               if the primary object has many children, then this is the
         *                               primary key of the primary object.
         * @return a function that deserializes raw data into a single matched entity of type
         *         RelationConstructor
         */
        static getRelationParser(RelationConstructor, relationIdName, primaryIdName) {
            return (relationData, primaryObjectData) => {
                if (relationData instanceof RelationConstructor) {
                    // sometimes these objects aren't instantiated from POJOs but are used to copy an existing object
                    return relationData;
                }

                const primaryId = primaryObjectData[primaryIdName];
                const relationId = relationData[relationIdName];

                if (primaryId && relationId && primaryId !== relationId) {
                    throw Error(`Inconsistent Object IDs on ${RelationConstructor.name}:` +
                                `${primaryIdName}(${primaryId})=?>${relationIdName}(${relationId})`);
                } else if (primaryId && !relationId) {
                    relationData[relationIdName] = primaryId;
                } else {
                    primaryObjectData[primaryIdName] = relationId;
                }


                // creating a relational object puts it in the central store where it will be
                // accessed via properties on all the other objects
                new RelationConstructor(relationData);  // eslint-disable-line no-new

                 // return null to delete the reference to the JSON object when deserializing
                return null;
            };
        }

        /**
         * return a function that parses an array of raw relation data into relational entitites, this
         * will ensure that the child ids have a pointer to the parent object
         * @param  RelationConstructor the constructor for the children
         * @param  relationIdName      the reference id name the children use to refer to the parent
         * @param  primaryIdName       the primary key name for the parent
         * @return a function that processes (childData, parentData) into the correct entity
         */
        static getRelationArrayParser(RelationConstructor, relationIdName, primaryIdName) {
            const createEntity = Relationship.getRelationParser(RelationConstructor, relationIdName, primaryIdName);

            return (relationDataArr, primaryObjectData) => (
                relationDataArr.map(relationData =>
                    createEntity(relationData, primaryObjectData)
                )
            );
        }


        /**
         * get a relationship function that return the targ
         * @param  relationName    target object
         * @param  relationIdName  the local id that references the target object
         * @return a relation resource of the target type
         */
        static getRelationMethod(relationName, relationIdName) {
            return function getRelation() {
                // need to be a formal function to be able to use 'this' to get the Id
                return entityLibrary[relationName][this[relationIdName]];
            };
        }

        /**
         * return a function that fetches an array of all the referring children
         * @param  childObjectName  the name of the child Objects being targetted
         * @param  referenceIdName  how the children refer the parent
         * @param  primaryIdName    the primary key name of the parent being reference
         * @return an array of the children
         */
        static getBackrefMethod(childObjectName, referenceIdName, primaryIdName) {
            return function getChildren() {
                const parentId = this[primaryIdName];
                return _.filter(entityLibrary[childObjectName], child => child[referenceIdName] === parentId);
            };
        }
    }

    const relationship = (targetName, config) => new Relationship(targetName, config);


    /**
     * define a simple deserializer for an object coming down the pipe
     */
    function deserializeObject(Constructor) {
        if (Array.isArray(Constructor)) {
            // the constructor should be the lone element of the array
            return (arr, _parentData) => arr.map(x => new Constructor[0](x));
        }
        return (x, _parentData) => new Constructor(x);
    }


    const DEFAULT_OPTIONS = {
        cache: true, // whether this instances should be tracked in the central entity cache
    };

    /**
     * the object all relational objects must inherit from
     */
    class RelationalBase {

        constructor(data_) {
            let data = data_;

            if (!this._relationConfig) {
                return this.$initialize(data);
            }

            if (!data) {
                return this;
            }

            const idName = this._relationConfig.id;

            if (data instanceof RelationalBase) {
                logger.info(`Copying ${data} to new object by removing id`);
                data = Object.assign({}, data, { [idName]: null });
                return this.$initialize(data);
            }

            let id = data[idName];
            if (!id) {
                return this.$initialize(data);
            }

            // if there's a PK, coerce it and return the object from the cache if it exists
            id = Number.parseInt(id, 10);

            if (Number.isInteger(id) === false) {
                throw Error(`id ${id} for ${this._relationConfig.name} is not a number.` +
                            ' All PK are expected to be numbers.');
            }


            const rest = _.omit(data, idName);
            data = { [idName]: id, ...rest };

            // check if there's an entity in the cache
            const cached = this.constructor.cached(id);
            if (cached) {
                cached.$initialize(data);
                return cached;
            }

            return this.$initialize(data);
        }

        $initialize(data = {}) {
            // if passing in an object of a different type this will clobber
            // the real configuration (was an issue when prepopulating from profiles)
            delete data._relationConfig;

            this.$parseInput(data);
            if (this._relationConfig) {
                this.$register();
            }

            return this;
        }

        $deregister() {
            return _deregister(this._relationConfig.name, this[this._relationConfig.id]);
        }

        $register() {
            if (this._relationConfig.options.cache === true) {
                _register(this._relationConfig.name, this[this._relationConfig.id], this);
            }
        }

        $registered() {
            return entityLibrary[this._relationConfig.name][this[this._relationConfig.id]] !== undefined;
        }

        static deregister(id) {
            _deregister(this._relationConfig.name, id);
        }

        static deregisterObj(obj) {
            _deregister(this._relationConfig.name, obj[this._relationConfig.id]);
        }

        static cached(id) {
            return entityLibrary[this._relationConfig.name][id];
        }

        static all() {
            return entityLibrary[this._relationConfig.name];
        }

        static clear() {
            entityLibrary[this._relationConfig.name] = {};
        }


        static reinitialize() {
            for (const name of Object.keys(entityLibrary)) {
                entityLibrary[name] = {};
            }
        }

        /**
         * this deep copy will copy properties and iterate through relationships
         * to remove native javascript objects and register them in the
         * relational library if a relationship has been configured
         */
        $parseInput(data) {
            if (this._relationConfig) {
                _.forEach(this._relationConfig.deserializers, (parser, propertyPath) => {
                    let entity = _.get(data, propertyPath);
                    if (entity) {
                        entity = parser(entity, data);
                    }
                    // setting the entity regardless of the property has the side effect of ensuring
                    // deep objects are available at instantiation, even if the fields are undefined
                    _.set(data, propertyPath, entity);
                });
            }

            return $.extend(true, this, data);
        }

        /**
         * defer endpoint creation to ngResource
         * @param  {...[type]} args [description]
         * @return {[type]}         [description]
         */
        static createEndpoint(...args) {
            const cls = this;
            addResourceMethods(baseResourceFactory, cls, ...args);
        }

        /**
         * define relaitonships and mappers for a relational resource
         * @param  name    unique name for identifying the resource
         * @param  mappers an object mapping property paths to either relationship definitions or functions
         *                 to be executed on those fields when JSON comes down the pipe to this endpoint
         * @param  config  object containing configuration options
         *      - name: name to uniquely identify object in db
         *      - id: name of property that identifies the primary key identity of each entity
         *      - options: configuration options for the object
         *          - cached: default true, if false, objects will not be centralized in the DB
         *                    which means relationships an target other entities, but they cannot be targeted
         *
         * Note: it would be nice to extend mappers automatically, but that creates issues with name
         * collisions on backrefs for relationships, so better to keep it simple
         */
        static configureRelationships(mappers = {},
                                      { name = _.snakeCase(this.relationName), id = `${name}_id`, options } = {}) {
            const cls = this;

            if (cls.relationName === undefined) {
                // must explicitly define relational names because of Minification
                throw Error(`Warning did not have a name explicitly defined for ${cls.name}`);
            }

            const inheritedConfigOptions = _.get(cls, '_relationConfig.options', {});

            const config = {
                id,
                name,
                deserializers: {},

                options: _.defaults(options, inheritedConfigOptions, DEFAULT_OPTIONS),
            };

            cls._relationConfig = cls.prototype._relationConfig = config;
            for (const [propertyPath, mapper] of _.entries(mappers)) {
                if (mapper instanceof Relationship) {
                    mapper.buildRelationships(propertyPath, cls);
                } else if (mapper instanceof Function) {
                    config.deserializers[propertyPath] = mapper;
                } else {
                    throw Error('Unrecognized Mapper');
                }
            }

            getResource(cls.relationName, cls);  // this sets the resource and resolves promises
            entityLibrary[name] = {};
        }

        /**
         * Returns a copy of the fields of this class, without fields starting with _ and $.
         */
        toJSON() {
            return _.pickBy(this, (val, key) => key[0] !== '_' && key[0] !== '$');
        }
    }

    return {
        RelationalBase,
        baseResourceFactory,

        Relationship,
        // not sure if we prefer the class or function API, keeping these for now to minimize the diff
        relationship,
        deserializeObject,
    };
}

// /shim for using original $resource
angular.module('relational', []).factory('$resource', () => _baseResourceFactory);
