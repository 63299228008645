import { getBetaBillingURL } from 'helioscope/app/utilities/url';
import { user as loggedInUser } from 'helioscope/app/users';


export class AccountBillingCtrl {
    constructor($scope) {
        'ngInject';

        this.betaBillingURL = loggedInUser.email !== $scope.currentUser.email
            ? getBetaBillingURL($scope.user(), $scope.currentUser.email, {})
            : getBetaBillingURL($scope.user(), '', {});
    }
}
