import moment from 'moment';

export function getUTCString(date) {
    return moment(utcDate(date)).toISOString();
}

export function combineDateTime(date, time) {
    return new Date(date.getFullYear(),
                    date.getMonth(),
                    date.getDate(),
                    time.getHours(),
                    time.getMinutes(),
                    time.getSeconds());
}

export function userLocalDate(dateString) {
    // converts a datestring to the users local time (in UTC)
    // by manually adding the users (Browser) timezone
    //
    //  if you just initialize a time `new Date('2016-05-05 12:00')` naively, it will
    //  display incorrectly in the browser, because of the default encoded time zone,
    //  so shift the time zone out, so when viewing the date it looks correct
    const _date = new Date(dateString);
    _date.setHours(_date.getHours() + _date.getTimezoneOffset() / 60);
    return _date;
}

/**
 * take an arbitrary local date and timezone, and get matching single date aligned with UTC
 */
export function utcDate(date, tzOffset = 0) {
    let typedDate;

    if (typeof date === 'string') {
        typedDate = userLocalDate(date);
    } else {
        typedDate = date;
    }

    return new Date(Date.UTC(typedDate.getFullYear(),
                             typedDate.getMonth(),
                             typedDate.getDate(),
                             typedDate.getHours() - tzOffset,
                             typedDate.getMinutes(),
                             typedDate.getSeconds()));
}

export function createShadeTime(month, day, hour, minute, tzOffset) {
    // Note: don't hardcode a year that uses a leap year, becaue the days of the year will be incorrect
    const dateString = `2017-${leadingZero(month)}-${leadingZero(day)}T${leadingZero(hour)}:${leadingZero(minute)}Z`;
    return utcDate(dateString, tzOffset);
}


const ONE_HOUR = 1000 * 60 * 60;
const ONE_DAY = ONE_HOUR * 24;


/**
 * get the day of the year from 1 to 365
 *
 * because javascript injects time zones by default, make sure to manually sync with the same
 * time zone used for the data
 */
export function getDayOfYear(date, tzOffset) {
    const onejan = new Date(Date.UTC(date.getUTCFullYear(), 0, 1, -tzOffset));
    return Math.ceil((date - onejan) / ONE_DAY);
}

/**
 * get the hour of the year from 1 to 8760 (+24 during a leap year)
 *
 * because javascript injects time zones by default, make sure to manually sync with the same
 * time zone used for the data
 *
 * need the manual adjustment because getting a date, because javascript would otherwise
 * store the dates in the users timezone, so need to ensure that this is always 1-8760 regardless
 * of user location, and project location
 */
export function getHourOfYear(date, tzOffset) {
    const onejan = new Date(Date.UTC(date.getUTCFullYear(), 0, 1, -tzOffset));
    return Math.ceil((date - onejan) / ONE_HOUR);
}


function leadingZero(num) {
    return (num < 10 ? '0' : '') + parseInt(num, 10);
}
