import { EntityPremade } from 'helioscope/app/designer/premade/Premade';
import { FieldSegment } from '../field_segment';
import { Keepout } from '../keepout';

export class InternalClipboard {
    constructor() {
        this.components = [];
    }

    writeToClipboard(selectedEntities) {
        const validEntities = selectedEntities.filter(entity =>
            entity instanceof Keepout ||
            entity instanceof FieldSegment ||
            entity instanceof EntityPremade
        );
        if (validEntities.length > 0) {
            this.components = validEntities.map(entity => {
                if (entity instanceof Keepout) {
                    return new Keepout(entity);
                } else if (entity instanceof FieldSegment) {
                    return new FieldSegment(entity);
                } else if (entity instanceof EntityPremade) {
                    return new EntityPremade(entity);
                }
            });
        }
        return validEntities.length > 0;
    }

    readFromClipboard() {
        return this.components;
    }

    isNotEmpty() {
        return this.components.length > 0;
    }
}
