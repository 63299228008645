/**
 * Functions for saving asynchronously from user actions.
 *
 */
import _ from 'lodash';
import Logger from 'js-logger';

import { Messager, $rootScope, $q } from 'helioscope/app/utilities/ng';
import { BulkObjects } from './BulkObjects';

const logger = Logger.get('persistence');

/**
 * object to schedule Resource saves in the background with a basic
 * debounce rate to limit and combine multiple server calls
 *
 * TODO: need to send current timestamp with requests and have server
 * ensure only latest requests are processed
 */
export class DeferredResourceSaver {
    static PERSISTENCE_DEBOUNCE = 5000; // how long to debounce save calls for each resource

    constructor(obj) {
        this.resource = obj;
        this.resolved = false;
        this.deferred = $q.defer();
    }

    _debouncedSave = _.debounce(() => this.save(), DeferredResourceSaver.PERSISTENCE_DEBOUNCE);


    /**
     * stop the save from even happening
     */
    cancel() {
        this._debouncedSave.cancel();

        if (this.resource.$cancel) {
            // if the re source had an outstanding save request that
            // hasn't returned yet, cancel it before initiating a
            // new save, this cancels requests on the javascript
            // side, but doesn't guarantee the server won't
            // commit the change

            // we can make resources automatically cancel previously scheduled requests if desired
            // (see $cancel code in relational/resource.js);
            this.resource.$cancel('cancel');
        }


        // reject with cancel so other processes know this was deliberate
        this.deferred.reject('cancel');
        return this.deferred.promise;
    }

    /**
     * cancel debounce and save immediately
     */
    async save() {
        if (this.resolved) {
            // no issues if already resolved
            return this.deferred.promise;
        } else if (this.requestPromise && this.resource.$cancel) {
            // a request has already been sent, cancel it before sending a new one
            this.resource.$cancel('superceded');
        }

        // otherwise, no request, so cancel the debouncer and trigger a save
        this._debouncedSave.cancel();

        this.inFlight = true;
        this.requestPromise = this.resource.$update()
            .then(resource => {
                logger.log(`Saved ${resource} successfully`);
                this.resolved = true;
                this.deferred.resolve(resource);
            })
            .catch(err => {
                if (_.get(err, 'config.timeout.$$state.status') === 1) {
                    // this is a janky way to check that the request was manually canceled
                    logger.info('Cancelled', _.get(err, 'config.timeout.$$state.value'));

                    // don't need to do anything to the original promise, since it will now
                    // be resolved by the new request
                    return;
                }

                logger.warn(`Error saving ${this.resource}`);
                this.deferred.reject(err);
            })
            .finally(() => {
                this.inFlight = false;
            });


        return this.deferred.promise;
    }

    trigger() {
        this._debouncedSave();
        return this.deferred.promise;
    }
}

/**
 * wrap the resource saver in an object to handle the save state of groups of objects
 */
export class ResourceUpdateQueue {
    constructor() {
        this.updateQueue = new Map();
        this.createQueue = new Map();
        this.deleteQueue = new Map();

        this.resourcesWithErrors = new Set();

        this.bulkUpdateInProgress = null;
    }

    /**
     * everything in the queue, and return a promise for when the saves are complete
     * @return {Promise} promise that resolves when all saves are complete
     */
    flush() {
        logger.info(`Flushing ${this.updateQueue.size} saves`);

        const promises = (
            [...this.updateQueue.keys()].map(resource => this.schedule(resource, { now: true }))
                .concat([...this.resourcesWithErrors].map(resource => this.schedule(resource, { now: true })))
                .concat([...this.createQueue.values()])
                .concat([...this.deleteQueue.values()])
        );

        return $q.all(promises);
    }

    /**
     * schedule a save for a given resource, if it already exists in the queue, trigger
     * the countdown
     * @param  {RelationalResource} resource the resource instance to persist
     * @return {Promise}            promsie that resolves when the resource is persisted
     */
    async schedule(resource, { now = false } = {}) {
        let resourceSaver = this.updateQueue.get(resource);
        const newResource = (resourceSaver === undefined);

        if (newResource) {
            resourceSaver = new DeferredResourceSaver(resource);
            this.updateQueue.set(resource, resourceSaver);
        }

        try {
            await (now ? resourceSaver.save() : resourceSaver.trigger());
            this.resourcesWithErrors.delete(resource);
        } catch (err) {
            if (err !== 'cancel') {
                this.resourcesWithErrors.add(resource);
                if (newResource) {
                    Messager.error(`Warning: had a problem saving ${resource}`, { delay: 10000 });
                }
                logger.error('Could not save a resource', resource, err);
            } else {
                logger.log('Got a save rejection (cancel)', err);
            }
        }


        if (newResource) {
            // only schedule a delete on successful save the first
            // time a save is triggered
            this.updateQueue.delete(resource);

            // await/async hates the digest cycle
            $rootScope.$apply();
        }

        return resource;
    }


    /**
     * create a new resource with basic duplication protection
     */
    create(resource) {
        const preexistingCreate = this.createQueue.get(resource);
        if (preexistingCreate) {
            logger.warn('Already had create scheduled');
            return $q.reject('duplicate');
        }

        const preexistingUpdate = this.updateQueue.get(resource);
        if (preexistingUpdate) {
            logger.warn(`Got create on object with outstanding update ${resource}`);
            preexistingUpdate.cancel();
        }

        delete resource[resource._idName];
        const newCreate = resource.$save().finally(() => this.createQueue.delete(resource));
        this.createQueue.set(resource, newCreate);

        return newCreate;
    }


    /**
     * delete a resource with basic duplication protection
     */
    delete(resource) {
        if (resource instanceof BulkObjects) {
            resource.objects.forEach(resourceChild => {
                const ongoingSave = this.updateQueue.get(resourceChild);
                if (ongoingSave) {
                    ongoingSave.cancel();
                }
            });
        } else {
            const saver = this.updateQueue.get(resource);
            if (saver) {
                saver.cancel();
            }
        }

        const preexistingDelete = this.deleteQueue.get(resource);
        if (preexistingDelete) {
            logger.warn('Already had delete scheduled');
            return $q.reject('duplicate');
        }

        const newDelete = resource.$delete().finally(() => {
            // delete resource[resource._idName];
            this.deleteQueue.delete(resource);
        });
        this.deleteQueue.set(resource, newDelete);

        return newDelete;
    }
    async update(resource) {
        if (this.bulkUpdateInProgress) {
            await this.bulkUpdateInProgress;
            return this.update(resource);
        }
        const newUpdate = resource.$update().finally(() => {
            this.bulkUpdateInProgress = null;
        });
        this.bulkUpdateInProgress = newUpdate;

        return newUpdate;
    }

    get saving() {
        return this.deleteQueue.size + this.createQueue.size + _.sumBy([...this.updateQueue.values()], 'inFlight');
    }

    get size() {
        return this.updateQueue.size + this.deleteQueue.size + this.createQueue.size;
    }

    get errors() {
        return this.resourcesWithErrors.size;
    }
}
