
let hasBeenLoggedOut = false;

export function login(user = null) {
    if (!user) {
        hasBeenLoggedOut = true;
    }
    if (window.zE && user) {
        window.zE(() => {
            window.zE('webWidget', 'updateSettings', {
                webWidget: {
                    authenticate: {
                        chat: {
                            jwtFn: function(callback) {
                                fetch('/api/zendesk_jwt').then((resp) => {
                                    if (resp.status === 200) {
                                        resp.json().then((respObj) => {
                                            callback(respObj.token);
                                        });
                                    } else {
                                        callback()
                                    }
                                });
                            },
                        },
                    },
                },
            })

            // Every time we call chat:reauthenticate, it causes the chat client to flicker and reinitialize.
            // We only want to do this when we have a freshly logged in user
            if (hasBeenLoggedOut) {
                window.zE('webWidget', 'chat:reauthenticate');
            }
        });
    }
}

export function newMessage() {
    if (window.zE) {
        window.zE(() => {
            window.zE.activate();
        });
    }
}

export function hideChat() {
    if (window.zE) {
        window.zE(() => {
            window.zE.hide();
        });
    }
}

// The functions below are functions unimplemented on Olark chat as well.
export function logout() {}

export function shutdown() {}

export function update() {}
