import { uniq, flatten } from 'lodash';

import { flMap } from 'helioscope/app/utilities/containers';

import {
    Bounds,
    bufferPolygonSingle,
    bufferPolygonMulti,
    differencePaths,
    intersectPathsMulti,
    pathIntersects,
    simplifyPaths,
} from 'helioscope/app/utilities/geometry';

import { Keepout } from '../keepout';

import { multiPathOnSurface, calculatePathAbove } from './surface_helpers';
import { LayoutRegion, THE_GROUND } from '../field_segment';

export class LayoutManager {
    constructor(designScene) {
        this.designScene = designScene;

        this.exclusions = flMap(() => new Map()); // from surface to surfaces that intersect it

        this.layoutRegions = flMap(surface => new LayoutRegion(this._calculateLayoutPath(surface), surface, surface));

        this.pathHandlers = new SurfacePathHandlerCache();
    }

    getLayoutRegion(surface) {
        const region = this.layoutRegions.get(surface);

        region.updateParameters(surface);
        return region;
    }

    getLayoutPath(surface) {
        const region = this.layoutRegions.get(surface);
        if (region) return region.paths;
        return null;
    }

    getExclusionPaths(surface) {
        return flatten([...this.exclusions.get(surface).values()]);
    }

    getExcluders(surface) {
        return [...this.exclusions.get(surface).keys()];
    }

    getExcludedBy(surface) {
        const allExcluded = [];

        for (const [excluded, exclusionMap] of this.exclusions) {
            if (exclusionMap.has(surface)) {
                allExcluded.push(excluded);
            }
        }

        return allExcluded;
    }

    setExclusions(surface, excluder, exclusionPaths) {
        this.exclusions.get(surface).set(excluder, exclusionPaths);
        this.layoutRegions.delete(surface);
    }

    getInnerSetbackForRenderer(surface, includeOuterPath = true) {
        const pathHandler = this.pathHandlers.get(surface);

        return [
            ...(includeOuterPath ? pathHandler.simplifiedPath : []),
            ...pathHandler.innerPaths.map(subpath => subpath.slice().reverse()),
        ];
    }

    getExclusionPathsForRenderer(surface, includeOuterPath = true) {
        if (surface.constructor === Keepout) {
            return null; // should never setbacks on keepouts
        }

        const exclusionPaths = this.getExclusionPaths(surface);

        if (surface !== THE_GROUND) {
            // add the inner setback for any shape (note, this will always be a field segment)
            exclusionPaths.push(...this.getInnerSetbackForRenderer(surface, includeOuterPath));
        } else {
            // for the ground, add negative space for all the field segments benath it
            for (const excluder of this.getExcluders(surface)) {
                exclusionPaths.push(...this.pathHandlers.get(excluder).simplifiedPath.map(x => x.slice().reverse()));
            }
        }

        return multiPathOnSurface(surface, exclusionPaths);
    }

    _calculateLayoutPath(surface) {
        const innerSetbackPaths = this.pathHandlers.get(surface).innerPaths;
        const excludingPaths = this.getExclusionPaths(surface);

        if (this.designScene.design.shade_keepouts) {
            excludingPaths.push(...this.designScene.shadowManager.getShadowPath(surface));
        }

        return multiPathOnSurface(surface, differencePaths(innerSetbackPaths, excludingPaths));
    }

    removeSurface(surface) {
        const affected = [];

        this.exclusions.delete(surface);
        this.layoutRegions.delete(surface);

        // anything excluded by the surface is affected
        for (const [excluded, exclusionMap] of this.exclusions) {
            if (exclusionMap.delete(surface)) {
                affected.push(excluded);
                this.layoutRegions.delete(excluded);
            }
        }

        return affected;
    }

    /**
     * recalculate all the intersections between surfaces
     *
     * for shapes that are direct children, move the path down slightly numerically, this ensures
     * that shadows wont accidentally be projected down onto the parent surface for numeric reasons
     */
    updateSurface(updatedSurfaces) {
        const affected = [...updatedSurfaces];

        for (const surface of updatedSurfaces) {
            const pathHandler = this.pathHandlers.get(surface); // needed up update the setback cache

            // add the old surfaces that targeted this one
            affected.push(...this.removeSurface(surface));

            const intersectingSurfaces2d = this.designScene.intersectingSurfaces(surface);

            const potentialExcluders = uniq([
                ...intersectingSurfaces2d,
                ...this.excludersFromParent(surface),
            ]);

            // all the things that intersect the surface
            const allExclusions = [
                ...this.getInternalExclusions(surface),
                ...this.getExternalExclusions(surface, potentialExcluders),
            ];

            // the cached layout path was already removeSurface above
            this.exclusions.set(surface, new Map(allExclusions));

            // make this exclude the parent
            const parent = this.designScene.parentSurface(surface);
            if (parent) {
                this.setExclusions(
                    parent, surface,
                    this.pathHandlers.get(parent).clip(pathHandler.outerPath)
                );

                affected.push(parent);
            }

            // add exclusions for anything this intersects
            for (const potentiallyExcluded of potentialExcluders) {
                const overlapPaths = this.getOverlapPaths(potentiallyExcluded, surface);

                if (overlapPaths) {
                    this.setExclusions(potentiallyExcluded, surface, overlapPaths);
                    affected.push(potentiallyExcluded);
                }
            }
        }

        return uniq(affected);
    }

    /**
     * historically, we have been letting people draw keepouts along the ground, and
     * then use their setback path to define a keepout on the parent surface.  This is a hack
     * to account for the fact that we don't allow defining setbacks for the 'ridge' of a field
     * segment
     *
     * to enable this now, we will allow all keepouts to setback on a surface
     * if the keepout or its setback overlaps on the parent surface.  Note, we check for
     * check for outer setback vs outersetback because in reality this particular function needs
     * to be bidirection, e.g. if a keepout or a field segment changes, we need to be able to
     * match the keepout newly excluding the field segment because it moved, or the field segment
     * newly being excluded by the keepout beacuse it changed
     */
    excludersFromParent(surface, pathHandler = this.pathHandlers.get(surface)) {
        if (surface === THE_GROUND) return [];

        const parentExcluders = [];

        for (const potentialExcluder of this.designScene.siblingSurfaces(surface)) {
            if (potentialExcluder === surface) continue;

            const exclPathHandler = this.pathHandlers.get(potentialExcluder);

            if (exclPathHandler.outerBounds.intersects(pathHandler.outerBounds)
                    && pathIntersects(exclPathHandler.outerPath, pathHandler.outerPath)) {
                // matching buffers doesn't mean they overlap, but it will be clipped anyway, so it's
                // fine to be conservative
                parentExcluders.push(potentialExcluder);
            }
        }

        return parentExcluders;
    }

    getExternalExclusions(surface, potentialExcluders) {
        const exclusions = [];

        const pathHandler = this.pathHandlers.get(surface);

        for (const potentialExcluder of potentialExcluders) {
            if (potentialExcluder === surface) continue;

            const overlapPaths = this.getOverlapPaths(surface, potentialExcluder, pathHandler);

            if (overlapPaths) {
                exclusions.push([potentialExcluder, overlapPaths]);
            }
        }

        return exclusions;
    }

    getInternalExclusions(surface) {
        const exclusions = [];

        const children = this.designScene.childSurfaces(surface);
        const setback = this.pathHandlers.get(surface);
        for (const child of children) {
            const childSetback = this.pathHandlers.get(child);
            exclusions.push([child, setback.clip(childSetback.outerPath)]);
        }

        return exclusions;
    }

    /**
     * get all the external surfaces that exclude this surface
     *
     * keepouts always exclude for module layouts regardless of height, this is
     * necessary because in the current setback interaction model, we need variable
     * setbacks, and it is often easier to draw a straight line keepout on the
     * ground, than to draw a keepout exactly along a surface.
     */
    getOverlapPaths(subject, excluder, pathHandler = this.pathHandlers.get(subject)) {
        if (subject instanceof Keepout) return null; // never care if a keepout is intersected by something

        let excluderPaths = null;

        let forceExcluderSetback = false;

        if (excluder.surfacePlane.approx(subject.surfacePlane)) {
            // coplanar points should be tiebroken by area
            const subjectNode = this.designScene.getNode(subject);
            const node = this.designScene.getNode(excluder);

            if (node.groundArea < subjectNode.groundArea) {
                // surfaces must be at the same height and coplanar
                // Subtract a small shadow epsilon to ensure overlapping areas never generate
                // shadows due to numerical precision
                excluderPaths = [excluder.surfacePath3d()];
                forceExcluderSetback = true;  // for same height the excluder setback makes more sense
            }
        } else {
            const subjectOverlapPaths = calculatePathAbove(subject, excluder);
            if (subjectOverlapPaths && subjectOverlapPaths.length) {
                excluderPaths = subjectOverlapPaths;
            } else if (excluder instanceof Keepout
                    && this.designScene.parentSurface(excluder) === this.designScene.parentSurface(subject)) {
                    // if the surface is a keepout, and is on the parent surface, then let it obstruct
                    // upwards onto this surface, even though it has no area above the plane.
                    //
                    // This is a hack to allow people to draw keepouts on the
                    // ground and have them affect only the top ridge of a roof if we had keepouts for
                    // selective parts of a roof, then this would a non-issue
                excluderPaths = [excluder.surfacePath3d()];
                forceExcluderSetback = true;  // force it to use it's own setback, since it is
                                              // below the field segment surface
            }
        }

        if (!excluderPaths) return null;

        let bufferFn;
        if (forceExcluderSetback || excluder.outerSetback > subject.innerSetback) {
            const excluderHandler = this.pathHandlers.get(excluder);
            bufferFn = (path) => excluderHandler.bufferPathFragment(path);
        } else {
            bufferFn = (path) => pathHandler.bufferExternalPath(path);
        }

        return pathHandler.clip(excluderPaths.map(bufferFn));
    }
}


class SurfacePathHandlerCache {
    constructor() {
        this._cache = new Map();
    }

    get(surface) {
        let surfacePathHandler = this._cache.get(surface);

        if (!surfacePathHandler) {
            surfacePathHandler = new SurfacePathHandler(surface);
            this._cache.set(surface);
        } else {
            surfacePathHandler.update(surface);
        }

        return surfacePathHandler;
    }
}

class SurfacePathHandler {
    constructor(surface) {
        this.update(surface);
    }

    update(surface) {
        const dirty = (
            this.path !== surface.geometry.path
            || this.innerSetback !== surface.innerSetback
            || this.outerSetback !== surface.outerSetback
        );

        if (dirty) {
            // note: using the 2d path means that we can keep the same geometry
            // regardless of how stacking changes
            this.path = surface.geometry.path;
            this.simplifiedPath = simplifyPaths([this.path]);
            this.innerSetback = surface.innerSetback;
            this.outerSetback = surface.outerSetback;

            this._innerPaths = null;
            this._outerPath = null;
            this._outerBounds = null;

            this._bufferedExternalPaths = new WeakMap();
            this._bufferedPathFragments = new WeakMap();
        }
    }

    clip(path) {
        return intersectPathsMulti(this.simplifiedPath, path);
    }

    /**
     * buffer a path using this shapes outer setback, used for when only a fragment of this shape
     * intersects another one (e.g. a portion of it pops through a slanted surfaces)
     */
    bufferPathFragment(path) {
        let bufferedPath = this._bufferedPathFragments.get(path);

        if (!bufferedPath) {
            // the 1cm buffer is used to ensure the straight-line setbacks are always a polygon
            bufferedPath = bufferPolygonSingle(path, this.outerSetback || 0.01);
            this._bufferedPathFragments.set(path, bufferedPath);
        }

        return bufferedPath;
    }

    /**
     * buffer external shapes that intersect this one, uses the internal setback
     */
    bufferExternalPath(path) {
        let bufferedPath = this._bufferedExternalPaths.get(path);

        if (!bufferedPath) {
            // buffer using the internal setback, to treat this like an internal edge,
            // so that when there are internal shapes we can use the max of the internal or external
            // shape for differences
            bufferedPath = bufferPolygonSingle(path, this.innerSetback || 0.01);
            this._bufferedExternalPaths.set(path, bufferedPath);
        }

        return bufferedPath;
    }

    get innerPaths() {
        if (!this._innerPaths) {
            this._innerPaths = bufferPolygonMulti(this.path, -this.innerSetback || 0);
        }

        return this._innerPaths;
    }

    get outerPath() {
        if (!this._outerPath) {
            this._outerPath = this.bufferPathFragment(this.path);
        }

        return this._outerPath;
    }

    get outerBounds() {
        if (!this._outerBounds) {
            this._outerBounds = new Bounds(this.outerPath);
        }

        return this._outerBounds;
    }
}
