import Logger from 'js-logger';
import { get } from 'lodash';

import { helioscopeConfig } from 'reports/angular-bridge.ts';

const logger = Logger.get('analytics');

const googleConversionId = 973842766;

export const conversionLabels = {
    purchase: 'Mq5WCPv5m2QQztKu0AM',
    accoutCreated: 'ub9ECPLVnWMQztKu0AM',
    accountCreatedCustomPage: '-RmLCNbarmMQztKu0AM',
};

export function track(name, payload) {
    if (window.analytics) {
        window.analytics.track(name, payload && {
            ...payload,
            app: window.location.href.includes('forceAngular') ? 'reports' : 'old helioscope',
        });
    }
}

export function trackConversion(user, conversionLabel, conversionValue = null) {
    const data = {
        google_conversion_id: googleConversionId,
        google_conversion_label: conversionLabel,
        google_custom_params: {
            email: user.email,
        },
        google_remarketing_only: true,
    };

    if (conversionValue) {
        data['google_conversion_value'] = conversionValue;
        data['google_conversion_currency'] = 'USD';
    }

    if (!window.google_trackConversion) {
        logger.debug(`convert transaction ${conversionLabel}`, data);
        return;
    }

    window.google_trackConversion(data);
}

export function trackKeyboardAction(action, design, user) {
    if (!design || !user) {
        logger.info('Failed to capture keyboard action. Missing design or user.');
        return;
    }

    track(action, {
        project_id: design.project_id || null,
        design_id: design.design_id || null,
        team_id: user.team_id || null,
    });
}
