import { XMLParser } from 'fast-xml-parser';
import Logger from 'js-logger';
import * as THREE from 'three';
import URI from 'urijs';

import { Bounds } from 'helioscope/app/utilities/geometry';

const logger = Logger.get('KMLDocument');
const xmlParser = new XMLParser({
    ignoreAttributes: false,
    attributeNamePrefix: '',
});

/**
 * This parses a subset of the KML specs
 * See https://developers.google.com/kml/documentation/kmlreference
 */
const DEFAULT_COLOR = {
    rgb: 'ffffff',
    opacity: 0.7,
};
const DEFAULT_STYLE = {
    width: 1,
    fill: true,
    outline: true,
    fillColor: DEFAULT_COLOR,
    lineColor: DEFAULT_COLOR,
};

export const TYPE_POLYGON = 'polygon';
export const TYPE_LINE_STRING = 'line_string';
export const TYPE_LINE_RING = 'line_ring';
export const TYPE_MULTI_GEOMETRY = 'multi_geometry';
export const TYPE_POINT = 'point';


export class KMLDocument {
    constructor(doc, { baseUrl = null } = {}) {
        this.groundOverlays = null;
        this.loaded = false;
        this._latLngbounds = new Bounds();
        this._styles = null;
        this._baseUrl = baseUrl;
        this._geometryParsers = {
            Polygon: this._parsePolygon.bind(this),
            LineRing: this._parseLinearRing.bind(this),
            LineString: this._parseLineString.bind(this),
            MultiGeometry: this._parseMultiGeometry.bind(this),
            Point: this._parsePointGeometry.bind(this),
        };

        this._parse(doc);
    }

    hasContent() {
        return this.groundOverlays.length > 0 || this.placemarks.length > 0;
    }

    getBounds() {
        return this._latLngbounds;
    }

    _getStyle(styleName) {
        return this._styles[styleName.replace(/#/, '')];
    }

    _parse(data) {
        const parser = new DOMParser();
        const kml = parser.parseFromString(data, 'text/xml');

        this._styles = this._parseGlobalStyles(kml);

        this.groundOverlays = KMLDocument._getElementsByTagName(kml, 'GroundOverlay')
            .map(node => this._processGroundOverlay(node))
            .filter(x => x);

        this.placemarks = KMLDocument._getElementsByTagName(kml, 'Placemark')
            .map(placemark => this._processPlacemark(placemark))
            .filter(x => x && x.geometry);
        this.loaded = true;
    }

    _parseGlobalStyles(kml) {
        const styles = {};

        for (const rawStyle of KMLDocument._getElementsByTagName(kml, 'Style')) {
            const parsedStyle = this._parseStyle(rawStyle);
            styles[parsedStyle.id] = parsedStyle;
        }

        // Style can be applied conditionally on whether or not it is highlighted (used for roll-over effects).
        // This is implemented using an additional indirection layer called "StyleMap".
        // Since we don't care about such nonsense, lets just make it an alias instead
        // See: https://developers.google.com/kml/documentation/kmlreference?hl=en#stylemap
        for (const styleMap of KMLDocument._getElementsByTagName(kml, 'StyleMap')) {
            const styleUrl = styleMap.Pair.find(x => x.key === 'normal').styleUrl;
            styles[styleMap.id] = styles[styleUrl.substr(1)];
        }

        return styles;
    }

    _parseStyle(style) {
        const parsedStyle = Object.assign({}, DEFAULT_STYLE);
        parsedStyle.id = style.id;
        if (style.PolyStyle) {
            const polyStyle = style.PolyStyle;
            if ('fill' in polyStyle) {
                parsedStyle.fill = polyStyle.fill === 1;
            }

            if ('outline' in polyStyle) {
                parsedStyle.outline = polyStyle.outline === 1;
            }

            if ('color' in polyStyle) {
                parsedStyle.fillColor = this._parseColor(polyStyle.color);
            }
        }

        if (style.LineStyle) {
            const lineStyle = style.LineStyle;
            if ('color' in lineStyle) {
                parsedStyle.lineColor = this._parseColor(lineStyle.color);
            }

            if ('width' in lineStyle) {
                parsedStyle.width = parseInt(lineStyle.width, 10);
            }
        }

        return parsedStyle;
    }

    _parseColor(kmlColor) {
        if (_.isNumber(kmlColor)) {
            kmlColor = _.padStart(kmlColor.toString(16), 8, '0');
        }

        const alpha = kmlColor.substr(0, 2);
        const blue = kmlColor.substr(2, 2);
        const green = kmlColor.substr(4, 2);
        const red = kmlColor.substr(6, 2);

        return {
            rgb: red + green + blue,
            opacity: parseInt(alpha, 16) / 255,
        };
    }

    _processGroundOverlay(rawOverlay) {
        try {
            const parsed = {
                icon: rawOverlay.Icon,
                latLonBox: rawOverlay.LatLonBox,
                latLonQuad: rawOverlay['gx:LatLonQuad'] ?
                    this._processLatLonQuad(rawOverlay['gx:LatLonQuad']) : undefined,
                opacity: rawOverlay.color ? (parseInt(rawOverlay.color, 16) >>> 24) / 255 : 1,
            };

            parsed.icon.href = this._createUrl(parsed.icon.href);
            KMLDocument._copyIfExists(rawOverlay, parsed, 'name');
            KMLDocument._copyIfExists(rawOverlay, parsed, 'drawOrder');

            // update bounds
            if ((parsed.latLonBox && parsed.latLonBox.north === undefined) ||
                (parsed.latLonQuad && parsed.latLonQuad.length === 0)) {
                return null;
            }

            let ne = null;
            let sw = null;
            if (parsed.latLonBox) {
                const coords = parsed.latLonBox;
                ne = { y: coords.north, x: coords.east };
                sw = { y: coords.south, x: coords.west };
            }

            if (parsed.latLonQuad) {
                const qps = parsed.latLonQuad;
                const maxX = Math.max(qps[0].x, qps[1].x, qps[2].x, qps[3].x);
                const minX = Math.min(qps[0].x, qps[1].x, qps[2].x, qps[3].x);
                const maxY = Math.max(qps[0].y, qps[1].y, qps[2].y, qps[3].y);
                const minY = Math.min(qps[0].y, qps[1].y, qps[2].y, qps[3].y);

                ne = { x: maxX, y: maxY };
                sw = { x: minX, y: minY };
            }

            this._latLngbounds.extend(ne);
            this._latLngbounds.extend(sw);

            return parsed;
        } catch (e) {
            logger.warn('Error parsing ground overlay: ', rawOverlay);
            logger.warn(e);
            return null;
        }
    }

    _processLatLonQuad(rawQuad) {
        // rawQuad.coordinates are stored as: lat, lng, 0 lat, lng 0 lat, lng 0 lat, lng 0
        // Split by ", " and remove the 0s
        const coords = rawQuad.coordinates.split(',');

        const newCoords = [];
        for (const coord of coords) {
            const splitResult = coord.split(' ');
            if (splitResult.length === 1) {
                newCoords.push(splitResult[0]);
            } else if (splitResult.length === 2) {
                newCoords.push(splitResult[1]);
            }
        }

        // 1st: top-left (North-West)
        // 2nd: bottom-left (South-West)
        // 3rd: bottom-right (South-East)
        // 4th: top-right (North-East)
        const quadPoints = [
            (new THREE.Vector2(newCoords[6], newCoords[7])),
            (new THREE.Vector2(newCoords[0], newCoords[1])),
            (new THREE.Vector2(newCoords[2], newCoords[3])),
            (new THREE.Vector2(newCoords[4], newCoords[5])),
        ];

        return quadPoints;
    }

    _processPlacemark(rawPlacemark) {
        try {
            const parsedPlacemark = {
                name: rawPlacemark.name,
                style: DEFAULT_STYLE,
            };

            parsedPlacemark.geometry = this._parseGeometryContainer(rawPlacemark);

            if (rawPlacemark.styleUrl) {
                parsedPlacemark.style = this._getStyle(rawPlacemark.styleUrl);
            } else if (rawPlacemark.Style) {
                parsedPlacemark.style = this._parseStyle(rawPlacemark.Style);
            }

            return parsedPlacemark;
        } catch (e) {
            logger.warn('Error processing placemark: ', rawPlacemark);
            logger.warn(e);
            return null;
        }
    }

    _parseGeometryContainer(container) {
        for (const key of Object.keys(this._geometryParsers)) {
            const geometry = container[key];
            if (!geometry) {
                continue;
            }

            return this._geometryParsers[key](geometry);
        }

        return null;
    }

    _parseLinearRing(linearRing) {
        if (Array.isArray(linearRing)) {
            const result = [];
            linearRing.map(elem => result.push({
                coordinates: this._parseCoordinates(elem.coordinates),
                type: TYPE_LINE_RING,
            }));
            return result;
        } else {
            return {
                coordinates: this._parseCoordinates(linearRing.coordinates),
                type: TYPE_LINE_RING,
            };
        }
    }


    _parseLineString(lineString) {
        return {
            coordinates: this._parseCoordinates(lineString.coordinates),
            type: TYPE_LINE_STRING,
        };
    }

    _parsePolygon(polygon) {
        const parsed = {
            outerBoundaryIs: { linearRing: this._parseLinearRing(polygon.outerBoundaryIs.LinearRing) },
            type: TYPE_POLYGON,
        };

        if (polygon.innerBoundaryIs && Array.isArray(polygon.innerBoundaryIs) === false) {
            polygon.innerBoundaryIs = [polygon.innerBoundaryIs];
        }

        parsed.innerBoundaryIs = (polygon.innerBoundaryIs || []).map(item => ({
            linearRing: this._parseLinearRing(item.LinearRing),
        }));

        return parsed;
    }

    _parseMultiGeometry(multiGeometry) {
        let parsedChildGeometry = [];
        for (const key of Object.keys(multiGeometry)) {
            const parser = this._geometryParsers[key];
            if (!parser) {
                continue;
            }

            let rawChildGeometry = multiGeometry[key];
            if (!Array.isArray(rawChildGeometry)) {
                rawChildGeometry = [rawChildGeometry];
            }

            const parsed = rawChildGeometry.map(geometry => parser(geometry));
            parsedChildGeometry = parsedChildGeometry.concat(parsed);
        }

        return {
            type: TYPE_MULTI_GEOMETRY,
            children: parsedChildGeometry,
        };
    }

    _parsePointGeometry(pointGeometry) {
        return {
            coordinates: this._parseCoordinates(pointGeometry.coordinates),
            type: TYPE_POINT,
        };
    }

    _parseCoordinates(coords_) {
        if (_.isEmpty(coords_)) return null;
        const coords = coords_.replace(/,\s+/g, ',');

        const path = coords.split(/\s+/g);
        const parsedCoords = path.map(coord => {
            const [lng, lat, alt] = coord.split(',').map(parseFloat);
            return { lat, lng, alt };
        });

        for (const coord of parsedCoords) {
            this._latLngbounds.extend({ y: coord.lat, x: coord.lng });
        }

        return parsedCoords;
    }

    _createUrl(rawUrl) {
        rawUrl = rawUrl.replace('//pol.pictometry.com/', '//d2dlmw05u98p8m.cloudfront.net/pol.pictometry.com/');

        // For new kmzs (upload zip file -> unzipped),
        // this._baseUrl is not provided for new kmz uploads.
        // images are stored in overlay.imageDict: {relativeURL: image blob url}
        // return relative url for images
        const url = new URI(rawUrl);

        if (url.is('absolute') || !this._baseUrl) {
            return rawUrl;
        }

        // for old kmzs (that were unzipped before being uploaded),
        // images are stored in a relative url to the base kml file
        let base = new URI(this._baseUrl);

        if (base.search(true).AWSAccessKeyId != null) {
            // remove AWS signing key, since this will be invalid for any nested URL on AWS and cause
            // a validation error
            base = base.removeQuery('AWSAccessKey').removeQuery('Expires').removeQuery('Signature');
        }

        // replace the "doc.xml" filename with the URL Path
        base = base.filename(rawUrl);

        return base.href();
    }

    /**
     * Copies a key/value from src to dst if it exists. Otherwise do nothing.
     */
    static _copyIfExists(src, dst, srcKey, dstKey = srcKey) {
        if (srcKey in src) {
            dst[dstKey] = src[srcKey];
        }
    }

    static _getElementsByTagName(kml, name) {
        /*
         * We rely on the DOM Node's "getElementsByTagName" to search for elements with the desired tag.
         * However, we then want to convert them to a friendly JSON object for further inspection. So, run the search
         * method, then turn the HTML of the found nodes into objects.
         *
         * (Due to a parser quirk, the returned objects contain a root object with the element tag name as its only
         * property - so, we navigate down from that to get the actual results.)
         */
        return Array.from(kml.getElementsByTagName(name))
            .map(node => xmlParser.parse(node.outerHTML)[name]);
    }
}
