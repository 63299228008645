import { $filter, $timeout } from 'helioscope/app/utilities/ng';
import invariant from 'invariant';

const identity = x => x;

/**
 * parent directive to link form elements into the statehandler tying model
 * changes t the undo states
 */
export function stateForm() {
    'ngInject';
    return {
        scope: { stateHandler: '=stateForm' },
        controller: function stateFormCtrl($scope) {
            'ngInject';
            this.markPropertyChange = (...args) => $scope.stateHandler.markPropertyChange(...args);
            this.disabled = $scope.stateHandler.disabled;
        },
    };
}


/**
 * directive beneath state form link individual form controls to the the parent
 * stateHandler.  Watches objects based on ngModel and adds a callback to all
 * changelisteners
 */
export function stateModel() {
    'ngInject';

    return {
        require: ['^stateForm', 'ngModel'],
        link: (scope, element, attrs, [persistenceCtrl, ngModelCtrl]) => {
            if (persistenceCtrl.disabled) {
                element.attr('disabled', 'disabled');
            }

            const config = scope.$eval(attrs.stateModel) || {};
            const filter = config.filter ? $filter(config.filter) : identity;

            const ngModelString = attrs.ngModel;

            // get the resourceName from config or the first portion of the ngModelString
            const resourceName = config.resourceName || ngModelString.split('.')[0];

            // remove both the resourceName and the following '.'
            const path = ngModelString.slice(resourceName.length + 1);

            // resourceName could be a path
            const parentObject = _.get(scope, resourceName);
            invariant(parentObject, `cannot find ${resourceName}`);
            let lastValue = _.get(parentObject, path);
            // updates the lastValue on undo action
            const updateLastValue = (change) => {
                lastValue = change.oldVal;
            };

            ngModelCtrl.$viewChangeListeners.push(() => {
                // create an delta, whcih will trigger the right callbacks downstream
                const newVal = ngModelCtrl.$modelValue;
                const delta = {
                    resource: parentObject,
                    filter,
                    loadMessage: config.loadMessage,
                    undoCallback: updateLastValue,
                    rollbackMessage: config.rollbackMessage,
                    name: config.name,
                    changes: [{ path, newVal, oldVal: lastValue }],
                };

                // if the model is invalid, don't persist the changes but still add the delta
                if (ngModelCtrl.$invalid === true) {
                    Object.assign(delta, { persistChanges: false });
                }

                persistenceCtrl.markPropertyChange(delta);
                lastValue = ngModelCtrl.$modelValue;
            });
        },
    };
}

/**
 * directive to give UI control to the stateHandler functionscreates a single
 * undo menu that is populated based on which child button is pressed
 *
 * add an 'undo' or 'redo' attribute to child buttons to hook them into
 * this directive
 */
export function undoButtons() {
    return {
        scope: { stateHandler: '=undoButtons' },
        templateUrl: require('helioscope/app/designer/persistence/partials/directives.undo.html'),
        transclude: true,
        controller: function undoButtonsCtrl($scope) {
            'ngInject';
            const stateHandler = $scope.stateHandler;

            $scope.dropdown = {
                show: false,
                mode: 'undo',
                highlight: -1,
            };

            $scope.getStack = () => {
                if ($scope.dropdown.mode === 'undo') {
                    return stateHandler.undoStack().slice(0, 10).map(delta => delta.undoMessage());
                }
                return stateHandler.redoStack().slice(0, 10).map(delta => delta.redoMessage());
            };

            this.undo = () => {
                stateHandler.undo();
                $scope.dropdown.mode = 'undo';
            };

            this.redo = () => {
                stateHandler.redo();
                $scope.dropdown.mode = 'redo';
            };

            this.showMenu = (mode) => {
                $scope.dropdown = {
                    show: true,
                    mode,
                    highlight: -1,
                };
            };

            this.$scope = $scope;

            $scope.shiftState = (index) => (
                stateHandler.shiftState(($scope.dropdown.mode === 'undo' ? -1 : 1) * (index + 1))
            );

            $scope.canUndo = () => stateHandler.canUndo();
            $scope.canRedo = () => stateHandler.canRedo();

            $scope.hideMenu = () => (
                ((!stateHandler.canUndo() && $scope.dropdown.mode === 'undo') ||
                 (!stateHandler.canRedo() && $scope.dropdown.mode === 'redo'))
            );
        },
    };
}

/**
 * factory to create directive definitions undo and redo buttons the key
 * behavior defined is the ability to click a button and hold to trigger the
 * dropdown, or to click the button for a single action
 */
function makeStateDirective(type) {
    const TOGGLE_DELAY = 500; // how long to wait until showing the menu

    return function stateDirective() {
        return {
            require: '^undoButtons',
            link: (scope, element, attrs, undoCtrl) => {
                element.bind('mousedown', (mousedownEvt) => {
                    mousedownEvt.preventDefault();
                    mousedownEvt.stopPropagation();

                    let isToggle = false;
                    const cancel = $timeout(() => {
                        isToggle = true;
                        undoCtrl.showMenu(type);

                        element.unbind('mouseup');
                    }, TOGGLE_DELAY);

                    element.bind('mouseup', (mouseupEvt) => {
                        mouseupEvt.preventDefault();
                        mouseupEvt.stopPropagation();

                        $timeout.cancel(cancel);
                        if (!isToggle) {
                            undoCtrl[type]();
                        }
                        element.unbind('mouseup');
                        scope.$apply();
                    });
                });

                undoCtrl.$scope.$watch(`can${_.capitalize(type)}()`, (val) => {
                    if (val) {
                        element.removeAttr('disabled');
                    } else {
                        element.attr('disabled', 'disabled');
                    }
                });
            },
        };
    };
}

export const undo = makeStateDirective('undo');
export const redo = makeStateDirective('redo');


export function saveButton() {
    return {
        scope: { updateQueue: '=saveButton', locked: '=', renderUpdater: '=' },
        templateUrl: require('helioscope/app/designer/persistence/partials/status.html'),
        transclude: true,
        controller: function controller($scope) {
            'ngInject';
            const updateQueue = $scope.updateQueue;

            $scope.isDisabled = () => (
                $scope.locked || ((updateQueue.size === 0 || updateQueue.saving) && !updateQueue.errors)
            );

            $scope.errorString = () => [...this.resourcesWithErrors].join(', ');

            $scope.queueStatus = () => {
                if (updateQueue.errors > 0) {
                    return 'errors';
                } else if (updateQueue.saving) {
                    return 'saving';
                } else if (updateQueue.size > 0) {
                    return 'dirty';
                } else if ($scope.locked) {
                    return 'locked';
                }

                return 'saved';
            };

            $scope.saveAndUpdate = () => {
                $scope.updateQueue.flush();
                $scope.renderUpdater.updateArray(); // should be temporary until we improve designer performance
            };

            $scope.tooltip = () => {
                if ($scope.locked) {
                    return 'Design is Locked';
                } else if (updateQueue.errors > 0) {
                    const errorString = [...updateQueue.resourcesWithErrors].join(', ');
                    return (
                        `We had a problem saving some of items (${errorString}). ` +
                        'Click to try again, or refresh your browser'
                    );
                }

                return 'Save your design';
            };
        },
    };
}
