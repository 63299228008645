/*globals angular:true, _:true, google:true */
import * as config from 'helioscope/app/config';

const SATELLITE_SOURCE_WEATHER_RANGE = 10000;

(function (angular, _) {
    'use strict';

    var mod = angular.module('helioscope.projects.services.scenarios',
                                ['helioscope.services', 'ui',
                                 'helioscope.libraries.resources']);

    mod.controller('ScenarioCtrl', ['$scope', '$modalInstance', 'Messager', 'scenario', 'SketchupModel', 'Horizon', '$log', 'ModuleCharacterization', 'PowerDeviceCharacterization', 'ContextualHelp', '$rootScope',
                                     function ($scope, $modalInstance, Messager, scenario, SketchupModel, Horizon, $log, ModuleCharacterization, PowerDeviceCharacterization, ContextualHelp, $rootScope) {
            $modalInstance.opened.then(function () { $scope.isOpen = true; });

            $scope.cancel = function () {
                $modalInstance.close();
            };

            $scope.shortMonthNames = config.shortMonths;
            $scope.scenario = scenario;

            $scope.data = {
                sketchupModels: SketchupModel.query({project_id: scenario.project_id}),
                horizons: Horizon.query({project_id: scenario.project_id}),
                module_characterizations: ModuleCharacterization.query({project_id: scenario.project_id}),
                device_characterizations: PowerDeviceCharacterization.query({project_id: scenario.project_id}),
            };

            var activeTab = "";
            $scope.setTab = function (tab) {
                activeTab = tab;
            };

            $scope.showHelp = function () {
                ContextualHelp.showHelpModal(`helioscope/app/projects/partials/scenarios/new.${activeTab}.help.html`);
            };

            $scope.showSingleAxisTrackers = () => $rootScope.user().hasSingleAxisTrackersAccess();

            $scope.save = function (scenario) {
                $scope.isLoading = true;

                var isEditing = (scenario.scenario_id !== undefined),
                    notify = Messager.load((isEditing ? "Updating" : "Creating new") + " Condition Set"),
                    verb = (isEditing ? "updated" : "created"),
                    method = (isEditing ? '$update' : '$save');

                scenario[method](function success() {
                    notify.success("Condition Set " + verb);
                    $scope.isLoading = false;

                    if (verb === 'updated') {
                        // if update remove and related simulations

                        scenario.simulations.forEach((sim) => {
                            sim.$deregister();

                            if ($scope.projectData.simulations) {
                                var index = $scope.projectData.simulations.indexOf(sim);
                                $scope.projectData.simulations.splice(index, 1);
                            }
                        });
                    } else {
                        // otherwise add to the project cache;
                        $scope.projectData.scenarios.push(scenario);
                    }

                    $modalInstance.dismiss();
                }, function failure(resp) {
                    notify.error("Condition Set could not be " + verb);
                    $log.warn(resp);
                    $scope.isLoading = false;
                    $scope.errors = resp.data;
                });
            };

        }]);

    mod.controller('ScenarioWeatherCtrl', ['$scope', 'WeatherSource', '$q', 'WeatherDataset',
                   function ($scope, WeatherSource, $q, WeatherDataset) {

            $scope.myMarkers = [];
            var scenario = $scope.scenario,
                projectLocation = scenario.project.location,
                currentWeatherMarker,
                weatherReady = $q.defer(),
                mapReady = $q.defer(),
                mapWatcher = $scope.$watch('data.myMap', function (map) {
                    if (map) {
                        mapReady.resolve(map);
                        mapWatcher(); //kill the watch as soon as the map is initialized
                    }
                });

            function getWeatherSource(weatherDatasetId) {
                var dataset = WeatherDataset.cached(weatherDatasetId);
                return _.find($scope.data.weather_sources,
                              function (ws) { return ws.weather_source_id === dataset.weather_source_id; });
            }

            function createWeatherMarker(weatherDatasetId) {
                if (weatherDatasetId) {
                    var selected = getWeatherSource(weatherDatasetId);
                    if (!selected) {
                        return;
                    }

                    if (currentWeatherMarker === undefined) {
                        currentWeatherMarker = new google.maps.Marker({
                            map: $scope.data.myMap,
                            icon: 'https://maps.gstatic.com/mapfiles/ms2/micons/flag.png',
                            position: selected.location.googleLatLng(),
                            zIndex: 999
                        });
                    } else {
                        currentWeatherMarker.setPosition(selected.location.googleLatLng());
                    }
                }
            }

            function initialSource(sources, team) {
                // default to using the preferred weather source type, if available.
                const preferredSourceType = team.preferred_weather_source_type || 'prospector';
                if (preferredSourceType) {
                    const preferredRadius = team.preferred_weather_source_radius || SATELLITE_SOURCE_WEATHER_RANGE;
                    const preferredSources = sources.filter(
                        w => w.source_type === preferredSourceType && w.distance <= preferredRadius,
                    );
                    if (preferredSources.length > 0) {
                        sources = preferredSources;
                    }
                }

                return _.sortBy(sources, 'distance')[0].weather_source_id;
            }

            $scope.weatherDatasetSelections = {};

            $scope.data.weather_sources = WeatherSource.query({project_id: scenario.project_id,
                                                               load_datasets: true},
                function (ws) {
                    angular.forEach(ws, function (w) {
                        w.distance = w.location.distance(projectLocation);
                        var datasets = w.weather_datasets,
                            ds = _.find(datasets, function (wd) { return wd.name === 'TMY'; }) || datasets[0];

                        $scope.weatherDatasetSelections[w.weather_source_id] = ds.weather_dataset_id;

                    });

                    if (scenario.weather_dataset_id !== undefined) {
                        $scope.weatherDatasetSelections[scenario.weather_dataset.weather_source_id] = scenario.weather_dataset_id;
                    }

                    weatherReady.resolve(ws);
                });

            $q.all([weatherReady.promise, mapReady.promise]).then(function (promises) {
                // Once the map is loaded, and the weather source is loaded draw all the locations
                var ws = promises[0],
                    bounds = new google.maps.LatLngBounds();
                bounds.extend(projectLocation.googleLatLng());

                $scope.data.weather_sources = ws;

                $scope.projectMarker = new google.maps.Marker({
                    map: $scope.data.myMap,
                    position: projectLocation.googleLatLng(),
                });

                if (ws.length > 0 && scenario.weather_dataset_id === undefined) {
                    $scope.scenario.weather_dataset_id =
                        $scope.weatherDatasetSelections[initialSource(ws, scenario.project.team)];
                }

                angular.forEach(ws, function (w) {
                    bounds.extend(w.location.googleLatLng());
                    $scope.myMarkers.push(new google.maps.Marker({
                        map: $scope.data.myMap,
                        position: w.location.googleLatLng(),
                        icon: 'https://maps.gstatic.com/mapfiles/ms2/micons/sunny.png'
                    }));
                });

                $scope.data.myMap.fitBounds(bounds);
                createWeatherMarker($scope.scenario.weather_dataset_id);
            });

            $scope.mapOptions = {
                center: projectLocation.googleLatLng(),
                zoom: 19,
                mapTypeId: google.maps.MapTypeId.HYBRID,
                disableDoubleClickZoom: true,
                draggableCursor: 'default',
                tilt: 0,
                panControl: false,
                zoomControl: true,
                scaleControl: true,
                streetViewControl: false,
                clickableIcons: false,
            };

            $scope.$watch('scenario.weather_dataset_id', createWeatherMarker);

            $scope.selectWeather = function (weatherIndex) {
                $scope.scenario.weather_dataset_id = $scope.weatherDatasetSelections[$scope.data.weather_sources[weatherIndex].weather_source_id];
            };

            // $scope.create15MinWeather = function (weatherSourceId) {
            //     var notify = Messager.load("Creating 15 Minute Weather File"),
            //         weatherSource = getWeatherSource(weatherDatasetId);
            //     $scope.weatherInterpolating = true;
            //     WeatherSource.interpolate({original_weather_source_id: weatherSourceId},
            //         function success(newWeatherSource) {
            //             $scope.data.weather_sources.push(newWeatherSource);
            //             $scope.scenario.weather_source_id = newWeatherSource.weather_source_id;
            //             $scope.weatherInterpolating = false;
            //             newWeatherSource.distance = weatherSource.distance;
            //             notify.success("Created 15 minute weather data");
            //         }, function fail(resp) {
            //             $log.error(resp);
            //             $scope.weatherInterpolating = false;

            //             notify.error("Could not create 15 minute weather");
            //         });
            // };

        }]);

    mod.factory('DiffuseCellTempDlg', ['$modal', function ($modal) {
        return function (parameters) {
            var opts = {
                windowClass: 'temp-modal',
                templateUrl: require('helioscope/app/projects/partials/scenarios/new.temperature.diffuse.html'),
                controller: 'DiffuseParameterCtrl',
                resolve: {'parameters': function () { return parameters; }}
            };

            return $modal.open(opts);
        };
    }]);


    function mergeTemperatureDefaults(tempParameters, defaultParameters) {
        const fullParameters = angular.copy(tempParameters);

        for (const defaults of defaultParameters) {
            if (!_.find(fullParameters, param => param.rack_type === defaults.rack_type)) {
                fullParameters.push(angular.copy(defaults));
            }
        }

        return fullParameters;
    }

    mod.controller('DiffuseParameterCtrl', ['$scope', '$modalInstance', 'parameters', 'DefaultScenarioParameters',
                                     function ($scope, $modalInstance, parameters, DefaultScenarioParameters) {

            $scope.cancel = function cancel() {
                $modalInstance.close();
            };

            $scope.undo = function undo() {
                $scope.newParameters = angular.copy(parameters);
            };

            $scope.restoreDefaults = function restoreDefaults() {
                $scope.newParameters = angular.copy(DefaultScenarioParameters.temperature_parameters.diffuse);
            };

            $scope.save = function save() {
                $modalInstance.close($scope.newParameters);
            };

            $scope.newParameters = mergeTemperatureDefaults(parameters, DefaultScenarioParameters.temperature_parameters.diffuse);
        }]);

    mod.factory('SandiaCellTempDlg', ['$modal', function ($modal) {
        return function (parameters) {
            var opts = {
                windowClass: 'temp-modal',
                templateUrl: require('helioscope/app/projects/partials/scenarios/new.temperature.sandia.html'),
                controller: 'SandiaParameterCtrl',
                resolve: {'parameters': function () { return parameters; }}
            };

            return $modal.open(opts);
        };
    }]);

    mod.controller('SandiaParameterCtrl', ['$scope', '$modalInstance', 'parameters', 'DefaultScenarioParameters',
                                     function ($scope, $modalInstance, parameters, DefaultScenarioParameters) {
            $scope.cancel = function cancel() {
                $modalInstance.close();
            };

            $scope.undo = function undo() {
                $scope.newParameters = angular.copy(parameters);
            };

            $scope.restoreDefaults = function restoreDefaults() {
                $scope.newParameters = angular.copy(DefaultScenarioParameters.temperature_parameters.sandia);
            };

            $scope.save = function save() {
                $modalInstance.close($scope.newParameters);
            };

            $scope.newParameters = mergeTemperatureDefaults(parameters, DefaultScenarioParameters.temperature_parameters.sandia);
        }]);

    mod.controller('ScenarioComponentCtrl', ['$scope', function ($scope) {
        // the selection object in the UI needs to be a map, so can't link directly to the object,
        // instead create an object for selections that maps module/device_ids to the selected
        // characterizations
        $scope.selection = $scope.selection || {};

        // watch this object for changes, and change the underlying scenario
        $scope.$watch('selection.module_characterizations', function (module_characterizations) {
            $scope.scenario.module_characterizations =
                _.map(module_characterizations, function (mc) {
                    return { module_characterization_id: mc.module_characterization_id };
                });
        }, true);

        $scope.$watch('selection.device_characterizations', function (device_characterizations) {
            $scope.scenario.power_device_characterizations =
                _.map(device_characterizations, function (dc) {
                    return { power_device_characterization_id: dc.power_device_characterization_id };
                });
        }, true);

        var prevModuleCharacterizations = angular.copy($scope.scenario.module_characterizations || {}),
            prevDeviceCharacterizations = angular.copy($scope.scenario.power_device_characterizations || {}),

            mod_watch = $scope.$watch('data.module_characterizations', function (mc) {
                if (mc.$resolved) {
                    $scope.modules = _.groupBy($scope.data.module_characterizations, 'module_id');

                    $scope.selection.module_characterizations = {};
                    angular.forEach($scope.modules, function (characterizations, module_id) {
                        $scope.selection.module_characterizations[module_id] = _.find(characterizations, function (c) {
                            return c.module_characterization_id === (
                                (prevModuleCharacterizations[module_id] && prevModuleCharacterizations[module_id].module_characterization_id)
                                || c.module.user_default_characterization_id
                                || c.module.default_characterization_id
                            );
                        });
                    });

                    mod_watch();
                }
            }, true),
            dev_watch = $scope.$watch('data.device_characterizations', function (dc) {
                if (dc.$resolved) {
                    $scope.power_devices = _.groupBy($scope.data.device_characterizations, 'power_device_id');

                    $scope.selection.device_characterizations = {};
                    angular.forEach($scope.power_devices, function (characterizations, power_device_id) {
                        $scope.selection.device_characterizations[power_device_id] = _.find(characterizations, function (c) {
                            return c.power_device_characterization_id === (
                                (prevDeviceCharacterizations[power_device_id] && prevDeviceCharacterizations[power_device_id].power_device_characterization_id)
                                || c.power_device.user_default_characterization_id
                                || c.power_device.default_characterization_id
                            );
                        });

                    });

                    dev_watch();
                }
            }, true);

    }]);
}(angular, _));
