/* global angular:true */
import { fromPairs } from 'lodash';
import 'checklist-model';

import * as config from 'helioscope/app/config';
import { user } from 'helioscope/app/users';

import { RelationalBase, relationship } from 'helioscope/app/relational';
import { Messager, $log, $q, $state, $timeout, $filter, OptimizerLibrary, $rootScope } from 'helioscope/app/utilities/ng';
import * as analytics from 'helioscope/app/utilities/analytics';
import { Module, PowerDevice, Wire, AcConfig, FinancialTemplate, Incentive } from 'helioscope/app/libraries/resources';


export class Profile extends RelationalBase {
    static relationName = 'Profile';

    loadDependencies(requestArgs) { // pass request args to allow permissions propagation from project access
        const promises = [];
        const canViewFinancials = user.hasFinancialsAccess();
        const profileTypes = ['data', 'electrical', 'mechanical', 'scenario'];

        if(canViewFinancials) {
            profileTypes.push('financial');
        }

        for (const data of profileTypes) {
            if (this[data]) {
                promises.push(this[data].loadDependencies(requestArgs));
            }
        }

        return $q.all(promises).then(() => this);
    }
}

Profile.configureRelationships({
    creator: relationship('User', { id: 'creator_id' }),
    data: (rawProfileData) => new ProfileData(rawProfileData),

    // this is the way defaults are returned from the server
    electrical: (data) => new ProfileData(data),
    mechanical: (data) => new ProfileData(data),
    scenario: (data) => new ProfileData(data),
    financial: (data) => new ProfileData(data),
});

Profile.createEndpoint(
    '/api/profiles/:profile_id',
    { profile_id: '@profile_id' },
    {
        update: { method: 'PUT', isArray: false, params: { email: '@email' } },
        defaults: { method: 'GET', url: '/api/profiles/defaults/', cache: true },
    },
);



export class ProfileData extends RelationalBase {
    static relationName = 'ProfileData';

    constructor(rawData) {
        super(rawData);

        // the shadow caster flag has been incorrectly set on old profiles because of a javascript
        // validation bug.  This should never be a property that should be set on profiles in general.
        delete this.shadow_caster;
    }

    // could be nice to incorporate something like this into the relational library
    loadDependencies(requestArgs) { // pass request args to allow permissions propagation from project access
        const promises = [];
        const canViewFinancials = user.hasFinancialsAccess();

        if (this.module_id && !this.module) {
            promises.push(Module.get({ module_id: this.module_id, ...requestArgs }).$promise);
        }
        if (this.inverter_id && !this.inverter) {
            promises.push(PowerDevice.get({ power_device_id: this.inverter_id, ...requestArgs }).$promise);
        }
        if (this.power_optimizer_id && !this.power_optimizer) {
            promises.push(PowerDevice.get({ power_device_id: this.power_optimizer_id, ...requestArgs }).$promise);
        }

        if (this.financial_template_id && !this.financial_template && canViewFinancials) {
            promises.push(FinancialTemplate.get({
                financial_template_id: this.financial_template_id,
                ...requestArgs,
            }).$promise);
        }

        return $q.all(promises).then(() => this);
    }
}

ProfileData.configureRelationships({
    // mechanical profile
    module: relationship(Module),

    // electrical profile
    inverter: relationship(PowerDevice, { id: 'inverter_id' }),
    power_optimizer: relationship(PowerDevice, { id: 'power_optimizer_id' }),

    // financial profile
    financial_template: relationship(FinancialTemplate, { id: 'financial_template_id' }),
});

const dropdownTypes = {
    rack_type: ['rack', 'flush', 'dual', 'carport', 'single_axis'],
    tilt_strategy: ['fixed', 'latitude', 'simple_optimization'],
    orientation: ['horizontal', 'vertical'],
    row_spacing_strategy: ['fixed', 'gcr', 'span_to_rise', 'shading'],
    cell_temp_model: ['sandia', 'diffuse'],
    transposition_model: ['hay', 'perez'],
    stringing_strategy: ['along', 'updown'],
};


const PROFILE_TYPES = ['project', 'mechanical', 'electrical', 'scenario', 'financial'];
export const TEMPLATES = fromPairs(PROFILE_TYPES.map(type =>(
    [type, require('helioscope/app/users/partials/profiles/' + type + '.html')]
)));

class ProfilesCtrl {
    constructor(selectedUser, profiles) {
        'ngInject';

        this.profiles = profiles;
        this.selectedUser = selectedUser;
        this.profileTypes = ['project', 'mechanical', 'electrical', 'conditions'];
        this.hasFinancialsAccess = this.selectedUser.hasFinancialsAccess();

        if (this.hasFinancialsAccess) {
            this.profileTypes.push('financial');
        }

        this.savedVal = selectedUser.default_profile_id;
    }

    updateDefaultProfile() {
        this.selectedUser.$update()
            .then((user) => {
                Messager.success('Successfully updated your default profile');
                analytics.track(
                    'profile.user_default_profile_change',
                    {
                        old_profile_id: this.savedVal,
                        new_profile_id: user.default_profile_id,
                    },
                );
                this.savedVal = user.default_profile_id;
            })
            .catch((err) => {
                Messager.error('Error updating your profile');
                this.selectedUser.default_profile_id = this.savedVal;
                $log.warn(err);
            });
    }

    saveProfile(profile) {
        const newProfile = profile.profile_id === undefined;
        const method = newProfile ? '$save' : '$update';

        return profile[method]({ email: this.selectedUser.email })
            .then((savedProfile) => {
                Messager.success('Saved profile Successfully');

                if (newProfile) {
                    this.profiles.push(savedProfile);
                    $state.go('account.detail.profiles.list.detail', savedProfile);
                }
            })
            .catch((err) => {
                Messager.error('Could not save profile');
                $log.warn(err);
                return $q.reject(err.data);
            });
    }

    deleteProfile(profile, event) {
        event.preventDefault();
        event.stopPropagation();

        if ($state.includes('account.detail.profiles.list.detail')) {
            $state.go('account.detail.profiles.list',
                      { email: this.selectedUser.email, ui_profile_type: profile.type });
        }

        return profile.$delete({ email: this.selectedUser.email })
            .then(() => {
                const idx = this.profiles.indexOf(profile);
                if (idx !== -1) {
                    this.profiles.splice(idx, 1);
                }
                Messager.success('Successfully deleted profile');
                analytics.track(
                    'profile.delete',
                    { profile_id: profile.profile_id, profile_type: profile.type },
                );
            })
            .catch((err) => {
                Messager.error('Error deleting profile');
                $log.warn(err);
            });
    }
}

class ProfileListCtrl {
    constructor(profileType) {
        'ngInject';

        this.profileType = profileType;
    }
}

class ProfileDetailCtrl {
    constructor(profile, formTemplateUrl, $scope) {
        'ngInject';

        this.profile = profile;
        this.formTemplateUrl = formTemplateUrl;

        this.hsTypeFilter = $filter('hsType');
        this.spacingStrategyFilter = $filter('spacingStrategy');
        const rack_types = $rootScope.user().hasSingleAxisTrackersAccess() ? ['rack', 'flush', 'dual', 'carport', 'single_axis'] : ['rack', 'flush', 'dual', 'carport'];
        dropdownTypes.rack_type = rack_types;
        this.dropdownTypes = dropdownTypes;

        if (profile.profile_id === undefined) {
            // this is a hack to make sure the dom is ready for this controller
            $timeout(() => { $scope.form.$show(); });
        }
    }

    getProfile(profileId) {
        return Profile.cached(profileId);
    }

    trackCancel(profile) {
        analytics.track(
            'profile.cancel_edit',
            { profile_id: profile.profile_id, profile_type: profile.type },
        );
    }
}

class ProfileEditCtrl {
    constructor($scope, profileType, defaults) {
        'ngInject';

        const { form } = $scope;
        $scope.$on('$destroy', () => {
            form.$cancel();
        });

        form.$show();

        $scope.$watch('form.$visible', (newVal) => {
            if (newVal === false) {
                $state.go('^');
            }
        });

        // when editing a profile, bring in any defaults, in case the profile
        // was partial/incomplete
        $scope.profile.data = angular.extend({},
                                             defaults[profileType],
                                             $scope.profile.data || {});

        analytics.track('profile.edit', { profile_id: $scope.profile.profile_id, profile_type: profileType });
    }
}

class ProjectProfileCtrl {
    constructor($scope) {
        'ngInject';

        this.$scope = $scope;
        this.filter = $filter('filter');
        this.nullProfile = {
            profile_id: null,
            name: '- Select a Profile -',
        };
        this.hasFinancialsAccess = this.$scope.user().hasFinancialsAccess();
    }

    profileList(type) {
        return [this.nullProfile].concat(this.filter(this.$scope.profilesCtrl.profiles, type));
    }
}

class ElectricalProfileCtrl {
    constructor($scope) {
        'ngInject';

        this.$scope = $scope;

        const nullDevice = {
            power_device_id: null,
            toString: () => 'None',
        };

        const nullAcConfig = {
            ac_config_id: null,
            toString: () => 'None',
        };


        // TODO: better handling of AC Configurations for inverters with known output
        // ideally should update the inverter AC output in the form when the inverter
        // changes
        // this.matchInverterAcConfig = function () {
        //     var inverter = ctrl.inverter;
        //     if (inverter && inverter.ac_config_id) {
        //         $scope.profile.data.inverter_ac_config_id = inverter.ac_config_id;
        //     }
        // };

        // $scope.$watch('profile.data.inverter_id', function (newVal) {
        //     if (newVal) {
        //         ctrl.matchInverterAcConfig();
        //     }
        // });

        Wire.query().$promise.then((wires) => {
            const nullWire = {
                wire_gauge_id: null,
                toString: () => 'None',
            };
            this.wireLibrary = [nullWire].concat(wires);
        });

        OptimizerLibrary().then((optimizers) => { // eslint-disable-line new-cap
            this.optimizerLibrary = [nullDevice].concat(optimizers);
        });

        AcConfig.query().$promise.then((acConfigs) => {
            this.acConfigLibrary = [nullAcConfig].concat(acConfigs);
        });

        if ($scope.profile.data.inverter_id) {
            // ensure the that the device for this profile is loaded
            PowerDevice.get({ power_device_id: $scope.profile.data.inverter_id });
        }
    }

    getWireName(wireId) {
        const wire = Wire.cached(wireId);
        return wire ? wire.toString() : 'None';
    }

    getAcConfigName(acConfigId) {
        const acConfig = AcConfig.cached(acConfigId);
        return acConfig ? acConfig.toString() : 'None';
    }

}

class MechanicalProfileDateCtrl {
    constructor($scope) {
        'ngInject';

        this.$scope = $scope;

        this.timeOffsetInHours = MechanicalProfileDateCtrl.stdTimezoneOffset(new Date()) / 60 * (-1);

        // todo: refactor this to use moment.js
        this.shadeDate = new Date($scope.profile.data.row_spacing_start_time || '2016-12-21T12:00:00Z');
        this.startTime = new Date($scope.profile.data.row_spacing_start_time || '2016-12-21T10:00:00Z');
        this.endTime = new Date($scope.profile.data.row_spacing_end_time || '2016-12-21T14:00:00Z');

        this.startTime.setHours(this.startTime.getHours() - this.timeOffsetInHours);
        this.endTime.setHours(this.endTime.getHours() - this.timeOffsetInHours);

        this.datePickerOptions = {
            'show-weeks': false,
        };

        $scope.$watchCollection('[mechDates.shadeDate, mechDates.startTime, mechDates.endTime]',
                                () => this.updateUserTimePrefs());
        this.updateUserTimePrefs();
    }

    open($event) {
        $event.preventDefault();
        $event.stopPropagation();

        this.$scope.opened = true;
    }

    updateUserTimePrefs() {
        const $scope = this.$scope;

        // update the user times from the preference spinner
        $scope.profile.data.row_spacing_start_time = new Date(this.shadeDate);
        $scope.profile.data.row_spacing_start_time.setHours(this.startTime.getHours() + this.timeOffsetInHours);
        $scope.profile.data.row_spacing_start_time.setMinutes(this.startTime.getMinutes());

        $scope.profile.data.row_spacing_end_time = new Date(this.shadeDate);
        $scope.profile.data.row_spacing_end_time.setHours(this.endTime.getHours() + this.timeOffsetInHours);
        $scope.profile.data.row_spacing_end_time.setMinutes(this.endTime.getMinutes());
    }

    static stdTimezoneOffset(date) {
        const jan = new Date(date.getFullYear(), 0, 1);
        const jul = new Date(date.getFullYear(), 6, 1);
        return Math.max(jan.getTimezoneOffset(), jul.getTimezoneOffset());
    }
}

class ScenarioProfileCtrl {
    constructor() {
        this.shortMonthNames = config.shortMonths;
    }
}

class FinancialProfileCtrl {
    constructor($scope) {
        'ngInject';

        this.$scope = $scope;

        FinancialTemplate.query().$promise.then((templates) => {
            const nullTemplate = {
                financial_template_id: null,
                toString: () => 'None',
            };
            this.finTemplateLibrary = [nullTemplate].concat(templates);
        });

        Incentive.query().$promise.then((allIncentives) => {
            this.incentiveLibrary = allIncentives;
        });
    }

    incentives(incentiveIds) {
        const rtn = [];
        if (this.incentiveLibrary != null) {
            for (const id of incentiveIds) {
                const incentive = this.incentiveLibrary.find(inc => inc.incentive_id === id);
                if (incentive != null) {
                    rtn.push(incentive);
                }
            }
        }
        return rtn;
    }
}

const mod = angular.module('helioscope.users.profiles', ['ui', 'xeditable', 'xeditable.extensions', 'checklist-model']);

mod.factory('Profile', () => Profile);
mod.controller('ProfilesCtrl', ProfilesCtrl);
mod.controller('ProfileListCtrl', ProfileListCtrl);
mod.controller('ProfileDetailCtrl', ProfileDetailCtrl);
mod.controller('ProfileEditCtrl', ProfileEditCtrl);
mod.controller('ProjectProfileCtrl', ProjectProfileCtrl);
mod.controller('ElectricalProfileCtrl', ElectricalProfileCtrl);

mod.controller('MechanicalProfileDateCtrl', MechanicalProfileDateCtrl);
mod.controller('ScenarioProfileCtrl', ScenarioProfileCtrl);
mod.controller('FinancialProfileCtrl', FinancialProfileCtrl);

mod.factory('profileFilter', () => (val) => val === 'conditions' ? 'scenario' : val);
