import moment from 'moment';

const mod = angular.module('helioscope.admin.extensions', ['helioscope.users.auth']);

mod.controller('ExtensionsListCtrl', ['$scope', 'User', function ($scope, User) {
    $scope.loadOutstandingExtensions = () => {
        $scope.loaded = true;

        User.trialExtensions((users) => {
            $scope.users = _(users)
                .map((u) => {
                    u.trial_extensions = _(u.trial_extensions).sortBy((te) => te.created).reverse().value();
                    return u;
                })
                .sortBy((u) => _.head(u.trial_extensions).created)
                .reverse() // reverse to put the user with the newest trial extension at top
                .value();
        });
    };

}]);


mod.controller('ExtensionsUserSearchCtrl', ['$scope', 'User', function ($scope, User) {
    $scope.getSearchUser = (userId) => {
        $scope.searchUser = User.cached(userId);
    };
}]);

mod.directive('extendUserForm', ['$log', 'Authenticator', 'Messager', function ($log, Authenticator, Messager) {
    return {
        restrict: 'EA',
        scope: { user: '=', simple: '@' },
        templateUrl: require('helioscope/app/admin/partials/extensions.directives.extend-user.html'),
        controller: ['$scope', function ($scope) {
            $scope.days = 14;

            $scope.updateUser = (user, rawDays) => {
                const notify = Messager.load('Updating user...');

                let days;

                if (rawDays === -1) {
                    // cancel users account
                    days = $scope.calculateDelta(moment()) - 2;
                } else {
                    days = rawDays;
                }

                user.$extendTrial(
                    { reason: `Trial adjusted by admin: ${Authenticator.user().email}`, days },
                    () => {
                        notify.success(`User trial changed, trial wil expire ${user.current_period_end.fromNow()}`);
                        user.trial_extensions = _.sortBy(user.trial_extensions, (te) => te.created).reverse();
                    },
                    (response) => {
                        notify.error("User's trial could not be changed");
                        $log.log("User's could not be extended.", response);
                    }
                );
            };

            $scope.open = function ($event) {
                $event.preventDefault();
                $event.stopPropagation();

                $scope.opened = true;
            };

            $scope.datepickerOptions = {
                'show-weeks': false,
            };

            $scope.calculateDelta = function (newDate) {
                const dt = moment(newDate);

                let compareDate = moment();
                if ($scope.user.current_period_end.diff(compareDate) > 0) {
                    // if the user hasn't expired yet, baseline to that day instead of now
                    compareDate = $scope.user.current_period_end;
                }
                return dt.diff(compareDate, 'days') + 1; // moment rounds towards zero, so pad with one
            };
        }],
    };
}]);
