import { flatten } from 'helioscope/app/utilities/helpers';

import { Vector } from './vector';
import { pointInPolygon } from './clipper';
import * as THREE from 'three';
import { toRadians } from './math';

export function signedArea(path) {
    const length = path.length;
    let area = 0;
    let lastPoint = path[length - 1];

    for (let i = 0; i < length; i += 1) {
        const point = path[i];
        area += (lastPoint.x * point.y - point.x * lastPoint.y);
        lastPoint = point;
    }

    return area / 2;
}

export function makeEarcutPath(path) {
    const out = [];
    for (const pt of path) {
        out.push(pt.x);
        out.push(pt.y);
    }

    return out;
}

export function makeEarcutPathsWithHoles(paths) {
    const signed = _.map(paths, path => ({ path, area: signedArea(path) }));
    _.range(0, signed.length).forEach(i => { signed[i].index = i; });
    const sorted = _.sortBy(signed, i => i.area);

    const earcutInputs = [];

    for (const i of sorted) {
        if (i.area > 0 && i.path.length) {
            const vertices = [];
            const holeIndices = [];
            const originalIndices = [];

            let k1 = 0;
            for (const pt of i.path) {
                vertices.push(pt.x);
                vertices.push(pt.y);
                vertices.push(pt.z || 0);

                originalIndices.push([i.index, k1]);
                k1++;
            }

            for (const j of sorted) {
                if (j.area >= 0) break;
                if (!j.included && j.path.length) {
                    if (!pathContains(i.path, j.path)) {
                        continue;
                    }

                    j.included = true;
                    holeIndices.push(vertices.length / 3);

                    let k2 = 0;
                    for (const pt of j.path) {
                        vertices.push(pt.x);
                        vertices.push(pt.y);
                        vertices.push(pt.z || 0);

                        originalIndices.push([j.index, k2]);
                        k2++;
                    }
                }
            }

            earcutInputs.push({ vertices, holes: holeIndices, originalIndices });
        }
    }

    return earcutInputs;
}

/**
 * return the orientation of a path of vectors
 * returns True if the signed area is non-negative (the points are counter clockwise)
 */
export function pathOrientation(path) {
    return signedArea(path) >= 0;
}

/**
 * correct the orientation of a path of vectors
 */
export function correctOrientation(path) {
    return pathOrientation(path) ? path : _.reverse(path);
}

export function rotatedGridVector(source, dest, angle) {
    const gridOffsets = dest.subtract(source);
    const rotatedOffsets = gridOffsets.rotate(-angle);

    return {
        midpoint: source.add(new Vector(rotatedOffsets.x, 0).rotate(angle)),
        distance: Math.abs(rotatedOffsets.x) + Math.abs(rotatedOffsets.y),
    };
}


export function dedupePath(path, tolerance = 1e-5) {
    const [firstPoint, ...rest] = path;

    let lastPoint = firstPoint;

    const rtn = [lastPoint];

    for (const pt of rest) {
        if (!lastPoint.approx(pt, tolerance)) {
            rtn.push(pt);
        }

        lastPoint = pt;
    }

    if (firstPoint.approx(lastPoint)) {
        rtn.pop();
    }

    return rtn;
}


export function sanitizePath(path, dedupeTolerance = 1e-5) {
    return correctOrientation(dedupePath(path, dedupeTolerance));
}


/**
 * test for two paths either intersecting or one being on top of the other by checking if any segments intersect
 * and then checking if either of the shapes is fully enclosed
 *
 * only works for simple polygons, note, does not take into account collinearity
 */
export function pathIntersects(path1, path2, fullOverlap = false) {
    if (lineSegmentsIntersect(path1, path2)) {
        return true;
    }

    if (fullOverlap) {
        if (pointInPolygon(path1[0], path2)) {
            return true;
        } else if (pointInPolygon(path2[0], path1)) {
            return true;
        }
    }

    return false;
}

export function pathContains(outerPath, innerPath) {
    if (lineSegmentsIntersect(outerPath, innerPath)) {
        return false;
    }

    return pointInPolygon(innerPath[0], outerPath);
}

export function pathContainsMulti(outerPath, innerPaths) {
    for (const innerPath of innerPaths) {
        if (!pathContains(outerPath, innerPath)) {
            return false;
        }
    }

    return true;
}

/**
 * test if two paths have any line segments that cross
 */
export function lineSegmentsIntersect(path1, path2) {
    let p1 = path1[path1.length - 1];

    for (let i = 0; i < path1.length; i++) {
        const p2 = path1[i];

        let q1 = path2[path2.length - 1];
        for (let j = 0; j < path2.length; j++) {
            const q2 = path2[j];

            if (segmentIntersects(p1, p2, q1, q2)) {
                return true;
            }

            q1 = q2;
        }

        p1 = p2;
    }

    return false;
}

function isCCW(p1, p2, p3) {
    return (p3.y - p1.y) * (p2.x - p1.x) > (p2.y - p1.y) * (p3.x - p1.x);
}

/**
 * check if two line segments intersect
 */
function segmentIntersects(p1, p2, p3, p4) {
    return (isCCW(p1, p3, p4) !== isCCW(p2, p3, p4)) && (isCCW(p1, p2, p3) !== isCCW(p1, p2, p4));
}

/**
 * optimized version of:
   return _(path)
        .flatten()
        .map(vec => vec.transform(matrix))
        .map('y')
        .min();
 */
export function calcMinY(matrix, path) {
    const m10 = matrix.get(1, 0);
    const m11 = matrix.get(1, 1);
    const m12 = matrix.get(1, 2);
    const m13 = matrix.get(1, 3);

    const pathFlattened = flatten(path);
    let minY = Number.MAX_VALUE;

    for (let i = 0; i < pathFlattened.length; i++) {
        const vec = pathFlattened[i];
        // this is optimized version of: vec.transform(matrix).y
        // also, instead of adding m13 to each y, we only add it once
        // to the final result
        const y = vec.x * m10 + vec.y * m11 + vec.z * m12;
        if (y < minY) {
            minY = y;
        }
    }

    return minY + m13;
}

export function generateCirclePath(center, radius) {
    let points = [];
    let centerVector = new THREE.Vector2(center.x, center.y);
    let radiusVector = new THREE.Vector2(center.x + radius, center.y);
    for (let i = 0; i < 360; i += 10) {
        let angle = toRadians(i);
        let rotated = radiusVector.clone().rotateAround(centerVector, angle);
        points.push(new Vector(rotated.x, rotated.y, 0));
    }
    return points;
}

export function rotatePath(path, center, angleDegrees) {
    const newPath = [];
    const angle = toRadians(angleDegrees);
    const centerVector = new THREE.Vector2(center.x, center.y);
    path.forEach((point) => {
        const pointVector = new THREE.Vector2(point.x, point.y);
        const rotated = pointVector.clone().rotateAround(centerVector, angle);
        newPath.push(new Vector(rotated.x, rotated.y, 0));
    });
    return newPath;
}
