import _ from 'lodash';
import numeric from 'numeric';
import * as THREE from 'three';
import seedrandom from 'seedrandom';

import { arrayPermute } from 'helioscope/app/utilities/helpers';
import { Vector } from './vector';
import { clampRadians, toRadians, toDegrees } from './math';

export function argmin(args) {
    let min = args[0];
    let idx = 0;
    for (let i = 1; i < args.length; ++i) {
        if (args[i] < min) {
            min = args[i];
            idx = i;
        }
    }

    return idx;
}

class LineEstimator {
    constructor(tol) {
        this.tol = tol;
        this.tolsq = this.tol * this.tol;
    }

    fit2(pt1, pt2) {
        this.origin = (new THREE.Vector2()).copy(pt1);

        const delta = (new THREE.Vector2()).subVectors(pt2, pt1);
        this.norm = (new THREE.Vector2(delta.y, -delta.x)).normalize();
        if (this.norm.y < 0) this.norm.multiplyScalar(-1);
    }

    fitInliers(pts) {
        const inlierPts = _.filter(pts, pt => this.inlier(pt));

        const center = avgPts2(inlierPts);
        const mtxA = _.map(inlierPts, pt => [pt.x - center.x, pt.y - center.y]);
        const mtxAt = numeric.transpose(mtxA);
        const mtxAtA = numeric.dot(mtxAt, mtxA);
        const { lambda, E } = numeric.eig(mtxAtA);

        this.origin = center;

        const minEig = argmin(_.map(lambda.x, i => i * i));
        this.norm = new THREE.Vector2(
            E.x[0][minEig],
            E.x[1][minEig],
            );

        if (this.norm.y < 0) this.norm.multiplyScalar(-1);
    }

    inlierCnt(pts) {
        return _.sumBy(pts, pt => (this.inlier(pt) ? 1 : 0));
    }

    inlier(pt) {
        const delta = (new THREE.Vector2()).subVectors(pt, this.origin);
        const dot = this.norm.dot(delta);
        return (dot * dot < this.tolsq);
    }

    tiltDegrees() {
        return toDegrees(clampRadians(Math.atan2(-this.norm.x, this.norm.y)));
    }

    height(x) {
        const slope = -this.norm.x / this.norm.y;
        const dx = x - this.origin.x;
        return this.origin.y + slope * dx;
    }
}

class PlaneEstimator {
    constructor(tol) {
        this.tol = tol;
        this.tolsq = this.tol * this.tol;
    }

    fit3(pt1, pt2, pt3) {
        const norm = (new THREE.Vector3()).crossVectors(
            (new THREE.Vector3()).subVectors(pt2, pt1),
            (new THREE.Vector3()).subVectors(pt3, pt1))
            .normalize();

        if (norm.z < 0) norm.multiplyScalar(-1);

        this.origin = (new THREE.Vector3()).copy(pt1);
        this.norm = norm;
    }

    fitInliers(pts) {
        const inlierPts = _.filter(pts, pt => this.inlier(pt));

        const center = avgPts3(inlierPts);
        const mtxA = _.map(inlierPts, pt => [pt.x - center.x, pt.y - center.y, pt.z - center.z]);
        const mtxAt = numeric.transpose(mtxA);
        const mtxAtA = numeric.dot(mtxAt, mtxA);
        const { lambda, E } = numeric.eig(mtxAtA);

        this.origin = center;

        const minEig = argmin(_.map(lambda.x, i => i * i));
        this.norm = new THREE.Vector3(
            E.x[0][minEig],
            E.x[1][minEig],
            E.x[2][minEig],
            );

        if (this.norm.z < 0) this.norm.multiplyScalar(-1);
    }

    fitHeight(pt) {
        this.origin = (new THREE.Vector3()).copy(pt);
        this.norm = new THREE.Vector3(0, 0, 1);
    }

    fitHeightInliers(pts) {
        const inlierPts = _.filter(pts, pt => this.inlier(pt));

        const center = avgPts3(inlierPts);

        this.origin = (new THREE.Vector3()).copy(center);
        this.norm = new THREE.Vector3(0, 0, 1);
    }

    getInliers(pts) {
        return _.filter(pts, pt => this.inlier(pt));
    }

    inlierCnt(pts) {
        return _.sumBy(pts, pt => (this.inlier(pt) ? 1 : 0));
    }

    inlier(pt) {
        const delta = (new THREE.Vector3()).subVectors(pt, this.origin);
        const dot = this.norm.dot(delta);
        return (dot * dot < this.tolsq);
    }

    errorSSD(pts) {
        let ssd = 0;
        for (const pt of pts) {
            const delta = (new THREE.Vector3()).subVectors(pt, this.origin);
            const dot = this.norm.dot(delta);
            ssd += dot * dot;
        }

        return ssd;
    }

    tiltDegrees() {
        const horz = (new THREE.Vector3(this.norm.x, this.norm.y, 0)).length();
        return toDegrees(clampRadians(Math.atan2(horz, this.norm.z)));
    }

    azimuthDegrees() {
        return toDegrees(clampRadians(Math.atan2(this.norm.x, this.norm.y)));
    }
}

function avgPts2(pts) {
    const center = new THREE.Vector2(
        _.sumBy(pts, pt => pt.x) / pts.length,
        _.sumBy(pts, pt => pt.y) / pts.length,
        );
    return center;
}

function avgPts3(pts) {
    const center = new THREE.Vector3(
        _.sumBy(pts, pt => pt.x) / pts.length,
        _.sumBy(pts, pt => pt.y) / pts.length,
        _.sumBy(pts, pt => pt.z) / pts.length,
        );
    return center;
}

function ransac(pts, ransacFn, refineFn = (est) => est, ransacIter, minPts) {
    if (pts.length < minPts) return null;

    const ptIdx = _.range(pts.length);

    let bestEst = null;
    let maxInliers = 0;
    for (let i = 0; i < ransacIter; ++i) {
        const { inliers, estimate } = ransacFn(ptIdx, pts);
        if (inliers > maxInliers) {
            maxInliers = inliers;
            bestEst = estimate;
        }
    }

    if (bestEst && maxInliers >= minPts) {
        return refineFn(bestEst, pts);
    }

    return null;
}

function ransacLineEstimate(data, ransacIter = 250, minPts = 10, tol = 0.125) {
    const rng = seedrandom('seed');

    const ransacFn = (ptIdx, pts) => {
        arrayPermute(ptIdx, 2, rng);
        const estimate = new LineEstimator(tol);
        estimate.fit2(pts[ptIdx[0]], pts[ptIdx[1]]);
        const inliers = estimate.inlierCnt(pts);
        return { inliers, estimate };
    };

    const refineFn = (estimate, pts) => {
        estimate.fitInliers(pts);
        return estimate;
    };

    return ransac(data, ransacFn, refineFn, ransacIter, minPts);
}

export function fitPhysicalSurfaceHeightTiltToPoints(ps, allPts) {
    if (!allPts || !allPts.length) return {};

    const fitPts = _.filter(allPts, pt => ps.containsPoint(pt));

    const azimuthDir = new Vector(
        -Math.sin(toRadians(ps.surfaceAzimuth())),
        -Math.cos(toRadians(ps.surfaceAzimuth())), 0);
    const fitPts2 = _.map(fitPts, pt => new THREE.Vector2(azimuthDir.dot(pt), pt.z));

    const bestEst = ransacLineEstimate(fitPts2);
    if (!bestEst) return {};

    let tilt = bestEst.tiltDegrees();

    const baseHeight = ps.referenceBottomHeight();
    const fitHeight = bestEst.height(azimuthDir.dot(ps.referencePoint()));
    const referenceHeight = Math.max(0, fitHeight - baseHeight);

    // it's possible that we found a fit but azimuth is backwards
    // do not change azimuth
    if (tilt >= 90) tilt = 0;
    return { tilt, referenceHeight };
}

export function fitPhysicalSurfaceTiltToPoints(ps, allPts) {
    if (!allPts || !allPts.length) return null;

    const fitPts = _.filter(allPts, pt => ps.containsPoint(pt));

    const azimuthDir = new Vector(
        -Math.sin(toRadians(ps.surfaceAzimuth())),
        -Math.cos(toRadians(ps.surfaceAzimuth())), 0);
    const fitPts2 = _.map(fitPts, pt => new THREE.Vector2(azimuthDir.dot(pt), pt.z));

    const bestEst = ransacLineEstimate(fitPts2);
    if (!bestEst) return null;

    const tilt = bestEst.tiltDegrees();

    // it's possible that we found a fit but azimuth is backwards
    // do not change azimuth
    if (tilt >= 90) return 0;
    return tilt;
}

export function fitPhysicalSurfaceHeightToPoints(ps, allPts) {
    const minPts = 10;
    const ransacIter = 250;
    const tol = 0.125;

    if (!allPts || !allPts.length || allPts.length < minPts) return null;

    const fitPts = _.filter(allPts, pt => ps.containsPoint(pt));

    const rng = seedrandom('seed');

    const ransacFn = (ptIdx, pts) => {
        arrayPermute(ptIdx, 1, rng);
        const estimate = new PlaneEstimator(tol);
        estimate.fitHeight(pts[ptIdx[0]]);
        const inliers = estimate.inlierCnt(pts);
        return { inliers, estimate };
    };

    const refineFn = (estimate, pts) => {
        estimate.fitHeightInliers(pts);
        return estimate;
    };

    const bestEst = ransac(fitPts, ransacFn, refineFn, ransacIter, minPts);

    if (!bestEst) return null;

    const estHeight = bestEst.origin.z;
    const baseHeight = ps.referenceBottomHeight();
    const referenceHeight = Math.max(0, estHeight - baseHeight);
    return referenceHeight;
}

export function fitPhysicalSurfaceToPoints(ps, allPts) {
    const minPts = 10;
    const ransacIter = 250;
    const tol = 0.125;

    if (!allPts || !allPts.length || allPts.length < minPts) return {};

    const fitPts = _.filter(allPts, pt => ps.containsPoint(pt));

    const rng = seedrandom('seed');

    const ransacFn = (ptIdx, pts) => {
        arrayPermute(ptIdx, 3, rng);
        const estimate = new PlaneEstimator(tol);
        estimate.fit3(pts[ptIdx[0]], pts[ptIdx[1]], pts[ptIdx[2]]);
        const inliers = estimate.inlierCnt(pts);
        return { inliers, estimate };
    };

    const refineFn = (estimate, pts) => {
        estimate.fitInliers(pts);
        return estimate;
    };

    const bestEst = ransac(fitPts, ransacFn, refineFn, ransacIter, minPts);

    if (!bestEst) return {};

    const tilt = bestEst.tiltDegrees();
    const azimuth = bestEst.azimuthDegrees();

    // need to compute referencePoint() with new azimuth
    const psState = _.assign({}, ps);

    if (ps.independentTiltEnabled) {
        ps.independent_tilt_surface_tilt = tilt;
        ps.independent_tilt_surface_azimuth = azimuth;
    } else {
        ps.tilt = tilt;
        ps.azimuth = azimuth;
    }

    const baseHeight = ps.referenceBottomHeight();
    const newRefPt = getPoint3D(
        Vector.fromObject(bestEst.origin),
        tilt,
        azimuth,
        ps.referencePoint(),
    );

    _.assign(ps, psState);

    const referenceHeight = Math.max(0, newRefPt.z - baseHeight);
    return { tilt, azimuth, referenceHeight };
}


export function fitPhysicalSurfaceHeightToHighestInlierPoint(ps, allPts) {
    const minPts = 10;
    const ransacIter = 250;
    const tol = 0.125;

    if (!allPts || !allPts.length || allPts.length < minPts) return null;

    const fitPts = _.filter(allPts, pt => ps.containsPoint(pt));

    const rng = seedrandom('seed');

    const ransacFn = (ptIdx, pts) => {
        arrayPermute(ptIdx, 3, rng);
        const estimate = new PlaneEstimator(tol);
        estimate.fit3(pts[ptIdx[0]], pts[ptIdx[1]], pts[ptIdx[2]]);
        const inliers = estimate.inlierCnt(pts);
        return { inliers, estimate };
    };

    const refineFn = (estimate, pts) => {
        const inliers = estimate.getInliers(pts);
        const max = _.maxBy(inliers, pt => pt.z);
        return max.z;
    };

    let bestEst = ransac(fitPts, ransacFn, refineFn, ransacIter, minPts);

    if (bestEst === null) {
        if (fitPts && fitPts.length) {
            // fallback to just use max inside point
            // common problem with 1. small data set (small ko) and 2. awkward data (trees)
            const maxpt = _.maxBy(fitPts, pt => pt.z);
            bestEst = maxpt.z;
        } else {
            return null;
        }
    }

    const baseHeight = ps.referenceBottomHeight();
    const referenceHeight = Math.max(0, bestEst - baseHeight);
    return referenceHeight;
}

export function findLowestInlierZ(allPts) {
    const minPts = 10;
    const ransacIter = 250;
    const tol = 0.125;

    if (!allPts || !allPts.length || allPts.length < minPts) return {};

    const fitPts = allPts;

    const rng = seedrandom('seed');

    const ransacFn = (ptIdx, pts) => {
        arrayPermute(ptIdx, 3, rng);
        const estimate = new PlaneEstimator(tol);
        estimate.fit3(pts[ptIdx[0]], pts[ptIdx[1]], pts[ptIdx[2]]);
        const inliers = estimate.inlierCnt(pts);
        return { inliers, estimate };
    };

    const refineFn = (estimate, pts) => {
        const inliers = estimate.getInliers(pts);
        const max = _.minBy(inliers, pt => pt.z);
        return max.z;
    };

    const bestEst = ransac(fitPts, ransacFn, refineFn, ransacIter, minPts);

    if (bestEst === null) return null;

    return bestEst;
}


function getPoint3D(origin, tilt, azimuth, pt) {
    const tiltSlope = Math.tan(toRadians(tilt));

    const delta = pt.subtract(origin);
    delta.z = 0;

    const azimuthDir = new Vector(-Math.sin(toRadians(azimuth)), -Math.cos(toRadians(azimuth)), 0);
    const azimuthDist = delta.dot(azimuthDir);
    const pt3 = origin.z + azimuthDist * tiltSlope;
    return new Vector(pt.x, pt.y, pt3);
}
