/* globals window:true */

import Logger from 'js-logger';
import lodash from 'lodash';

import { setConfig } from 'reports/angular-bridge.ts';

export const helioscopeConfig = window.globalParameters || {};
setConfig(helioscopeConfig);

export const shortMonths = [
    'jan', 'feb', 'mar', 'apr', 'may', 'jun',
    'jul', 'aug', 'sep', 'oct', 'nov', 'dec',
];

angular.module('helioscope.config', [])
    .constant('helioscopeConfig', helioscopeConfig)
    .constant('shortMonthNames', shortMonths);


function configureZip() {
    let canCompress = false;

    if (window.Worker) {
        canCompress = true;
    }

    return { canCompress };
}

function configureLogging() {
    let level = Logger.INFO;
    if (helioscopeConfig.debug || lodash.get(helioscopeConfig, 'user.is_admin', false)) {
        level = Logger.DEBUG;
    }

    Logger.useDefaults({
        defaultLevel: level,
    });
}

export const zipConfig = configureZip();

configureLogging();
