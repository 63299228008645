import { ngRefs as ng } from 'helioscope/app/utilities/ng';


export class PubSub {
    static ANGULAR_DIGEST_DEBOUNCE = 50; // getting multiple similar events can cause excessive
                                         // digest runs, so make sure they're grouped somewhat

    constructor(runDigest = true) {
        this.channels = {};
        this.runDigest = runDigest;
    }

    getChannel(actionType) {
        let channel = this.channels[actionType];

        if (channel === undefined) {
            channel = this.channels[actionType] = [];
        }

        return channel;
    }

    debouncedDigest = _.debounce(() => {
        ng.$rootScope.$apply();
    }, PubSub.ANGULAR_DIGEST_DEBOUNCE);

    /**
     * subscribe to a action and return an unsubscribe function
     * @param  {String}   action
     * @param  {Function} callback  callback on events
     * @return {Function}           unsubsribe function
     */
    subscribe(actionType, callback) {
        const channel = this.getChannel(actionType);
        channel.push(callback);
        return () => { // unsubscriber
            const idx = channel.indexOf(callback);
            if (idx !== -1) {
                channel.splice(idx, 1);
            }
        };
    }

    subscribeOnce(actionType, callback) {
        const unsubscribe = this.subscribe(actionType, (...args) => {
            callback(...args);
            unsubscribe();
        });
    }

    publish(actionType, context, digest = true) {
        for (const listener of this.getChannel(actionType)) {
            listener(this, context);
        }

        if (digest && this.runDigest && !ng.$rootScope.$$phase) {
            this.debouncedDigest();
        }
    }
}
