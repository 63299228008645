import * as Q from 'q';
import { RelationalBase } from 'helioscope/app/relational';
import { $http } from 'helioscope/app/utilities/ng';
import logger from 'js-logger';

export class S3File extends RelationalBase {
    static relationName = 'File';

    static requestUploadUrl(filename, size) {
        return $http.post('/api/files/request_upload', { filename, size });
    }

    /**
     * put a fileItem (froom angular uploader) on S3 and return the full metadata object for creating a file
     * (can save the file directly by calling fileItemData.$save() or pass it to another object)
     */
    static async createS3FileFromFileItem(fileItem, getMeta = (_fileItem) => ({})) {
        const resp = await S3File.requestUploadUrl(fileItem.file.name, fileItem.file.size);
        await putFileItemToS3(fileItem, resp.data.put_url, resp.data.content_type);


        let meta = {};
        try {
            meta = await getMeta(fileItem);
        } catch (err) {
            logger.warn(err);
        }

        const s3File = new S3File({
            seed: resp.data.seed,
            filename: fileItem.file.name,
            name: fileItem.file.name,
            size: fileItem.file.size,
            meta,
        });

        return s3File;
    }
}

export function putFileItemToS3(fileItem, putUrl, contentType) {
    fileItem.url = putUrl;
    fileItem.method = 'PUT';
    fileItem.headers['Content-Type'] = contentType;

    const deferred = Q.defer();
    fileItem.onSuccess = () => {
        deferred.resolve(fileItem);
    };

    fileItem.onError = (err) => {
        deferred.reject(err);
    };

    fileItem.upload();

    return deferred.promise;
}


S3File.createEndpoint(
    '/api/files/:file_id',
    { file_id: '@file_id' },
    {
        update: { method: 'PUT', isArray: false },
    },
);
