
import { user } from 'helioscope/app/users';

import {
    FixedTiltEngineV2,
    NewFlushMountEngine,
    NewDualTiltEngine,
    NewSingleAxisTrackerEngine,
} from './racking_v2';

import {
    FixedTiltEngineV3,
} from './racking_v3';

export { moduleCache } from './caching';

export { DEFAULT_CHARACTERIZATION } from './common';

export {
    Module,
    RackingFrame,
    RackingStructure,
} from './racking';

export { LayoutRegion } from './racking_v2';

export const LAYOUT_ENGINES = () => ({
    rack: user && user.hasFeature('enable_fixed_tilt_v3') ? FixedTiltEngineV3 : FixedTiltEngineV2,
    flush: NewFlushMountEngine,
    dual: NewDualTiltEngine,
    carport: NewFlushMountEngine,
    single_axis: NewSingleAxisTrackerEngine,
});
