/* global angular:true, google:true */

import { getInfoBoxConstructor } from './infobox';
import { $q, $document } from 'helioscope/app/utilities/ng';
import {
    loadTemplate, getBody, compileBoundElement, KEY,
} from 'helioscope/app/utilities/helpers';

function placeElement(element, map, offset, latLng) {
    const mapPosition = map.getDiv().getBoundingClientRect();
    const xyPosition = map.fromLatLngToContainerPixel(latLng);

    // const offset = (offset || new google.maps.Point(0, 0));
    element.css({
        left: `${(xyPosition.x + offset.x + mapPosition.left)}px`,
        top: `${(xyPosition.y + offset.y + mapPosition.top)}px`,
    });
}

function createMapElement(options) {
    const element = angular.element('<div class="map-overlay-container">');
    const { location, map, classes, offset = new google.maps.Point(0, 0) } = options;

    element.addClass(classes);

    if (options.html !== undefined) {
        element.html(options.html);
        compileBoundElement(element, options);
    } else {
        loadTemplate(options.templateUrl).then((html) => {
            element.html(html);
            compileBoundElement(element, options);
        });
    }

    getBody().append(element);

    placeElement(element, map, offset, location);

    return {
        element,
        updateLocation(newLoc) {
            placeElement(element, map, offset, newLoc);
        },
    };
}


// track all context menus globally
const contextMenuClosers = [];
function closeContextMenus() {
    contextMenuClosers.forEach(close => close());
    contextMenuClosers.length = 0;
}

let isFieldSegmentContextMenuEnabled = true;

export function disableFieldSegmentContextMenu() {
    isFieldSegmentContextMenuEnabled = false;
}

export function enableFieldSegmentContextMenu() {
    isFieldSegmentContextMenuEnabled = true;
}

export function isFieldSegmentContextMenuDisabled() {
    return !isFieldSegmentContextMenuEnabled;
}

/**
 * return a function that creates a context menu when passed a click event and optional scope variables
 */
export function createContextMenu(map, options) {
    const defaultLocals = options.locals;

    return function activateContextMenu(clickEvent, scopeVars) {
        closeContextMenus();

        // gross hack so that we can disable context menu on field segments,
        // which we want in keepouts view so that we can use right-click
        if (defaultLocals.fieldSegment && !isFieldSegmentContextMenuEnabled) {
            return;
        }

        const { element } = createMapElement(angular.extend({}, options,
            {
                map,
                location: clickEvent.latLng,
                locals: angular.extend({ clickEvent, location: map.toXY(clickEvent.latLng) }, defaultLocals, scopeVars),
                offset: new google.maps.Point(-18, -14),
            }));

        google.maps.event.addListenerOnce(map, 'rightclick', closeContextMenus);
        google.maps.event.addListenerOnce(map, 'dragstart', closeContextMenus);

        element.on('click.contextMenu', closeContextMenus);
        $document.on('click.contextMenu', closeContextMenus);
        $document.on('rightclick.contextMenu', closeContextMenus);
        $document.on('keydown.contextMenu', evt => {
            if (evt.keyCode === KEY.ESC) {
                closeContextMenus(evt);
            }
        });

        contextMenuClosers.push(() => {
            element.unbind('click.contextMenu');
            $document.unbind('click.contextMenu');
            $document.unbind('rightclick.contextMenu');
            $document.unbind('keydown.contextMenu');
            element.remove();
        });
    };
}


function getMapPosition(map, evt) {
    const rect = map.getDiv().getBoundingClientRect();
    return map.fromContainerPixelToLatLng(new google.maps.Point(evt.clientX - rect.left, evt.clientY - rect.top));
}

export function getBoundInfoBox(opts) {
    const InfoBox = getInfoBoxConstructor();
    const { element, $scope } = compileBoundElement(angular.element(opts.content));

    const infobox = new InfoBox(angular.extend({}, opts, { content: element[0] }));

    infobox.$scope = $scope;

    // this was not a smart way to handle notifications, since they are being universally removed
    // from promises
    const eventBusDeferred = $q.defer();
    infobox.eventBus = eventBusDeferred.promise;


    if (opts.contextMenu) {
        google.maps.event.addDomListener(element[0], 'contextmenu', (event) => {
            event.preventDefault();
            event.stopPropagation();

            event.latLng = getMapPosition(infobox.map, event);

            eventBusDeferred.notify({
                event,
                name: 'context_menu',
                scopeVars: {
                    distance: infobox.$scope.distance,
                    heading: infobox.$scope.heading,
                },
            });

            return false;
        });
    }

    infobox.$update = (scopeVars) => {
        angular.extend(infobox.$scope, scopeVars);
        if (!infobox.$scope.$$phase) {
            infobox.$scope.$apply();
        }
    };

    infobox.$update(opts.scopeVars);

    return infobox;
}
