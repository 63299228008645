import { MapConfig } from '../designer/MapConfig';
import { Design3DRenderer } from './Design3DRenderer';

const mod = angular.module('helioscope.apollo.directives', []);

const persistState = {};

mod.directive('apollo', () => ({
    restrict: 'EA',
    scope: {
        dispatcher: '=',
        canvasSettings: '=',
    },
    templateUrl: require('helioscope/app/apollo/partials/apollo.html'),
    link: ($scope, element, attributes) => {
        const options = {
            enableEdgeLabels: attributes.enableEdgeLabels === 'true',
            enableSurfaceLabels: attributes.enableSurfaceLabels === 'true',
            enableLidar: attributes.enableLidar === 'true',
            enableInteractionTools: attributes.enableInteractionTools === 'true',
            renderConfig: MapConfig,
        };

        const persistCamera = attributes.persistCamera === 'true';

        const renderer = new Design3DRenderer($scope.dispatcher, options);

        $scope.webglUnsupported = () => renderer.initFailed;

        const initRenderer = async (teamId, designId) => {
            const container = element[0].querySelector('#apolloContainer');

            renderer.initRenderer(container, teamId, designId, $scope.canvasSettings);

            if (persistCamera && persistState.cameraState && designId === persistState.lastDesignID) {
                renderer.restoreCameraState(persistState.cameraState);
            } else {
                renderer.fitProjectView();
            }

            await renderer.onReady();
            $scope.dispatcher.registerRenderer(renderer);
        };

        $scope.$on('$destroy', () => {
            if (persistCamera) {
                persistState.cameraState = renderer.saveCameraState();
                persistState.lastDesignID = $scope.dispatcher.design.design_id;
            }
            // Renderer is cleaned up via the Dispatcher.cleanup() method
            renderer.shutdownRenderer();
        });

        initRenderer($scope.dispatcher.team_id, $scope.dispatcher.design.design_id);
    },
}));
